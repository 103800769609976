import React, { useState } from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import {
  formblattText,
  nachweisText,
  verfahrensanweisungText,
} from "../../../../utils/helperTexts";
import DocumentSelect from "../../../../components/filehandling/DocumentSelect";

function SelectDocumentType({ defaultDocType, onSubmit }) {
  const [helpText, setHelpText] = useState(null);

  const handleDocumentSelected = (docType) => {
    onSubmit(docType);
  };

  const handleShowText = (text) => {
    if (helpText === text) {
      // User clicks on same button again => hide info
      setHelpText(null);
      return null;
    }
    if (!text) {
      // Hide text
      setHelpText(null);
    } else {
      setHelpText(text);
    }
  };

  const showText = (docType) => {
    let text = "";
    switch (docType) {
      case "verfahrensanweisung":
        text = verfahrensanweisungText();
        break;
      case "formblatt":
        text = formblattText();
        break;
      case "nachweis":
        text = nachweisText();
        break;

      default:
        break;
    }
    return text;
  };

  return (
    <div>
      <div>
        <Typography variant="h4">
          Möchten Sie eine
          <Tooltip
            title="Klicken Sie hier, um mehr Informationen zu erhalten."
            placement="bottom"
          >
            <Button
              onClick={() => handleShowText("verfahrensanweisung")}
              sx={{ fontSize: "110%", paddingTop: 0, margin: "0 10px" }}
              variant=""
              endIcon={
                <Icon icon="material-symbols:info" width="24" height="24" />
              }
            >
              Verfahrensanweisung,
            </Button>
          </Tooltip>
          ein
          <Tooltip
            title="Klicken Sie hier, um mehr Informationen zu erhalten."
            placement="bottom"
          >
            <Button
              onClick={() => handleShowText("formblatt")}
              variant=""
              sx={{ fontSize: "110%", paddingTop: 0, margin: "0 10px" }}
              endIcon={
                <Icon icon="material-symbols:info" width="24" height="24" />
              }
            >
              Formblatt,
            </Button>
          </Tooltip>
          oder einen
          <Tooltip
            title="Klicken Sie hier, um mehr Informationen zu erhalten."
            placement="bottom"
          >
            <Button
              onClick={() => handleShowText("nachweis")}
              variant=""
              sx={{ fontSize: "110%", paddingTop: 0, margin: "0 10px" }}
              endIcon={
                <Icon icon="material-symbols:info" width="24" height="24" />
              }
            >
              Nachweis
            </Button>
          </Tooltip>
          hochladen?
        </Typography>
      </div>
      <div>
        {helpText && (
          <div>
            <hr />
            {showText(helpText)}
            <Button
              onClick={() => handleShowText(false)}
              fullWidth
              variant="contained"
              startIcon={<Icon icon="mdi:arrow-up" width="24" height="24" />}
              endIcon={<Icon icon="mdi:arrow-up" width="24" height="24" />}
            >
              EINKLAPPEN
            </Button>
            <hr />
          </div>
        )}
      </div>
      <div style={{ marginTop: 10, display: "flex" }}>
        <Typography sx={{ pt: 2, pr: 5 }} variant="h5">
          Bitte Dokumenttyp wählen:
        </Typography>
        <DocumentSelect
          handleDocumentSelected={handleDocumentSelected}
          defaultDocType={defaultDocType}
        />
      </div>
    </div>
  );
}

SelectDocumentType.propTypes = {
  onSubmit: PropTypes.func,
  defaultDocType: PropTypes.string,
};

export default SelectDocumentType;
