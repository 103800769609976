import axios from "axios";
// import { store } from "../../store";

var SERVER_URL = "/api";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  SERVER_URL = "http://localhost:5001/api";
} else {
  // production code
  SERVER_URL = "/api";
}

export const serverURL = () => {
  let url = "/api";

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    url = "http://localhost:5001/api";
  } else {
    // production code
    url = "/api";
  }
  return url;
};

export const httpPublic = axios.create({
  baseURL: `${SERVER_URL}/public`,
  timeout: 50000,
  headers: {
    "Content-type": "application/json",
  },
});

export const httpCommonAuth = axios.create({
  baseURL: SERVER_URL,
  withCredentials: true,
  timeout: 50000,
  headers: {
    "Content-type": "application/json",
  },
});

httpCommonAuth.interceptors.request.use(
  (request) => {
    // Works fine
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// // Add a request interceptor
// httpCommonAuth.interceptors.request.use(
//   (config) => {
//     const state = store.getState();
//     const token = state.auth.token;
//     if (token) {
//       config.headers["Authorization"] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );
