import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import TextField from "../../../components/forms/TextField";
import MailTextField from "../MailTextField";

function OfficeForm({ control }) {
  return (
    <div>
      <Stack spacing={2} pt={1}>
        <TextField
          name="officeName"
          label="Praxisname"
          required={true}
          errorMsg="Bitte angeben"
          control={control}
        />
        <TextField
          name="contactPerson"
          label="Ansprechpartner"
          errorMsg="Bitte angeben"
          control={control}
        />
        <MailTextField control={control} />
        <TextField
          name="phone"
          label="Rufnummer"
          errorMsg="Bitte angeben"
          control={control}
        />
        <TextField
          name="homepage"
          label="Website"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Stack>
      <Stack spacing={2} pt={3}>
        <TextField
          name="address"
          label="Straße, Hausnummer"
          errorMsg="Bitte angeben"
          control={control}
        />
        <TextField
          name="zip"
          label="PLZ"
          errorMsg="Bitte angeben"
          control={control}
        />
        <TextField
          name="city"
          label="Stadt"
          errorMsg="Bitte angeben"
          control={control}
        />

        <TextField
          name="comment"
          label="Kommentar"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Stack>
    </div>
  );
}

OfficeForm.propTypes = { props: PropTypes.object };

export default OfficeForm;
