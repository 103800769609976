import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  CardHeader,
  CardContent,
  Box,
  Button,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

const StyledCard = styled(Card)(({ theme }) => ({
  height: 465, // Gleiche Höhe wie die anderen Karten
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between", // Verteilung zwischen Inhalt und Button
  cursor: "pointer",
}));

// ----------------------------------------------------------------------

export default function AppSupport() {
  const navigate = useNavigate();

  return (
    <StyledCard onClick={() => navigate("/dashboard/support")}>
      <CardHeader
        title="Support"
        subheader="Helfen Sie uns, die Software zu verbessern!"
        titleTypographyProps={{ variant: "h6", fontWeight: "bold" }}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          flexGrow: 1, // Damit der Inhalt den Platz füllt
        }}
      >
        <Iconify
          icon="fluent:person-feedback-20-filled"
          width={110}
          height={110}
        />

        <Typography variant="h6" sx={{ mt: 3 }}>
          Ihre Meinung ist uns wichtig! Besuchen Sie unsere Support-Seite und
          geben Sie uns Ihr Feedback, um die Software weiter an Ihre Bedürfnisse
          anzupassen.
        </Typography>
        <Box sx={{ textAlign: "center", mt: 5 }}>
          <Button variant="contained" color="primary">
            Jetzt Feedback geben
          </Button>
        </Box>
      </CardContent>
    </StyledCard>
  );
}
