import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setDialog } from "../../features/dialog/dialogSlice";
import ErrorAlert from "../alerts/ErrorAlert";
import CreateFileDataForm from "../forms/Forms/CreateFileDataForm/CreateFileDataForm";
import { useAddNewFileDataMutation } from "../../features/fileData/fileDataApiSlice";
import { useGetFileStructureQuery } from "../../features/files/filesApiSlice";

function FileDataDialog({ dialog, data, handleClose }) {
  const { type } = dialog;
  const { handleSubmit, control, watch, setError } = useForm({
    defaultValues: {
      fileName: "",
      description: "",
      responsibleUser: "",
      fileFormat: "hochformat",
      type: "FB",
      recallAmount: "1",
      recallPeriod: "years",
    },
  });

  const { data: fileStructure } = useGetFileStructureQuery();

  const revisionChecked = watch("revision");
  const selectedFolder = watch("selectedFolder");

  const [addNewFileData, { isError }] = useAddNewFileDataMutation();

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    console.log("data :>> ", data);
    let fileData = {
      ...data,
      filePath: selectedFolder[selectedFolder.length - 1]?.id,
    };

    delete fileData.selectedFolder;

    if (type === "CREATE") {
      try {
        let resultFileData = await addNewFileData({ ...fileData }).unwrap();
        console.log("resultFileData :>> ", resultFileData);
        if (resultFileData?.type === "success") {
          dispatch(
            setDialog({
              title: "Dokument erstellt!",
              type: "SUCCESS",
              data: {
                successText: "Dokument wurde erfolgreich erstellt!",
              },
            })
          );
        }
      } catch (error) {
        if (error?.status === 409) {
          // filename already in use
          setError("fileName", {
            type: "custom",
            message: "Dieser Dokumentenname ist bereits vergeben.",
          });
        }
        console.log("error :>> ", error);
      }
    }
  };

  return (
    <div>
      <Dialog open maxWidth="lg">
        <DialogTitle>{dialog?.title ?? ""}</DialogTitle>
        <DialogContent>
          <form className="form">
            <CreateFileDataForm
              control={control}
              revisionChecked={revisionChecked}
              fileStructure={fileStructure}
              selectedFolder={selectedFolder}
            />
            {isError && (
              <div style={{ marginTop: 15 }}>
                <ErrorAlert errorMessage="Fehler beim erstellen des Dokuments" />
              </div>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            autoFocus
            onClick={handleSubmit(onSubmit)}
          >
            {type === "CREATE" ? "NEUES DOKUMENT ERSTELLEN" : "SPEICHERN"}
          </Button>
          <Button onClick={handleClose} color="primary">
            Beenden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

FileDataDialog.propTypes = {
  dialog: PropTypes.object,
  handleClose: PropTypes.func,
};

export default FileDataDialog;
