import { useSelector } from 'react-redux';
import { selectCurrentRoles } from '../features/auth/authSlice';
import { ROLES_LIST } from '../features/roles/roles.config';

const useAuth = () => {
  const roles = useSelector(selectCurrentRoles);
  let isEditor = false;
  let isAdmin = false;
  let isUser = false;
  let isSuperAdmin = false;
  let status = 'User';

  const { User, Editor, Admin, SuperAdmin } = ROLES_LIST;

  if (roles) {
    isUser = roles.includes(User);
    isEditor = roles.includes(Editor);
    isAdmin = roles.includes(Admin);
    isSuperAdmin = roles.includes(SuperAdmin);

    if (isUser) status = 'User';
    if (isEditor) status = 'Editor';
    if (isAdmin) status = 'Admin';
    if (isSuperAdmin) status = 'SuperAdmin';

    return { roles, status, isUser, isEditor, isAdmin, isSuperAdmin };
  }

  return { roles: [], isUser, isEditor, isAdmin, isSuperAdmin, status };
};
export default useAuth;
