import { apiSlice } from "../../app/api/apiSlice";

export const billingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBillings: builder.query({
      query: () => ({
        url: "/billings",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),

      // eslint-disable-next-line
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Billing", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Billing", id })),
          ];
        }
        return [{ type: "Billing", id: "LIST" }];
      },
    }),
    getTenantBilling: builder.query({
      query: () => ({
        url: "/billings/tenant",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      invalidatesTags: ["Billings"],
    }),
    addNewBilling: builder.mutation({
      query: (initialBilling) => ({
        url: "/billings",
        method: "POST",
        body: {
          ...initialBilling,
        },
      }),
      invalidatesTags: [{ type: "Billing", id: "LIST" }],
    }),
    updateBilling: builder.mutation({
      query: (initialBilling) => ({
        url: "/billings",
        method: "PATCH",
        body: {
          ...initialBilling,
        },
      }),
      // eslint-disable-next-line
      invalidatesTags: (result, error, arg) => [
        { type: "Billing", id: arg.id },
      ],
    }),
    deleteBilling: builder.mutation({
      query: ({ id }) => ({
        url: `/billings`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Billing", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetBillingsQuery,
  useGetTenantBillingQuery,
  useAddNewBillingMutation,
  useUpdateBillingMutation,
  useDeleteBillingMutation,
} = billingsApiSlice;
