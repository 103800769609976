import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import React from 'react';

function ErrorAlert({ errorMessage }) {
  return (
    <div>
      <Alert severity="error">{errorMessage}</Alert>
    </div>
  );
}

ErrorAlert.propTypes = {
  errorMessage: PropTypes.string,
};

export default ErrorAlert;
