import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import {
  styled,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { useGetUsersQuery } from "src/features/users/usersApiSlice";
import { useAssignEmployeesToOfficeMutation } from "src/features/offices/officesApiSlice";

// Styled Dialog
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// PropTypes
AssignEmployeeDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  office: PropTypes.object.isRequired,
};

// Main Component
export default function AssignEmployeeDialog({ handleClose, office }) {
  const { handleSubmit, control } = useForm();
  const { data: userList, isLoading } = useGetUsersQuery();

  const [assignEmployeesToOffice] = useAssignEmployeesToOfficeMutation();

  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  useEffect(() => {
    if (!isLoading && userList) {
      const assignedEmployeeIds =
        office?.employees.map((emp) => emp.toString()) || [];
      const filteredUsers = userList.filter(
        (user) => !assignedEmployeeIds.includes(user._id.toString())
      );
      setLeft(filteredUsers);
      setRight(
        userList.filter((user) =>
          assignedEmployeeIds.includes(user._id.toString())
        )
      );
    }
  }, [userList, isLoading, office]);

  const handleTransfer = (newLeft, newRight) => {
    setLeft(newLeft);
    setRight(newRight);
  };

  const onSubmit = async () => {
    try {
      console.log("Assigned Employees: ", right);
      await assignEmployeesToOffice({
        officeId: office?._id,
        employees: right.map((employee) => employee._id),
      });

      handleClose("REFETCH");
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
            id="customized-dialog-title"
          >
            Mitarbeiter zuweisen
          </Typography>
          <Box sx={{ flex: "0 0 auto" }}>
            <Iconify
              onClick={handleClose}
              icon="mingcute:close-line"
              style={{ fontSize: "50px", color: "action.active" }}
              sx={{
                transition: "transform 0.1s", // Smooth transition for transform
                "&:hover": {
                  transform: "scale(1.8)", // Scale up the icon when hovered
                },
              }}
            />
          </Box>
        </Box>

        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1" color="textPrimary">
              Wählen Sie einen oder mehrere Mitarbeiter aus der Liste aus, um
              sie einer Praxis zuzuordnen.
            </Typography>
          </DialogContentText>
          <br />
          <TransferList
            left={left}
            right={right}
            onTransfer={handleTransfer}
            leftTitle="Verfügbare Mitarbeiter"
            rightTitle="Zugewiesene Mitarbeiter"
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: "#9E9E9E" }}
            variant="contained"
            onClick={handleClose}
          >
            Abbrechen
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Zuweisen
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

// TransferList Component
function TransferList({ left, right, onTransfer, leftTitle, rightTitle }) {
  const [checked, setChecked] = useState([]);
  const leftChecked = checked.filter((value) => left.includes(value));
  const rightChecked = checked.filter((value) => right.includes(value));

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    onTransfer(
      left.filter((value) => !leftChecked.includes(value)),
      [...right, ...leftChecked]
    );
    setChecked([]);
  };

  const handleCheckedLeft = () => {
    onTransfer(
      [...left, ...rightChecked],
      right.filter((value) => !rightChecked.includes(value))
    );
    setChecked([]);
  };

  const customList = (title, items) => (
    <Box sx={{ flex: 1 }}>
      <Typography
        sx={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}
      >
        {title}
      </Typography>
      <Divider />
      <List
        dense
        component="div"
        role="list"
        sx={{ maxHeight: 400, overflow: "auto" }}
      >
        {items.map((value) => {
          const labelId = `transfer-list-item-${value._id}-label`;

          return (
            <ListItem
              key={value._id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value.firstName} ${value.lastName}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      {customList(leftTitle, left)}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          size="small"
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          aria-label="move selected left"
          sx={{ mt: 2 }}
        >
          &lt;
        </Button>
      </Box>
      {customList(rightTitle, right)}
    </Box>
  );
}
