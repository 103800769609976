import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../../features/auth/authSlice";
import Iconify from "../../iconify";
import { useGetOfficesQuery } from "../../../features/offices/officesApiSlice";

function FileDataOfficeSelect({ control }) {
  const { data: offices } = useGetOfficesQuery();

  const currentUserData = useSelector(selectCurrentUserData);

  const assignCurrentOffice = (e, onChange) => {
    e.preventDefault();
    onChange(currentUserData?.selectedOffice);
  };

  const createSelectValueArrays = () => {
    let a = offices.map((office) => ({
      label: `${office?.officeName}`,
      value: office?._id,
    }));
    return a.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  };

  return (
    <Controller
      name="officeRef"
      control={control}
      rules={{
        required: true,
      }}
      render={({ field, field: { onChange }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <InputLabel>Gehört zu Praxis</InputLabel>
          <Select
            {...field}
            label="Gehört zu Praxis"
            variant="outlined"
            error={error && true}
          >
            <MenuItem value="" disabled>
              Praxis auswählen
            </MenuItem>
            {offices && createSelectValueArrays()}
            {/* {FILTER_STATUS_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))} */}
          </Select>
          <FormHelperText error>
            {error && "Bitte eine Praxis auswählen"}
          </FormHelperText>
          <Button
            variant="text"
            onClick={(e) => assignCurrentOffice(e, onChange)} // Ruft assignToMe mit onChange auf
            startIcon={
              <Iconify
                icon="fa-solid:clinic-medical"
                style={{ color: "blue", fontSize: "24px" }}
              />
            }
          >
            Aktuelle Praxis zuweisen
          </Button>
        </FormControl>
      )}
    />
  );
}

FileDataOfficeSelect.propTypes = {
  control: PropTypes.object,
};

export default FileDataOfficeSelect;
