import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

function FileDataVersionField({ control }) {
  return (
    <Controller
      name="version"
      control={control}
      rules={{
        required: true,
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          variant="outlined"
          label="Version"
          fullWidth
          required
          error={error && true}
          helperText={error && "Bitte eine Version im Format X.X.X angeben"}
        />
      )}
    />
  );
}

FileDataVersionField.propTypes = {
  control: PropTypes.object,
};

export default FileDataVersionField;
