import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextField from "../../../components/forms/TextField";
import DEDateField from "../../../components/forms/DEDateField";
import SelectController from "../../../components/forms/SelectController";
import ControlledCheckbox from "../../../components/forms/ControlledCheckbox";
import ControlledRadioButton from "../../../components/forms/ControlledRadioButton";
import ControlledSelectField from "../../../components/forms/ControlledSelectField";
import SelectControllerForAreas from "src/components/forms/SelectControllerForAreas";

// ----------------------------------------------------------------------------

TaskForm.propTypes = {
  control: PropTypes.object,
};

// ----------------------------------------------------------------------------

export default function TaskForm({ control }) {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="taskName"
            label="Aufgabenname"
            errorMsg="Bitte Taskname eingeben!"
            fullWidth
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="description"
            label="Beschreibung"
            variant="outlined"
            errorMsg="Bitte Beschreibung angeben"
            multiline
            fullWidth
            required={true}
          />
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <ControlledCheckbox
            control={control}
            name="auditRelevant"
            label="Audit-Relevant"
            description="Auditrelevante Aufgaben werden separat aufgerufen."
          />
        </Grid>
        <Grid item xs={5}>
          <ControlledRadioButton
            control={control}
            name="visibility"
            label="Sichtbarkeit"
            itemArray={[
              {
                label: "Öffentlich",
                value: true,
              },
              {
                label: "Privat",
                value: false,
              },
            ]}
            tooltip={{
              title: "Erklärung - Sichtbarkeit",
              content:
                "Wenn Sie die Aufgabe auf 'Öffentlich' setzen, können alle Mitarbeiter der Praxis sie sehen. Im Modus 'Privat' ist die Aufgabe nur für Sie als Ersteller und die zugewiesene Person sichtbar.",
              icon: "clarity:help-solid",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectControllerForAreas
            control={control}
            name="area"
            label="Bereich"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <ControlledSelectField
            control={control}
            name="status"
            label="Status:"
            errorMsg="Bitte Status auswählen."
            itemArray={[
              { label: "Neu", value: "new" },
              { label: "In Bearbeitung", value: "edit" },
              { label: "In Überprüfung", value: "revision" },
              { label: "Erledigt", value: "done" },
            ]}
          />
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              alignItems: "flex-start",
              minHeight: "100px",
            }}
          >
            <DEDateField
              control={control}
              name="dueDate"
              label="Datum"
              errorMsg="Bitte wähle ein Datum aus"
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              alignItems: "flex-start",
              minHeight: "100px",
            }}
          >
            <SelectController
              control={control}
              name="responsibleUser"
              label="Zugewiesen an:"
              errorMsg="Bitte wähle ein Name aus."
              required={true}
            />
          </Grid>
        </Grid>{" "}
        <Grid item xs={5}>
          <ControlledRadioButton
            control={control}
            name="scope"
            label="Geltungsbereich"
            itemArray={[
              {
                label: "Für alle Praxen",
                value: true,
              },
              {
                label: "Nur diese Praxis",
                value: false,
              },
            ]}
            tooltip={{
              title: "Erklärung - Geltungsbereich",
              content:
                "Wenn Sie die Aufgabe auf 'Für alle Praxen' setzen, ist sie für alle Mitarbeiter aller Praxen sichtbar. Im Modus 'Nur diese Praxis' können nur die Mitarbeiter dieser Praxis die Aufgabe sehen.",
              icon: "clarity:help-solid",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
