import React, { useEffect, useState } from "react";
import { IconButton, Badge, Tooltip } from "@mui/material";
import Iconify from "../../../../components/iconify";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useGetChatsQuery } from "../../../../features/chats/chatsApiSlice";
import { useSocket } from "../../../../app/socket/SocketProvider";

export default function UsersPopover() {
  const currentToken = useSelector(selectCurrentToken);
  const navigate = useNavigate();
  const [connected, setConnected] = useState(false);
  const { data: chats, refetch } = useGetChatsQuery();
  const [unreadCounter, setUnreadCounter] = useState(0);

  const socket = useSocket();

  useEffect(() => {
    if (socket) {
      socket.on("refreshChats", () => {
        refetch();
      });

      socket.on("connect", () => {
        setConnected(true);
        console.log("Socket verbunden mit ID:", socket.id);
      });

      socket.on("disconnect", (reason) => {
        setConnected(false);
        console.log("Verbindung getrennt:", reason);
      });

      socket.on("connect_error", (error) => {
        setConnected(false);
        console.error("Fehler bei der Verbindung:", error);
      });

      return () => {
        socket.off("refreshChats");
        socket.off("connect");
        socket.off("disconnect");
        socket.off("connect_error");
      };
    }
  }, [socket, refetch]);

  useEffect(() => {
    if (chats) {
      let counter = 0;
      chats.forEach((chat) => {
        if (chat.unreadCount > 0) {
          counter++;
        }
      });
      setUnreadCounter(counter);
    }
  }, [chats]);

  return (
    <IconButton
      onClick={() => navigate("/dashboard/chat")}
      sx={{ width: 40, height: 40, position: "relative" }}
    >
      <Badge badgeContent={unreadCounter} color="error">
        <Iconify icon="majesticons:chat-2" />
      </Badge>

      <Tooltip title={connected ? "Online" : "Offline"}>
        <Badge
          overlap="circular"
          color={connected ? "success" : "error"}
          variant="dot"
          sx={{
            position: "absolute",
            bottom: 0,
            right: 10,
            height: 10,
          }}
        />
      </Tooltip>
    </IconButton>
  );
}
