import { apiSlice } from "../../app/api/apiSlice";

export const supportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSupports: builder.query({
      query: () => ({
        url: `/support`, // Grund-URL für die Abfrage aller Support-Nachrichten
        method: "GET",
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Support", id })),
              { type: "Support", id: "LIST" },
            ]
          : [{ type: "Support", id: "LIST" }],
    }),

    addNewSupport: builder.mutation({
      query: (supportData) => ({
        url: "/support",
        method: "POST",
        body: {
          ...supportData,
        },
      }),
      invalidatesTags: [{ type: "Support", id: "LIST" }],
    }),

    updateSupport: builder.mutation({
      query: (supportUpdate) => ({
        url: `/support/${supportUpdate.id}`, // Unterstützung der URL für die Aktualisierung mit der ID
        method: "PATCH",
        body: {
          ...supportUpdate,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Support", id: arg.id },
      ],
    }),

    deleteSupport: builder.mutation({
      query: ({ id }) => ({
        url: `/support/${id}`, // URL zum Löschen einer spezifischen Support-Nachricht mit der ID
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Support", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetSupportsQuery,
  useAddNewSupportMutation,
  useUpdateSupportMutation,
  useDeleteSupportMutation,
} = supportApiSlice;
