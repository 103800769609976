import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  styled,
} from "@mui/material";
import LawForm from "./LawForm";
import LawDialogStep2 from "./LawDialogStep2";
import Iconify from "../../../../components/iconify";
import { FormProvider, useForm } from "react-hook-form";
import VisibilityForm from "../../news/NewsDialogComponents/VisibilityForm";
import {
  useCreateNewLawMutation,
  useUpdateLawMutation,
} from "../../../../features/laws/lawsApiSlice";
import { uploadLawDocumentAxios } from "../../../../app/api/axios/services/files.service";
import { serverURL } from "../../../../app/api/axios/http.service";

const steps = [
  "Details eingeben",
  "Dokument hochladen",
  "Sichtbarkeit einstellen",
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function LawDialog({ handleClose, lawDetails, lawFlag }) {
  const [createNewLaw] = useCreateNewLawMutation();
  const [updateLaw] = useUpdateLawMutation();
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const methods = useForm({
    defaultValues: {
      title: lawDetails?.title || "",
      description: lawDetails?.description || "",
      externalUrl: lawDetails?.externalUrl || "",
      favorite: lawDetails?.favorite || false,
      documentPath: lawDetails?.documentPath || "",
      visibilityScope: lawDetails?.visibilityScope || "office",
      subjectToDisplay: lawDetails?.subjectToDisplay || false,
      uploadDocument: lawDetails?.documentPath ? true : false,
    },
  });

  const { handleSubmit, trigger, control, watch } = methods;
  const uploadDocument = watch("uploadDocument");

  useEffect(() => {
    if (lawDetails?.documentPath) {
      const documentPath = `${serverURL()}/public/law/document/${
        lawDetails._id
      }`;
      setFilePreview(documentPath);
      setFile(documentPath);
    }
  }, [lawDetails]);

  const handleNext = async () => {
    const isValid = await trigger();
    if (isValid) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const onSubmit = async (data) => {
    try {
      const finalData = { ...data, documentPath: file?.path || file };

      if (lawFlag === "EDIT") {
        const result = await updateLaw({
          ...finalData,
          id: lawDetails._id,
          uploadDocument,
        });
        if (result) {
          if (file !== filePreview) {
            if (uploadDocument) {
              await uploadLawDocumentAxios(lawDetails._id, file);
            }
          }
        }
      } else {
        const result = await createNewLaw(finalData);
        const lawId = result?.data?.lawId;

        if (file) {
          await uploadLawDocumentAxios(lawId, file);
        }
      }
      handleClose("REFETCH");
    } catch (error) {
      console.error("Fehler beim Verarbeiten des Gesetzes:", error);
    }
  };

  return (
    <FormProvider {...methods}>
      <BootstrapDialog open>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}>
            {lawFlag === "EDIT"
              ? "Gesetz bearbeiten"
              : "Neues Gesetz hinzufügen"}
          </Typography>
          <Iconify
            onClick={handleClose}
            icon="mingcute:close-line"
            style={{ fontSize: "50px", color: "action.active" }}
            sx={{
              transition: "transform 0.1s",
              "&:hover": { transform: "scale(1.8)" },
            }}
          />
        </Box>

        <DialogContent dividers>
          <Stepper activeStep={activeStep} sx={{ pb: 2 }}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && <LawForm control={control} />}
          {activeStep === 1 && (
            <LawDialogStep2
              control={control}
              file={file}
              setFile={setFile}
              filePreview={filePreview}
              setFilePreview={setFilePreview}
              uploadDocument={uploadDocument}
            />
          )}
          {activeStep === 2 && <VisibilityForm control={methods.control} />}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleBack}
            sx={{ textTransform: "none" }}
            disabled={activeStep === 0}
          >
            Zurück
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              onClick={handleSubmit((data) => onSubmit(data))}
              sx={{ textTransform: "none" }}
            >
              Speichern
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={handleNext}
            >
              Weiter
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </FormProvider>
  );
}

LawDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  lawFlag: PropTypes.string.isRequired,
  lawDetails: PropTypes.object,
};
