import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import TextField from "../../../components/forms/TextField";
import MailTextField from "../../../components/forms/MailTextField";
import PasswordTextField from "../../../components/forms/PasswordTextField";
import PasswordCheckTextField from "../../../components/forms/PasswordCheckTextField";
import MultipleSelectChip from "../../../components/forms/MultipleSelectChip";
import ControlledRadioButton from "../../../components/forms/ControlledRadioButton"; // Importiere die ControlledRadioButton Komponente
import { useGetOfficesQuery } from "../../../features/offices/officesApiSlice";
import { selectCurrentOffice } from "src/features/auth/authSlice";
import { useSelector } from "react-redux";
import Iconify from "src/components/iconify";

function NewUserForm({ initialValues, onSubmit, dialogType }) {
  const currentOffice = useSelector(selectCurrentOffice);
  const officeIds = initialValues?.offices?.map((office) => office._id) || [
    currentOffice,
  ];

  const determineUserRole = (roles) => {
    if (roles?.Admin) {
      return "isAdmin";
    } else if (roles?.Editor) {
      return "isEditor";
    } else if (roles?.User) {
      return "isUser";
    }
    return "isUser";
  };
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      ...initialValues,
      userRole: determineUserRole(initialValues?.roles),
      offices: officeIds,
    },
  });

  const { data: offices } = useGetOfficesQuery();

  const handleCheckMatch = (checkPwd) => {
    if (watch("password") !== checkPwd) {
      return "Die Passwörter stimmen nicht überein";
    }
    return true;
  };

  const permissionItems = [
    {
      value: "isUser",
      label: "Benutzer",
      helperText:
        "Lesezugriff auf freigegebene Dokumente und Schreibzugriff auf zugewiesene Dokumente.",
    },
    {
      value: "isEditor",
      label: "Freigabeberechtigter Arzt",
      helperText: "Dokumente freigeben und bearbeiten.",
    },
    {
      value: "isAdmin",
      label: "Admin (QMB)",
      helperText: "Voller Zugriff, Benutzerverwaltung, Praxisverwaltung.",
    },
  ];

  const selectedRole = watch("userRole");

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id="user-form" className="form">
        <Grid container spacing={3} pt={1}>
          <Grid item xs={12} md={4}>
            <TextField
              name="username"
              label="Benutzername"
              errorMsg="Bitte angeben"
              required={true}
              fullWidth
              control={control}
            />
          </Grid>
          {dialogType !== "EDIT" && (
            <>
              <Grid item xs={12} md={4}>
                <PasswordTextField fullWidth control={control} required />
              </Grid>
              <Grid item xs={12} md={4}>
                <PasswordCheckTextField
                  handleCheckMatch={handleCheckMatch}
                  name="checkPassword"
                  label="Passwort wiederholen"
                  control={control}
                  fullWidth
                  required
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} md={4}>
            <TextField
              name="firstName"
              label="Vorname"
              errorMsg="Bitte angeben"
              control={control}
              required={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="lastName"
              label="Nachname"
              errorMsg="Bitte angeben"
              control={control}
              required={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MailTextField control={control} required={false} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Praxis zuweisen</FormLabel>
              <FormGroup>
                <MultipleSelectChip
                  name="offices"
                  label="Zugeordnete Praxis"
                  errorMsg="Bitte angeben"
                  required={true}
                  itemArray={
                    offices &&
                    offices.map((o) => ({
                      label: o.officeName,
                      value: o._id,
                    }))
                  }
                  control={control}
                />
              </FormGroup>
              <FormHelperText>
                Bitte weisen Sie dem Nutzer mindestens eine Praxis zu.
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h6"
                  component="legend"
                  sx={{ marginRight: 1 }}
                ></Typography>
                <Tooltip
                  title={
                    <Box sx={{ maxWidth: 300 }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", marginBottom: 1 }}
                      >
                        Rechte:
                      </Typography>
                      <ul style={{ paddingLeft: 20, margin: 0 }}>
                        {permissionItems.map((item) => (
                          <li>
                            <Typography variant="body2">
                              <strong>{item.label}:</strong> {item.helperText}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  }
                  placement="right"
                  arrow
                >
                  <Iconify
                    icon="clarity:help-solid"
                    style={{ fontSize: "24px", color: "action.active" }}
                    sx={{
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.2)",
                      },
                    }}
                  />
                </Tooltip>
              </Box>
              <ControlledRadioButton
                control={control}
                name="userRole"
                label="Benutzerrolle auswählen"
                itemArray={permissionItems.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
                required={true}
              />
              <FormHelperText>
                {permissionItems.find((item) => item.value === selectedRole)
                  ?.helperText ||
                  "Wählen Sie eine Rolle aus, um Details zu sehen."}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

NewUserForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default NewUserForm;
