import React, { useState } from "react";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Badge,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { AvatarIntern } from "../../../components/avatar";
import Iconify from "../../../components/iconify";
import {
  useDeleteChatMutation,
  useLeaveGroupMutation,
} from "../../../features/chats/chatsApiSlice";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserData } from "../../../features/auth/authSlice";

// ----------------------------------------------------------------------------

const formatDateBadge = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString("de-DE", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
};

// ----------------------------------------------------------------------------

const UserItem = ({ chat, onClick, onSelectChat }) => {
  const currentUserData = useSelector(selectCurrentUserData);
  const currentUserId = currentUserData?.id;
  const dispatch = useDispatch();
  const [leaveGroup] = useLeaveGroupMutation();
  const [deleteChat] = useDeleteChatMutation();
  const [hovered, setHovered] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => setMenuAnchor(event.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);

  const otherUsers = chat?.users?.filter((user) => user._id !== currentUserId);
  const lastMessage = chat?.messages[chat.messages.length - 1] || {};
  const lastMessageText = lastMessage.text || "Keine Nachrichten";
  const lastMessageTime = new Date(chat.lastMessage).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const lastMessageDate = formatDateBadge(chat.lastMessage);

  const handleLeaveGroup = async () => {
    await leaveGroup({ chatId: chat._id });
    onSelectChat(null);
  };

  const handleDeleteChat = async () => {
    await deleteChat(chat._id);
    onSelectChat(null);
  };

  const handleEditGroup = () => {
    console.log("chat", chat);
    dispatch(
      setDialog({
        content: "EditGroup",
        data: chat,
      })
    );
    handleMenuClose();
  };

  return (
    <ListItem
      button
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        py: 1.5,
        px: 2,
        borderRadius: 2,
        backgroundColor: hovered ? "#f9f9f9" : "inherit",
      }}
    >
      {chat?.isGroup ? (
        <ListItemAvatar>
          <Iconify icon="pepicons-pencil:people-circle-filled" width={40} />
        </ListItemAvatar>
      ) : (
        <ListItemAvatar>
          <AvatarIntern
            name={`${otherUsers[0]?.firstName} ${otherUsers[0]?.lastName}`}
          />
        </ListItemAvatar>
      )}

      <ListItemText
        primary={
          <Typography variant="subtitle1" fontWeight="bold">
            {chat?.isGroup
              ? chat?.groupName
              : `${otherUsers[0]?.firstName} ${otherUsers[0]?.lastName}`}
          </Typography>
        }
        secondary={
          <Typography variant="body2" color="textSecondary" noWrap>
            {lastMessageText}
          </Typography>
        }
      />

      <Box sx={{ position: "relative", minWidth: 60, textAlign: "right" }}>
        {!hovered ? (
          <>
            {chat?.unreadCount > 0 && (
              <Badge
                badgeContent={chat?.unreadCount}
                color="secondary"
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  transform: "translate(50%, -50%)",
                }}
              />
            )}
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ position: "absolute", bottom: 0, right: 0 }}
            >
              {lastMessageTime}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{
                position: "absolute",
                bottom: -40,
                right: 0,
                fontSize: "0.75rem",
              }}
            >
              {lastMessageDate}
            </Typography>
          </>
        ) : (
          <IconButton
            onClick={handleMenuOpen}
            size="small"
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translate(50%, -50%)",
            }}
          >
            <Icon icon="mdi:dots-vertical" width={20} height={20} />
          </IconButton>
        )}
      </Box>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {[
          chat.isGroup && (
            <MenuItem key="members" onClick={() => handleEditGroup()}>
              <Iconify icon="mdi:pencil" width={24} sx={{ mr: 1 }} />
              Gruppe bearbeiten
            </MenuItem>
          ),

          chat.isGroup && (
            <MenuItem key="leave" onClick={handleLeaveGroup}>
              <Iconify
                icon="material-symbols:exit-to-app"
                width={24}
                sx={{ mr: 1 }}
              />
              Verlassen
            </MenuItem>
          ),
          !chat.isGroup && ( // Only show 'Delete' if it's NOT a group chat
            <MenuItem key="delete" onClick={handleDeleteChat}>
              <Iconify
                icon="material-symbols:delete-outline"
                width={24}
                sx={{ mr: 1 }}
              />
              Löschen
            </MenuItem>
          ),
        ].filter(Boolean)}
      </Menu>
    </ListItem>
  );
};

export default UserItem;
