import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import Loading from "../../../../components/spinners/Loading";
import { useCreateNewFileDataMutation } from "../../../../features/files/filesApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentOffice } from "../../../../features/auth/authSlice";
import { uploadFileAxios } from "../../../../app/api/axios/services/files.service";

export default function UploadFileStep({ data, handleNext }) {
  const [loading, setLoading] = useState(false);
  const [createNewFileData] = useCreateNewFileDataMutation();
  const currentOffice = useSelector(selectCurrentOffice);

  console.log("data?.directory?.main :>> ", data?.directory?.main);
  const handleUpload = async () => {
    data.fileDetails.fileName = `${data.fileDetails.fileName}.${data.fileDetails.extension}`;
    setLoading(true);
    try {
      let resultNewFileData = await createNewFileData({
        directory: data?.directory?.sub
          ? data?.directory?.sub?.id
          : data?.directory?.main,
        fileDetails: data.fileDetails,
        officeRef: currentOffice,
      }).unwrap();
      if (resultNewFileData && resultNewFileData?.type === "SUCCESS") {
        // Metadata created
        const { _id: fileId } = resultNewFileData.fileData;
        // Upload file
        const formData = new FormData();
        formData.append("file", data.file);
        let resultUploadFile = await uploadFileAxios(formData, fileId);
        if (resultUploadFile.status === 200) {
          // File upload was successful
          setLoading(false);
          handleNext();
        }
      }
    } catch (error) {
      // Handle upload error
      console.error("Error uploading file:", error.message);
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div>
        <div style={{ minWidth: 300, minHeight: 300 }}>
          <Loading />
        </div>
        <div>
          <Typography sx={{ mt: 6, p: 2 }} textAlign={"center"} variant="h3">
            Bitte warten, das Dokument wird hochgeladen...
          </Typography>
        </div>
      </div>
    );

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleUpload}
        disabled={loading}
        fullWidth
        sx={{ padding: "25px" }}
        style={{ fontSize: "150%" }}
      >
        DOKUMENT HOCHLADEN
      </Button>
    </div>
  );
}
