import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDeleteOfficeMutation } from "../../features/offices/officesApiSlice";
import Loading from "../spinners/Loading";
import { useDispatch } from "react-redux";
import { setDialog } from "../../features/dialog/dialogSlice";
import ErrorAlert from "../alerts/ErrorAlert";

function OfficeDeleteDialog({ officeData, handleClose }) {
  const dispatch = useDispatch();

  const [deleteOffice, { isLoading, isError }] = useDeleteOfficeMutation();

  const handleDeleteOffice = async () => {
    try {
      let result = await deleteOffice({ id: officeData._id }).unwrap();
      if (result?.status === "success") {
        dispatch(
          setDialog({
            title: officeData.officeName + " wurde erfolgreich gelöscht!",
            type: "SUCCESS",
            data: {
              successText:
                officeData.officeName + " wurde erfolgreich gelöscht!",
            },
          })
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <div>
      <Dialog
        onClose={!isLoading ? () => handleClose() : null}
        open
        maxWidth="lg"
      >
        <DialogTitle>Praxis löschen</DialogTitle>
        <DialogContent>
          {isLoading && (
            <div style={{ minHeight: 300, minWidth: 300 }}>
              <Loading />
            </div>
          )}

          {!isLoading && (
            <div>
              <Typography variant="h3" id="success-dialog-description">
                Möchten Sie diese Praxis wirklich endültig löschen?
              </Typography>
              <table style={{ fontSize: "150%" }}>
                <tr>
                  <th>Praxisname:</th>
                  <th>{officeData.officeName}</th>
                </tr>
              </table>
            </div>
          )}
          {isError && (
            <ErrorAlert errorMessage="Fehler beim Löschen der Praxis" />
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={handleClose} color="primary">
            Beenden
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleDeleteOffice}
            color="error"
            variant="contained"
            autoFocus
          >
            LÖSCHEN
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OfficeDeleteDialog;

OfficeDeleteDialog.propTypes = {
  officeData: PropTypes.object,
  dialogType: PropTypes.string,
};
