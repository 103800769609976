import React, { useState } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useFieldArray } from "react-hook-form";
import FileDataStatusSelect from "../../../../components/forms/Fields/FileDataStatusSelect";
import FileDataVersionField from "../../../../components/forms/Fields/FileDataVersionField";
import FileDataTypeSelect from "../../../../components/forms/Fields/FileDataTypeSelect";
import FileDataAssignedUserSelect from "../../../../components/forms/Fields/FileDataAssignedUserSelect";
import FileDataResponsibleUserSelect from "../../../../components/forms/Fields/FileDataResponsibleUserSelect";
import FileDataCreatorSelect from "../../../../components/forms/Fields/FileDataCreatorSelect";
import FileDataAuditRelevantCheckbox from "../../../../components/forms/Fields/FileDataAuditRelevantCheckbox";
import FileDataAccessRestrictedCheckbox from "../../../../components/forms/Fields/FileDataAccessRestrictedCheckbox";
import FileDataOfficeSelect from "../../../../components/forms/Fields/FileDataOfficeSelect";
import Iconify from "../../../../components/iconify";
import FileDataCheckedOutCheckbox from "../../../../components/forms/Fields/FileDataCheckedOutCheckbox";

const BulkEditFileData = ({ control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "editItems",
  });

  const [selectedField, setSelectedField] = useState(""); // Initialize with an empty string

  const allFields = [
    { value: "status", label: "Status", component: FileDataStatusSelect },
    { value: "version", label: "Version", component: FileDataVersionField },
    { value: "type", label: "Typ", component: FileDataTypeSelect },
    {
      value: "assignedUser",
      label: "Zugewiesen an",
      component: FileDataAssignedUserSelect,
    },
    {
      value: "responsibleUser",
      label: "Verantwortlich",
      component: FileDataResponsibleUserSelect,
    },
    {
      value: "creator",
      label: "Erstellt von",
      component: FileDataCreatorSelect,
    },
    {
      value: "auditRelevant",
      label: "Auditrelevant",
      component: FileDataAuditRelevantCheckbox,
    },
    {
      value: "isCheckedOut",
      label: "Ausgecheckt",
      component: FileDataCheckedOutCheckbox,
    },
    {
      value: "accessRestricted",
      label: "Zugriff beschränkt",
      component: FileDataAccessRestrictedCheckbox,
    },
    { value: "officeRef", label: "Praxis", component: FileDataOfficeSelect },
  ];

  // Filter available fields to exclude those already selected
  const availableFields = allFields.filter(
    (field) => !fields.some((item) => item.field === field.value)
  );

  const handleAddField = () => {
    if (selectedField) {
      append({ field: selectedField }); // Append new field item
      setSelectedField(""); // Reset selected field after appending
    }
  };

  return (
    <Paper elevation={0} sx={{ minWidth: { xs: "200px", lg: 800 } }}>
      <Grid container spacing={2} mt={2}>
        {/* Displaying the selected fields for editing */}
        {fields.map((item, index) => {
          const selectedFieldValue = item.field;
          const selectedFieldLabel = allFields.find(
            (f) => f.value === selectedFieldValue
          )?.label;

          return (
            <React.Fragment key={item.id}>
              <Grid item xs={12} lg={10}>
                <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                  {selectedFieldLabel}
                </Typography>
                {selectedFieldValue &&
                  // Render the corresponding component
                  React.createElement(
                    allFields.find((f) => f.value === selectedFieldValue)
                      .component,
                    { control }
                  )}
              </Grid>
              <Grid item xs={12} lg={2} sx={{ mt: { lg: 5.5, xs: -1 } }}>
                <Button
                  variant="contained"
                  onClick={() => remove(index)}
                  color="error"
                >
                  <Iconify
                    icon="mdi:delete"
                    sx={{
                      m: 1,
                      height: "25px",
                      width: "25px",
                    }}
                  />
                </Button>
              </Grid>
            </React.Fragment>
          );
        })}
        {/* Dropdown for selecting a field to edit */}
        <Grid item xs={12} lg={8}>
          <Select
            value={selectedField} // Controlled value
            onChange={(e) => setSelectedField(e.target.value)}
            displayEmpty
            fullWidth
            disabled={availableFields.length === 0} // Disable if no fields are available
          >
            <MenuItem value="" disabled>
              Feld auswählen
            </MenuItem>
            {availableFields.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button
            variant="contained"
            onClick={handleAddField}
            disabled={!selectedField} // Enable button only when a field is selected
            sx={{ m: 0, p: 2 }}
          >
            Feld hinzufügen
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BulkEditFileData;
