import { Helmet } from "react-helmet-async";
// @mui
import { Button, Container, Stack, Typography } from "@mui/material";
// components
import Iconify from "../components/iconify";
import LawAccordion from "../sections/@dashboard/laws/LawAccordion";
import { setDialog } from "../features/dialog/dialogSlice";
import { useDispatch } from "react-redux";
import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------

export default function LawsPage() {
  const dispatch = useDispatch();
  const { status } = useAuth();
  const canEdit = status === "Admin";

  const handleCreateNewBlog = () => {
    dispatch(setDialog({ content: "LawDialog", data: "", lawFlag: "NEW" }));
  };

  return (
    <>
      <Helmet>
        <title> Digit QM | Gesetzestexte </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Gesetzesübersicht
          </Typography>
          <Button
            variant="contained"
            disabled={!canEdit}
            sx={{
              textTransform: "none",
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => handleCreateNewBlog()}
          >
            Neues Gesetz einfügen
          </Button>
        </Stack>

        <LawAccordion canEdit={canEdit} />
      </Container>
    </>
  );
}
