import {
  Box,
  DialogContentText,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import Iconify from "../../../../components/iconify";
import ControlledRadioButton from "../../../../components/forms/ControlledRadioButton";
import TextField from "../../../../components/forms/TextField";
import DEDateField from "../../../../components/forms/DEDateField";

export default function FormControl({ control, zeitraumval }) {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Tooltip
            title={
              <Typography variant="body1" sx={{ maxWidth: 300 }}>
                <strong>Erstellen Sie eine neue Kontrolle:</strong>
                <ul style={{ paddingLeft: 20 }}>
                  <li>
                    Diese Aktion erstellt eine neue Kontrolle, die in der
                    Übersicht der Einträge angezeigt wird.
                  </li>
                  <li>
                    Der Ersteller des Eintrags wird am Tag der Kontrolle
                    benachrichtigt.
                  </li>
                  <li>
                    Ab dem Tag der Kontrolle ist es möglich, die Kontrolle zu
                    bearbeiten.
                  </li>
                </ul>
              </Typography>
            }
          >
            <Box>
              <Iconify
                icon="clarity:help-solid"
                style={{ fontSize: "50px", color: "action.active" }}
                sx={{
                  transition: "transform 0.1s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              />
            </Box>
          </Tooltip>
        </Grid>
        <Grid item>
          <DialogContentText sx={{ mb: 1 }}>
            Neue Kontrolle hinzufügen:
          </DialogContentText>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} sx={{ mb: 1 }}>
          <ControlledRadioButton
            control={control}
            name="date"
            label="Wählen Sie einen Zeitraum aus"
            itemArray={[
              {
                label: "1 Monat",
                value: "1_monat",
              },
              {
                label: "3 Monate",
                value: "3_monate",
              },
              {
                label: "1 Jahr",
                value: "1_jahr",
              },
              {
                label: "Individuell",
                value: "individuell",
              },
            ]}
          />
        </Grid>

        <Grid item xs={6} sx={{ mt: "140px" }}>
          {zeitraumval === "individuell" && (
            <DEDateField
              control={control}
              name="individuellesDatum"
              label="Individuelles Datum"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
