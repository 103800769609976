import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import ControlledCheckbox from "../../../../components/forms/ControlledCheckbox";
import AllFileTypesUpload from "../../../../components/FileUploadArea/AllFileTypesUpload";

export default function LawDialogStep2({
  control,
  file,
  setFile,
  filePreview,
  setFilePreview,
  uploadDocument,
}) {
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Dokument verlinken oder hochladen:
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <ControlledCheckbox
          control={control}
          label="Dokument hochladen"
          name="uploadDocument"
        />
      </Box>

      {uploadDocument && (
        <Box sx={{ mt: 2 }}>
          <AllFileTypesUpload
            file={file}
            setFile={setFile}
            filePreview={filePreview}
            setFilePreview={setFilePreview}
            previewTitle="Hochgeladene Datei:"
            dropzoneText="Hier klicken oder Datei hierherziehen"
          />
        </Box>
      )}
    </Box>
  );
}

LawDialogStep2.propTypes = {
  file: PropTypes.object,
  setFile: PropTypes.func,
  filePreview: PropTypes.string,
  setFilePreview: PropTypes.func,
  uploadDocument: PropTypes.bool,
};
