import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import UpdateFileDataForm from "../../forms/Forms/UpdateFileDataForm";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";

export default function UpdateFileUserDialog({
  fileData,
  handleSave,
  loading,
}) {
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      description: "",
      revision: false,
    },
  });

  const [open, setOpen] = React.useState(false);

  const revisionChecked = watch("revision");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (formData) => {
    handleSave(formData, "USER");
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        disabled={loading}
        onClick={handleClickOpen}
      >
        {loading ? "LADEN..." : "SPEICHERN"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${fileData.fileName} speichern`}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Sie können das Dokument speichern, um an einem späteren Zeitpunkt
            daran weiterzuarbeiten.
          </Typography>
          <br />
          <Typography>
            Wenn Ihnen das Dokument zur Bearbeitung zugewiesen wurde, können Sie
            nach erfolgter Bearbeitung das Dokument zur Prüfung an den
            Dokument-Verantwortlichen zuweisen.
          </Typography>
          <br />
          <div>
            <form className="form">
              <UpdateFileDataForm
                control={control}
                revisionChecked={revisionChecked}
              />
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="secondary"
            autoFocus
          >
            {revisionChecked ? "BEARBEITUNG BEENDEN" : "SPEICHERN"}
          </Button>
          <Button onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
