import { apiSlice } from "../../app/api/apiSlice";

export const officesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOffices: builder.query({
      query: () => "/office",
      // keepUnusedDataFor: 5,
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: "Office", id })), "Office"]
          : ["Office"],
    }),
    addNewOffice: builder.mutation({
      query: (office) => ({
        url: "/office",
        method: "POST",
        body: {
          ...office,
        },
        invalidatesTags: ["Office"],
      }),
    }),
    updateOffice: builder.mutation({
      query: (data) => ({
        url: "/office",
        method: "PATCH",
        body: { ...data },
        invalidatesTags: ["Office"],
      }),
    }),
    deleteOffice: builder.mutation({
      query: (data) => ({
        url: "/office",
        method: "DELETE",
        body: data,
        invalidatesTags: ["Office"],
      }),
    }),
    getOfficeAreas: builder.query({
      query: (officeId) => ({
        url: `/office/areas/${officeId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Event", id: arg }],
    }),
    addNewArea: builder.mutation({
      query: (data) => ({
        url: "/office/area",
        method: "POST",
        body: {
          ...data,
        },
        invalidatesTags: ["Office"],
      }),
    }),
    deleteArea: builder.mutation({
      query: (data) => ({
        url: "/office/area",
        method: "DELETE",
        body: data,
        invalidatesTags: ["Office"],
      }),
    }),
    updateArea: builder.mutation({
      query: (data) => ({
        url: "/office/area",
        method: "PATCH",
        body: { ...data },
        invalidatesTags: ["Office"],
      }),
    }),
    assignEmployeesToOffice: builder.mutation({
      query: (data) => ({
        url: "/office/assignEmployees",
        method: "POST",
        body: {
          ...data,
        },
        invalidatesTags: ["Office"],
      }),
    }),
    updateEmployeesToArea: builder.mutation({
      query: (data) => ({
        url: "/office/updateEmployeesToArea",
        method: "POST",
        body: {
          ...data,
        },
        invalidatesTags: ["Office"],
      }),
    }),
  }),
});

export const {
  useGetOfficesQuery,
  useAddNewOfficeMutation,
  useUpdateOfficeMutation,
  useDeleteOfficeMutation,
  useGetOfficeAreasQuery,
  useAddNewAreaMutation,
  useDeleteAreaMutation,
  useUpdateAreaMutation,
  useAssignEmployeesToOfficeMutation,
  useUpdateEmployeesToAreaMutation,
} = officesApiSlice;
