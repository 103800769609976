import React from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Stack,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetNewsByStatusQuery } from "../../../features/news/newsApiSlice";
import NewsItem from "./NewsItem";
import Iconify from "../../../components/iconify";
import Loading from "../../../components/spinners/Loading";

export default function ArchivNewsPage() {
  const navigate = useNavigate();

  // Hole alle archivierten News
  const {
    data: archivedNews,
    isLoading,
    error,
  } = useGetNewsByStatusQuery("archived");

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Typography variant="h6" align="center" color="error" sx={{ mt: 3 }}>
        Fehler beim Laden der archivierten Beiträge.
      </Typography>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3, backgroundColor: "#f9f9f9" }}>
      {/* Top Navigation und Überschrift */}
      <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
        <IconButton onClick={() => navigate(-1)}>
          <Iconify icon="eva:arrow-back-fill" style={{ fontSize: 40 }} />
        </IconButton>
        <Typography variant="h4" sx={{ color: "#006666", ml: 2 }}>
          Archiv
        </Typography>
      </Stack>

      {/* Top Divider */}
      <Divider
        sx={{
          borderBottomWidth: 3,
          backgroundColor: "#006666",
          mt: 3,
          mb: 3,
        }}
      />

      {/* News Grid */}
      {archivedNews?.length > 0 ? (
        <Grid container spacing={2}>
          {archivedNews
            .slice()
            .reverse()
            .map((news) => (
              <Grid item xs={12} key={news._id}>
                <NewsItem news={news} />
                <Divider
                  sx={{
                    borderBottomWidth: 3,
                    backgroundColor: "#006666",
                    mt: 2,
                  }}
                />
              </Grid>
            ))}
        </Grid>
      ) : (
        <Typography variant="h6" align="center" sx={{ mt: 3 }}>
          Keine archivierten Beiträge gefunden.
        </Typography>
      )}
    </Box>
  );
}
