import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  useMediaQuery,
  Tooltip,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import Message from "./Message";
import { useGetMessagesQuery } from "../../../features/chats/chatsApiSlice";
import { AvatarIntern } from "../../../components/avatar";
import { getSocket } from "../../../app/socket/socket";
import { selectCurrentUserData } from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import Iconify from "../../../components/iconify";

// ---------------------------------------------------------------------------

const formatDateBadge = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString("de-DE", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

const groupMessagesByDate = (messages) => {
  return messages.reduce((groups, message) => {
    const messageDate = new Date(message.timestamp).toDateString();
    if (!groups[messageDate]) groups[messageDate] = [];
    groups[messageDate].push(message);
    return groups;
  }, {});
};
// ---------------------------------------------------------------------------

const ChatWindow = ({ chat, onBack }) => {
  const currentUser = useSelector(selectCurrentUserData);
  const userId = currentUser?.id;
  const [newMessage, setNewMessage] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const messagesEndRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    data: userMessages,
    error: chatError,
    isLoading: chatLoading,
    refetch,
  } = useGetMessagesQuery(chat?._id);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // Scroll to bottom on new messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const socket = getSocket();
    if (chat && chat._id) {
      socket.emit("joinChat", chat._id);
      socket.emit("markMessagesAsRead", chat._id, userId);
    }

    socket.on("receiveMessage", (messageData) => {
      if (messageData.chatId === chat._id) {
        refetch();
      }
    });

    return () => {
      socket.off("receiveMessage");
    };
  }, [chat, refetch, userId]);

  useEffect(() => {
    scrollToBottom();
    if (chat && userMessages) {
      setUserInfo(userMessages?.userInfo?.[0]);
    }
  }, [userMessages, chat]);

  const handleSendMessage = () => {
    const socket = getSocket();
    if (newMessage.trim()) {
      const messageData = {
        chatId: chat._id,
        from: userId,
        text: newMessage,
        timestamp: new Date().toISOString(),
      };

      socket.emit("sendMessage", messageData, (acknowledgment) => {
        if (acknowledgment?.success) {
          refetch();
        } else {
          console.error(
            "Fehler beim Senden der Nachricht:",
            acknowledgment?.error
          );
        }
      });

      setNewMessage("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  if (chatLoading) {
    return (
      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (chatError) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Typography variant="h6" color="textSecondary">
          Es gab einen Fehler beim Laden der Nachrichten. <br />
          Bitte versuche es später noch einmal.
        </Typography>
      </Box>
    );
  }

  const groupedMessages = groupMessagesByDate(userMessages?.messages || []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          padding: 2,
          display: "flex",
          alignItems: "center",
          mt: isMobile ? 8 : 0,
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isMobile && (
            <IconButton onClick={onBack} sx={{ mr: 2 }}>
              <Iconify icon="material-symbols:arrow-back-ios" width={24} />
            </IconButton>
          )}
          {chat?.isGroup ? (
            <>
              <Iconify
                icon="pepicons-pencil:people-circle-filled"
                width={30}
                sx={{ mr: 2 }}
              />
              <Typography variant="h6" fontWeight="bold">
                {chat?.groupName}
              </Typography>
            </>
          ) : userInfo ? (
            <>
              <AvatarIntern
                name={`${userInfo.firstname} ${userInfo.lastname}`}
              />
              <Typography variant="h6" sx={{ marginLeft: "16px" }}>
                {`${userInfo.firstname} ${userInfo.lastname}`}
              </Typography>
            </>
          ) : (
            <Typography variant="h6" sx={{ marginLeft: "16px" }}>
              Lade Benutzerinformationen...
            </Typography>
          )}
        </Box>

        {/* Icon für Gruppenmitglieder */}
        {chat?.isGroup && (
          <Tooltip title="Mitglieder">
            <IconButton onClick={handlePopoverOpen}>
              <Iconify icon="ic:baseline-group" width={24} />
            </IconButton>
          </Tooltip>
        )}

        {/* Popover mit Mitgliederliste */}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={{ p: 2, minWidth: 200 }}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
              Gruppenmitglieder
            </Typography>
            <List>
              {chat?.users?.map((member) => (
                <ListItem key={member.id}>
                  <ListItemAvatar>
                    <AvatarIntern
                      name={`${member.firstName} ${member.lastName}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${member.firstName} ${member.lastName}`}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Popover>
      </Box>

      {/* Nachrichtenanzeige */}
      <Box sx={{ flexGrow: 1, overflowY: "overlay", p: 3 }}>
        {Object.entries(groupedMessages).map(([date, messages]) => (
          <React.Fragment key={date}>
            <Box
              sx={{
                textAlign: "center",
                mb: 2,
                fontSize: "0.875rem",
                color: "gray",
                padding: "4px 8px",
                backgroundColor: "#e0e0e0",
                borderRadius: "8px",
                alignSelf: "center",
                width: "fit-content",
                margin: "16px auto",
              }}
            >
              {formatDateBadge(date)}
            </Box>
            {messages.map((message, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Message
                  message={message}
                  isOwn={message.isOwn}
                  currentUser={userInfo}
                  isGroup={chat.isGroup}
                />
              </Box>
            ))}
          </React.Fragment>
        ))}
        <div ref={messagesEndRef} />
      </Box>

      {/* Nachrichten senden */}
      <Box
        sx={{
          p: 2,
          borderTop: "1px solid #ddd",
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextField
          fullWidth
          placeholder="Nachricht schreiben..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button onClick={handleSendMessage} sx={{ ml: 1 }}>
          Senden
        </Button>
      </Box>
    </Box>
  );
};

export default ChatWindow;
