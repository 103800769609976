import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, Button, MenuItem, Typography } from "@mui/material";
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

BlogPostsSort.propTypes = {
  options: PropTypes.array.isRequired, // Array of sorting options
  value: PropTypes.string.isRequired, // Currently selected sorting value
  setValue: PropTypes.func.isRequired, // Function to update sorting value
};

// ----------------------------------------------------------------------

export default function BlogPostsSort({ options, value, setValue }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOptionSelect = (option) => {
    setValue(option); // Update the selected sort value using setValue
    handleClose();
  };

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        sx={{
          textTransform: "none",
        }}
        endIcon={
          <Iconify
            icon={open ? "eva:chevron-up-fill" : "eva:chevron-down-fill"}
          />
        }
      >
        Sortieren nach:&nbsp;
        <Typography
          component="span"
          variant="subtitle2"
          sx={{ color: "text.secondary" }}
        >
          {options.find((option) => option.value === value)?.label}
        </Typography>
      </Button>

      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === value}
            onClick={() => handleOptionSelect(option.value)}
            sx={{ typography: "body2" }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
