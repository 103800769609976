import { Backdrop } from "@mui/material";
import React from "react";
import { PuffLoader } from "react-spinners";

const override = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function Loading({ backdrop }) {
  if (backdrop) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <PuffLoader color="#36d7b7" size={250} cssOverride={override} />
      </Backdrop>
    );
  }

  return (
    <div>
      <PuffLoader color="#36d7b7" size={250} cssOverride={override} />
    </div>
  );
}

export default Loading;
