import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
// @mui
import { Stack, Button, Container, Typography } from "@mui/material";
// components
import { setDialog } from "../features/dialog/dialogSlice";
import Iconify from "../components/iconify";
import NewUserListTable from "../sections/@dashboard/user/NewUserListTable";
import { useGetUsersQuery } from "../features/users/usersApiSlice";
import { useGetTenantInformationQuery } from "../features/tenants/tenantsApiSlice";
import { allowNewUser, getCurrentUserCount } from "../utils/tenantUtils";
import Loading from "../components/spinners/Loading";

// ----------------------------------------------------------------------

export default function UserPage() {
  const dispatch = useDispatch();
  const { data: userList, isLoading } = useGetUsersQuery();
  const { data: tenant, isLoading: isLoadingTenant } =
    useGetTenantInformationQuery();

  const userCount = tenant && getCurrentUserCount(tenant);

  const handleOpenNewUserDialog = () => {
    if (allowNewUser(tenant)) {
      dispatch(
        setDialog({
          title: "Neuen Benutzer erstellen",
          type: "CREATE",
          content: "create-user-form",
        })
      );
    } else {
      dispatch(
        setDialog({
          title: "Maximale Anzahl an Benutzern erreicht!",
          type: "REDIRECT",
          text: "Die für diese Praxis maximale Anzahl an Benutzern wurde erreicht. Wenn Sie mehr Benutzer benötigen, buchen Sie bitte unter Einstellungen -> Module -> WEITERE BENUTZER BUCHEN die gewünschte Anzahl an Benutzern hinzu.",
          redirectTo: "/dashboard/settings/modules",
          redirectButtonText: "ZUR BUCHUNGSSEITE",
        })
      );
    }
  };

  if (isLoading || isLoadingTenant) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title> Digit QM | Benutzer </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Benutzer
          </Typography>
          <Button
            variant="contained"
            onClick={handleOpenNewUserDialog}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Neuer Benutzer
          </Button>
        </Stack>
        {userCount && userList && (
          <NewUserListTable userList={userList} userCount={userCount} />
        )}
      </Container>
    </>
  );
}
