import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Divider,
  Stack,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGetNewsByStatusQuery } from "../../../features/news/newsApiSlice";
import { setDialog } from "../../../features/dialog/dialogSlice";
import DraftNewsItem from "./DraftNewsItem";
import Iconify from "../../../components/iconify";
import Loading from "../../../components/spinners/Loading";

export default function DraftsNewsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Hole alle News mit dem Status "draft" (Entwürfe)
  const {
    data: draftNews,
    isLoading,
    isError,
    error,
  } = useGetNewsByStatusQuery("draft");
  console.log("draftNews", draftNews);

  const handleEditDraft = (newsItem) => {
    dispatch(
      setDialog({
        content: "NewsDialog",
        data: newsItem,
        eventFlag: "EDIT",
      })
    );
  };

  const handleDeleteDraft = (newsItem) => {
    console.log("Delete draft: ", newsItem._id);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* Top Navigation and Title */}
      <Stack direction="row" alignItems="center">
        <IconButton onClick={() => navigate(-1)}>
          <Iconify icon="eva:arrow-back-fill" />
        </IconButton>
        <Typography variant="h4" sx={{ color: "#006666", ml: 2 }}>
          Meine Entwürfe
        </Typography>
      </Stack>

      <Divider
        sx={{
          borderBottomWidth: 3,
          backgroundColor: "#006666",
          mt: 3,
          mb: 3,
        }}
      />

      {/* News Grid */}
      {draftNews && draftNews.length > 0 ? (
        <Grid container spacing={2}>
          {draftNews.map((news) => (
            <Grid item xs={12} key={news._id}>
              <DraftNewsItem
                news={news}
                onEdit={() => handleEditDraft(news)}
                onDelete={() => handleDeleteDraft(news)}
              />
              <Divider
                sx={{ borderBottomWidth: 3, backgroundColor: "#006666" }}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="h6" align="center" sx={{ mt: 3 }}>
          Keine Entwürfe gefunden.
        </Typography>
      )}

      {/* Bottom Divider */}
    </Box>
  );
}
