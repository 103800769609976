import React from "react";
import PropTypes from "prop-types";
import { Card, Typography, Stack, Tooltip, Grid, Box } from "@mui/material";
import Iconify from "../../../components/iconify";

function EntryCard({ data, handleClickOpen, selected }) {
  return (
    <Card
      elevation={3}
      sx={{
        mb: 0.5,
        display: "flex",
        borderRadius: "5px",
        border: "1px solid #e0e0e0",
        background: selected ? "#d4d4d4" : "#ffffff",
        cursor: "pointer",
        "&:hover": {
          background: "#e8e8e8",
        },
      }}
      onClick={() => handleClickOpen(data)}
    >
      <Box
        sx={{
          width: "30px",
          backgroundColor:
            data.incidentType === "fehler"
              ? "#d9f3f2"
              : data.incidentType === "beschwerde"
              ? "#aee3e4"
              : "#48aead",
          borderRadius: "5px 0 0 5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          fontSize: "0.75rem",
          fontWeight: "bold",
          padding: "2px",
        }}
      >
        <Tooltip
          title={
            data.incidentType === "fehler"
              ? "Fehler"
              : data.incidentType === "beschwerde"
              ? "Beschwerde"
              : "Risiko"
          }
        >
          <Typography
            sx={{
              textAlign: "center",
              color: "black",
            }}
          >
            {data.incidentType.charAt(0).toUpperCase()}
          </Typography>
        </Tooltip>
      </Box>
      <Box sx={{ flex: 1, padding: 1 }}>
        <Stack spacing={2}>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: "13px",
              fontWeight: "bold",
              textDecoration: data.status === "done" ? "line-through" : "none",
              textDecorationThickness: "1.5px",
            }}
          >
            {data.title}
          </Typography>
          <Grid container spacing={1}>
            {!data.anonym && (
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="ic:twotone-person" size={20} />
                  <Typography sx={{ fontSize: "12px" }}>
                    Erstellt von: {data.creator.firstName}{" "}
                    {data.creator.lastName}
                  </Typography>
                </Stack>
              </Grid>
            )}
            {data.responsibleUser && (
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="ion:person-outline" size={20} />
                  <Typography sx={{ fontSize: "12px" }}>
                    Verantwortlicher: {data.responsibleUser.firstName}{" "}
                    {data.responsibleUser.lastName}
                  </Typography>
                </Stack>
              </Grid>
            )}
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Iconify icon="clarity:date-line" size={20} />
                <Typography sx={{ fontSize: "12px" }}>
                  Erstelldatum:{" "}
                  {new Date(data.createdAt).toLocaleDateString("de-DE")}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      {data.classification === "schwer" && (
        <Tooltip title={"Einstufung: SCHWER"}>
          <Iconify
            icon="bi:exclamation-circle-fill"
            sx={{
              color: "red",
              width: 27,
              height: 27,
              marginRight: 2,
              alignSelf: "center",
            }}
          />
        </Tooltip>
      )}
      {(data.status === "abgeschlossen" || data.status === "notRelevant") && (
        <Tooltip title={"Status: ABGESCHLOSSEN"}>
          <Iconify
            icon="ic:sharp-done"
            sx={{
              color: "green",
              width: 27,
              height: 27,
              marginRight: 2,
              alignSelf: "center",
            }}
          />
        </Tooltip>
      )}
    </Card>
  );
}

EntryCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    incidentType: PropTypes.string.isRequired,
    status: PropTypes.string,
    creator: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }).isRequired,
    responsibleUser: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    createdAt: PropTypes.string.isRequired,
    classification: PropTypes.string,
  }).isRequired,
  handleClickOpen: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default EntryCard;
