import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import Iconify from "../components/iconify";
import { BlogPostsSort } from "../sections/@dashboard/blog";
import NewsGrid from "../sections/@dashboard/news/NewsGrid";
import SearchBar from "../sections/@dashboard/blog/NewsSearch";
import { useDispatch } from "react-redux";
import { setDialog } from "../features/dialog/dialogSlice";
import { useGetNewsByStatusQuery } from "../features/news/newsApiSlice";
import { useLocation, useNavigate } from "react-router-dom";

const SORT_OPTIONS = [
  { value: "latest", label: "Aktuellste" },
  { value: "oldest", label: "Älteste" },
];

export default function NewsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: newsDrafts } = useGetNewsByStatusQuery("draft");

  const {
    data: newsData,
    isLoading,
    refetch,
  } = useGetNewsByStatusQuery("publish");

  useEffect(() => {
    refetch();
  }, [location.state, refetch]);

  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("latest");

  // Handle loading state
  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  // Ensure newsData is an array
  let newsItems = Array.isArray(newsData) ? newsData : [];

  // Filter newsItems based on the search query
  if (searchQuery) {
    newsItems = newsItems.filter((news) => {
      const searchText = searchQuery.toLowerCase();
      return (
        news.title.toLowerCase().includes(searchText) ||
        news.subtitle.toLowerCase().includes(searchText) ||
        news.content.toLowerCase().includes(searchText) ||
        (news.author &&
          (news.author.firstName.toLowerCase().includes(searchText) ||
            news.author.lastName.toLowerCase().includes(searchText)))
      );
    });
  }

  // Sorting logic based on the selected sort option
  newsItems = newsItems.slice(); // Create a copy to avoid mutating the original data
  switch (sortOption) {
    case "latest":
      newsItems.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      break;
    case "oldest":
      newsItems.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      break;
    default:
      break;
  }

  const handleCreateNewBlog = () => {
    dispatch(setDialog({ content: "NewsDialog", data: "", eventFlag: "NEW" }));
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> Digit QM | Neuigkeiten </title>
      </Helmet>

      <div>
        <Stack
          sx={{
            flexGrow: 1,
            pl: 3,
            pr: 3,
            pb: 3,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
          }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            Neuigkeiten
          </Typography>

          <Button
            onClick={handleCreateNewBlog}
            variant="contained"
            sx={{
              textTransform: "none",
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Neuer Beitrag
          </Button>
        </Stack>

        <Stack
          mb={5}
          sx={{
            flexGrow: 1,
            pl: 3,
            pr: 3,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
          }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <SearchBar
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Suche..."
          />
          <Stack direction="row" spacing={2}>
            <Badge
              badgeContent={newsDrafts?.length} // Die Anzahl der Entwürfe
              color="error"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#4A90E2", // Ein klares, modernes Blau
                  "&:hover": {
                    backgroundColor: "#357ABD", // Dunkleres Blau beim Hover
                  },
                  textTransform: "none",
                }}
                onClick={() => navigate("drafts")}
              >
                Meine Entwürfe
              </Button>
            </Badge>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#50C878", // Ein grüner Farbton für 'archiv'
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#3DA96E", // Dunklerer Grünton beim Hover
                },
              }}
              onClick={() => navigate("archive")}
            >
              Archivierte Beiträge
            </Button>

            <BlogPostsSort
              options={SORT_OPTIONS}
              value={sortOption}
              setValue={setSortOption}
            />
          </Stack>
        </Stack>

        <NewsGrid newsData={newsItems} />
      </div>
    </>
  );
}
