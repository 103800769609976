import React, { useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  CardHeader,
  Chip,
  IconButton,
  Link,
  TextField,
  Button,
  Menu,
  MenuItem,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";
import {
  useAddNewAreaMutation,
  useDeleteAreaMutation,
  useUpdateAreaMutation, // Für das Aktualisieren der Area
} from "src/features/offices/officesApiSlice";
import { apiSlice } from "src/app/api/apiSlice";
import useAuth from "../../../hooks/useAuth";

export default function OfficeCardItem({ office, lastOffice }) {
  const [addNewArea] = useAddNewAreaMutation();
  const [deleteArea] = useDeleteAreaMutation();
  const [updateArea] = useUpdateAreaMutation(); // Mutation zum Aktualisieren eines Bereichs
  const dispatch = useDispatch();

  const { isSuperAdmin } = useAuth();

  const [areas, setAreas] = useState(office?.area || []);
  const [newArea, setNewArea] = useState("");
  const [isCreatingArea, setIsCreatingArea] = useState(false);
  const [isEditingArea, setIsEditingArea] = useState(false); // Für den Bearbeitungsmodus
  const [selectedAreaForEdit, setSelectedAreaForEdit] = useState(null); // Der ausgewählte Bereich zum Bearbeiten
  const [areaExistsError, setAreaExistsError] = useState(false); // Fehlerstatus

  const [anchorEl, setAnchorEl] = useState(null); // Für Dropdown-Menü
  const [selectedArea, setSelectedArea] = useState(null); // Der ausgewählte Bereich für das Menü

  // delete Praxis - Dialog
  const openDelete = () => {
    dispatch(
      setDialog({
        title: "Praxis löschen",
        type: "DELETE",
        content: "office-delete",
        data: office,
      })
    );
  };

  // Assign employees to office - Dialog
  const handleAdd = () => {
    dispatch(
      setDialog({
        content: "office-assign-employees",
        data: office,
      })
    );
  };

  // edit Praxis - Dialog
  const openEdit = () => {
    dispatch(
      setDialog({
        title: "Praxis bearbeiten",
        type: "EDIT",
        content: "office-form",
        data: office,
      })
    );
  };

  // create new area with handling error (already exist)
  const handleCreateNewArea = async () => {
    if (areas.some((area) => area.name === newArea.trim())) {
      setAreaExistsError(true);
    } else {
      setAreaExistsError(false);

      await addNewArea({ officeId: office?._id, newAreaName: newArea });
      setAreas([...areas, { name: newArea }]);
      setNewArea("");
      setIsCreatingArea(false);
      dispatch(apiSlice.util.resetApiState());
    }
  };

  const handleDeleteArea = async (areaToDelete) => {
    const result = await deleteArea({
      officeId: office?._id,
      areaNameToDelete: areaToDelete,
    });
    if (result) {
      setAreas(areas.filter((area) => area.name !== areaToDelete.name));
      dispatch(apiSlice.util.resetApiState());
    }
  };

  const handleOpenMenu = (event, area) => {
    setAnchorEl(event.currentTarget);
    setSelectedArea(area);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedArea(null);
  };

  // Assign employees to an area - Dialog
  const handleAssignEmployees = () => {
    dispatch(
      setDialog({
        title: "Mitarbeiter zuweisen",
        content: "assign-employees",
        data: { office, selectedArea },
      })
    );
    handleCloseMenu();
  };

  // Bereich bearbeiten
  const handleEditArea = () => {
    setNewArea(selectedArea.name); // Setze den aktuellen Namen in das Textfeld
    setIsEditingArea(true); // Bearbeitungsmodus aktivieren
    setSelectedAreaForEdit(selectedArea); // Speichere den Bereich, der bearbeitet wird
    setIsCreatingArea(true); // Textfeld anzeigen
    handleCloseMenu();
  };

  // Bereich aktualisieren
  const handleUpdateArea = async () => {
    if (
      areas.some(
        (area) =>
          area.name === newArea.trim() && area._id !== selectedAreaForEdit._id
      )
    ) {
      setAreaExistsError(true);
    } else {
      setAreaExistsError(false);

      const updatedArea = { ...selectedAreaForEdit, name: newArea };
      await updateArea({
        officeId: office?._id,
        areaId: selectedAreaForEdit._id,
        updatedArea,
      });

      dispatch(apiSlice.util.resetApiState());

      setIsEditingArea(false);
      setSelectedAreaForEdit(null);
      setNewArea("");
      setIsCreatingArea(false);
    }
  };

  const handleDeleteSelectedArea = () => {
    handleDeleteArea(selectedArea);
    handleCloseMenu();
  };

  return (
    <Card sx={{ minWidth: 275, borderRadius: 3 }}>
      <CardHeader
        title={`${office?.officeName}`}
        titleTypographyProps={{ variant: "h4", textAlign: "center" }}
        subheader={`Kontaktperson: ${office?.contactPerson}`}
        subheaderTypographyProps={{ textAlign: "center" }}
      />
      <CardContent>
        <Typography sx={{ mb: 2 }} textAlign="center">
          <Link
            href={`https://${office?.homepage}`}
            target="_blank"
            underline="hover"
            variant="h6"
          >
            {office?.homepage}
          </Link>
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant="h6">
          Benutzer: {office?.employees?.length}
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant="h6">
          Adresse
        </Typography>
        <Typography color="text.secondary">{office?.street}</Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {office?.address} <br />
          {office?.zip}, {office?.city}
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant="h6">
          Bereiche
        </Typography>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          <Chip
            label={
              <>
                <Icon icon="lets-icons:add-round" />
                Neuer Bereich
              </>
            }
            onClick={() => setIsCreatingArea(true)}
            color="primary"
          />
          {areas.map((area, index) => (
            <Chip
              key={index}
              label={
                <>
                  {area.name}
                  <Icon icon="gridicons:dropdown" />
                </>
              }
              onClick={(event) => handleOpenMenu(event, area)}
            />
          ))}
        </div>
        {isCreatingArea && (
          <div style={{ marginTop: "10px" }}>
            <TextField
              label={
                isEditingArea ? "Bereich bearbeiten" : "Neuen Bereich eingeben"
              }
              variant="outlined"
              value={newArea}
              onChange={(e) => setNewArea(e.target.value)}
              fullWidth
            />
            {/* Zeige die Fehlermeldung an, wenn der Bereich bereits existiert */}
            {areaExistsError && (
              <FormHelperText error>
                Bereich mit diesem Namen existiert bereits
              </FormHelperText>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={isEditingArea ? handleUpdateArea : handleCreateNewArea}
              style={{ marginTop: "10px" }}
            >
              {isEditingArea ? "Aktualisieren" : "Speichern"}
            </Button>
          </div>
        )}
      </CardContent>
      <CardActions style={{ justifyContent: "space-between" }} disableSpacing>
        <Tooltip title="Standort bearbeiten">
          <IconButton onClick={openEdit} aria-label="Standort bearbeiten">
            <Icon icon="material-symbols:edit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Mitarbeiter zuweisen">
          <IconButton onClick={handleAdd} aria-label="Mitglied hinzufügen">
            <Icon icon="material-symbols:person-add" />
          </IconButton>
        </Tooltip>
        {isSuperAdmin && (
          <IconButton
            disabled={lastOffice}
            onClick={openDelete}
            aria-label="Standort löschen"
          >
            <Icon icon="material-symbols:delete" />
          </IconButton>
        )}
      </CardActions>

      {/* Dropdown-Menü für Bereiche */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleAssignEmployees}>
          <Icon icon="material-symbols:person-add" style={{ marginRight: 8 }} />
          Mitarbeiter zuweisen
        </MenuItem>
        <MenuItem onClick={handleEditArea}>
          <Icon icon="material-symbols:edit" style={{ marginRight: 8 }} />
          Bereich bearbeiten
        </MenuItem>
        <MenuItem onClick={handleDeleteSelectedArea}>
          <Icon icon="material-symbols:delete" style={{ marginRight: 8 }} />
          Bereich löschen
        </MenuItem>
      </Menu>
    </Card>
  );
}

OfficeCardItem.propTypes = {
  office: PropTypes.object.isRequired,
  lastOffice: PropTypes.bool,
};
