import React from "react";
import { Snackbar, Button, Slide } from "@mui/material";
import Iconify from "../iconify";

export default function MySlideSnackbar({ open, onClose, message }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      message={message}
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      TransitionProps={{ direction: "up" }}
      action={
        <Button color="secondary" size="small" onClick={onClose}>
          <Iconify icon="fa-solid:times" />
        </Button>
      }
      sx={{
        color: "white",
        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
      }}
    />
  );
}
