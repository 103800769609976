import { HelmetProvider } from "react-helmet-async";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// routes
import Router from "./pages/routes";
// theme
import ThemeProvider from "./theme";
import "./App.css";
// components
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import eventBus from "./common/EventBus";

import { logOut, selectCurrentToken } from "./features/auth/authSlice";
import { useRefreshMutation } from "./features/auth/authApiSlice";
import Loading from "./components/spinners/Loading";
import { SocketProvider } from "./app/socket/SocketProvider";

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  const [{ isLoading }] = useRefreshMutation();

  const handleLogOut = useCallback(() => {
    dispatch(logOut());
  }, [dispatch]);

  useEffect(() => {
    eventBus.on("logout", () => {
      handleLogOut();
    });

    return () => {
      eventBus.remove("logout");
    };
  }, [handleLogOut]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <HelmetProvider>
      <SocketProvider>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router />
        </ThemeProvider>
      </SocketProvider>
    </HelmetProvider>
  );
}
