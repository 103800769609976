import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useRefreshMutation } from "./authApiSlice";
import usePersist from "../../hooks/usePersist";
import { selectCurrentToken } from "./authSlice";
import Loading from "../../components/spinners/Loading";

const PersistLogin = () => {
  const [persist] = usePersist();
  const token = useSelector(selectCurrentToken);
  const effectRan = useRef(false);
  const [trueSuccess, setTrueSuccess] = useState(false);
  const [refresh, { isUninitialized, isLoading, isSuccess, isError }] =
    useRefreshMutation();
  const location = useLocation();

  useEffect(() => {
    if (effectRan.current === true || process.env.NODE_ENV !== "development") {
      // React 18 Strict Mode

      const verifyRefreshToken = async () => {
        try {
          // const response =
          await refresh();
          // const { accessToken } = response.data
          setTrueSuccess(true);
        } catch (err) {
          console.error(err);
        }
      };

      if (!token && persist) verifyRefreshToken();
    }

    return () => {
      effectRan.current = true;
    };

    // eslint-disable-next-line
  }, []);

  let content;
  if (!persist) {
    // persist: no
    // console.log('no persist');
    content = <Outlet />;
  } else if (isLoading) {
    // persist: yes, token: no
    // console.log('loading');
    content = <Loading />;
  } else if (isError) {
    // persist: yes, token: no
    // console.log('error');
    // window.alert('Ihr Zugang ist abgelaufen.\nBitte melden Sie sich erneut an.');
    content = <Navigate to="/login" state={{ from: location }} replace />;
  } else if (isSuccess && trueSuccess) {
    // persist: yes, token: yes
    // console.log('success');
    content = <Outlet />;
  } else if (token && isUninitialized) {
    // persist: yes, token: yes
    // console.log('token and uninit');
    // console.log(isUninitialized);
    content = <Outlet />;
  }

  return content;
};
export default PersistLogin;
