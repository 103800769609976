// Importiere den bestehenden apiSlice
import { apiSlice } from "../../app/api/apiSlice";

// Erweitere den apiSlice um die Dashboard-Endpunkte
export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardData: builder.query({
      query: () => ({
        url: `/dashboard`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => {
        if (result) {
          const tags = [{ type: "Dashboard", id: "LIST" }];

          return tags;
        }
        return [{ type: "Dashboard", id: "LIST" }];
      },
    }),
  }),
});

export const { useGetDashboardDataQuery } = dashboardApiSlice;
