import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useController } from "react-hook-form";

//---------------------------------------------------------------------------------
ControlledSelectField.propTypes = {
  control: PropTypes.object,
  style: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  helperText: PropTypes.string,
  multiline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

//---------------------------------------------------------------------------------

export default function ControlledSelectField({
  control,
  style,
  name,
  label,
  errorMsg,
  fullWidth,
  disabled,
  helperText,
  itemArray,
  required,
  defaultValue,
}) {
  const {
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: required,
    },
  });

  const getColor = (value) => {
    switch (value) {
      case "haeufig":
        return "#FF0000"; // Rot
      case "schwer":
        return "#FF0000"; // Rot
      case "hoch":
        return "#FF0000"; // Rot
      case "mittel":
        return "#FFFF00"; // Gelb
      case "selten":
        return "#FFFF00"; // Gelb
      case "erstmalig":
        return "#008000"; // Grün
      case "gering":
        return "#008000"; // Grün
      case "leicht":
        return "#008000"; // Grün
      case "#008000":
        return "#008000"; // Dunkelgrün
      case "#77dd77":
        return "#77dd77"; // Grün
      case "#0000FF":
        return "#0000FF"; // Dunkelblau
      case "#89cff0":
        return "#89cff0"; // Blau
      case "#FF007F":
        return "#FF007F"; // Pink
      case "#ffb6c1":
        return "#ffb6c1"; // Rosa
      case "#9400D3":
        return "#9400D3"; // Violett
      case "#b39eb5":
        return "#b39eb5"; // Lila
      default:
        return "transparent";
    }
  };

  return (
    <FormControl style={style} fullWidth error={error}>
      <InputLabel id={`${name}-select-field-label`}>
        {required ? label + "*" : label}
      </InputLabel>
      <Controller
        render={({ field }) => (
          <Select
            id={`${name}-select-field`}
            label={label}
            {...field}
            disabled={disabled}
            required={required}
            defaultValue={defaultValue}
          >
            {itemArray.map((item) => (
              <MenuItem key={item.value + "-key"} value={item.value}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: 18,
                      height: 18,
                      borderRadius: "50%",
                      backgroundColor: getColor(item.value),
                      marginRight: 1,
                    }}
                  />
                  {item.label}
                </Box>
              </MenuItem>
            ))}
          </Select>
        )}
        control={control}
        name={name}
        fullWidth={fullWidth}
      />
      {error && <FormHelperText>{errorMsg}</FormHelperText>}
      {helperText && !error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
