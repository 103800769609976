import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  FormDesigner,
  PageOrganizer,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { downloadFileByID } from "../../../../app/api/axios/services/files.service";
import { arrayBufferToBase64 } from "../../../../utils/fileUtils";

// For later use:
// https://hub.docker.com/r/syncfusion/pdfviewer-server
// Server to local run syncfusion service

function PdfViewer({ data }) {
  const { _id } = data.fileData;
  const viewerRef = useRef(null);

  const loadDocument = async () => {
    try {
      const file = await downloadFileByID(_id); // Download file with id

      const base64StringFile = arrayBufferToBase64(file.data);
      if (viewerRef.current) {
        viewerRef.current.load(
          "data:application/pdf;base64," + base64StringFile
        );
        viewerRef.current.fileName = data?.fileName;
      }
    } catch (error) {
      console.error("Error loading document:", error);
    }
  };

  React.useEffect(() => {
    loadDocument();
  });

  const documentLoaded = (args) => {
    console.log("Do stuff when doc is loaded 🖥️");
    // console.log("args.documentName :>> ", args.documentName);
  };

  return (
    <div style={{ height: "100%", overflow: "scroll" }}>
      {/* Render the PDF Viewer */}
      <PdfViewerComponent
        ref={viewerRef}
        id="container"
        // resourceUrl="https://cdn.syncfusion.com/ej2/23.2.6/dist/ej2-pdfviewer-lib"
        // serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
        serviceUrl="https://services.syncfusion.com/react/production/api/pdfviewer"
        style={{ height: "100%" }}
        documentLoad={documentLoaded}
        downloadFileName={data?.fileName}
      >
        <Inject
          services={[
            Toolbar,
            Magnification,
            Navigation,
            LinkAnnotation,
            BookmarkView,
            ThumbnailView,
            Print,
            TextSelection,
            TextSearch,
            Annotation,
            FormFields,
            FormDesigner,
            PageOrganizer,
          ]}
        />
      </PdfViewerComponent>
    </div>
  );
}

PdfViewer.propTypes = {
  data: PropTypes.shape({
    fileId: PropTypes.string.isRequired,
  }).isRequired,
};

export default PdfViewer;
