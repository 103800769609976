import { apiSlice } from "../../app/api/apiSlice";

export const fileDataApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFileDatas: builder.query({
      query: () => ({
        url: "/filedata",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
    }),
    getFileDatasWithReadingConfirmation: builder.query({
      query: () => ({
        url: "/filedata/reading-confirmation",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
    }),
    getFileDataByPath: builder.mutation({
      query: (data) => ({
        url: "/filedata/getFileDataByPath",
        method: "POST",
        body: data,
      }),
    }),
    addNewFileData: builder.mutation({
      query: (filedata) => ({
        url: "/filedata",
        method: "POST",
        body: {
          ...filedata,
        },
        invalidatesTags: ["FileData"],
      }),
    }),
    createProofDocFromFormDoc: builder.mutation({
      query: (filedata) => ({
        url: "/filedata/createproof",
        method: "POST",
        body: {
          ...filedata,
        },
        invalidatesTags: ["FileData"],
      }),
    }),
    updateFileData: builder.mutation({
      query: (data) => ({
        url: "/filedata",
        method: "PATCH",
        body: { ...data },
        invalidatesTags: ["FileData"],
      }),
      // invalidatesTags: [{ type: 'File', id: 'LIST' }],
    }),
    assignFileData: builder.mutation({
      query: (data) => ({
        url: "/filedata/assign",
        method: "PATCH",
        body: { ...data },
        invalidatesTags: ["FileData"],
      }),
      // invalidatesTags: [{ type: 'File', id: 'LIST' }],
    }),
    revisionFileData: builder.mutation({
      query: (data) => ({
        url: "/filedata/revision",
        method: "PATCH",
        body: { ...data },
        invalidatesTags: ["FileData"],
      }),
      // invalidatesTags: [{ type: 'File', id: 'LIST' }],
    }),
    releaseDocument: builder.mutation({
      query: (data) => ({
        url: "/filedata/release",
        method: "PATCH",
        body: { ...data },
        invalidatesTags: ["FileData"],
      }),
      // invalidatesTags: [{ type: 'File', id: 'LIST' }],
    }),
    deleteDocument: builder.mutation({
      query: (id) => {
        return {
          url: `filedata/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (fileData, id) => [{ type: "FileData", id }],
    }),
  }),
});

export const {
  useGetFileDatasQuery,
  useGetFileDatasWithReadingConfirmationQuery,
  useGetFileDataByPathMutation,
  useAddNewFileDataMutation,
  useCreateProofDocFromFormDocMutation,
  useUpdateFileDataMutation,
  useAssignFileDataMutation,
  useRevisionFileDataMutation,
  useReleaseDocumentMutation,
  useDeleteDocumentMutation,
} = fileDataApiSlice;
