import { apiSlice } from "../../app/api/apiSlice";

export const eventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: () => ({
        url: "/events",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      // eslint-disable-next-line
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Event", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Event", id })),
          ];
        }
        return [{ type: "Event", id: "LIST" }];
      },
    }),
    getEventHistoryById: builder.query({
      query: (eventId) => ({
        url: `/events/${eventId}/history`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Event", id: arg }],
    }),
    getEventById: builder.query({
      query: (id) => `/events/${id}`,
      providesTags: (result, error, arg) => [{ type: "Event", id: result?.id }],
    }),
    addNewEvent: builder.mutation({
      query: (event) => ({
        url: "/events",
        method: "POST",
        body: {
          ...event,
        },
      }),
      invalidatesTags: [{ type: "Event", id: "LIST" }],
    }),
    updateEvent: builder.mutation({
      query: (eventUpdate) => ({
        url: "/events",
        method: "PATCH",
        body: {
          ...eventUpdate,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Event", id: arg.id }],
    }),
    updateEventStatus: builder.mutation({
      query: (status) => ({
        url: `/events/status`,
        method: "PATCH",
        body: { ...status },
      }),
      invalidatesTags: [{ type: "Event", id: "LIST" }],
    }),
    deleteEvent: builder.mutation({
      query: (id) => ({
        url: `/events`,
        method: "DELETE",
        body: { ...id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Event", id: arg.id }],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventHistoryByIdQuery,
  useGetEventByIdQuery,
  useAddNewEventMutation,
  useUpdateEventMutation,
  useUpdateEventStatusMutation,
  useDeleteEventMutation,
} = eventsApiSlice;
