import { apiSlice } from "../../app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => "/users",
      keepUnusedDataFor: 5,
    }),
    getUsersClean: builder.query({
      query: () => "/users/clean",
      keepUnusedDataFor: 5,
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: "/users",
        method: "PATCH",
        body: { ...data },
      }),
    }),
    deleteUser: builder.mutation({
      query: (data) => ({
        url: "/users",
        method: "DELETE",
        body: { ...data },
      }),
    }),
    updateUserdataAsUser: builder.mutation({
      query: (data) => ({
        url: "/users/updateUserdataAsUser",
        method: "PATCH",
        body: { ...data },
      }),
    }),
    firstLogin: builder.mutation({
      query: () => ({
        url: "/users/firstlogin",
        method: "POST",
      }),
    }),
    updateSelectedOffice: builder.mutation({
      query: (data) => ({
        url: "/users/selectedoffice",
        method: "POST",
        body: { ...data },
      }),
    }),
    getUsersNotificationSettings: builder.query({
      query: (userId) => `/users/settings/${userId}`,
      keepUnusedDataFor: 5,
    }),
    updateUserNotificationSettings: builder.mutation({
      query: ({ userId, notificationSettings }) => ({
        url: `/users/settings/${userId}`,
        method: "PATCH",
        body: { notificationSettings },
      }),
    }),
    updatePassword: builder.mutation({
      query: (data) => ({
        url: "/users/changepassword",
        method: "PATCH",
        body: { ...data },
      }),
    }),
    changeUsersPW: builder.mutation({
      query: (data) => ({
        url: "/users/changeUsersPW",
        method: "PATCH",
        body: { ...data },
      }),
    }),
    getUsersTaskfilter: builder.query({
      query: (userId) => `/users/taskfilter`,
      keepUnusedDataFor: 5,
    }),
    createTaskFilter: builder.mutation({
      query: (data) => ({
        url: "/users/taskfilter",
        method: "POST",
        body: { ...data },
      }),
    }),
    deleteTaskFilter: builder.mutation({
      query: (data) => ({
        url: "/users/taskfilter",
        method: "DELETE",
        body: { ...data },
      }),
    }),
    updateTaskFilter: builder.mutation({
      query: (data) => ({
        url: "/users/taskfilter",
        method: "PATCH",
        body: { ...data },
      }),
    }),
    replacetaskfilter: builder.mutation({
      query: (data) => ({
        url: "/users/replacetaskfilter",
        method: "PATCH",
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUsersCleanQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdateUserdataAsUserMutation,
  useFirstLoginMutation,
  useUpdateSelectedOfficeMutation,
  useGetUsersNotificationSettingsQuery,
  useUpdateUserNotificationSettingsMutation,
  useUpdatePasswordMutation,
  useChangeUsersPWMutation,
  useGetUsersTaskfilterQuery,
  useCreateTaskFilterMutation,
  useDeleteTaskFilterMutation,
  useUpdateTaskFilterMutation,
  useReplacetaskfilterMutation,
} = usersApiSlice;
