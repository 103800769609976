import React from "react";
import { Button, TextField, Grid } from "@mui/material";
import PropTypes from "prop-types";
import Iconify from "../../../components/iconify";
import ControlledToggleButtonGroup from "../../../components/forms/ControlledToggleButtonGroup";
import SurveyFormQuestionItems from "./SurveyFormQuestionItems";

CreateSurveyForm.propTypes = {
  register: PropTypes.func,
  control: PropTypes.func,
};

function CreateSurveyForm({
  register,
  control,
  fields,
  append,
  remove,
  watchQuestions,
}) {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div style={{ textAlign: "right" }}>
            <ControlledToggleButtonGroup
              control={control}
              name="surveyType"
              fullWidth
              options={[
                { label: "Mitarbeiter", value: "employee" },
                { label: "Patienten", value: "patients" },
              ]}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            margin="normal"
            label="Titel der Befragung"
            {...register("surveyTitle", {
              required: "Dieses Feld ist erforderlich",
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            label="Beschreibung"
            {...register("surveyDescription")}
          />
        </Grid>
        <SurveyFormQuestionItems
          fields={fields}
          remove={remove}
          register={register}
          watchQuestions={watchQuestions}
        />
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => append({})}
            startIcon={<Iconify icon="tabler:plus" />}
          >
            Frage hinzufügen
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

CreateSurveyForm.propTypes = {};

export default CreateSurveyForm;
