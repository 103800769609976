import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  Typography,
  Box,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  styled,
} from "@mui/material";
import Iconify from "../../../components/iconify";
import { useGetHistoryByIdQuery } from "../../../features/task/taskApiSlice";
import { useGetEventHistoryByIdQuery } from "../../../features/event/eventApiSlice";

// Styled components using MUI's 'styled' utility
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

HistoryDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  eventTaskID: PropTypes.string.isRequired,
  art: PropTypes.string.isRequired,
};

export default function HistoryDialog({ handleClose, eventTaskID, art }) {
  const { data: historyTask } = useGetHistoryByIdQuery(
    art === "TASK" ? eventTaskID : undefined
  );
  const { data: historyEvent } = useGetEventHistoryByIdQuery(
    art === "EVENT" ? eventTaskID : undefined
  );

  const history = useMemo(() => {
    return art === "TASK" ? historyTask : historyEvent;
  }, [art, historyTask, historyEvent]);

  console.log("History :>> ", history);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
          id="customized-dialog-title"
        >
          {art === "TASK" ? "Aufgabehistorie" : "Eventhistorie"}
        </Typography>
        <Iconify
          onClick={handleClose}
          icon="mingcute:close-line"
          style={{ fontSize: "50px", color: "action.active" }}
          sx={{
            transition: "transform 0.1s",
            "&:hover": { transform: "scale(1.8)" },
          }}
        />
      </Box>
      <DialogContent dividers>
        <List sx={{ maxHeight: "400px", overflow: "auto" }}>
          {history
            ?.slice()
            .reverse()
            .map((item) => (
              <ListItem key={item.id}>
                <Iconify
                  icon="material-symbols-light:history"
                  style={{ marginRight: "5%" }}
                  sx={{ "&:hover": { transform: "scale(1.8)" } }}
                />
                <ListItemText
                  primary={item.content}
                  secondary={new Date(item.createdAt).toLocaleString()}
                />
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ background: "#9E9E9E" }}
          variant="contained"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
