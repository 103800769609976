import PropTypes from "prop-types";
// @mui
import { Grid } from "@mui/material";
import TaskCard from "./TaskCard";

// ----------------------------------------------------------------------

TaskList.propTypes = {
  tasks: PropTypes.array.isRequired,
  handleDeleteTask: PropTypes.func.isRequired,
  handleClickOpen: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------

export default function TaskList({ tasks, handleDeleteTask, handleClickOpen }) {
  return (
    <Grid container spacing={3}>
      {tasks.map((task) => (
        <Grid key={task.id} item xs={12} sm={6} md={3}>
          <TaskCard
            task={task}
            handleDeleteTask={handleDeleteTask}
            handleClickOpen={handleClickOpen}
            isMobile={true}
          />
        </Grid>
      ))}
    </Grid>
  );
}
