import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

function FileNameTextField({ control }) {
  return (
    <Controller
      name="fileName"
      control={control}
      rules={{
        required: true,
      }}
      render={({ field, formState: { errors } }) => (
        <TextField
          {...field}
          variant="outlined"
          label="Dokumentname"
          fullWidth
          required
          error={errors.fileName}
          helperText={errors.fileName && "Bitte angeben"}
        />
      )}
    />
  );
}

FileNameTextField.propTypes = {
  control: PropTypes.object,
};

export default FileNameTextField;
