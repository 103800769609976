import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat"; // Importieren Sie das Plugin für benutzerdefinierte Formate
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
dayjs.extend(customParseFormat);

export default function DETimeField({ control, name, label, errorMsg }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="de"
          valueType="date"
        >
          <TimePicker
            label={label}
            value={value ? dayjs(value, "HH:mm") : null}
            onChange={(newValue) =>
              onChange(newValue ? newValue.format("HH:mm") : null)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                helperText={error ? errorMsg : null}
              />
            )}
            ampm={false}
          />
        </LocalizationProvider>
      )}
    />
  );
}

DETimeField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
};
