// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
// components
import Iconify from '../iconify/Iconify';

// ----------------------------------------------------------------------

const StyledIconComponent = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(7),
  height: theme.spacing(7),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

StyledIcon.defaultProps = {
  size: 24,
};

StyledIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

export default function StyledIcon({ icon, size, color = 'primary' }) {
  return (
    <StyledIconComponent
      sx={{
        color: (theme) => theme.palette[color].dark,
        backgroundImage: (theme) =>
          `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
            theme.palette[color].dark,
            0.24
          )} 100%)`,
      }}
    >
      <Iconify icon={icon} width={size} height={size} />
    </StyledIconComponent>
  );
}
