// import React, { useState } from "react";
// import { Box, Typography, Menu, MenuItem } from "@mui/material";
// import { AvatarIntern } from "../../../components/avatar";
// import { format } from "date-fns";
// import { selectCurrentUserData } from "../../../features/auth/authSlice";
// import { useSelector } from "react-redux";

// const formatDate = (timestamp) => {
//   const date = new Date(timestamp);
//   return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }); // Nur "HH:MM"
// };

// const formatDateForSeenMessages = (timestamp) =>
//   format(new Date(timestamp), "dd.MM.yyyy, HH:mm");

// const Message = ({ message, isOwn, currentUser }) => {
//   const currentUserData = useSelector(selectCurrentUserData);
//   const currentUserId = currentUserData?.id;
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleRightClick = (event) => {
//     event.preventDefault();
//     setAnchorEl(event.currentTarget);
//   };

//   const handleCloseMenu = () => {
//     setAnchorEl(null);
//   };

//   const seenByDetails = message?.read
//     ?.filter((readEntry) => readEntry?.userId?._id !== currentUserId)
//     .map((readEntry, index) => (
//       <MenuItem key={index}>
//         <Box sx={{ display: "flex", flexDirection: "column" }}>
//           <Typography variant="body2" sx={{ color: "gray" }}>
//             {readEntry?.userId?.firstName} {readEntry?.userId?.lastName}
//           </Typography>
//           <Typography
//             variant="body2"
//             sx={{ color: "gray", fontSize: "0.75rem" }}
//           >
//             {formatDateForSeenMessages(readEntry?.timestamp)}
//           </Typography>
//         </Box>
//       </MenuItem>
//     ));

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: isOwn ? "flex-end" : "flex-start",
//         mb: 1,
//         position: "relative",
//       }}
//       onContextMenu={handleRightClick} // Rechtsklick öffnet das Menü
//     >
//       {/* Avatar nur anzeigen, wenn die Nachricht nicht von "Ich" kommt */}
//       {!isOwn && message?.from && (
//         <AvatarIntern
//           name={`${message?.from?.firstName} ${message?.from?.lastName}`}
//         />
//       )}

//       <Box
//         sx={{
//           maxWidth: "60%",
//           p: 1,
//           borderRadius: 2,
//           bgcolor: isOwn ? "#d1e7dd" : "#e9ecef",
//           position: "relative",
//         }}
//       >
//         <Typography variant="body2">{message?.text}</Typography>

//         {/* Datum der Nachricht anzeigen */}
//         <Typography variant="caption" sx={{ color: "gray", display: "block" }}>
//           {formatDate(message.timestamp)} {/* Datum unter der Nachricht */}
//         </Typography>
//       </Box>

//       {/* Avatar nur anzeigen, wenn die Nachricht von "Ich" kommt */}
//       {isOwn && message?.from && (
//         <AvatarIntern
//           sx={{ ml: 3 }}
//           name={`${message?.from?.firstName} ${message?.from?.lastName}`}
//         />
//       )}
//       {/* Menü für Rechtsklick */}
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleCloseMenu}
//         PaperProps={{
//           sx: {
//             width: 200,
//             borderRadius: 2,
//             boxShadow: 2,
//           },
//         }}
//       >
//         <MenuItem onClick={handleCloseMenu}>
//           <Typography variant="h6">Gesehen von:</Typography>
//         </MenuItem>
//         {message?.read?.length > 0 ? (
//           seenByDetails
//         ) : (
//           <MenuItem onClick={handleCloseMenu}>
//             <Typography variant="body2" sx={{ color: "gray" }}>
//               Noch nicht gesehen
//             </Typography>
//           </MenuItem>
//         )}
//       </Menu>
//     </Box>
//   );
// };

// export default Message;

import React, { useState } from "react";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import { AvatarIntern } from "../../../components/avatar";
import { format } from "date-fns";
import { selectCurrentUserData } from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";

const formatDate = (timestamp) =>
  new Date(timestamp).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
const formatDateForSeenMessages = (timestamp) =>
  format(new Date(timestamp), "dd.MM.yyyy, HH:mm");

const Message = ({ message, isOwn, currentUser, isGroup }) => {
  const currentUserData = useSelector(selectCurrentUserData);
  const currentUserId = currentUserData?.id;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleRightClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const seenByDetails = message?.read
    ?.filter((readEntry) => readEntry?.userId?._id !== currentUserId)
    .map((readEntry, index) => (
      <MenuItem key={index}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ color: "gray" }}>
            {readEntry?.userId?.firstName} {readEntry?.userId?.lastName}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "gray", fontSize: "0.75rem" }}
          >
            {formatDateForSeenMessages(readEntry?.timestamp)}
          </Typography>
        </Box>
      </MenuItem>
    ));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: isOwn ? "flex-end" : "flex-start",
        mb: 1,
        position: "relative",
      }}
      onContextMenu={handleRightClick}
    >
      {!isOwn && message?.from && (
        <AvatarIntern
          name={`${message?.from?.firstName} ${message?.from?.lastName}`}
        />
      )}

      <Box
        sx={{
          maxWidth: "60%",
          p: 1,
          borderRadius: 2,
          bgcolor: isOwn ? "#d1e7dd" : "#e9ecef",
        }}
      >
        {isGroup && !isOwn && (
          <Typography
            variant="caption"
            fontWeight="bold"
            sx={{ color: "#495057" }}
          >
            {message?.from?.firstName} {message?.from?.lastName}
          </Typography>
        )}
        <Typography variant="body1" sx={{ mt: 0.5, mb: 0.5 }}>
          {message.text}
        </Typography>
        <Typography
          variant="caption"
          sx={{ display: "block", textAlign: "right", color: "gray" }}
        >
          {formatDate(message.timestamp)}
        </Typography>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <Typography variant="body2" sx={{ p: 1 }}>
          Gelesen von:
        </Typography>
        {seenByDetails.length > 0 ? seenByDetails : <MenuItem>Keine</MenuItem>}
      </Menu>
    </Box>
  );
};

export default Message;
