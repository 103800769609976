import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import { useForm } from "react-hook-form";
import DEDateField from "../../../components/forms/DEDateField";
import MulitSelectUsersController from "../../../components/forms/MultiSelectUsersController";
import ControlledSelectField from "../../../components/forms/ControlledSelectField";
import SelectControllerForAreas from "../../../components/forms/SelectControllerForAreas";
import ControlledCheckbox from "../../../components/forms/ControlledCheckbox";
import {
  useCreateTaskFilterMutation,
  useUpdateTaskFilterMutation,
} from "../../../features/users/usersApiSlice"; // import update mutation
import TextField from "../../../components/forms/TextField";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../../app/api/apiSlice";
import MultipleSelectControllerForAreas from "../../../components/forms/MultipleSelectControllerForAreas";

TaskFilterSidebar.propTypes = {
  openFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  handleSaveFilter: PropTypes.func.isRequired,
  filterToEdit: PropTypes.object, // New prop for filter being edited
};

export default function TaskFilterSidebar({
  openFilter,
  onOpenFilter,
  onCloseFilter,
  filterToEdit, // Destructure the new prop
}) {
  const [createTaskFilter] = useCreateTaskFilterMutation();
  const [updateTaskFilter] = useUpdateTaskFilterMutation();
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      filterName: "",
      areas: [],
      auditRelevant: false,
      visibility: true,
      startDate: null,
      endDate: null,
      assignedUsers: [],
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (filterToEdit) {
      // Pre-fill form with filterToEdit data
      setValue("filterName", filterToEdit.filterName);
      setValue("areas", filterToEdit.areas);
      setValue("auditRelevant", filterToEdit.auditRelevant);
      setValue("visibility", filterToEdit.visibility);
      setValue("startDate", filterToEdit.startDate);
      setValue("endDate", filterToEdit.endDate);
      setValue("assignedUsers", filterToEdit.assignedUsers);
    } else {
      reset(); // Reset form if no filter is being edited
    }
  }, [filterToEdit, setValue, reset]);

  const onSubmit = async (data) => {
    try {
      if (filterToEdit) {
        await updateTaskFilter({
          ...data,
          filterId: filterToEdit._id,
        }).unwrap(); // Update the filter
      } else {
        await createTaskFilter(data).unwrap(); // Create new filter
      }
      dispatch(apiSlice.util.resetApiState());
      onCloseFilter();
      reset();
    } catch (error) {
      console.error("Error saving task filter:", error);
    }
  };

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Iconify icon="ic:round-filter-list" />}
        onClick={onOpenFilter}
      >
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{ sx: { width: 280, border: "none", overflow: "hidden" } }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ p: 3 }}>
              <Typography>
                Erstellen Sie einen Filter und Sie können diesen
                wiederverwenden. Sie finden diesen neben den Filters.
              </Typography>
              <TextField
                control={control}
                name="filterName"
                label="Filter Name"
                errorMsg="Bitte Filtername eingeben!"
                fullWidth
                required={true}
              />
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Bereich
                </Typography>
                <MultipleSelectControllerForAreas
                  control={control}
                  name="areas"
                  label="Bereiche auswählen"
                  disabled={false}
                />
              </div>

              <div>
                <ControlledCheckbox
                  control={control}
                  name="auditRelevant"
                  label="Audit-Relevant"
                />
              </div>

              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Sichtbarkeit
                </Typography>
                <ControlledSelectField
                  control={control}
                  name="visibility"
                  label="Sichtbarkeit"
                  itemArray={[
                    {
                      label: "Öffentlich",
                      value: true,
                    },
                    {
                      label: "Privat",
                      value: false,
                    },
                  ]}
                />
              </div>

              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Datum von
                </Typography>
                <DEDateField control={control} name="startDate" label="Von" />
              </div>
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Datum bis
                </Typography>
                <DEDateField control={control} name="endDate" label="Bis" />
              </div>

              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Zugewiesene Person
                </Typography>
                <MulitSelectUsersController
                  control={control}
                  name="assignedUsers"
                  label="Zugewiesene Person"
                  errorMsg="Bitte auswählen"
                  fullWidth
                />
              </div>
            </Stack>

            <Box sx={{ p: 3 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<Iconify icon="ic:round-save" />}
              >
                {filterToEdit ? "Filter aktualisieren" : "Filter speichern"}{" "}
                {/* Change button text based on editing state */}
              </Button>
            </Box>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}
