import PropTypes from "prop-types";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { useState } from "react";
import Iconify from "../iconify/Iconify";

export default function PasswordCheckTextField({
  control,
  name = "password",
  label = "Passwort",
  handleCheckMatch,
  fullWidth,
  required,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: "Bitte Passwort eingeben.", validate: handleCheckMatch },
  });

  return (
    <TextField
      label={label}
      variant="outlined"
      required={required}
      fullWidth={fullWidth}
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      type={showPassword ? "text" : "password"} // show or hide password
      error={error && true}
      helperText={error?.message}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              <Iconify
                icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

PasswordCheckTextField.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  handleCheckMatch: PropTypes.func,
};
