// src/socket.js
import { io } from "socket.io-client";

var SOCKET_URL;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  SOCKET_URL = "http://localhost:5001";
} else {
  // production code
  // SOCKET_URL = "http://localhost:5001"; // For testing on local server
  SOCKET_URL = "https://qm.softality-applications.de";
}

var socket;

export const initializeSocket = (token) => {
  // if (!socket) {
  socket = io(SOCKET_URL, {
    auth: {
      token: token,
    },
  });

  // console.log("socket?.io?.opts :>> ", socket?.io?.opts); // Check status of connection

  socket.on("connect", () => {
    console.log("Socket connected:", socket.id);
  });

  socket.on("disconnect", (reason) => {
    console.log("Socket disconnected:", reason);
  });

  // Hier kannst du weitere Socket-Events hinzufügen, die global verwendet werden sollen
  // }

  return socket;
};

export const getSocket = () => {
  if (!socket) {
    throw new Error("Socket not initialized. Call initializeSocket first.");
  }
  return socket;
};
