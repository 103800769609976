import { apiSlice } from "../../app/api/apiSlice";

export const tasksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query({
      query: () => ({
        url: "/tasks",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      // eslint-disable-next-line
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Task", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Task", id })),
          ];
        }
        return [{ type: "Task", id: "LIST" }];
      },
    }),
    getHistoryById: builder.query({
      query: (taskId) => ({
        url: `/tasks/${taskId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Task", id: arg }],
    }),

    addNewTask: builder.mutation({
      query: (task) => ({
        url: "/tasks",
        method: "POST",
        body: {
          ...task,
        },
      }),
      invalidatesTags: [{ type: "Task", id: "LIST" }],
    }),
    updateTask: builder.mutation({
      query: (taskUpdate) => ({
        url: "/tasks",
        method: "PATCH",
        body: {
          ...taskUpdate,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Task", id: arg.id }],
    }),
    updateTaskStatus: builder.mutation({
      query: (status) => ({
        url: `/tasks/status`,
        method: "PATCH",
        body: { ...status },
      }),
      invalidatesTags: [{ type: "Task", id: "LIST" }],
    }),
    deleteTask: builder.mutation({
      query: (id) => ({
        url: `/tasks`,
        method: "DELETE",
        body: { ...id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Task", id: arg.id }],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetHistoryByIdQuery,
  useAddNewTaskMutation,
  useUpdateTaskMutation,
  useUpdateTaskStatusMutation,
  useDeleteTaskMutation,
} = tasksApiSlice;
