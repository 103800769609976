import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { useController } from "react-hook-form";

export default function MailTextField({
  control,
  name = "mail",
  required,
  fullWidth,
}) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: required && "Bitte eine gültige E-Mail angeben.",
      // pattern: { value: /^\S+@\S+$/i, message: 'Eingabe besitzt kein gültiges E-Mail Format.' },
    },
    // rules: { required: true, pattern: /^\S+@\S+$/i },
  });

  return (
    <TextField
      label="E-Mail Addresse"
      variant="outlined"
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      error={error && true}
      required={required}
      fullWidth={fullWidth}
      helperText={error?.message}
    />
  );
}

MailTextField.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  required: PropTypes.bool,
};

// ALTERNATIVE:
// <Controller
// render={({ field }) => <TextField {...field} />}
// name="TextField"
// control={control}
// />
