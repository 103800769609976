import PropTypes from "prop-types";
import {
  Card,
  Typography,
  CardHeader,
  CardContent,
  Box,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Label from "../../../components/label";

// ----------------------------------------------------------------------

const ScrollableBox = styled(Box)(({ theme }) => ({
  maxHeight: 340, // Max-Höhe für die Liste
  overflowY: "auto", // Scrollbar aktivieren
  paddingRight: theme.spacing(1),
  "&::-webkit-scrollbar": {
    display: "none", // Scrollbar in WebKit-Browsern (Chrome, Safari) ausblenden
  },
  "-ms-overflow-style": "none", // Scrollbar in Internet Explorer und Edge ausblenden
  "scrollbar-width": "none", // Scrollbar in Firefox ausblenden
}));

AppUpdateList.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired, // 'feature', 'improvement', 'bugfix' etc.
    })
  ).isRequired,
};

export default function AppUpdateList({ title, subheader, tasks, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <CardContent>
        <ScrollableBox>
          {tasks.map((task, index) => (
            <Box key={task.id}>
              <UpdateItem task={task} />
              {index !== tasks.length - 1 && (
                <Divider
                  sx={{
                    my: 3, // Mehr vertikaler Abstand
                    borderColor: "black", // Primärfarbe verwenden
                    height: 3, // Dickere Linie
                  }}
                />
              )}
            </Box>
          ))}
        </ScrollableBox>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------

UpdateItem.propTypes = {
  task: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

function UpdateItem({ task }) {
  const { title, description, type } = task;
  return (
    <Box mb={2}>
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {description}
      </Typography>

      <Label
        color={
          type === "feature"
            ? "success"
            : type === "improvement"
            ? "warning"
            : "error"
        }
      >
        {type === "feature"
          ? "Feature"
          : type === "improvement"
          ? "Verbesserung"
          : "Fehlerbehebung"}
      </Label>
    </Box>
  );
}
