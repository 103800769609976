import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

export default function ModuleAddDialog({ tenant, module }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBookModule = () => {
    console.log("tenant :>> ", tenant);
    console.log("module :>> ", module);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        // disabled={loading}
        fullWidth
        sx={{ m: 2 }}
        onClick={handleClickOpen}
      >
        Weitere {module === "OFFICE" ? "PRAXIS" : "BENUTZER"} Buchen
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${module === "OFFICE" ? "PRAXIS" : "BENUTZER"} buchen`}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Hier können Sie kostenpflichtig weitere Praxen/Benutzer buchen.
          </Typography>
          <br />
          <Typography>
            Kosten: 5 Euro/monatlich pro Benutzer 20 Euro/monatlich pro Praxis
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            // disabled={loading}
            onClick={handleBookModule}
            variant="contained"
            color="secondary"
            autoFocus
          >
            {"Kostenpflichtig buchen"}
          </Button>
          <Button onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
