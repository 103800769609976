export const combineDateAndTime = (date, time) => {
  return `${date}T${time}`;
};

export const getDateFromString = (dateString) => {
  // Check for null, undefined, or empty string
  if (!dateString) {
    return "Kein Datum";
  }

  // Try to parse the date string
  const date = new Date(dateString);

  // Check if the parsed date is valid
  if (isNaN(date.getTime())) {
    return "Kein Datum";
  }

  // Format date using toLocaleDateString with German locale
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const formatDateTime = (dateTime, format) => {
  const date = new Date(dateTime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  if (format === "date") {
    return `${year}-${month}-${day}`;
  } else if (format === "time") {
    return `${hours}:${minutes}`;
  } else {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
};
