import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

function RegisterSuccess({ close }) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    close();
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{'Registrierung erfolgreich!'}</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Icon icon="icon-park-twotone:success" color="lawngreen" width="120" height="120" style={{ margin: 25 }} />
          <DialogContentText id="alert-dialog-slide-description">
            Der Benutzer wurde erfolgreich erstellt. Sie können sich jetzt mit den Daten einloggen.
          </DialogContentText>
          <Button variant="contained" LinkComponent={Link} to={'/login'} onClick={handleClose} sx={{ mt: 5 }}>
            Zum Login
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Schließen</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

RegisterSuccess.propTypes = {
  close: PropTypes.func,
};

export default RegisterSuccess;
