import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import AuditItemStatus from "./AuditItemStatus";
import { useParams } from "react-router-dom";
import { useGetAuditByYearQuery } from "../../../features/audit/auditApiSlice";
import Loading from "../../../components/spinners/Loading";
import AuditItemResponsibleUser from "./AuditItemResponsibleUser";
import NoAuditError from "./NoAuditError";

// Die AuditTable-Komponente
const AuditTable = () => {
  let { year } = useParams();

  const {
    data: audit,
    error,
    isError,
    isLoading,
    isFetching,
  } = useGetAuditByYearQuery(year);

  if (error && error?.data?.noAudits) {
    return <NoAuditError />;
  }

  if (isError) {
    return (
      <Typography>
        Probleme beim Laden der Audit Checkliste. Bitte versuchen Sie es erneut.
      </Typography>
    );
  }

  return (
    <TableContainer sx={{ maxHeight: "80vh" }} elevation={3} component={Paper}>
      {(isLoading || isFetching) && <Loading />}
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left">Nr.</TableCell>
            <TableCell>Zeitraum/-punkt</TableCell>
            <TableCell>Aufgaben/Termine</TableCell>
            <TableCell sx={{ whiteSpace: "nowrap" }}>
              Kapitel im Mojave QM
            </TableCell>
            <TableCell>Verantwortlich</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {audit &&
            audit?.auditItems.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell>{row.period}</TableCell>
                <TableCell>{row.task}</TableCell>
                <TableCell>{row.chapter}</TableCell>
                <TableCell>
                  <AuditItemResponsibleUser
                    auditItemId={row._id}
                    responsibleUser={row.responsibleUser}
                    loading={isLoading || isFetching}
                  />
                </TableCell>
                <TableCell>
                  <AuditItemStatus
                    auditItemId={row._id}
                    status={row.status}
                    loading={isLoading || isFetching}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AuditTable;
