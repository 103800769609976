import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";

function FileDataCheckedOutCheckbox({ control }) {
  return (
    <Controller
      name="isCheckedOut"
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox {...field} checked={field.value} />}
          label="Ausgecheckt"
        />
      )}
    />
  );
}

FileDataCheckedOutCheckbox.propTypes = {
  control: PropTypes.object,
};

export default FileDataCheckedOutCheckbox;
