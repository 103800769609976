import React from "react";
import { Grid } from "@mui/material";
import TextField from "../../../components/forms/TextField";
import ControlledCheckbox from "../../../components/forms/ControlledCheckbox";
import MultiSelectController from "../../../components/forms/MultiSelectUsersController";
import DEDateField from "../../../components/forms/DEDateField";
import DETimeField from "../../../components/forms/DETimeField";
import ControlledColorComponent from "src/components/forms/ControlledColorComponent";

function EventForm({ control, allDay }) {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="title"
            label="Titel"
            required
            multiline
            errorMsg="Bitte Titel eingeben!"
            fullWidth
          />
        </Grid>

        <Grid
          container
          item
          spacing={1}
          sx={{ justifyContent: "space-between" }}
        >
          <Grid item xs={allDay ? 6 : 3.5}>
            <DEDateField
              control={control}
              name="start"
              label="Startdatum"
              errorMsg="Bitte wähle ein Datum aus"
              required={true}
            />
          </Grid>
          {!allDay && (
            <>
              <Grid item xs={2.5}>
                <DETimeField
                  control={control}
                  name="startTime"
                  label="Startzeit"
                  errorMsg="Bitte wähle eine Uhrzeit aus"
                  required={true}
                />
              </Grid>
              <Grid item xs={2.5}>
                <DETimeField
                  control={control}
                  name="endTime"
                  label="Endzeit"
                  errorMsg="Bitte wähle eine Uhrzeit aus"
                  required={true}
                />
              </Grid>
            </>
          )}
          <Grid item xs={allDay ? 6 : 3.5}>
            <DEDateField
              control={control}
              name="end"
              label="Enddatum"
              errorMsg="Bitte wähle ein Datum aus"
              required={true}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <ControlledCheckbox
            control={control}
            name="allDay"
            label="Ganztägig"
            description=""
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectController
            control={control}
            name="user"
            label="Personen"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledColorComponent
            control={control}
            name="color"
            label="Farbe"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            control={control}
            name="description"
            label="Beschreibung"
            variant="outlined"
            errorMsg="Bitte Beschreibung angeben"
            multiline
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="location"
            label="Ort"
            variant="outlined"
            errorMsg="Bitte Beschreibung angeben"
            multiline
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default EventForm;
