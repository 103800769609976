import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
// @mui
import { styled } from "@mui/material/styles";
//
import Header from "./header";
import Nav from "./nav";
import { selectCurrentDialog } from "../../features/dialog/dialogSlice";
import SimpleDialog from "../../components/dialogs/SimpleDialog";
import { APP_BAR_DESKTOP, APP_BAR_MOBILE } from "./styleUtils";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(true);
  const dialog = useSelector(selectCurrentDialog);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav
        openNav={open}
        onOpenNav={() => setOpen(true)}
        onCloseNav={() => setOpen(false)}
      />
      {dialog && <SimpleDialog dialog={dialog} />}

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
