import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
// @mui
import { Stack, Button, Container, Typography } from "@mui/material";
// components
import { setDialog } from "../features/dialog/dialogSlice";
import Iconify from "../components/iconify";
import NewUserListTable from "../sections/@dashboard/user/NewUserListTable";
import {
  useGetTenantInformationQuery,
  useGetTenantsQuery,
} from "../features/tenants/tenantsApiSlice";
import { allowNewUser, getCurrentUserCount } from "../utils/tenantUtils";
import Loading from "../components/spinners/Loading";
import { NavLink } from "react-router-dom";
import TenantOverview from "../sections/@dashboard/admin/TenantOverview";

// ----------------------------------------------------------------------

export default function AdminPage() {
  const dispatch = useDispatch();
  const { data: tenants, isLoading } = useGetTenantsQuery();
  const { data: tenant, isLoading: isLoadingTenant } =
    useGetTenantInformationQuery();

  const userCount = tenant && getCurrentUserCount(tenant);

  if (isLoading || isLoadingTenant) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title> Digit QM | Admin Zentrale </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Admin Zentrale
          </Typography>
          <Button
            component={NavLink}
            to={"/register"}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Neuer Kunde
          </Button>
        </Stack>
        {tenants && <TenantOverview tenants={tenants} userCount={userCount} />}
      </Container>
    </>
  );
}
