import React from "react";
import PropTypes from "prop-types";
import SelectController from "../../../components/forms/SelectController";
import { useUpdateAuditItemResponsibleMutation } from "../../../features/audit/auditApiSlice";
import { useForm } from "react-hook-form";

const AuditItemResponsibleUser = ({
  auditItemId,
  responsibleUser,
  loading,
}) => {
  const { control, watch } = useForm({
    defaultValues: { responsibleUser: responsibleUser },
  });
  const [updateAuditItemResponsible] = useUpdateAuditItemResponsibleMutation();

  const currentUser = watch("responsibleUser");

  React.useEffect(() => {
    const handleUserChange = async () => {
      try {
        let res = await updateAuditItemResponsible({
          _id: auditItemId,
          responsibleUser: currentUser,
        }).unwrap();
        if (res && res.status === 200) {
          window.alert(res.message);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    };
    if (responsibleUser !== currentUser) {
      handleUserChange();
    }
  }, [currentUser, auditItemId, responsibleUser, updateAuditItemResponsible]);

  return (
    <SelectController
      control={control}
      disabled={loading}
      name="responsibleUser"
      label="Verantwortlich:"
      errorMsg="Bitte wähle ein Name aus."
      required={true}
    />
  );
};

AuditItemResponsibleUser.propTypes = {
  responsibleUser: PropTypes.string,
  auditItemId: PropTypes.string,
  loading: PropTypes.bool,
};

export default AuditItemResponsibleUser;
