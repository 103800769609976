import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { MenuItem, TextField, Checkbox, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../features/auth/authSlice";
import { useGetOfficeAreasQuery } from "src/features/offices/officesApiSlice";

export default function MultipleSelectControllerForAreas({
  control,
  name,
  label,
  errorMsg,
  required,
  disabled,
}) {
  const currentUserData = useSelector(selectCurrentUserData);
  const { data: areas } = useGetOfficeAreasQuery(
    currentUserData?.selectedOffice
  );

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (areas && Array.isArray(areas.areas)) {
      setOptions(
        areas.areas.map((area) => ({
          label: area.name,
          value: area._id,
        }))
      );
    }
  }, [areas]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TextField
          select
          fullWidth
          variant="outlined"
          label={label}
          value={value || []}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          inputRef={ref}
          error={!!error}
          required={required}
          disabled={disabled}
          helperText={errorMsg}
          SelectProps={{
            multiple: true,
            renderValue: (selected) =>
              selected.length > 0
                ? selected
                    .map(
                      (selectedValue) =>
                        options.find((option) => option.value === selectedValue)
                          ?.label
                    )
                    .join(", ")
                : "Keine Bereiche ausgewählt",
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={value?.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
}

MultipleSelectControllerForAreas.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
