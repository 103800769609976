import React, { useCallback } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useDropzone } from "react-dropzone";

export default function FileUpload({
  file,
  setFile,
  filePreview,
  setFilePreview,
  onUpload,
  previewTitle = "Vorschau:",
  dropzoneText = "Hier klicken oder Datei hierherziehen",
  acceptedFileTypes = "image/*",
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFile(file);
      const previewUrl = URL.createObjectURL(file);
      setFilePreview(previewUrl);
      onUpload(file);
    },
    [onUpload, setFile, setFilePreview]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/gif": [".gif"],
      "image/webp": [".webp"],
    },
    multiple: false,
  });

  const handleRemoveFile = () => {
    setFile(null);
    setFilePreview(null);
  };

  return (
    <Box fullwidth>
      {file ? (
        <>
          <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}>
            {previewTitle}
          </Typography>
          <Typography variant="body2" component="p" sx={{ mb: 2 }}>
            {file?.name}
          </Typography>
          {filePreview && (
            <>
              <Box mt={2} sx={{ textAlign: "center" }}>
                <img
                  src={filePreview}
                  alt="File Preview"
                  style={{ maxWidth: "100%", maxHeight: 200 }}
                />
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  sx={{ m: 2, minWidth: 250 }}
                  variant="contained"
                  color="warning"
                  onClick={handleRemoveFile}
                >
                  Foto ändern
                </Button>
              </Box>
            </>
          )}
        </>
      ) : (
        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed #000000",
            borderRadius: "8px",
            padding: "20px",
            textAlign: "center",
            cursor: "pointer",
            mb: 3,
            color: "#000000",
            fontWeight: "bold",
            width: "100%",
            maxWidth: "600px", // Breite des Upload-Bereichs
            margin: "0 auto", // Zentriert den Bereich
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography
              variant="body1"
              sx={{ color: "black", fontWeight: "bold" }}
            >
              Ziehe die Datei hierher...
            </Typography>
          ) : (
            <Typography
              variant="body1"
              sx={{ color: "black", fontWeight: "bold" }}
            >
              {dropzoneText}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
