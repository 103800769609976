import PropTypes from "prop-types";
// @mui
import { Link, Stack, Button } from "@mui/material";
// components
import LoginTextField from "../../../components/forms/LoginTextField";
import PasswordTextField from "../../../components/forms/PasswordTextField";
import ControlledCheckbox from "../../../components/forms/ControlledCheckbox";

// ----------------------------------------------------------------------

export default function LoginForm({ control }) {
  return (
    <>
      <Stack spacing={3}>
        <LoginTextField control={control} />
        <PasswordTextField control={control} />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <ControlledCheckbox
          name="stayLoggedIn"
          label="Angemeldet bleiben"
          description="Wenn diese Option aktiviert ist, bleiben Sie auch nach dem Schließen des Browsers angemeldet."
          control={control}
        />

        <Link href="/resetpassword" variant="subtitle2" underline="hover">
          Passwort vergessen?
        </Link>
      </Stack>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{
          mt: 2,
          mb: 3,
          fontSize: "125%",
          backgroundColor: "#0097a5",
          "&:hover": {
            backgroundColor: "#035e66",
          },
        }}
      >
        LOGIN
      </Button>
    </>
  );
}

LoginForm.propTypes = {
  control: PropTypes.object,
};
