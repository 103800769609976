import React from "react";
import { Box, Typography } from "@mui/material";
import ControlledSelectField from "../../../../components/forms/ControlledSelectField";
import TextField from "../../../../components/forms/TextField";

export default function CreateChatUserForm({ control, filteredUsers, users }) {
  const options = filteredUsers.map((user) => ({
    value: `${user._id}`,
    label: `${user.firstName} ${user.lastName}`,
  }));

  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Wähle einen Benutzer aus, um einen Chat zu starten:
      </Typography>
      <ControlledSelectField
        control={control}
        name="users"
        itemArray={options}
        label="Benutzer auswählen"
        required={true}
      />
      {users?.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <TextField
            control={control}
            label="Schreiben Sie eine Nachricht..."
            name="message"
            fullWidth
            multiline
            minRows={3}
            required={true}
          />
        </Box>
      )}
    </>
  );
}
