export const FILE_STATUS_ARRAY = [
  { label: "Neu", value: "new" },
  { label: "In Bearbeitung", value: "edit" },
  { label: "In Überprüfung", value: "revision" },
  { label: "Freigegeben", value: "released" },
];

export const FILE_UPDATE_VERSION_ARRAY = [
  { label: "Änderung", value: "main" },
  { label: "Überprüfung", value: "revision" },
  { label: "Korrektur", value: "sub" },
];

export const FILE_TYPE_ARRAY = [
  { label: "Formblatt", value: "FB" },
  { label: "Verfahrensanweisung", value: "VA" },
  { label: "Nachweis", value: "NW" },
  { label: "Sonstiges", value: "SONSTIGES" },
];

export const FILE_TYPE_ICONS = [
  { type: ".pdf", icon: "vscode-icons:file-type-pdf2" },
  { type: ".doc", icon: "vscode-icons:file-type-word" },
  { type: ".docx", icon: "vscode-icons:file-type-word" },
  { type: ".xls", icon: "vscode-icons:file-type-excel" },
  { type: ".xlsx", icon: "vscode-icons:file-type-excel" },
];

export const checkAllowEditingDocument = (fileData, isAdmin, userId) => {
  if (fileData?.extension === ".docx" && isAdmin) {
    return true;
  }
  if (fileData?.extension === ".pdf") {
    return false;
  }
  if (fileData?.status !== "released") {
    return true;
  }
  if (fileData?.status === "released" && fileData.creator._id === userId) {
    return true;
  }
};

export const hasUserConfirmedReading = (file, userId) => {
  // Check if the file is released
  if (file?.status !== "released") {
    return true; // Return true to prevent button display if the file is not released
  }

  // Check if reading confirmation is enabled
  if (!file?.readingConfirmation) {
    return true; // Return true to prevent button display if reading confirmation is not enabled
  }

  // Check if the readingConfirmationList exists
  if (!file?.readingConfirmationList) {
    return true; // Return true to prevent button display if the list is missing
  }

  // Find the user's entry in the readingConfirmationList
  const userEntry = file.readingConfirmationList.find(
    (entry) => entry?.reader?._id?.toString() === userId.toString()
  );

  // Check if the user exists in the list and has confirmed the reading
  return userEntry?.readingConfirmed || !userEntry; // Return true if confirmed or user not in the list
};

export const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const getFileTypeLabelFromValue = (value) => {
  return FILE_TYPE_ARRAY.find((t) => t.value === value).label;
};

/**
 * Gets storage and type information from filename.
 *
 * If correcty used, the fileName gives information where the file
 * should be stored and what type of file it is. (use period)
 *
 *
 * @param {string}   fileName Name of the file to be uploaded (eg. 1A FB-01).
 *
 * @return {Object} Returns the folder and type {folder: folder, type: type}.
 */
export const getFileDataFromName = (fileName) => {
  // Regular expression to match the pattern
  const regex = /^(\d{1,2})([A-Za-z])*\s?(VA|FB|NW)/;

  // Use regex to extract information
  const match = fileName.match(regex);

  // Check if there's a match
  if (match) {
    const [, mainFolder, subFolder, fileType] = match;
    return { mainFolder, subFolder: subFolder ?? false, fileType };
  }

  // Return null if no match is found
  return null;
};

const findMainFolder = (folder, mainFolder) => {
  // mainFolder: "6" folder.name: "06-QM"
  if (parseInt(folder.name) === parseInt(mainFolder)) {
    return true;
  }
  return false;
};

const findSubFolder = (folder, subFolder) => {
  if (
    folder.name.charAt(1).toLowerCase() === subFolder.toLowerCase() &&
    folder.type === "folder"
  ) {
    return true;
  }
  return false;
};

/**
 * Gets storage and type information from filename.
 *
 * If correcty used, the fileName gives information where the file
 * should be stored and what type of file it is. (use period)
 *
 *
 * @param {Object}   fileData Name of the file to be uploaded (eg. 1A FB-01).
 * @param {Array}   folders Name of the file to be uploaded (eg. 1A FB-01).
 *
 * @return {Object} Returns the folder and type {folder: folder, type: type}.
 */
export const getFolderFromFileData = (mainFolder, subFolder, folders) => {
  let foundSubFolder = false;
  let foundMainFolder = false;
  folders.forEach((folder, index) => {
    if (findMainFolder(folder, mainFolder)) {
      foundMainFolder = { ...folder, index };
    }
  });

  if (subFolder && foundMainFolder && foundMainFolder?.children.length > 0) {
    foundMainFolder.children.forEach((folder, index) => {
      if (findSubFolder(folder, subFolder)) {
        foundSubFolder = { ...folder, index };
      }
    });
  }

  return { foundMainFolder, foundSubFolder };
};

/**
 * Retrieves the last 8 characters of a Mongoose document's _id.
 *
 * @param {Object} document - The Mongoose document with the _id field.
 * @returns {string} - The last 8 characters of the _id.
 *
 * @example
 * const doc = await Model.findOne(); // Assuming doc._id exists
 * const last8Chars = getLast8CharsOfId(doc);
 * console.log(last8Chars); // Outputs something like 'eab6e33d'
 */
export function getLast8CharsOfId(id) {
  if (!id) {
    throw new Error("Invalid id");
  }

  // Convert the _id (which is typically an ObjectId) to a string and extract the last 8 characters
  return id.toString().slice(-8);
}
