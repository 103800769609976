import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useGetFileDataByPathMutation } from "../../../features/fileData/fileDataApiSlice";
import { FILE_TYPE_ICONS } from "../../../utils/fileUtils";
import { downloadFileByID } from "../../../app/api/axios/services/files.service";
import FolderTreeItemMenu from "./FolderTreeItemMenu";

const FolderTree = ({
  data,
  handleOpenFileDialog,
  handleOpenAssignFileDialog,
}) => {
  const [open, setOpen] = React.useState([]);
  const [getFileDataByPath] = useGetFileDataByPathMutation();

  const handleClick = (node) => {
    const { id } = node;
    const isOpen = open.includes(id);
    setOpen(isOpen ? open.filter((item) => item !== id) : [...open, id]);
  };

  const handleClickFile = async (file, action) => {
    console.log("file :>> ", file);

    try {
      const fileData = await getFileDataByPath({ ...file, action }).unwrap();

      if (fileData._id && action === "ASSIGN") {
        console.log("Assign file to user");
        handleOpenAssignFileDialog(fileData);
        return;
      }

      if (fileData._id && action === "RELEASE") {
        console.log("Release file");
        return;
      }

      if (fileData._id && ["SHOW", "EDIT"].includes(action)) {
        handleOpenFileDialog(fileData, action);
      }

      if (action === "DOWNLOAD") {
        // Fetch the file as arraybuffer
        const response = await downloadFileByID(fileData._id);
        const arrayBuffer = response.data;

        // Create a blob from the arraybuffer
        const blob = new Blob([arrayBuffer], {
          type: response.headers["content-type"],
        });

        // Create a link element, set its href to a URL created from the blob, and trigger a download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file.name; // Set the desired file name

        // Trigger a download
        link.click();

        // Clean up the URL object and remove the link
        window.URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const renderTree = (node) => {
    if (node.type === "folder") {
      return (
        <>
          <ListItem
            button
            onClick={() => handleClick(node)}
            style={{ paddingLeft: 8 }}
          >
            <Icon icon="material-symbols:folder-outline" />
            <ListItemText style={{ paddingLeft: 8 }} primary={node.name} />
          </ListItem>
          <Collapse in={open.includes(node.id)} timeout="auto" unmountOnExit>
            <List style={{ marginLeft: 16 }} component="div" disablePadding>
              {node.children.map((child) => (
                <React.Fragment key={child.id}>
                  {renderTree(child)}
                </React.Fragment>
              ))}
            </List>
          </Collapse>
        </>
      );
    }

    if (node.type === "file") {
      return (
        <ListItem button style={{ paddingLeft: 8 }}>
          <Icon
            icon={FILE_TYPE_ICONS.find((e) => e.type === node?.extension)?.icon}
            fontSize={"175%"}
          />
          <ListItemText
            style={{ paddingLeft: 8 }}
            primary={node.name}
            primaryTypographyProps={
              node.type === "file" && {
                fontWeight: "bold",
              }
            }
          />
          {node?.extension !== ".pdf" && (
            <Button
              variant="contained"
              color="error"
              disabled
              sx={{ ml: 2 }}
              onClick={() => handleClickFile(node, "EDIT")}
            >
              PRÜFEN
            </Button>
          )}
          <FolderTreeItemMenu node={node} handleClickFile={handleClickFile} />
        </ListItem>
      );
    }
  };

  return (
    <Box>
      <List>
        {data &&
          data.map((item) => (
            <React.Fragment key={item.id}>{renderTree(item)}</React.Fragment>
          ))}
      </List>
    </Box>
  );
};

export default FolderTree;
