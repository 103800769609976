import React from "react";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";

function UploadDocumentView() {
  const dispatch = useDispatch();

  const openDialog = () => {
    dispatch(
      setDialog({
        title: "Datei hochladen",
        content: "FileUpload",
        redirect: "/dashboard/files",
      })
    );
  };

  React.useEffect(() => {
    openDialog();
  });

  return <div></div>;
}

export default UploadDocumentView;
