// @mui
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Divider,
  Typography,
  CardHeader,
  styled,
  CardContent,
} from "@mui/material";
// components
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { getDateFromString } from "../../../../utils/dateUtils";
import Label from "../../../../components/label";

// ----------------------------------------------------------------------

ReadingConfirmationWidget.propTypes = {
  title: PropTypes.string,
  fileDataStatistics: PropTypes.object.isRequired,
};

const ScrollableBox = styled(Box)(({ theme }) => ({
  maxHeight: 240, // Max-Höhe für die Liste
  overflowY: "auto", // Scrollbar aktivieren
  paddingRight: theme.spacing(1),
  "&::-webkit-scrollbar": {
    display: "none", // Scrollbar in WebKit-Browsern (Chrome, Safari) ausblenden
  },
  "-ms-overflow-style": "none", // Scrollbar in Internet Explorer und Edge ausblenden
  "scrollbar-width": "none", // Scrollbar in Firefox ausblenden
}));

export default function ReadingConfirmationWidget({
  title,
  fileDataStatistics,
  ...other
}) {
  const { isAdmin } = useAuth();
  const { readingConfirmation, readingConfirmationAdminOverview } =
    fileDataStatistics;
  return (
    <Card {...other} sx={{ minHeight: "100%" }}>
      <CardHeader title={title} />
      <CardContent sx={{ p: 1 }} style={{ paddingBottom: 1 }}>
        <ScrollableBox>
          {readingConfirmation && readingConfirmation?.length > 0 ? (
            readingConfirmation.map((entry) => (
              <ReadingItem key={"item-user-" + entry.fileId} entry={entry} />
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              Keine offenen Lesebestätigungen.
            </Typography>
          )}

          {isAdmin && (
            <>
              <Divider />
              <Typography variant="h6" py={1}>
                <Label color={"warning"}>Admin Ansicht</Label>
              </Typography>
              {readingConfirmationAdminOverview &&
              readingConfirmationAdminOverview?.length > 0 ? (
                readingConfirmationAdminOverview.map((entry) => (
                  <AdminReadingItem
                    key={"item-admin-" + entry.fileId}
                    entry={entry}
                  />
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  Keine offenen Lesebestätigungen.
                </Typography>
              )}
            </>
          )}
        </ScrollableBox>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------

ReadingItem.propTypes = {
  entry: PropTypes.shape({
    fileName: PropTypes.string,
    fileId: PropTypes.string,
    readingConfirmed: PropTypes.bool,
    readingDueDate: PropTypes.string,
  }),
};

function ReadingItem({ entry }) {
  const navigate = useNavigate();
  const { fileId, fileName, readingDueDate } = entry;

  return (
    <Box
      mb={1}
      pl={1}
      py={1}
      onClick={() => navigate(`/dashboard/files/show/${fileId}`)}
      sx={{
        minHeight: 50,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          borderRadius: 1,
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Typography variant="subtitle2" noWrap>
        {fileName}
      </Typography>

      <Typography
        variant="caption"
        sx={{ pr: 1, flexShrink: 0, color: "text.secondary" }}
      >
        {"Frist: " + getDateFromString(readingDueDate)}
      </Typography>
    </Box>
  );
}

// ----------------------------------------------------------------------

AdminReadingItem.propTypes = {
  entry: PropTypes.shape({
    fileName: PropTypes.string,
    fileId: PropTypes.string,
    readingConfirmed: PropTypes.bool,
    readingDueDate: PropTypes.string,
  }),
};

function AdminReadingItem({ entry }) {
  const navigate = useNavigate();
  const {
    fileId,
    fileName,
    readingDueDate,
    totalReadersCount,
    totalConfirmedCount,
  } = entry;

  return (
    <Box
      mb={1}
      pl={1}
      py={1}
      onClick={() => navigate(`/dashboard/files/show/${fileId}`)}
      sx={{
        minHeight: 50,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          borderRadius: 1,
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Typography variant="subtitle2" noWrap>
        {fileName}
      </Typography>

      <Typography
        variant="caption"
        sx={{ pr: 1, flexShrink: 0, color: "text.secondary" }}
      >
        {"Frist: " + getDateFromString(readingDueDate)} |{" "}
        {`Status: ${totalConfirmedCount}/${totalReadersCount}`}
      </Typography>
    </Box>
  );
}
