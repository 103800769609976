import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  Typography,
  Grid,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Iconify from "src/components/iconify";
import NotificationSettings from "src/sections/@dashboard/settings/NotificationSettings";
import TenantSettings from "src/sections/@dashboard/settings/TenantSettings";
import NewPasswordSettings from "src/sections/@dashboard/settings/NewPasswordSettings";
import FAQs from "src/sections/@dashboard/settings/FAQs";
import useAuth from "src/hooks/useAuth";
import { NavLink, Outlet, Route, Routes } from "react-router-dom";
import { settingsNavList } from "../sections/@dashboard/settings/settingsNav";
import BillingSettings from "../sections/@dashboard/settings/BillingSettings";
import ModuleSettings from "../sections/@dashboard/settings/ModuleSettings";
import { Helmet } from "react-helmet-async";

const SettingsPage = () => {
  const { isAdmin } = useAuth();

  return (
    <Container maxWidth="ml" sx={{ minHeight: "100%" }}>
      <Helmet>
        <title> Digit QM | Einstellungen</title>
      </Helmet>
      <Typography variant="h3" component="h1" gutterBottom>
        Einstellungen
      </Typography>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ minHeight: "80vh" }}
      >
        <Grid item xs={12} md={3}>
          <List component="nav" aria-label="settings navigation">
            {settingsNavList.map((item) => (
              <SettingsNavItem item={item} isAdmin={isAdmin} />
            ))}
          </List>
        </Grid>
        <Divider
          orientation="vertical"
          sx={{
            minHeight: "100%",
            alignSelf: "stretch",
            display: { xs: "none", lg: "flex" },
            borderWidth: 3,
            borderRadius: 5,
            borderColor: "black",
          }}
          flexItem
        />
        <Grid item xs={12} md={8}>
          <Routes>
            <Route
              index
              element={
                <Typography variant="h3" textAlign={"center"}>
                  Bitte links einen Menüpunkt auswählen
                </Typography>
              }
            />
            <Route path="tenant" element={<TenantSettings />} />
            <Route path="modules" element={<ModuleSettings />} />
            <Route path="payment" element={<BillingSettings />} />
            <Route path="notifications" element={<NotificationSettings />} />
            <Route path="password" element={<NewPasswordSettings />} />
            <Route path="faq" element={<FAQs />} />
          </Routes>

          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SettingsPage;

// ----------------------------------------------------------------------

SettingsNavItem.propTypes = {
  item: PropTypes.object,
};

function SettingsNavItem({ item, isAdmin }) {
  const { title, path, icon, role } = item;

  if (role === "Admin" && !isAdmin) {
    return null;
  }

  return (
    <ListItemButton
      component={NavLink}
      to={path}
      sx={{
        "&.active": {
          color: "text.primary",
          fontWeight: "fontWeightBold",
        },
        maxWidth: 300,
      }}
    >
      <ListItemIcon sx={{ minWidth: 25 }}>
        <Iconify icon={icon} />
      </ListItemIcon>

      <ListItemText disableTypography primary={title} />
    </ListItemButton>
  );
}
