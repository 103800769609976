import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import TextField from "../TextField";
import ControlledCheckbox from "../ControlledCheckbox";
import ControlledSelectField from "../ControlledSelectField";
import SelectController from "../SelectController";
import { Controller } from "react-hook-form";
import FolderSelector from "./CreateFileDataForm/FolderSelector";
import { useGetFileStructureQuery } from "../../../features/files/filesApiSlice";
import DEDateField from "../DEDateField";

function ReleaseDocumentForm({
  control,
  revisionChecked,
  checkCreateProof,
  checkReadingConfirmation,
}) {
  const { data: fileStructure } = useGetFileStructureQuery();

  const proofDocsFolder = fileStructure && [
    fileStructure[0]?.children?.find((o) => o.name.includes("Nachweise")),
  ];

  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <ControlledCheckbox
          name="checkCreateProof"
          label="Nachweis erzeugen"
          errorMsg="Bitte angeben"
          control={control}
          description="Sollen aus diesem Dokument Nachweise erzeugt werden können?"
        />
      </Grid>
      {checkCreateProof && (
        <Grid item xs={12}>
          <Controller
            name="selectedFolder"
            required={false}
            control={control}
            defaultValue={""} // Set a default value for selectedFolder
            render={({ field }) => (
              <FolderSelector
                folderStructure={proofDocsFolder}
                control={control}
                name="selectedFolder"
                infoText={
                  "Bitte einen Ordner auswählen, in dem die zukünftigen Nachweise gespeichert werden."
                }
                required={false}
                {...field}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <ControlledCheckbox
          name="readingConfirmation"
          label="Lesebestätigung"
          errorMsg="Bitte angeben"
          control={control}
          description="Benutzer müssen bestätigen, dass Sie das Dokument gelesen haben."
        />
      </Grid>
      {checkReadingConfirmation && (
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Alle Benutzer der Praxis werden aufgefordert das Dokument zu lesen.
            In Zukunft werden auch einzelne Benutzer und Bereiche auswählbar
            sein.
          </Typography>
          <DEDateField
            control={control}
            name="readingDueDate"
            label="Frist Lesebestätigung"
            errorMsg="Bitte ein Datum auswählen."
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body1">Gültigkeitsprüfung:</Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="recallAmount"
          label="Gültigkeitsprüfung in"
          type="number"
          required={true}
          errorMsg="Bitte angeben"
          control={control}
          helperText="Nur Zahlen eingeben"
        />
      </Grid>
      <Grid item xs={8}>
        <ControlledSelectField
          name="recallPeriod"
          label="Zeitraum"
          required={true}
          errorMsg="Bitte angeben"
          control={control}
          itemArray={[
            { label: "Tage", value: "days" },
            { label: "Wochen", value: "weeks" },
            { label: "Monate", value: "months" },
            { label: "Jahre", value: "years" },
          ]}
          helperText="Der Zeitraum, wann an das Dokument erinnert werden soll. (Standard: 1 Jahr)"
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledCheckbox
          name="revisionDoctor"
          label="Freigabe durch Arzt"
          errorMsg="Bitte angeben"
          control={control}
          description="Wenn angekreuzt, muss Arzt die finale Freigabe erteilen."
        />
      </Grid>
      {revisionChecked && (
        <Grid item xs={12}>
          <SelectController
            control={control}
            name="assignedUser"
            label="Zuweisen an:"
            errorMsg="Bitte Mitarbeiter auswählen."
            assignToMeQ={false}
            required={revisionChecked}
            filterUsers={["Editor"]}
          />
        </Grid>
      )}
    </Grid>
  );
}

ReleaseDocumentForm.propTypes = { props: PropTypes.object };

export default ReleaseDocumentForm;
