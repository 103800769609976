import { Button, Typography } from "@mui/material";
import React from "react";
import Loading from "../../../components/spinners/Loading";
import Iconify from "../../../components/iconify";
import { NavLink } from "react-router-dom";
import { useCreateNewAuditMutation } from "../../../features/audit/auditApiSlice";
import useAuth from "../../../hooks/useAuth";

export default function NoAuditError() {
  const { isAdmin } = useAuth();
  const [
    createNewAudit,
    { isError: isErrorCreate, isLoading: isLoadingCreate },
  ] = useCreateNewAuditMutation();

  const handleCreateNewCurrentAudit = async () => {
    try {
      // Make API call to create the Audit with the provided year
      const response = await createNewAudit({
        year: new Date().getFullYear(),
      }).unwrap();

      // Handle the response if the audit was successfully created
      if (response.type === "success") {
        alert("Audit Checkliste erfolgreich erstellt!");
        window.refresh();
      }
    } catch (err) {
      console.error("Error creating audit:", err);
    }
  };

  if (isErrorCreate) {
    return (
      <Typography>
        Fehler beim Erstellen der Audit Checkliste. Bitte versuchen Sie es
        erneut.
      </Typography>
    );
  }

  return (
    <div>
      <Typography variant="h3" gutterBottom>
        Es wurde noch keine Audit Checkliste für das aktuelle Jahr angelegt.
      </Typography>
      {isLoadingCreate && <Loading />}
      {isAdmin ? (
        <div>
          <div>
            <Button
              onClick={handleCreateNewCurrentAudit}
              variant="contained"
              color="primary"
              sx={{ m: 3, minWidth: { xs: 100, lg: 500 }, fontSize: "125%" }}
              startIcon={<Iconify icon="mdi:arrow-right" />}
              disabled={isLoadingCreate}
            >
              Aktuelle Checkliste erstellen
            </Button>
          </div>
          <div>
            <Button
              component={NavLink}
              to={"/dashboard/audit/create"}
              variant="contained"
              color="primary"
              sx={{ m: 3, minWidth: { xs: 100, lg: 500 }, fontSize: "125%" }}
              startIcon={<Iconify icon="mdi:arrow-right" />}
            >
              Checkliste für anderes Jahr erstellen
            </Button>
          </div>
        </div>
      ) : (
        <Typography>
          "Wenden Sie sich bitte an Ihre/n QM-Beauftragten damit eine Checkliste
          erstellt wird."
        </Typography>
      )}
    </div>
  );
}
