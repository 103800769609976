import { Grid, Typography } from "@mui/material";
import TextField from "../../../../../components/forms/TextField";
import DEDateField from "../../../../../components/forms/DEDateField";
import SelectController from "../../../../../components/forms/SelectController";
import ControlledCheckbox from "../../../../../components/forms/ControlledCheckbox";

export function Step2AdditionalFields({
  control,
  typeOfEntry,
  meetingContent,
}) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Bitte ergänzen Sie, falls gewünscht, weitere Informationen zu Ihrem
          Eintrag. Diese Angaben sind optional, können jedoch hilfreich sein.
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <TextField
          control={control}
          name="measure.content"
          label={
            typeOfEntry === "risiko" ? "Präventionsmaßnahme" : "Sofortmaßnahme"
          }
          helperText={
            typeOfEntry === "risiko"
              ? "Was wird unternommen, um das Risiko zu minimieren?"
              : "Was wurde unternommen, um das Problem zu beheben?"
          }
          fullWidth
          multiline
        />
      </Grid>

      <Grid item xs={4}>
        <DEDateField
          control={control}
          name="measure.date"
          label={
            typeOfEntry === "risiko" ? "Datum der Präv." : "Datum der Maßn."
          }
          fullWidth
        />
      </Grid>
      {typeOfEntry !== "risiko" && (
        <Grid item xs={7}>
          <TextField
            control={control}
            name="causeAnalysis"
            label="Ursachenanalyse"
            helperText="Wie kam es zu dem Problem? Wie hätte es vermieden werden können?"
            fullWidth
            multiline
          />
        </Grid>
      )}
      <Grid item xs={5}>
        <SelectController
          control={control}
          name="responsibleUser"
          label="Zugewiesen an:"
        />
      </Grid>

      <Grid container sx={{ pl: 2 }}>
        <Grid item xs={6}>
          <ControlledCheckbox
            name="discussionInTeamMeetingContent"
            control={control}
            label="Besprechung in Teammeeting"
          />
        </Grid>
        {meetingContent && (
          <Grid item xs={6}>
            <DEDateField
              control={control}
              name="discussionInTeamMeetingDate"
              label="Datum Besprechung"
              fullWidth
              shrinkInputLabel="true"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
