import React, { useState } from "react";
import { TextField, Button, Container, Typography } from "@mui/material";
import { useCreateNewAuditMutation } from "../../../features/audit/auditApiSlice";
import ErrorAlert from "../../../components/alerts/ErrorAlert";

const CreateAudit = () => {
  const [year, setYear] = useState("");
  const [error, setError] = useState("");
  const [createNewAudit, { isError, isLoading }] = useCreateNewAuditMutation();

  // Handle input changes
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    // Validate year input (ensure it's a valid number and not empty)
    if (!year || isNaN(year) || year < 1000 || year > 9999) {
      setError("Bitte ein gültiges Jahr im Format YYYY angeben.  (Z.B. 2024)");
      return;
    }

    try {
      // Make API call to create the Audit with the provided year
      const response = await createNewAudit({ year }).unwrap();

      // Handle the response if the audit was successfully created
      if (response.type === "success") {
        alert("Audit created successfully!");
        setYear(""); // Reset the year input
      }
    } catch (err) {
      console.error("Error creating audit:", err);
      setError("Failed to create audit. Please try again.");
    }
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        Eine neue Checkliste für einen Audit erstellen
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          label="Jahr"
          type="number"
          value={year}
          onChange={handleYearChange}
          fullWidth
          required
          error={!!error}
          helperText={error}
          margin="normal"
        />
        {isError && (
          <ErrorAlert errorMessage="Fehler beim Erstellen der Audit Checkliste" />
        )}

        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          disabled={isLoading}
        >
          {isLoading ? "Audit wird erstellt..." : "Audit erstellen"}
        </Button>
      </form>
    </Container>
  );
};

export default CreateAudit;
