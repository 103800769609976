import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  CardHeader,
  CardContent,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import PropTypes from "prop-types";
import Iconify from "src/components/iconify";

export default function AppCalendarList({ title, events }) {
  const navigate = useNavigate();

  const today = new Date().setHours(0, 0, 0, 0);
  const listViewEvents =
    events?.filter((event) => new Date(event.start) >= today).slice(0, 5) || []; // Zeige die ersten 5 Events an, die heute oder später sind

  return (
    <Card
      onClick={() => navigate("/dashboard/calendar")}
      sx={{ cursor: "pointer" }}
    >
      <CardHeader title={title} />
      <CardContent>
        <Box>
          <List>
            {listViewEvents.length > 0 ? (
              listViewEvents.map((event, index) => (
                <ListItem key={index}>
                  <Box
                    sx={{
                      width: 18,
                      height: 18,
                      borderRadius: "50%",
                      backgroundColor: event.color,
                      marginRight: 2,
                    }}
                  />
                  <ListItemText
                    primary={
                      <Typography variant="body1" fontWeight="bold">
                        {event.title}
                      </Typography>
                    }
                    secondary={
                      event.allDay
                        ? `${new Date(
                            event.start
                          ).toLocaleDateString()} - Ganztägig`
                        : `${new Date(
                            event.start
                          ).toLocaleDateString()} - ${new Date(
                            event.start
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}`
                    }
                  />
                </ListItem>
              ))
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ mt: 2 }}
              >
                <Iconify icon="eva:info-outline" width={24} height={24} />
                <Typography variant="body1" sx={{ ml: 1 }}>
                  Keine Events gefunden
                </Typography>
              </Box>
            )}
          </List>
        </Box>
      </CardContent>
    </Card>
  );
}

AppCalendarList.propTypes = {
  title: PropTypes.string,
  events: PropTypes.array.isRequired,
};
