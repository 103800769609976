import React from "react";
import OfficeCardItemList from "./OfficeCardItemList";
import Iconify from "../../../components/iconify/Iconify";
import { Typography } from "@mui/material";
import { useGetOfficesQuery } from "../../../features/offices/officesApiSlice";
import Loading from "../../../components/spinners/Loading";

function OfficeCardsView() {
  const { data: offices, isLoading } = useGetOfficesQuery();

  if (isLoading) {
    return <Loading />;
  }

  if (!offices) {
    return (
      <div style={{ textAlign: "center" }}>
        <div>
          <Iconify icon={"fa-solid:clinic-medical"} width={250} height={250} />
        </div>
        <Typography variant="h3">Noch keine Praxen!</Typography>
      </div>
    );
  }

  return <div>{offices && <OfficeCardItemList offices={offices} />}</div>;
}

export default OfficeCardsView;
