import React from "react";
import { Tooltip, Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import Iconify from "../../../components/iconify";

export default function CustomTooltip({ title, content, icon }) {
  return (
    <Tooltip
      title={
        <Typography variant="body1" sx={{ maxWidth: 300 }}>
          <strong>{title}</strong>
          {/* Prüfe, ob content ein Array ist, ansonsten als String behandeln */}
          {Array.isArray(content) ? (
            <ul style={{ paddingLeft: 20 }}>
              {content.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          ) : (
            <Typography>{content}</Typography> // Einzelner String wird normal angezeigt
          )}
        </Typography>
      }
    >
      <Box>
        <Iconify
          icon={icon}
          style={{ fontSize: "50px", color: "action.active" }}
          sx={{
            transition: "transform 0.1s",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
        />
      </Box>
    </Tooltip>
  );
}

CustomTooltip.propTypes = {
  title: PropTypes.string.isRequired, // Titel muss ein String sein
  content: PropTypes.oneOfType([
    // content kann String oder Array sein
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  icon: PropTypes.string.isRequired, // Icon muss ebenfalls ein String sein
};
