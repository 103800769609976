import React from "react";
import PropTypes from "prop-types";
import TenantTable from "./TenantTable";

function TenantOverview({ tenants }) {
  console.log("tenants :>> ", tenants);
  return (
    <div>
      <TenantTable tenants={tenants} />
    </div>
  );
}

TenantOverview.propTypes = {};

export default TenantOverview;
