import React, { useState } from "react";
import PropTypes from "prop-types";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

const DocumentSelect = ({ handleDocumentSelected, defaultDocType }) => {
  const [documentType, setDocumentType] = useState(defaultDocType);

  const handleDocumentTypeChange = (docType) => {
    handleDocumentSelected(docType);
    setDocumentType(docType);
  };

  return (
    <FormControl style={{ marginTop: 10, minWidth: 220 }}>
      <InputLabel id="document-type-label">Dokumenttyp</InputLabel>
      <Select
        labelId="document-type-label"
        id="document-type"
        label={"Dokumenttyp"}
        value={documentType}
        onChange={(e) => handleDocumentTypeChange(e.target.value)}
      >
        <MenuItem value="FB">Formblatt</MenuItem>
        <MenuItem value="VA">Verfahrensanweisung</MenuItem>
        <MenuItem value="NW">Nachweis</MenuItem>
        <MenuItem value="SONSTIGES">Sonstiges</MenuItem>
      </Select>
    </FormControl>
  );
};

export default DocumentSelect;

DocumentSelect.propTypes = {
  handleDocumentSelected: PropTypes.func,
};
