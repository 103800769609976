// FileUpload.js
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Iconify from "../../../components/iconify";
import { Icon } from "@iconify/react";

const FileUpload = ({ handleFile }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleClear = () => {
    setSelectedFile(null);
    handleFile(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFile(file);
    setSelectedFile(file);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      handleFile(file);
    }
  };

  const handleButtonClick = () => {
    // Trigger file input click on button click
    document.getElementById("file-input").click();
  };

  return (
    <Box
      onClick={!selectedFile ? handleButtonClick : undefined}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      display="flex"
      flexDirection="column"
      justifyContent="center" // Center the contents vertically
      alignItems="center" // Center the contents horizontally
      sx={{
        border: isDragging ? "2px dashed #2196f3" : "2px dashed #ccc",
        borderRadius: "8px",
        padding: "16px",
        textAlign: "center",
        cursor: "pointer",
        marginTop: "16px",
        width: "100%",
        minHeight: "300px",
      }}
    >
      <input
        type="file"
        accept="*"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="file-input"
      />
      {selectedFile && (
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
          {/* You can customize the preview based on the file type */}
          {selectedFile.name.endsWith("docx") && (
            <div>
              Keine Vorschau für Word-Dokumente möglich.
              <div>
                <Icon
                  icon="vscode-icons:file-type-word"
                  width="42"
                  height="42"
                />
              </div>
            </div>
          )}
          {selectedFile.type.startsWith("image/") && (
            <img
              src={URL.createObjectURL(selectedFile)}
              alt={selectedFile.name}
              style={{ maxWidth: "400px", maxHeight: "100%" }}
            />
          )}
          {/* Add additional conditions for other file types if needed */}
        </Box>
      )}
      <label htmlFor="file-input">
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: isDragging ? "#2196f3" : "inherit",
            marginBottom: "8px",
          }}
        >
          {selectedFile
            ? `Ausgewählte Datei: ${selectedFile.name}`
            : "Hier Klicken oder Datei hierherziehen"}
        </Typography>
      </label>
      <div>
        <Button
          variant="contained"
          startIcon={<Iconify icon="subway:file-11" />}
          onClick={handleButtonClick}
        >
          Datei auswählen
        </Button>
        <Button
          variant="contained"
          startIcon={<Iconify icon="ph:trash-bold" />}
          onClick={handleClear}
          disabled={!selectedFile}
          sx={{ marginLeft: "8px" }}
        >
          Zurücksetzen
        </Button>
      </div>
    </Box>
  );
};

export default FileUpload;

// <DocViewer
//   documents={[
//     {
//       uri: window.URL.createObjectURL(selectedFile),
//       fileName: selectedFile.name,
//     },
//   ]}
//   pluginRenderers={DocViewerRenderers}
// />
