import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
// @mui
import { Button, Container, Stack, Typography } from "@mui/material";
// components
import Iconify from "../components/iconify";
// mock
// import FileManager from "../sections/@dashboard/files/FileManager";
import { setDialog } from "../features/dialog/dialogSlice";
import FileDataTable from "../sections/@dashboard/files/FileDataTable/FileDataTable";
import DisplayFolderStructure from "../sections/@dashboard/files/DisplayFolderStructure";
import { useGetFileDatasQuery } from "../features/fileData/fileDataApiSlice";
import Loading from "../components/spinners/Loading";
import ReadingConfirmationOverview from "../sections/@dashboard/files/ReadingConfirmation/ReadingConfirmationOverview";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import PreviewDocument from "../sections/@dashboard/files/PreviewDocument";
import useAuth from "../hooks/useAuth";
import CreateNewDocumentView from "../sections/@dashboard/files/CreateNewDocumentView";
import UploadDocumentView from "../sections/@dashboard/files/UploadDocumentView";
import CreateQMStructure from "../sections/@dashboard/files/CreateQMStructure";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const dev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export default function FilesPage() {
  const { isAdmin } = useAuth();
  let location = useLocation();
  const { data: fileDatas, isLoading } = useGetFileDatasQuery();

  const dispatch = useDispatch();

  const toggleUpload = () => {
    dispatch(
      setDialog({
        title: "Datei hochladen",
        content: "FileUpload",
      })
    );
  };

  const handleOpenNewFileDataDialog = () => {
    dispatch(
      setDialog({
        title: "Neues Dokument erstellen",
        type: "CREATE",
        content: "create-filedata",
      })
    );
  };

  const handleOpenFileDialog = (fileData, action) => {
    if (action === "SINGLE-FILEDATA") {
      // Set action to SHOW so file will only be shown.
      dispatch(
        setDialog({
          title: "Dokumentdaten bearbeiten",
          type: "EDIT",
          content: "filedata-edit",
          data: { fileData, action: "EDIT" },
        })
      );
      return null;
    }

    if (action === "MULTI-FILEDATA") {
      // Set action to SHOW so file will only be shown.
      dispatch(
        setDialog({
          title: "Daten mehrerer Dokumente bearbeiten",
          type: "EDIT",
          content: "filedata-edit-multi",
          data: { fileData, action: "EDIT" },
        })
      );
      return null;
    }

    if (action === "RELEASE") {
      // Set action to SHOW so file will only be shown.
      dispatch(
        setDialog({
          title: "Dokument freigeben",
          type: "CREATE",
          content: "file-release",
          data: { fileData, action: "SHOW" },
        })
      );
      return null;
    }
    if (action === "DELETE") {
      // Set action to SHOW so file will only be shown.
      dispatch(
        setDialog({
          title: "Dokument löschen",
          type: "DELETE",
          content: "file-delete",
          data: { fileData, action },
        })
      );
      return null;
    }
    if (action === "create-proof") {
      dispatch(
        setDialog({
          title: `Nachweis erzeugen`,
          type: "CREATE",
          content: action,
          data: { fileData, action },
        })
      );
      return null;
    }

    if (["REVIEW", "EDIT"].includes(action)) {
      if (fileData.isCheckedOut) {
        // Document is already checked out
        console.log(
          "Update here so that in the future a request is sent to backend that checks if checkedOut is true"
        );
        return dispatch(
          setDialog({
            title: `${fileData.fileName} ist ausgecheckt!`,
            type: "ERROR",
            data: {
              errorText:
                "Das von Ihnen angeforderte Dokument wird bereits bearbeitet. Bitte versuchen Sie es später erneut.",
            },
          })
        );
      }

      // When document is opened and the user is able to edit, set Checked out flag in Backend

      console.log("Update doc so isCheckedOut is true!");

      dispatch(
        setDialog({
          title: "Datei anzeigen",
          type: "CREATE",

          content: "file-show",
          data: { fileData, action, checkOut: true },
        })
      );
      return null;
    }

    dispatch(
      setDialog({
        title: "Datei anzeigen",
        type: "CREATE",
        content: "file-show",
        data: { fileData, action },
      })
    );
  };

  const handleOpenAssignFileDialog = (fileData) => {
    dispatch(
      setDialog({
        title: "Dokument zuweisen",
        type: "ASSIGN",
        content: "assign-file",
        data: { fileData },
      })
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title> Digit QM | QM Dokumente </title>
      </Helmet>

      <Container sx={{ minHeight: "100%" }} maxWidth="">
        {location?.pathname !== "/dashboard/files" ? (
          <Button
            component={NavLink}
            to={"/dashboard/files"}
            // onClick={() => handleChangeMenuState("/dashboard/files")}
            variant="contained"
            color="warning"
            sx={{ minWidth: 300, mb: 2 }}
            startIcon={<Iconify icon="mdi:arrow-left" />}
          >
            MENÜ
          </Button>
        ) : (
          <Typography variant="h4" gutterBottom>
            QM-Dokumente
          </Typography>
        )}

        <Routes>
          <Route index element={<FilesMainMenu isAdmin={isAdmin} />} />
          <Route
            path="overview"
            element={
              <div>
                {fileDatas ? (
                  <FileDataTable
                    handleOpenFileDialog={handleOpenFileDialog}
                    handleOpenAssignFileDialog={handleOpenAssignFileDialog}
                    fileDatas={fileDatas}
                  />
                ) : (
                  <h1>Noch keine Dokumenteneinträge</h1>
                )}
              </div>
            }
          />
          {isAdmin && (
            <Route
              path="folders"
              element={
                <div>
                  <DisplayFolderStructure
                    handleOpenFileDialog={handleOpenFileDialog}
                    handleOpenAssignFileDialog={handleOpenAssignFileDialog}
                  />
                </div>
              }
            />
          )}

          {isAdmin && (
            <Route path="reading" element={<ReadingConfirmationOverview />} />
          )}
          {isAdmin && (
            <Route path="create-qm" element={<CreateQMStructure />} />
          )}
          <Route path="show/:fileId" element={<PreviewDocument />} />
          <Route path="new" element={<CreateNewDocumentView />} />
          <Route path="upload" element={<UploadDocumentView />} />
        </Routes>
      </Container>
    </>
  );
}

function FilesMainMenu({ isAdmin }) {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{ minHeight: "100%", py: 3 }}
    >
      <Button
        component={NavLink}
        to={"new"}
        variant="contained"
        sx={{ minWidth: 300, my: 3 }}
        startIcon={<Iconify icon="mdi:file-document-plus-outline" />}
      >
        Neues Dokument
      </Button>
      <Button
        component={NavLink}
        to={"upload"}
        variant="contained"
        sx={{ minWidth: 300, my: 3 }}
        startIcon={<Iconify icon="grommet-icons:document-upload" />}
      >
        Dokument hochladen
      </Button>
      <Button
        component={NavLink}
        to={"overview"}
        variant="contained"
        sx={{ minWidth: 300, my: 3 }}
        startIcon={<Iconify icon="gala:menu-left" />}
      >
        Dokumentenübersicht
      </Button>
      {isAdmin && (
        <Button
          component={NavLink}
          to={"folders"}
          variant="contained"
          sx={{ minWidth: 300, my: 3 }}
          startIcon={<Iconify icon="mdi:folders-outline" />}
        >
          Ordnerstruktur anzeigen
        </Button>
      )}
      {isAdmin && (
        <Button
          component={NavLink}
          to={"reading"}
          variant="contained"
          sx={{ minWidth: 300, my: 3 }}
          startIcon={<Iconify icon="mdi:book-check-outline" />}
        >
          Lesebestätigungen
        </Button>
      )}
      {isAdmin && (
        <Button
          component={NavLink}
          to={"create-qm"}
          variant="contained"
          sx={{ minWidth: 300, my: 3 }}
          startIcon={<Iconify icon="mdi:book-check-outline" />}
        >
          QM Struktur erstellen
        </Button>
      )}
    </Stack>
  );
}
