import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { MenuItem, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../features/auth/authSlice";
import { useGetOfficeAreasQuery } from "src/features/offices/officesApiSlice";

export default function SelectControllerForAreas({
  control,
  name,
  label,
  errorMsg,
  required,
  disabled,
}) {
  const currentUserData = useSelector(selectCurrentUserData);
  const { data: areas } = useGetOfficeAreasQuery(
    currentUserData?.selectedOffice
  );

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (areas && Array.isArray(areas.areas)) {
      setOptions(
        areas.areas.map((area) => ({
          label: area.name,
          value: area._id,
        }))
      );
    }
  }, [areas]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TextField
          select
          fullWidth
          variant="outlined"
          label={label}
          value={value || ""}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          inputRef={ref}
          error={!!error}
          required={required}
          disabled={disabled}
          helperText={errorMsg}
        >
          <MenuItem value="">Kein Bereich ausgewählt</MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
}

SelectControllerForAreas.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  required: PropTypes.bool,
  assignToMeQ: PropTypes.bool,
  disabled: PropTypes.bool,
  officeId: PropTypes.string.isRequired, // officeId ist erforderlich, um die Bereiche abzurufen
};
