import * as React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import {
  styled,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import { useUpdateTaskMutation } from "../../../features/task/taskApiSlice";
import Comments from "./Comments";
import TaskForm from "./TaskForm";
import Iconify from "../../../components/iconify";
import PopOverComponent from "../../../components/popover/PopOverComponent";
import dayjs from "dayjs";

// ---------------------------------------------------------------------------------------------------------

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

EditTaskDialog.propTypes = {
  updatedTask: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

// --------------------------------------------------------------------------------------------------------------

export default function EditTaskDialog({ updatedTask, handleClose }) {
  let dV = updatedTask;
  const { handleSubmit, control } = useForm({
    defaultValues: { ...dV, responsibleUser: dV?.responsibleUser?._id },
  });

  const [updateTask] = useUpdateTaskMutation();

  const onSubmit = async (data) => {
    try {
      let result = await updateTask({
        ...data,
      });
      handleClose();
      console.log("result :>> ", result);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <PopOverComponent
            icon="octicon:three-bars-16"
            dataId={updatedTask._id}
            creator={updatedTask?.creator?._id}
            art="TASK"
          />
          <Typography
            sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
            id="customized-dialog-title"
          >
            Aufgabe bearbeiten
          </Typography>
          <Box sx={{ flex: "0 0 auto" }}>
            <Iconify
              onClick={handleClose}
              icon="mingcute:close-line"
              style={{ fontSize: "50px", color: "action.active" }}
              sx={{
                transition: "transform 0.1s", // Smooth transition for transform
                "&:hover": {
                  transform: "scale(1.8)", // Scale up the icon when hovered
                },
              }}
            />
          </Box>
        </Box>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1" color="textPrimary">
              Bearbeiten Sie die Aufgabe und drücken Sie auf aktualisieren um
              Ihre Änderungen zu speichern.
            </Typography>
          </DialogContentText>
          <br />
          <TaskForm control={control} />
          <Typography variant="h6" sx={{ m: 1 }} color="textPrimary">
            Erstellt von{" "}
            {updatedTask?.creator?.firstName +
              " " +
              updatedTask?.creator?.lastName}{" "}
            am {dayjs(updatedTask.createdAt).format("DD.MM.YYYY")} um{" "}
            {dayjs(updatedTask.createdAt).format("HH:mm")} Uhr
          </Typography>
          <Typography
            sx={{
              m: 0,
              pl: 2,
              pt: 2,
              pb: 1,
              textAlign: "left",
              fontSize: "20px ",
            }}
            id="customized-dialog-title"
          >
            Kommentare
          </Typography>
          <Comments taskId={updatedTask._id} comments={updatedTask.comments} />
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: "#9E9E9E" }}
            variant="contained"
            onClick={handleClose}
          >
            Abbrechen
          </Button>
          <Button
            type="submit"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Aktualisieren
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
