import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Iconify from "../../../components/iconify";
import {
  useGetNewsByIdQuery,
  useLikeNewsMutation,
} from "../../../features/news/newsApiSlice";
import { Link as MuiLink } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";
import useAuth from "../../../hooks/useAuth";
import { selectCurrentUserData } from "../../../features/auth/authSlice";
import { serverURL } from "../../../app/api/axios/http.service";

const NewsDetails = () => {
  const dispatch = useDispatch();
  const { status } = useAuth();
  const currentUserData = useSelector(selectCurrentUserData);
  const { id } = useParams();
  const { data: news, error, isLoading } = useGetNewsByIdQuery(id); // error und isLoading hinzugefügt
  const [likeNews] = useLikeNewsMutation();
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const canEdit = status === "Admin" || currentUserData?.id === news?.author;

  // Ladezustand und Fehlerbehandlung
  if (isLoading) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4">Lade...</Typography>
      </Box>
    );
  }

  if (error || !news) {
    // Fehlerbehandlung
    return (
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4">Artikel nicht gefunden</Typography>
        <Typography variant="body2" color="text.secondary">
          {error ? error.message : "Der angeforderte Artikel existiert nicht."}
        </Typography>
      </Box>
    );
  }

  const handleLike = async () => {
    const result = await likeNews(news?._id);
    console.log("result", result);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("de-DE");
  };

  const getImageSrc = () => {
    console.log("news.imagePath", news.imagePath);
    if (news.imagePath) {
      const newsId = news._id;
      return `${serverURL()}/public/new/image/${newsId}`;
    }

    // Use default image based on imageDefaultPath
    switch (news.imageDefaultPath) {
      case "1":
        return require("./NewsDialogComponents/1.jpg");
      case "2":
        return require("./NewsDialogComponents/2.jpg");
      case "3":
        return require("./NewsDialogComponents/3.jpg");
      default:
        return "/assets/images/praxis.jpg"; // Fallback image
    }
  };

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleUpdate = () => {
    dispatch(
      setDialog({ content: "NewsDialog", data: news, eventFlag: "EDIT" })
    );
    console.log("Update clicked");
    setMenuAnchorEl(null);
  };

  const handleArchive = () => {
    dispatch(
      setDialog({
        content: "ArchiveForm",
        data: { newId: news?._id },
      })
    );
    setMenuAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(
      setDialog({
        content: "DeleteForm",
        data: { newId: news?._id },
        art: "DELETENEW",
      })
    );
    setMenuAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* Bildbereich */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "50vh",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            pl: 3,
            pr: 3,
            position: "absolute",
            top: 16,
            left: 0,
            right: 0, // Fügen Sie dies hinzu, um den Container über die gesamte Breite zu ziehen
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Elemente auf die Enden des Containers ausrichten
          }}
        >
          {/* Zurück-Navigationsbutton */}
          <IconButton
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              color: "black",
              zIndex: 10,
              fontSize: "32px",
            }}
            onClick={() => navigate(-1)}
          >
            <Iconify icon="mdi:arrow-left" width={32} height={32} />
          </IconButton>

          {/* Rechter Bereich für Favoritenstern und Bearbeiten-Button */}
          <Box display="flex" alignItems="center">
            {/* Gelber Stern, falls favorit */}
            {news.favorite && (
              <Tooltip title="Favorite" sx={{ mx: 1 }}>
                <Iconify
                  icon="mdi:star"
                  width={40} // Größe erhöhen
                  height={40} // Größe erhöhen
                  sx={{
                    mr: 2,
                    color: "gold", // Kräftigere Farbe verwenden
                    "&:hover": {
                      color: "orange", // Farbe bei Hover ändern
                      transform: "scale(1.1)", // Vergrößern bei Hover
                      transition: "0.2s ease", // Übergangseffekt
                    },
                  }}
                />
              </Tooltip>
            )}

            {canEdit && (
              <>
                <IconButton
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    color: "black",
                    zIndex: 10,
                    fontSize: "32px",
                  }}
                  onClick={handleMenuOpen}
                >
                  <Iconify icon="mdi:dots-vertical" width={32} height={32} />
                </IconButton>

                <Menu
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={handleUpdate}>
                    <Iconify
                      icon="mdi:pen"
                      width={24}
                      height={24}
                      sx={{ mr: 1, color: "blue" }}
                    />
                    Aktualisieren
                  </MenuItem>
                  <MenuItem onClick={handleArchive}>
                    <Iconify
                      icon="mdi:archive-outline"
                      width={24}
                      height={24}
                      sx={{ mr: 1, color: "orange" }}
                    />
                    Archivieren
                  </MenuItem>
                  <MenuItem onClick={handleDelete}>
                    <Iconify
                      icon="mdi:delete-outline"
                      width={24}
                      height={24}
                      sx={{ mr: 1, color: "red" }}
                    />
                    Löschen
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Box>

        <img
          src={getImageSrc()}
          alt={news.title}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            padding: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: "bold", color: "black" }}>
            {news.title}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{ marginBottom: 1 }}
        >
          {news.subtitle}
        </Typography>

        <Typography
          variant="body1"
          sx={{ marginTop: 3, whiteSpace: "pre-line" }}
        >
          {news.content}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          padding: 3,
          mt: "auto",
        }}
      >
        {news?.url && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ mt: "10px" }}
          >
            <Tooltip title={news.url}>
              <Iconify icon="ic:baseline-public" sx={{ cursor: "pointer" }} />
              <MuiLink
                href={
                  news.url.startsWith("http://") ||
                  news.url.startsWith("https://")
                    ? news.url
                    : `https://${news.url}`
                }
                target="_blank"
                rel="noopener noreferrer"
                sx={{ cursor: "pointer", ml: 0.5 }}
              >
                Website öffnen
              </MuiLink>
            </Tooltip>
          </Stack>
        )}
        {news?.documentPath && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ mt: "10px" }}
          >
            <Tooltip title="Dokument öffnen">
              <Iconify
                icon="ic:baseline-description"
                sx={{ cursor: "pointer" }}
              />
              <MuiLink
                href={`${serverURL()}/public/new/document/${news._id}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ cursor: "pointer", ml: 0.5 }}
              >
                Dokument öffnen
              </MuiLink>
            </Tooltip>
          </Stack>
        )}

        <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
          {/* Author */}
          <Grid item xs={12} sm={3} display="flex" alignItems="center">
            <Tooltip title={"Erstellt von:"}>
              <Iconify icon="mdi:account" width={20} height={20} />
            </Tooltip>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {news.author.firstName + " " + news.author.lastName}
            </Typography>
          </Grid>

          {/* Likes */}
          <Grid item xs={12} sm={3} display="flex" alignItems="center">
            <Tooltip
              title={
                news?.likeStatus ? "Gefällt mir zurücknehmen" : "Jetzt liken"
              }
            >
              <IconButton onClick={handleLike}>
                <Iconify
                  icon={news?.likeStatus ? "mdi:heart" : "mdi:heart-outline"}
                  width={24}
                  height={24}
                  color={news?.likeStatus ? "red" : "default"}
                />
              </IconButton>
            </Tooltip>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {news?.likes?.length || 0}{" "}
              {news?.likes?.length === 1 ? "Like" : "Likes"}
            </Typography>
          </Grid>

          {/* Views */}
          <Grid item xs={12} sm={3} display="flex" alignItems="center">
            <Tooltip title="Anzahl der Aufrufe">
              <Iconify icon="mdi:eye-outline" width={20} height={20} />
            </Tooltip>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {news?.views?.length || 0}{" "}
              {news?.views?.length === 1 ? "View" : "Views"}
            </Typography>
          </Grid>

          {/* Date */}
          <Grid item xs={12} sm={3} display="flex" alignItems="center">
            <Iconify icon="mdi:calendar" width={20} height={20} />
            <Typography variant="body2" sx={{ ml: 1, fontStyle: "italic" }}>
              Veröffentlicht am: {formatDate(news.createdAt)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NewsDetails;
