import React, { useEffect, useState } from "react";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListSubheader,
} from "@mui/material";
// components
import Iconify from "../../../../components/iconify";
import Scrollbar from "../../../../components/scrollbar";
import NotificationItem from "./NotificationItem";
import {
  useGetNotesQuery,
  useMarkAllAsReadMutation,
} from "../../../../features/notes/notesApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice } from "../../../../app/api/apiSlice";
import { selectCurrentUserData } from "../../../../features/auth/authSlice";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { data: test } = useGetNotesQuery();
  const [notifications, setNotifications] = useState([]);
  const [markAllAsRead] = useMarkAllAsReadMutation();
  const [taskToShow, setTaskToShow] = useState(5);
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();

  const currentUserData = useSelector(selectCurrentUserData);
  const currentUserId = currentUserData.id;

  useEffect(() => {
    if (test) {
      // Convert createdAt to Date object
      const updatedNotifications = test.map((notification) => ({
        ...notification,
        createdAt: new Date(notification.createdAt),
      }));
      setNotifications(updatedNotifications);
    }
  }, [test]);

  const totalUnRead = notifications.filter((item) =>
    item.recipients.some(
      (recipient) =>
        recipient.userId._id.toString() === currentUserId.toString() &&
        !recipient.read
    )
  ).length;

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setTaskToShow(5);
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead().unwrap();
      dispatch(apiSlice.util.resetApiState());
    } catch (error) {
      console.error("Failed to mark all notes as read:", error);
    }
  };

  const showMore = () => {
    setTaskToShow(taskToShow + 8);
  };

  const handleDeleteNotification = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification._id !== id)
    );
  };

  const handleUpdateNotificationStatus = (id, userId) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification._id === id
          ? {
              ...notification,
              recipients: notification.recipients.map((recipient) =>
                recipient.userId._id.toString() === userId.toString()
                  ? { ...recipient, read: !recipient.read }
                  : recipient
              ),
            }
          : notification
      )
    );
  };

  return (
    <>
      <IconButton
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
            maxHeight: 650,
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Benachrichtigungen</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Sie haben {totalUnRead} ungelesene Nachrichten
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title="Alle Nachrichten auf gelesen setzen">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Neue Nachrichten
              </ListSubheader>
            }
          >
            {notifications.filter((notification) =>
              notification.recipients.some(
                (recipient) =>
                  recipient.userId._id.toString() ===
                    currentUserId.toString() && !recipient.read
              )
            ).length > 0 ? (
              notifications
                .filter((notification) =>
                  notification.recipients.some(
                    (recipient) =>
                      recipient.userId._id.toString() ===
                        currentUserId.toString() && !recipient.read
                  )
                )
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((notification) => (
                  <NotificationItem
                    key={notification._id}
                    notification={notification}
                    currentUserId={currentUserId}
                    isUnRead={true}
                    onDelete={handleDeleteNotification}
                    onUpdateStatus={handleUpdateNotificationStatus}
                  />
                ))
            ) : (
              <Typography
                variant="subtitle2"
                sx={{ p: 2, textAlign: "center" }}
              >
                Keine neue Nachrichten vorhanden.
              </Typography>
            )}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Alte Nachrichten
              </ListSubheader>
            }
          >
            {notifications.filter((notification) =>
              notification.recipients.some(
                (recipient) =>
                  recipient?.userI?._id.toString() ===
                    currentUserId.toString() && recipient?.read
              )
            ).length > 0 ? (
              notifications
                .filter((notification) =>
                  notification?.recipients?.some(
                    (recipient) =>
                      recipient?.userId?._id?.toString() ===
                        currentUserId?.toString() && recipient?.read
                  )
                )
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .slice(0, taskToShow) // Ensure slice(0, taskToShow) is used
                .map((notification) => (
                  <NotificationItem
                    key={notification._id}
                    notification={notification}
                    currentUserId={currentUserId}
                    isUnRead={false}
                    onDelete={handleDeleteNotification}
                    onUpdateStatus={handleUpdateNotificationStatus}
                  />
                ))
            ) : (
              <Typography
                variant="subtitle2"
                sx={{ p: 2, textAlign: "center" }}
              >
                Keine alte Nachrichten vorhanden.
              </Typography>
            )}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box onClick={showMore} sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            Mehr anzeigen
          </Button>
        </Box>
      </Popover>
    </>
  );
}
