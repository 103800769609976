import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { getSurveyOptionFromValue } from "../../../utils/surveyUtils";
import { useNavigate } from "react-router-dom";

function SurveyItem({ survey, handleDelete }) {
  const navigate = useNavigate();

  const handleShowPublicSurvey = (id) => {
    navigate(`/survey/${id}`);
  };

  return (
    <Box key={survey._id}>
      <Card variant="outlined">
        <CardContent>
          <Typography
            gutterBottom
            sx={{ color: "text.secondary", fontSize: 14 }}
          >
            {survey.surveyType === "employee"
              ? "Mitarbeiterbefragung"
              : "Patientenbefragung"}
          </Typography>
          <Typography variant="h5" component="div">
            {survey.surveyTitle}
          </Typography>
          <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
            {survey.surveyDescription}
          </Typography>
          <Typography variant="body2">
            {survey &&
              survey.surveyQuestions.map((q, index) => (
                <>
                  <span key={index}>
                    {index + 1}. {q.question} <br />
                  </span>
                  <span style={{ marginLeft: 15 }}>
                    Art: {q.type && getSurveyOptionFromValue(q.type).label}
                    <br />
                  </span>
                </>
              ))}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">BEARBEITEN</Button>
          <Button
            size="small"
            color="error"
            onClick={() => handleDelete(survey._id, survey.surveyTitle)}
          >
            LÖSCHEN
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => handleShowPublicSurvey(survey._id)}
          >
            ANZEIGEN
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

SurveyItem.propTypes = {
  survey: PropTypes.object,
  handleDelete: PropTypes.func,
};

export default SurveyItem;
