import React from "react";
import { Route, Routes } from "react-router-dom";
import AuditTable from "./AuditTable";
import AuditsOverviewTable from "./AuditsOverviewTable";

function AuditView() {
  return (
    <div>
      <Routes>
        <Route index element={<AuditsOverviewTable />} />
        <Route path="/:year" element={<AuditTable />} />
      </Routes>
    </div>
  );
}

export default AuditView;
