import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Checkbox, FormControlLabel } from "@mui/material";
import TextField from "../../../../components/forms/TextField";
import ControlledCheckbox from "../../../../components/forms/ControlledCheckbox";

// ----------------------------------------------------------------------------

LawForm.propTypes = {
  control: PropTypes.object.isRequired,
};

// ----------------------------------------------------------------------------

export default function LawForm({ control }) {
  return (
    <div>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
        Felder mit * am Ende sind Pflichtfelder, bitte füllen Sie alle
        Pflichtfelder aus!
      </Typography>

      <Grid container spacing={2}>
        {/* Title */}
        <Grid item xs={12}>
          <TextField
            control={control}
            name="title"
            label="Titel"
            errorMsg="Bitte geben Sie einen Titel ein!"
            fullWidth
            required={true}
          />
        </Grid>

        {/* Content */}
        <Grid item xs={12}>
          <TextField
            control={control}
            name="description"
            label="Inhalt"
            variant="outlined"
            errorMsg="Bitte geben Sie den Inhalt ein!"
            multiline
            minRows={3}
            fullWidth
            required={true}
          />
        </Grid>

        {/* URL */}
        <Grid item xs={12}>
          <TextField
            control={control}
            name="externalUrl"
            label="Website/Url"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledCheckbox
            control={control}
            name="subjectToDisplay"
            label="Aushangpflichtig"
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
}
