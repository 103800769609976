export const RELEASE_DOCUMENT = {
  checkCreateProof: false,
  selectedFolder: "",
  readingConfirmation: false,
  readingDueDate: new Date(),
  recallAmount: 1,
  recallPeriod: "years",
  revisionDoctor: false,
  assignedUser: "", // for SelectController
};
