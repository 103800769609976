// ----------------------------------------------------------------------

const account = {
  displayName: "Michael Angermeyer",
  email: "m.a@awds.de",
  role: "Admin",
  photoURL: "/assets/images/avatars/avatar_4.jpg",
};

export default account;
