import { Paper, Typography } from "@mui/material";

export const verfahrensanweisungText = () => (
  <div>
    <Typography variant="body1">
      Eine Verfahrensanweisung im Kontext des Qualitätsmanagements für
      Arztpraxen ist eine schriftliche Anleitung, die die spezifischen Schritte
      und Verfahren beschreibt, die innerhalb der Praxis befolgt werden sollen,
      um qualitativ hochwertige Dienstleistungen und Patientenversorgung zu
      gewährleisten. Sie dient dazu, Arbeitsabläufe zu standardisieren, die
      Qualität zu sichern und die Einhaltung von Standards und Richtlinien zu
      gewährleisten. Angenommen, eine Arztpraxis möchte sicherstellen, dass der
      Prozess für die Patientenaufnahme und -registrierung einheitlich und
      effizient abläuft.
      <br />
      Die Verfahrensanweisung könnte Schritte wie folgt enthalten:
      <ul style={{ marginTop: 5, marginLeft: 50 }}>
        <li>
          Begrüßung des Patienten: Beschreibt, wie das Front-Desk-Personal den
          Patienten freundlich empfangen soll.
        </li>
        <li>
          Dokumentation der Patientendaten: Legt fest, welche Informationen
          aufgenommen werden müssen, wie Name, Geburtsdatum,
          Kontaktinformationen usw. Überprüfung von Versicherungsinformationen:
          Beschreibt den Prozess zur Überprüfung der Versicherungsdetails des
          Patienten.
        </li>
        <li>
          Vergabe von Patientenakten: Legt fest, wie und wo die Patientenakte
          erstellt und aufbewahrt wird.
        </li>
        <li>
          Weiterleitung an den Arzt: Beschreibt den nahtlosen Übergang des
          Patienten an den behandelnden Arzt.
        </li>
      </ul>
    </Typography>
  </div>
);

export const formblattText = () => (
  <Paper elevation={3} style={{ padding: "16px" }}>
    <Typography paragraph>
      Ein Formblatt im Kontext des Qualitätsmanagements für Arztpraxen ist ein
      vorgedrucktes Dokument oder eine elektronische Vorlage, die dazu dient,
      spezifische Informationen zu erfassen und standardisierte Daten innerhalb
      der Praxis zu sammeln. Diese Formblätter können für verschiedene Zwecke
      verwendet werden, einschließlich der Dokumentation von Verfahren, der
      Aufzeichnung von Patienteninformationen oder der Erfassung von
      Qualitätsmetriken.
    </Typography>
    <Typography paragraph>
      Beispiel: Ein Formblatt könnte für die Dokumentation von Schulungen des
      medizinischen Personals erstellt werden. Es könnte Felder enthalten, in
      denen der Name des Mitarbeiters, das Datum der Schulung, der behandelte
      Inhalt und andere relevante Details eingetragen werden. Durch die
      Verwendung eines solchen Formblatts wird sichergestellt, dass alle
      Schulungen einheitlich erfasst werden und die notwendigen Informationen
      leicht abgerufen werden können.
    </Typography>
    <Typography paragraph>
      Ein weiteres Beispiel könnte ein Formblatt für Patientenbefragungen sein.
      Hier könnten Fragen zu Zufriedenheit, Wartezeiten oder anderen Aspekten
      der Patientenerfahrung aufgeführt sein. Die Verwendung eines
      standardisierten Formblatts ermöglicht eine konsistente Datenerfassung und
      Auswertung.
    </Typography>
    <Typography paragraph>
      Insgesamt dienen Formblätter dazu, den dokumentierten Informationsfluss
      innerhalb der Arztpraxis zu erleichtern, die Einheitlichkeit zu fördern
      und die Qualitätssicherung zu unterstützen.
    </Typography>
  </Paper>
);

export const nachweisText = () => (
  <div>
    <Typography variant="body1">
      Ein Nachweis im Kontext des Qualitätsmanagements für Arztpraxen ist eine
      dokumentierte Evidenz oder Aufzeichnung, die dazu dient, die Einhaltung
      von Qualitätsstandards, Richtlinien oder durchgeführten Verfahren zu
      belegen. Diese Aufzeichnungen spielen eine entscheidende Rolle bei der
      Sicherung der Qualität, der Erfüllung regulatorischer Anforderungen und
      der Nachverfolgung von durchgeführten Aktivitäten in der Praxis.
      <br />
      Zum Beispiel könnte ein Nachweis im Zusammenhang mit der Hygienekontrolle
      in der Arztpraxis dokumentieren, dass regelmäßige Desinfektionsmaßnahmen
      gemäß den festgelegten Protokollen durchgeführt wurden.
    </Typography>
    <Typography paragraph>
      Die Arten von Nachweisen können vielfältig sein und hängen von den
      spezifischen Anforderungen und Abläufen der Praxis ab. Dazu gehören
      Protokolle von Schulungen, Ergebnisse von Qualitätsaudits, Nachweise über
      die Wartung von medizinischen Geräten oder Aufzeichnungen von
      Patienteninterventionen.
    </Typography>
    <Typography paragraph>
      Diese Nachweise dienen nicht nur als Beleg für die Einhaltung von
      Standards, sondern ermöglichen auch eine systematische Überprüfung und
      Verbesserung der Prozesse in der Arztpraxis. Sie tragen dazu bei,
      Transparenz zu schaffen und das Vertrauen der Patienten sowie die
      Compliance mit regulatorischen Anforderungen zu stärken.
    </Typography>
  </div>
);
