import React from "react";
import { Icon } from "@iconify/react";
import { Typography } from "@mui/material";

function SuccessStep() {
  return (
    <div style={{ textAlign: "center" }}>
      <Icon
        icon="icon-park-twotone:success"
        color="lawngreen"
        width={"20vh"}
        height={"20vh"}
        style={{ margin: 25 }}
      />
      <Typography variant="h3" id="success-dialog-description">
        Dokument wurde erfolgreich hochgeladen!
      </Typography>
    </div>
  );
}

export default SuccessStep;
