import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FILEDATA_TYPE_OPTIONS_LONG } from "../../../sections/@dashboard/files/FileDataTable/fileDataTableUtils";

function FileDataTypeSelect({ control }) {
  return (
    <Controller
      name="type"
      control={control}
      rules={{
        required: true,
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <InputLabel>Dokumenttyp</InputLabel>
          <Select {...field} label="Dokumenttyp" error={error && true}>
            {FILEDATA_TYPE_OPTIONS_LONG.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>
            {error && "Bitte einen Dokumenttyp auswählen"}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}

FileDataTypeSelect.propTypes = {
  control: PropTypes.object,
};

export default FileDataTypeSelect;
