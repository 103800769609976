import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { useController } from "react-hook-form";

export default function LoginTextField({
  control,
  name = "username",
  required,
}) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: required && "Bitte einen gültigen Benutzername angeben.",
      // pattern: { value: /^\S+@\S+$/i, message: 'Eingabe besitzt kein gültiges E-Mail Format.' },
    },
    // rules: { required: true, pattern: /^\S+@\S+$/i },
  });

  return (
    <TextField
      label="Benutzername"
      variant="outlined"
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      error={error && true}
      required={required}
      helperText={error?.message}
    />
  );
}

LoginTextField.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  required: PropTypes.bool,
};

// ALTERNATIVE:
// <Controller
// render={({ field }) => <TextField {...field} />}
// name="TextField"
// control={control}
// />
