import { apiSlice } from "../../app/api/apiSlice";

export const auditApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAuditByYear: builder.query({
      query: (year) => ({
        url: `/audit/year/${year}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Audit", id: arg }],
    }),
    getAllAudits: builder.query({
      query: () => ({
        url: "/audit",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Audit", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Audit", id })),
          ];
        }
        return [{ type: "Audit", id: "LIST" }];
      },
    }),
    updateAuditItemStatus: builder.mutation({
      query: (update) => ({
        url: `/audit/item/status`,
        method: "PATCH",
        body: {
          ...update,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Audit", id: arg.id }],
    }),
    updateAuditItemResponsible: builder.mutation({
      query: (update) => ({
        url: `/audit/item/responsible`,
        method: "PATCH",
        body: {
          ...update,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Audit", id: arg.id }],
    }),
    getAuditById: builder.query({
      query: (auditId) => ({
        url: `/audit/${auditId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Audit", id: arg }],
    }),
    createNewAudit: builder.mutation({
      query: (audit) => ({
        url: "/audit",
        method: "POST",
        body: {
          ...audit,
        },
      }),
      invalidatesTags: [{ type: "Audit", id: "LIST" }],
    }),
    updateAudit: builder.mutation({
      query: (auditUpdate) => ({
        url: `/audit`,
        method: "PATCH",
        body: {
          ...auditUpdate,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Audit", id: arg.id }],
    }),
    deleteAudit: builder.mutation({
      query: (auditId) => ({
        url: `/audit`,
        method: "DELETE",
        body: {
          auditId,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Audit", id: arg }],
    }),
  }),
});

export const {
  useGetAuditByYearQuery,
  useGetAllAuditsQuery,
  useUpdateAuditItemStatusMutation,
  useUpdateAuditItemResponsibleMutation,
  useGetAuditByIdQuery,
  useCreateNewAuditMutation,
  useUpdateAuditMutation,
  useDeleteAuditMutation,
} = auditApiSlice;
