import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import { useAssignFileDataMutation } from "../../../features/fileData/fileDataApiSlice";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { useDispatch } from "react-redux";
import AssignFileToUserForm from "../../../components/forms/Forms/AssignFileToUserForm";

export default function AssignFileToUserDialog({ dialog, handleClose }) {
  const dispatch = useDispatch();
  const [assignFileData] = useAssignFileDataMutation();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      description: "",
      assignTo: "",
    },
  });

  const onSubmit = async (formData) => {
    try {
      const resultFileData = await assignFileData({
        _id: dialog.data.fileData._id,
        ...dialog.data.fileData,
        ...formData,
      }).unwrap();
      if (resultFileData?.type === "success") {
        dispatch(
          setDialog({
            title:
              dialog.data.fileData.fileName + " wurde erfolgreich zugewiesen!",
            type: "SUCCESS",
            data: {
              successText: resultFileData?.message,
            },
          })
        );
      }
    } catch (error) {
      console.error("Error updating fileData:", error.message);
    }
  };

  return (
    <Dialog
      open
      keepMounted
      onClose={handleClose} // Close when click oustside the dialog
      aria-describedby="dialog-assignFile"
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {dialog?.title}
          <Button onClick={handleClose} color="inherit">
            <Icon width="40" icon="icon-park-solid:close-one" />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          <form className="form">
            <AssignFileToUserForm control={control} />
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="secondary"
          autoFocus
        >
          SPEICHERN
        </Button>
        <Button onClick={handleClose}>Abbrechen</Button>
      </DialogActions>
    </Dialog>
  );
}
