import * as React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Controller, useController } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectChip({
  control,
  style,
  name,
  label,
  errorMsg,
  fullWidth,
  disabled,
  helperText,
  itemArray,
  required,
}) {
  const {
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: required,
    },
  });

  return (
    <FormControl sx={{ m: 1 }}>
      <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
      <Controller
        render={({ field }) => (
          <Select
            labelId={`${name}-select-label`}
            id={`${name}-select`}
            label={label}
            {...field}
            multiple
            disabled={disabled}
            required={required}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={itemArray.find((x) => x.value === value).label}
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {itemArray.map((item) => (
              <MenuItem key={item.value + "-key"} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
        control={control}
        name={name}
        fullWidth={fullWidth}
      />
    </FormControl>
  );
}
