import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextField from "../TextField";
import SelectController from "../SelectController";

function AssignFileToUserForm({ control }) {
  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <TextField
          sx={{ mt: 1 }}
          required={false}
          name="description"
          label="Beschreibung"
          fullWidth
          errorMsg="Bitte angeben"
          multiline={true}
          control={control}
          helperText="Bitte eine kurze Beschreibung angeben, was durch Mitarbeiter/in erledigt werden soll."
        />
      </Grid>
      <Grid item xs={12}>
        <SelectController
          control={control}
          name="assignedUser"
          label="Zuweisen an:"
          errorMsg="Bitte Mitarbeiter auswählen."
          assignToMeQ={false}
          required={true}
        />
      </Grid>
    </Grid>
  );
}

AssignFileToUserForm.propTypes = { control: PropTypes.object };

export default AssignFileToUserForm;
