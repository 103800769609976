import { apiSlice } from "../../app/api/apiSlice";

export const filesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFileStructure: builder.query({
      query: () => ({
        url: "/files",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
    }),
    uploadFile: builder.mutation({
      query: (d) => ({
        url: `/files/uploadfile/${d.fileId}`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data;",
        },
        body: d.formData,
        formData: true,
      }),
      // invalidatesTags: [{ type: 'File', id: 'LIST' }],
    }),
    createNewFileData: builder.mutation({
      query: (data) => ({
        url: "/files/filedata",
        method: "POST",
        body: data,
      }),
      // invalidatesTags: [{ type: 'File', id: 'LIST' }],
    }),
    initializeQMFolder: builder.mutation({
      query: (data) => ({
        url: "/files/initialize",
        method: "POST",
        body: data,
      }),
      // invalidatesTags: [{ type: 'File', id: 'LIST' }],
    }),
    deleteQMFolder: builder.mutation({
      query: (data) => ({
        url: "/files/deleteqmstructure",
        method: "DELETE",
        body: data,
        invalidatesTags: ["File"],
      }),
    }),
    // updateNote: builder.mutation({
    //   query: (initialNote) => ({
    //     url: '/notes',
    //     method: 'PATCH',
    //     body: {
    //       ...initialNote,
    //     },
    //   }),
    //   // eslint-disable-next-line
    //   invalidatesTags: (result, error, arg) => [{ type: 'Note', id: arg.id }],
    // }),
    // deleteNote: builder.mutation({
    //   query: ({ id }) => ({
    //     url: `/notes`,
    //     method: 'DELETE',
    //     body: { id },
    //   }),
    //   invalidatesTags: (result, error, arg) => [{ type: 'Note', id: arg.id }],
    // }),
  }),
});

export const {
  useGetFileStructureQuery,
  useUploadFileMutation,
  useCreateNewFileDataMutation,
  useInitializeQMFolderMutation,
  useDeleteQMFolderMutation,
} = filesApiSlice;
