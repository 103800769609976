import { apiSlice } from "../../app/api/apiSlice";

export const chatsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChats: builder.query({
      query: () => ({
        url: "/chats",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),

      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Chat", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Chat", id })),
          ];
        }
        return [{ type: "Chat", id: "LIST" }];
      },
    }),
    getMessages: builder.query({
      query: (chatId) => {
        return {
          url: `/chats/getmessages/${chatId}`,
          method: "GET",
          validateStatus: (response, result) =>
            response.status === 200 && !result.isError,
        };
      },
      providesTags: (result, error, arg) => {
        if (result && result.data && Array.isArray(result.data.messages)) {
          return [
            { type: "Chat", id: "LIST" },
            ...result.data.messages.map((message) => ({
              type: "Message",
              id: message._id,
            })),
          ];
        }
        return [{ type: "Chat", id: "LIST" }];
      },
    }),

    getUsersWithoutChat: builder.query({
      query: () => ({
        url: "/chats/getUsersWithoutChat",
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Task", id: arg }],
    }),
    deleteChat: builder.mutation({
      query: (chatId) => ({
        url: `/chats`,
        method: "DELETE",
        body: {
          chatId,
        },
      }),
      invalidatesTags: [{ type: "Chat", id: "LIST" }],
    }),

    createNewChat: builder.mutation({
      query: (data) => ({
        url: `/chats/createNewChat`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: [{ type: "Chat", id: "LIST" }],
    }),
    leaveGroup: builder.mutation({
      query: (chatId) => ({
        url: `/chats/leaveGroup`,
        method: "PATCH",
        body: {
          ...chatId,
        },
      }),
      invalidatesTags: [{ type: "Chat", id: "LIST" }],
    }),
    updateGroup: builder.mutation({
      query: (data) => ({
        url: `/chats/updateGroup`,
        method: "PATCH",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Chat", id: arg.id }],
    }),

    sendMessage: builder.mutation({
      query: (chat) => ({
        url: `/chats/sendMessage`,
        method: "POST",
        body: {
          ...chat,
        },
      }),
      invalidatesTags: [{ type: "Chat", id: "LIST" }],
    }),
    markMessagesAsRead: builder.mutation({
      query: (chatId) => ({
        url: `/chats/${chatId}/read`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Chat", id: arg.id }],
    }),
  }),
});

export const {
  useGetChatsQuery,
  useGetMessagesQuery,
  useGetUsersWithoutChatQuery,
  useCreateNewChatMutation,
  useDeleteChatMutation,
  useLeaveGroupMutation,
  useUpdateGroupMutation,
  useSendMessageMutation,
  useMarkMessagesAsReadMutation,
} = chatsApiSlice;
