import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setDialog: (state, action) => action.payload,
    // setDialog({content: String to determine which component should be rendered, data: object with data })
    clearDialog: () => null,
  },
});

export const { setDialog, clearDialog } = dialogSlice.actions;

export default dialogSlice.reducer;

export const selectCurrentDialog = (state) => state.dialog;
