import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  IconButton,
  Popover,
} from "@mui/material";
import {
  logOut,
  selectCurrentUser,
  selectCurrentUserData,
} from "../../../features/auth/authSlice";
import { useSendLogoutMutation } from "../../../features/auth/authApiSlice";
import { AvatarIntern } from "../../../components/avatar";
import { useSelector } from "react-redux";
import Iconify from "../../../components/iconify";

// auth
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: "eva:home-fill",
    path: "/dashboard/app",
  },
  {
    label: "Profil",
    icon: "eva:person-fill",
    path: "/dashboard/profil",
  },
  {
    label: "Support",
    icon: "tdesign:help-circle-filled",
    path: "/dashboard/support",
  },
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
    path: "/dashboard/settings",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const currentUserData = useSelector(selectCurrentUserData);
  const currentUserMail = useSelector(selectCurrentUser);
  const [open, setOpen] = useState(null);
  const [sendLogout] = useSendLogoutMutation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleLogout = () => {
    sendLogout()
      .unwrap()
      .then(() => {
        dispatch(logOut());
        setOpen(null);
        navigate("/login");
      })
      .catch((err) => {
        window.alert(
          "Etwas hat nicht funktioniert.\nBitte versuchen Sie es erneut."
        );
        console.log("err :>> ", err);
      });
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <AvatarIntern
          name={`${currentUserData.firstName} ${currentUserData.lastName}`}
        />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${currentUserData.firstName} ${currentUserData.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {currentUserMail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <Link
              to={option.path}
              key={option.label}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              {/* <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              > */}
              <MenuItem
                onClick={handleClose}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  transition: "transform 0.1s", // Smooth transition for transform
                  "&:hover": {
                    transform: "scale(1.1)", // Scale up the icon when hovered
                  },
                }}
              >
                <Iconify
                  onClick={handleClose}
                  icon={option.icon}
                  style={{
                    fontSize: "50px",
                    color: "action.active",
                  }}
                  sx={{ mr: "15px" }}
                />
                {option.label}
              </MenuItem>
              {/* </Box> */}
            </Link>
          ))}
        </Stack>
        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={handleLogout}
          sx={{
            display: "flex",
            alignItems: "center",
            m: 1,

            transition: "transform 0.1s", // Smooth transition for transform
            "&:hover": {
              transform: "scale(1.1)", // Scale up the icon when hovered
            },
          }}
        >
          <Iconify
            onClick={handleClose}
            icon="material-symbols:logout"
            sx={{ mr: "15px" }}
          />
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
