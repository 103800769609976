import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
} from "@mui/material";
import TextField from "../../TextField";
import SelectController from "../../SelectController";
import FolderSelector from "./FolderSelector";
import { Controller } from "react-hook-form";
import ControlledRadioButton from "../../ControlledRadioButton";
import ControlledCheckbox from "../../ControlledCheckbox";
import ControlledSelectField from "../../ControlledSelectField";

function CreateFileDataForm({ control, fileStructure }) {
  return (
    <Grid spacing={3} container>
      {fileStructure && (
        <Grid item xs={12}>
          <Controller
            name="selectedFolder"
            required
            control={control}
            render={({ field }) => (
              <FolderSelector
                folderStructure={fileStructure}
                control={control}
                name="selectedFolder"
                infoText="Bitte wählen Sie einen Ordner in dem das neue Dokument abgelegt
            werden soll."
                required={true}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          name="fileName"
          label="Dokumentname"
          required={true}
          fullWidth
          errorMsg="Bitte angeben"
          control={control}
          helperText="Bitte einen Dokumentnamen angeben."
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="description"
          label="Beschreibung"
          fullWidth
          errorMsg="Bitte angeben"
          multiline={true}
          control={control}
          helperText="Bitte eine kurze Beschreibung angeben."
        />
      </Grid>
      <Grid item xs={12}>
        <SelectController
          control={control}
          name="responsibleUser"
          label="Verantwortlich:"
          errorMsg="Bitte Mitarbeiter auswählen."
          assignToMeQ={true}
          required={true}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="recallAmount"
          label="Wiedervorlage in"
          type="number"
          required={true}
          errorMsg="Bitte angeben"
          control={control}
          helperText="Nur Zahlen eingeben"
        />
      </Grid>
      <Grid item xs={8}>
        <ControlledSelectField
          name="recallPeriod"
          label="Zeitraum"
          required={true}
          errorMsg="Bitte angeben"
          control={control}
          itemArray={[
            { label: "Tage", value: "days" },
            { label: "Wochen", value: "weeks" },
            { label: "Monate", value: "months" },
            { label: "Jahre", value: "years" },
          ]}
          helperText="Der Zeitraum, wann an das Dokument erinnert werden soll. (Standard: 1 Jahr)"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControlledRadioButton
          control={control}
          name="type"
          label="Art"
          required={true}
          errorMsg="Bitte Dokumentart auswählen."
          itemArray={[
            { value: "FB", label: "Formblatt" },
            { value: "VA", label: "Verfahrensanweisung" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControlledRadioButton
          control={control}
          name="fileFormat"
          label="Ausrichtung"
          errorMsg="Bitte Ausrichtung auswählen."
          itemArray={[
            { value: "hochformat", label: "Hochformat" },
            { value: "querformat", label: "Querformat" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">Auditrelevant</FormLabel>
          <FormGroup>
            <ControlledCheckbox
              name="auditRelevant"
              label="Auditrelevant"
              control={control}
            />
            <FormHelperText>
              Ist das Dokument relevant für einen Audit?
            </FormHelperText>
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

CreateFileDataForm.propTypes = { props: PropTypes.object };

export default CreateFileDataForm;
