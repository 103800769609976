import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import allLocales from "@fullcalendar/core/locales-all";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Iconify from "../components/iconify/Iconify";
import { setDialog } from "../features/dialog/dialogSlice";
import { useGetEventsQuery } from "../features/event/eventApiSlice";
import * as bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet-async";

export default function CalendarPage() {
  const { data: events } = useGetEventsQuery();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleEventClick = (event, eventFlag) => {
    console.log("event", event?.event?.endStr);
    let values = "";
    if (eventFlag === "EDIT") {
      values = event.event._def;
      values = {
        ...values,
        start: event?.event?.startStr,
        end: event?.event?.endStr || event?.event?.startStr,
      };
      console.log("values", values);
    } else if (eventFlag === "SELECT") {
      values = { startStr: event.startStr, endStr: event.endStr };
    }
    dispatch(
      setDialog({ content: "EventDialog", data: values, eventFlag: eventFlag })
    );
  };

  const handleNewEventClick = () => {
    handleEventClick({}, "NEW");
  };

  useEffect(() => {
    const popoverTriggerList = document.querySelectorAll(
      '[data-bs-toggle="popover"]'
    );
    popoverTriggerList.forEach((popoverTriggerEl) => {
      new bootstrap.Popover(popoverTriggerEl);
    });
  }, [events]);

  return (
    <Box sx={{ p: 2 }} className="calendar-page">
      <Helmet>
        <title> Digit QM | Kalender </title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "stretch" : "center",
          mb: 2,
        }}
      >
        <Typography variant="h4" sx={{ mb: isMobile ? 2 : 0 }}>
          Kalender
        </Typography>
        {isMobile && (
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleNewEventClick}
            sx={{ mb: isMobile ? 2 : 0, width: isMobile ? "100%" : "auto" }}
          >
            Neuer Termin
          </Button>
        )}
      </Box>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        initialView="dayGridMonth"
        firstDay={1}
        height="auto"
        locales={allLocales}
        locale="de"
        headerToolbar={{
          left: isMobile ? "prev,next today" : "prev,next today myCustomButton",
          center: "title",
          right: isMobile
            ? "dayGridMonth,timeGridWeek,timeGridDay"
            : "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        }}
        customButtons={{
          myCustomButton: {
            text: "+ Neuer Termin",
            click: handleNewEventClick,
          },
        }}
        editable
        selectable
        selectMirror
        dayMaxEvents
        weekends
        nextDayThreshold="00:00:00"
        select={(eventInfo) => handleEventClick(eventInfo, "SELECT")}
        eventClick={(eventInfo) => handleEventClick(eventInfo, "EDIT")}
        events={events || []}
        eventDidMount={(info) => {
          const userInfo =
            info.event.extendedProps.user
              ?.map((user) => `${user.firstName} ${user.lastName}`)
              .join(", ") || "Keine Benutzerinformation";

          let eventEnd = info.event.end;

          if (
            !eventEnd ||
            info.event.start.getTime() === info.event.end.getTime()
          ) {
            eventEnd = info.event.start;
          }

          if (info.event.allDay) {
            eventEnd = new Date(info.event.end);
            eventEnd.setDate(eventEnd.getDate() - 1);
          }

          const popoverContent = `
            <div>
              <p><strong>Beschreibung:</strong> ${
                info.event.extendedProps.description || "Keine Beschreibung"
              }</p>
              <p><strong>Ort:</strong> ${
                info.event.extendedProps.location || "Kein Ort angegeben"
              }</p>
              <p><strong>Start:</strong> ${new Date(
                info.event.start
              ).toLocaleString()}</p>
              <p><strong>Ende:</strong> ${new Date(
                eventEnd
              ).toLocaleString()}</p>
              ${
                info.event.allDay ? "<p><strong>Ganztägig:</strong> Ja</p>" : ""
              }
              <p><strong>Benutzer:</strong> ${userInfo}</p>
            </div>`;

          info.el.setAttribute("data-bs-toggle", "popover");
          info.el.setAttribute("title", info.event.title);
          info.el.setAttribute("data-bs-content", popoverContent);
          info.el.setAttribute("data-bs-html", "true");
          info.el.setAttribute("data-bs-trigger", "hover");
        }}
      />
      <style>{`
        .calendar-page .fc .fc-toolbar.fc-header-toolbar {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
        }

        .calendar-page .fc-toolbar-chunk {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        .calendar-page .fc .fc-toolbar-title {
          margin: 0 auto;
        }

        @media (max-width: 600px) {
          .calendar-page .fc-toolbar .fc-toolbar-chunk {
            width: 100%;
            justify-content: center;
            margin-bottom: 5px;
          }

          .calendar-page .fc-toolbar-chunk:first-of-type {
            order: 1;
          }

          .calendar-page .fc-toolbar-chunk:nth-of-type(2) {
            order: 2;
            margin-top: 10px;
          }

          .calendar-page .fc-toolbar-chunk:nth-of-type(3) {
            order: 3;
            margin-top: 10px;
          }

          .calendar-page .fc .fc-toolbar-title {
            margin-bottom: 10px;
          }
        }

        /* Popover custom style */
        .calendar-page .popover {
          z-index: 9999 !important;
        }

        .calendar-page a {
          color: #212529 !important;
        }

        /* Entferne Unterstreichung für Kalenderzahlen */
        .calendar-page .fc .fc-daygrid-day-number {
          text-decoration: none !important;
        }

        /* Entferne Unterstreichung für Wochentage (Mo, Di, Mi, etc.) */
        .calendar-page .fc .fc-col-header-cell-cushion {
          text-decoration: none !important;
        }

        /* Anpassung der Event-Größe und Schriftgröße */
        .calendar-page .fc-event, .calendar-page .fc-event-title {
          font-size: 12px !important;
          height: auto !important;
          display: flex;
          align-items: center;
        }

        .calendar-page .fc-event-time {
          font-size: 9px !important;
        }

        .calendar-page .fc-daygrid-event {
          margin: 1px !important;
          padding: 2px 4px !important;
          height: 16px !important;
          line-height: 16px !important;
        }

        .calendar-page .fc-daygrid-block-event {
          margin: 1px !important;
        }
      `}</style>
    </Box>
  );
}
