import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import { Box, MenuItem } from "@mui/material";
import Iconify from "../iconify";
import { useDispatch, useSelector } from "react-redux";
import { setDialog } from "../../features/dialog/dialogSlice";
import { selectCurrentUserData } from "../../features/auth/authSlice";

export default function PopOverComponent({ icon, dataId, art, creator }) {
  const currentUserData = useSelector(selectCurrentUserData);

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickOpen = (deleteFlag) => {
    console.log("deleteFlag :>> ", deleteFlag);
    if (deleteFlag === "DELETE") {
      console.log("test :>> ");
      dispatch(
        setDialog({ open: true, content: "DeleteForm", data: dataId, art: art })
      );
      return;
    }
    if (!deleteFlag)
      dispatch(
        setDialog({ open: true, content: "History", data: dataId, art: art })
      );
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
      }}
    >
      <Box sx={{ flex: "0 0 auto" }}>
        <Iconify
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          icon={icon}
          style={{ fontSize: "50px", color: "action.active" }}
          sx={{
            transition: "transform 0.1s",
            "&:hover": {
              transform: "scale(1.5)",
            },
          }}
        />
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem sx={{ p: 2 }} onClick={() => handleClickOpen()}>
          <Iconify
            icon="mdi:clipboard-text-history"
            style={{
              fontSize: "40px",
              color: "action.active",
              marginRight: "10px",
            }}
          />
          Historie
        </MenuItem>
        <MenuItem
          sx={{ p: 2, justifyContent: "space-evenly" }}
          disabled={currentUserData.id !== creator}
          onClick={() => handleClickOpen("DELETE")}
        >
          <Iconify
            icon="material-symbols:delete-outline"
            style={{
              fontSize: "40px",
              color: "red",
              marginRight: "10px",
            }}
          />
          {art === "TASK" ? "Aufgabe löschen" : "Event löschen"}
        </MenuItem>
      </Popover>
    </Box>
  );
}
