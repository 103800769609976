import { httpCommonAuth } from "../http.service";

const API_PATH = "/files";

export const downloadFile = (fileName) =>
  httpCommonAuth.get(`${API_PATH}/download/${fileName}`, {
    responseType: "arraybuffer",
  });

export const uploadFolderStructure = (formData, onProgress) => {
  return httpCommonAuth.post(`${API_PATH}/uploadfolderstructure/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentage = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      // Trigger the callback with the current progress
      if (onProgress) {
        onProgress(percentage);
      }
    },
  });
};

export const uploadFileAxios = (formData, fileId) => {
  return httpCommonAuth.post(`${API_PATH}/uploadfile/${fileId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadTenantLogoAxios = (formData) => {
  return httpCommonAuth.post(`tenant/upload-logo/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadNewsImageAxios = (newsId, file) => {
  const formData = new FormData(); // Erstellen eines FormData-Objekts
  formData.append("file", file); // Datei unter dem Namen "file" anhängen

  return httpCommonAuth.post(`news/upload-news-image/${newsId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadNewsDocumentAxios = (newsId, file) => {
  const formData = new FormData(); // Erstellen eines FormData-Objekts
  formData.append("file", file); // Datei unter dem Namen "file" anhängen

  return httpCommonAuth.post(`news/upload-news-document/${newsId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadLawDocumentAxios = (lawId, file) => {
  const formData = new FormData(); // Erstellen eines FormData-Objekts
  formData.append("file", file); // Datei unter dem Namen "file" anhängen

  return httpCommonAuth.post(`laws/upload-laws-document/${lawId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const checkOutFileByID = (fileDataId) =>
  httpCommonAuth.get(`/filedata/checkOutFileByID/${fileDataId}`, {
    responseType: "arraybuffer",
  });

export const checkInFileByID = (fileDataId) =>
  httpCommonAuth.put(`/filedata/checkInFileByID/${fileDataId}`);

export const downloadFileByID = (fileDataId) =>
  httpCommonAuth.get(`/filedata/downloadFileByID/${fileDataId}`, {
    responseType: "arraybuffer",
  });

export const downloadConvertedPdfFileByID = (fileDataId) =>
  httpCommonAuth.get(`/filedata/downloadConvertedPdfFileByID/${fileDataId}`, {
    responseType: "arraybuffer",
  });
