import React from "react";
import PropTypes from "prop-types";

import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useUpdateAuditItemStatusMutation } from "../../../features/audit/auditApiSlice";

const StyledToggleButton = styled(ToggleButton)(({ status }) => ({
  whiteSpace: "nowrap",
  "&.Mui-selected": {
    color: status !== "inProgress" ? "white" : "black",
    backgroundColor:
      status === "open"
        ? "red"
        : status === "inProgress"
        ? "yellow"
        : status === "completed"
        ? "green"
        : undefined,
  },
  "&:hover": {
    backgroundColor:
      status === "open"
        ? "rgba(255, 0, 0, 0.2) !important"
        : status === "inProgress"
        ? "#ffdd57"
        : status === "completed"
        ? "#66bb66"
        : undefined,
  },
}));

const AuditItemStatus = ({ auditItemId, status, loading }) => {
  const [updateAuditItemStatus] = useUpdateAuditItemStatusMutation();

  const handleStatusChange = async (event, newStatus) => {
    if (newStatus !== null) {
      try {
        let res = await updateAuditItemStatus({
          _id: auditItemId,
          newStatus,
        }).unwrap();
        console.log("res :>> ", res);
        if (res && res?.status === 201) {
          window.alert(res.message);
        }
      } catch (error) {
        if (error?.status === 401) {
          window.alert(
            "Sie verfügen nicht über die notwendigen Rechte um dies auszuführen."
          );
        }
        console.log("error :>> ", error);
      }
    }
  };

  return (
    <ToggleButtonGroup
      value={status}
      exclusive
      disabled={loading}
      onChange={handleStatusChange}
      aria-label="audit item status"
    >
      <StyledToggleButton value="open" status="open" aria-label="open">
        Offen
      </StyledToggleButton>
      <StyledToggleButton
        value="inProgress"
        status="inProgress"
        aria-label="in progress"
      >
        In Bearbeitung
      </StyledToggleButton>
      <StyledToggleButton
        value="completed"
        status="completed"
        aria-label="completed"
      >
        Erledigt
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
};

AuditItemStatus.propTypes = {
  status: PropTypes.string,
  auditItemId: PropTypes.string,
  loading: PropTypes.bool,
};

export default AuditItemStatus;
