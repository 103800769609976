import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
// @mui
import { Box, List, ListItemText } from "@mui/material";
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import navConfig from "./navConfig";
import { checkAuthorized } from "../../utils/checkAuthorized";
import { selectCurrentRoles } from "../../features/auth/authSlice";

// ----------------------------------------------------------------------

export default function NavSection({ ...other }) {
  const roles = useSelector(selectCurrentRoles);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map(
          (item) =>
            checkAuthorized(item?.role, roles) &&
            item?.show && <NavItem key={item.title} item={item} />
        )}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        "&.active": {
          color: "text.primary",
          bgcolor: "action.selected",
          fontWeight: "fontWeightBold",
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
    </StyledNavItem>
  );
}
