import PropTypes from "prop-types";
import { TextField as Field } from "@mui/material";
import { useController } from "react-hook-form";

//---------------------------------------------------------------------------------

export default function TextField({
  control,
  variant,
  required,
  style,
  name,
  label,
  errorMsg,
  multiline,
  fullWidth,
  disabled,
  helperText,
  minRows,
  InputLabelProps,
  sx,
}) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: required,
    },
  });

  const showErrorMessage = () => {
    if (error?.message) {
      return error.message;
    }
    if (errorMsg) {
      return errorMsg;
    }
    return "";
  };

  return (
    <Field
      sx={sx}
      label={label}
      variant={variant ?? "outlined"}
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      error={error && true}
      helperText={error ? showErrorMessage() : helperText}
      fullWidth={fullWidth}
      disabled={disabled}
      multiline={multiline}
      required={required}
      style={style}
      minRows={minRows}
      InputLabelProps={InputLabelProps}
    />
  );
}

TextField.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  style: PropTypes.object,
  multiline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  type: PropTypes.string,
};

TextField.defaultProps = {
  helperText: "", // Hier den Standardwert festlegen
  required: false,
};
