import React from "react";
import { Grid, Typography } from "@mui/material";
import {
  useDeleteSurveyMutation,
  useGetSurveysQuery,
} from "../../../features/surveys/surveysApiSlice";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { useDispatch } from "react-redux";
import SurveyItem from "./SurveyItem";

function DisplaySurveys(props) {
  const { data: surveys, isFetching } = useGetSurveysQuery();
  const dispatch = useDispatch();
  const [deleteSurvey] = useDeleteSurveyMutation(); // For deleting surveys

  const handleDelete = async (surveyId, surveyTitle) => {
    try {
      let response = await deleteSurvey({ _id: surveyId }).unwrap(); // Call the delete API
      if (response && response?.status === "success") {
        dispatch(
          setDialog({
            title: "Befragung gelöscht!",
            type: "SUCCESS",
            data: {
              successText: `Befragung ${surveyTitle} wurde erfolgreich gelöscht!`,
            },
          })
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  if (isFetching) {
    return <div>Laden...</div>;
  }

  return (
    <div style={{ marginTop: 15 }}>
      <Grid container spacing={2}>
        {surveys && surveys.length > 0 ? (
          surveys.map((survey) => (
            <Grid item xs={12} md={4} key={survey._id}>
              <SurveyItem survey={survey} handleDelete={handleDelete} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary" align="center">
              Es wurden noch keine Befragungen erstellt
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default DisplaySurveys;
