import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import { Icon } from "@iconify/react";

const alignMent = "left";

const PublicSurveyForm = ({ survey, answers, handleAnswer }) => {
  const handleAnswerChange = (index, value) => {
    handleAnswer(index, value);
  };

  const renderQuestion = (questionObj, index) => {
    switch (questionObj.type) {
      case "points":
        return (
          <Box key={index} my={2}>
            <Typography variant="h5" gutterBottom>
              {questionObj.question}
            </Typography>
            <ToggleButtonGroup
              value={answers[index]?.value || ""}
              exclusive
              onChange={(event, newValue) =>
                handleAnswerChange(index, newValue)
              }
              aria-label="Punktebewertung"
            >
              {Array.from({ length: 10 }, (_, index) => (
                <ToggleButton
                  key={index + 1}
                  value={index + 1}
                  size="large"
                  sx={{
                    fontSize: { xs: 12, md: "125%" },
                    minWidth: { xs: 0, md: 50 },
                    p: { xs: 1.5, md: 2.5 },
                  }}
                  //   sx={{ fontSize: "125%", minWidth: 50 }}
                >
                  {index + 1}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            {answers[index]?.value && (
              <Typography
                variant="body1"
                display="block"
                align={alignMent}
                mt={1}
              >
                Ihre Auswahl: {answers[index]?.value} / 10
              </Typography>
            )}
          </Box>
        );

      case "smiley":
        return (
          <Box key={index} my={2}>
            <Typography variant="h5" gutterBottom>
              {questionObj.question}
            </Typography>
            <ToggleButtonGroup
              value={answers[index]?.value || ""}
              exclusive
              onChange={(event, newValue) =>
                handleAnswerChange(index, newValue)
              }
              aria-label="Smiley-Bewertung"
            >
              <ToggleButton sx={{ fontSize: { xs: 24, md: 42 } }} value="1">
                <Icon
                  icon="material-symbols:sentiment-extremely-dissatisfied"
                  color="darkred"
                />
              </ToggleButton>
              <ToggleButton sx={{ fontSize: { xs: 24, md: 42 } }} value="2">
                <Icon
                  icon="material-symbols:sentiment-dissatisfied"
                  color="red"
                />
              </ToggleButton>
              <ToggleButton sx={{ fontSize: { xs: 24, md: 42 } }} value="3">
                <Icon
                  icon="material-symbols:sentiment-neutral"
                  color="orange"
                />
              </ToggleButton>
              <ToggleButton sx={{ fontSize: { xs: 24, md: 42 } }} value="4">
                <Icon
                  icon="material-symbols:sentiment-satisfied"
                  color="lightgreen"
                />
              </ToggleButton>
              <ToggleButton sx={{ fontSize: { xs: 24, md: 42 } }} value="5">
                <Icon
                  icon="material-symbols:sentiment-very-satisfied"
                  color="green"
                />
              </ToggleButton>
            </ToggleButtonGroup>
            {answers[index]?.value && (
              <Typography
                variant="body1"
                display="block"
                align={alignMent}
                mt={1}
              >
                Ihre Auswahl: {answers[index]?.value} / 5
              </Typography>
            )}
          </Box>
        );

      case "openQuestion":
        return (
          <Box key={index} my={2}>
            <Typography variant="h5" gutterBottom>
              {questionObj.question}
            </Typography>
            <TextField
              fullWidth
              sx={{ maxWidth: { xs: 300, md: 750 }, mx: { xs: 5, md: 0 } }}
              multiline
              rows={4}
              value={answers[index]?.value || ""}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              placeholder="Ihre Antwort..."
            />
          </Box>
        );

      case "opinion":
        return (
          <Box key={index} my={2}>
            <Typography variant="h5" gutterBottom>
              {questionObj.question}
            </Typography>
            <RadioGroup
              value={answers[index]?.value || ""}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              sx={{ alignContent: { alignMent } }}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Stimme überhaupt nicht zu"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Stimme nicht zu"
              />
              <FormControlLabel value="3" control={<Radio />} label="Neutral" />
              <FormControlLabel
                value="4"
                control={<Radio />}
                label="Stimme zu"
              />
              <FormControlLabel
                value="5"
                control={<Radio />}
                label="Stimme voll und ganz zu"
              />
            </RadioGroup>
            {answers[index]?.value && (
              <Typography
                variant="body1"
                display="block"
                align={alignMent}
                mt={1}
              >
                Ihre Auswahl: {answers[index]?.value} / 5
              </Typography>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Grid textAlign={{ alignMent }} container spacing={3}>
      {survey.surveyQuestions.map((question, index) => (
        <Grid item sx={{ paddingLeft: 0 }} xs={12} key={index}>
          {renderQuestion(question, index)}
        </Grid>
      ))}
    </Grid>
  );
};

PublicSurveyForm.propTypes = {
  survey: PropTypes.object,
  answers: PropTypes.object,
  handleAnswer: PropTypes.func,
};

export default PublicSurveyForm;
