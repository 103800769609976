import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setDialog } from "../../features/dialog/dialogSlice";
import ErrorAlert from "../alerts/ErrorAlert";
import BulkEditFileData from "../../sections/@dashboard/files/FileData/BulkEditFileData";
import { useUpdateFileDataMutation } from "../../features/fileData/fileDataApiSlice";

function BulkEditFileDataDialog({ dialog, handleClose }) {
  const { type, data } = dialog;
  const { fileData: fileDataIds } = data;
  const { handleSubmit, control, clearErrors } = useForm({
    defaultValues: {
      status: "",
      version: "",
      type: "",
      assignedUser: "",
      responsibleUser: "",
      creator: "",
      auditRelevant: false,
      accessRestricted: false,
      isCheckedOut: false,
      officeRef: "",
    },
  });
  const [updateFileData, { isError }] = useUpdateFileDataMutation();

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    clearErrors();
    let fieldsToUpdate = data.editItems.map((item) => item.field);
    let newData = {};

    fieldsToUpdate.forEach((field) => {
      // Check if field is filled with a value

      if (
        ["auditRelevant", "accessRestricted", "isCheckedOut"].includes(field)
      ) {
        newData[field] = data[field];
        return null;
      }
      if (!data[field]) {
        // console.log("RETURN ERROR");
        return null;
      }

      // Prepare data for bulk update
      newData[field] = data[field];
    });

    try {
      let result = await updateFileData({
        updateMany: true,
        updateManyIds: fileDataIds,
        updateManyFields: newData,
      }).unwrap();

      if (result.type === "success") {
        // console.log("result :>> ", result);
        dispatch(
          setDialog({
            title: "Dokumente geändert!",
            type: "SUCCESS",
            data: {
              successText: result.message,
            },
          })
        );
      }

      // console.log("result :>> ", result);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <div>
      <Dialog open maxWidth="lg">
        <DialogTitle>{dialog?.title ?? ""}</DialogTitle>
        <DialogContent>
          <form className="form">
            <BulkEditFileData control={control} />
          </form>
        </DialogContent>
        {isError && (
          <div style={{ marginTop: 15 }}>
            <ErrorAlert errorMessage="Fehler beim Ändern der Dokumente" />
          </div>
        )}
        <DialogActions>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            autoFocus
            onClick={handleSubmit(onSubmit)}
          >
            {type === "CREATE" ? "NEUES DOKUMENT ERSTELLEN" : "SPEICHERN"}
          </Button>
          <Button onClick={handleClose} color="primary">
            Beenden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

BulkEditFileDataDialog.propTypes = {
  dialog: PropTypes.object,
  handleClose: PropTypes.func,
};

export default BulkEditFileDataDialog;
