import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  useGetFileDatasWithReadingConfirmationQuery,
  useUpdateFileDataMutation,
} from "../../../../features/fileData/fileDataApiSlice";
import { Icon } from "@iconify/react";
import { useAddReadingReminderMutation } from "../../../../features/notes/notesApiSlice";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDialog } from "../../../../features/dialog/dialogSlice";
import { selectCurrentUserData } from "../../../../features/auth/authSlice";
import { getDateFromString } from "../../../../utils/dateUtils";

function ReadingConfirmationOverview() {
  const { data: fileDataList, isLoading } =
    useGetFileDatasWithReadingConfirmationQuery();
  const [addReadingReminder] = useAddReadingReminderMutation();
  const [updateFileData] = useUpdateFileDataMutation();
  const userData = useSelector(selectCurrentUserData);

  const dispatch = useDispatch();

  const handleSendReminder = async (fileId, userId) => {
    // Logic for sending a reminder to a user for a specific document
    console.log("Reminder sent for file:", fileId, "to user:", userId);
    try {
      let newNote = await addReadingReminder({
        userId,
        fileId,
      }).unwrap();
      if (newNote?.type === "success") {
        dispatch(
          setDialog({
            title: `Erinnerung gesendet!`,
            type: "SUCCESS",
            data: {
              successText: `Erinnerung zur Lesebestätigung für Dokument wurde erfolgreich gesendet.`,
            },
          })
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const handleResetReadingConfirmation = async (fileData) => {
    try {
      let result = await updateFileData({
        ...fileData,
        updateFields: {
          readingConfirmationList: [],
          readingConfirmation: false,
        },
      }).unwrap();
      if (result?.type === "success") {
        dispatch(
          setDialog({
            title: `Lesebestätigungsliste gelöscht!`,
            type: "SUCCESS",
            data: {
              successText: `Lesebestätigung für Dokument ${fileData.fileName} wurde erfolgreich gelöscht.`,
            },
          })
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  if (isLoading) {
    return <Typography>Bitte warten...</Typography>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Übersicht aller Dokumente mit Lesebestätigungen
      </Typography>
      {fileDataList &&
        fileDataList.map((fileData) => (
          <Accordion key={fileData._id}>
            <AccordionSummary
              expandIcon={<Icon icon="mdi:keyboard-arrow-down" />}
            >
              <Typography>
                {fileData.fileName}
                <br />
                Lesebestätigungsfrist:{" "}
                {getDateFromString(fileData?.readingDueDate)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Benutzer</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Erinnerungen</TableCell>
                      <TableCell>Aktionen</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fileData.readingConfirmationList.map((entry) => (
                      <TableRow key={entry.reader._id}>
                        <TableCell>{entry.reader.fullName}</TableCell>
                        <TableCell>
                          {entry.readingConfirmed ? (
                            <Typography color="green">
                              <Icon
                                icon="icon-park-twotone:success"
                                color="lawngreen"
                                width={"20px"}
                                height={"20px"}
                                style={{ marginRight: 5 }}
                              />
                              Gelesen am{" "}
                              {new Date(entry.readAt).toLocaleDateString()}
                            </Typography>
                          ) : (
                            <Typography color="red">
                              <Icon
                                icon="ic:twotone-do-not-disturb-on"
                                color="red"
                                width={"20px"}
                                height={"20px"}
                                style={{ marginRight: 5 }}
                              />
                              Noch nicht gelesen
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>{entry?.remindingCounter}</TableCell>
                        <TableCell>
                          {!entry.readingConfirmed &&
                            entry.reader._id !== userData.id && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleSendReminder(
                                    fileData._id,
                                    entry.reader._id
                                  )
                                }
                              >
                                Erinnerung senden
                              </Button>
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() =>
                            handleResetReadingConfirmation(fileData)
                          }
                        >
                          Lesebestätigung löschen
                        </Button>
                      </TableCell>
                      <TableCell>
                        Löscht alle Einträge der Lesebestätigung. Möchten Sie
                        anschließend eine Lesebestätigung einfordern, müssen Sie
                        dies bitte über die{" "}
                        <NavLink to="/dashboard/files/overview">
                          Dokumentenübersicht
                        </NavLink>{" "}
                        auswählen.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}

ReadingConfirmationOverview.propTypes = {
  fileDatas: PropTypes.array,
};

export default ReadingConfirmationOverview;
