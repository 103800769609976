import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

function SelectDirectory({ qmFolders, mainFolder, subFolder, onSubmit }) {
  const [selectedFolder, setSelectedFolder] = useState({
    main: mainFolder ?? "",
    hasChildren: subFolder ? true : false,
    sub: subFolder ?? "",
  });

  console.log("qmFolders :>> ", qmFolders);

  const handleDirectoryChange = (dir, sub) => {
    console.log("dir :>> ", dir);
    console.log("sub :>> ", sub);
    if (sub) {
      // Subfolder selected, allow next step
      setSelectedFolder((prevState) => ({ ...prevState, sub: dir }));
      onSubmit({ ...selectedFolder, sub: dir });
    } else {
      let selDir = qmFolders.find((k) => k.id === dir);
      if (
        selDir.children.length > 0 &&
        selDir.children.some((e) => e.type === "folder")
      ) {
        // Selected folder has subfolders, next Step is allowed when subfolder is selected
        setSelectedFolder({ main: dir, hasChildren: true });
        onSubmit({ main: dir, hasChildren: true });
      } else {
        // Selected folder has no subfolders, allow next step
        setSelectedFolder({ main: dir, hasChildren: false });
        onSubmit({ main: dir, hasChildren: false });
      }
    }
  };

  return (
    <div>
      <div>
        <Typography variant="h4">
          Aktuell ist es nur möglich QM Dokumente hochzuladen.
        </Typography>
      </div>
      <div>
        <Typography variant="h4">
          Bitte wählen Sie das Verzeichnis aus, in das die Datei hochgeladen
          werden soll:
        </Typography>
      </div>
      <div>
        Es ist wichtig, Dateien bzw. Dokumente im Qualitätsmanagement
        ordnungsgemäß zu speichern, um eine strukturierte Organisation und
        schnelle Auffindbarkeit zu gewährleisten. Dies fördert die Effizienz,
        erleichtert die Zusammenarbeit und entspricht den Qualitätsstandards für
        die Dokumentation im Gesundheitswesen.
      </div>
      <div style={{ marginTop: 10, display: "flex" }}>
        <Typography sx={{ pt: 2, pr: 5 }} variant="h5">
          Bitte Hauptordner wählen:
        </Typography>
        <FormControl style={{ marginTop: 10, minWidth: 220 }}>
          <InputLabel id="document-type-label">Hauptordner</InputLabel>
          <Select
            labelId="document-type-label"
            id="document-type"
            label={"Hauptordner"}
            value={selectedFolder.main}
            onChange={(e) => handleDirectoryChange(e.target.value)}
          >
            {qmFolders &&
              qmFolders.map((dir, i) => {
                if (dir.type !== "folder") {
                  return null;
                }

                return (
                  <MenuItem key={String("MainDir-" && i)} value={dir.id}>
                    {dir.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>
      {selectedFolder.main && selectedFolder.hasChildren && (
        <div style={{ marginTop: 10, display: "flex" }}>
          <Typography sx={{ pt: 2, pr: 5 }} variant="h5">
            Bitte Unterordner wählen:
          </Typography>
          <FormControl style={{ marginTop: 10, minWidth: 220 }}>
            <InputLabel id="document-type-label">Unterordner</InputLabel>
            <Select
              labelId="document-type-label"
              id="document-type"
              label={"Unterordner"}
              value={selectedFolder.sub}
              onChange={(e) => handleDirectoryChange(e.target.value, "sub")}
            >
              {qmFolders?.find((k) => k.id === selectedFolder.main) &&
                qmFolders
                  ?.find((k) => k.id === selectedFolder.main)
                  .children.map((dir, i) => {
                    if (dir.type !== "folder") {
                      return null;
                    } else
                      return (
                        <MenuItem key={String("SubDir-" && i)} value={dir.id}>
                          {dir.name}
                        </MenuItem>
                      );
                  })}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
}

SelectDirectory.propTypes = { onSubmit: PropTypes.func };

export default SelectDirectory;
