import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import AdminRevisionForm from "../../forms/Forms/AdminRevisionForm";

export default function UpdateFileAdminDialog({
  fileData,
  handleSave,
  loading,
  action,
}) {
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      revision: false,
      description: "",
      assignedUser: "",
    },
  });

  const [open, setOpen] = React.useState(false);

  const revisionChecked = watch("revision");

  const handleClickOpen = () => {
    if (action === "REVIEW") {
      // Doc is being reviewed, Admin wants to Save, release or Assign back to user.
      console.log("REVIEW DOC! OPEN DIALOG WITH FORM");
      setOpen(true);

      return;
    } else {
      // EDIT, Doc is just being edited and Admin wants to save, no need to assign to user
      console.log("action: ", action);
      handleSave({}, "ADMIN-SAVE");
      return;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (formData) => {
    // CHECK IF FILE IS TO BE REVIEWED!
    handleSave(formData, "ADMIN");
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        disabled={loading}
        onClick={handleClickOpen}
      >
        {loading
          ? "LADEN..."
          : action === "REVIEW"
          ? "SPEICHERN/ZUWEISEN"
          : "SPEICHERN"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${fileData.fileName} speichern`}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Sie können das Dokument speichern, um an einem späteren Zeitpunkt
            daran weiterzuarbeiten.
          </Typography>
          <br />
          <Typography>
            Wenn das Dokument durch einen Mitarbeiter überarbeitet werden soll,
            können Sie es diesem erneut zuweisen.
          </Typography>
          <br />
          <div>
            <form className="form">
              <AdminRevisionForm
                control={control}
                revisionChecked={revisionChecked}
              />
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="secondary"
            autoFocus
          >
            {revisionChecked ? "SPEICHERN & ZUWEISEN" : "SPEICHERN"}
          </Button>
          <Button onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
