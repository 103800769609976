import React from "react";
import PropTypes from "prop-types";
import { Droppable } from "@hello-pangea/dnd";
import styled from "styled-components";
import TaskCard from "./TaskCard";

// Ensuring consistent styling for Container, Title, and TaskList
const Container = styled.div`
  border-radius: 15px;
  width: 300px;
  height: auto;
  min-height: 520px;
  margin-right: 8px;
  background: ${(props) => props.background};
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  background-color: ${(props) => props.background};
  padding: 25px 20px; /* Adjusted padding for more space */
  position: sticky;
  top: 0;
  z-index: 1;
  margin: 0;
  font-size: 1.25rem; /* Increased font-size for larger text */
  font-family: "Public Sans", sans-serif;
  font-weight: bold;
  text-align: left;
  color: rgb(33, 43, 54);
  border-top-left-radius: 15px; // Ensures rounded top-left
  border-top-right-radius: 15px; // Ensures rounded top-right
`;

const TaskList = styled.div`
  min-height: 520px;
  height: auto;
`;

Column.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  tasks: PropTypes.array.isRequired,
  value: PropTypes.string,
  color: PropTypes.string,
  handleClickOpen: PropTypes.func,
};

export default function Column({
  id,
  title,
  tasks,
  value,
  color,
  handleClickOpen,
}) {
  let tasksFiltered = [];
  if (tasks) {
    tasksFiltered = tasks.filter((task) => task?.status === value);
  }
  return (
    <Container background={color}>
      <Title background={color}>{title}</Title>
      <Droppable droppableId={id} key={id}>
        {(provided) => (
          <TaskList ref={provided.innerRef} {...provided.droppableProps}>
            {tasksFiltered &&
              tasksFiltered.map((task, index) => (
                <TaskCard
                  key={task._id}
                  index={index}
                  task={task}
                  color={color}
                  handleClickOpen={handleClickOpen}
                />
              ))}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </Container>
  );
}
