import { httpPublic } from "../http.service";

export const getPublicSurveyQuestions = (surveyId) => {
  return httpPublic.get(`/survey/${surveyId}`);
};

export const answerPublicSurvey = (surveyId, data) => {
  return httpPublic.patch(`/survey/${surveyId}`, data);
};

export const resetAdminPasswort = (adminMail) => {
  return httpPublic.post(`/resetpassword/${adminMail}`);
};
