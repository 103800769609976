import { Grid } from "@mui/material";
import TextField from "../../../../components/forms/TextField";
import ControlledSelectField from "../../../../components/forms/ControlledSelectField";
import DEDateField from "../../../../components/forms/DEDateField";
import SelectController from "../../../../components/forms/SelectController";

import { Step3Control } from "./createEntryForms/Step3Control";

export default function FormRisk({ control, activeStep, zeitraumval }) {
  return (
    <>
      {activeStep === 0 && (
        <>
          <p>Bitte ändern Sie die Daten, falls etwas geändert werden muss.</p>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <ControlledSelectField
                control={control}
                name="classification"
                label="Einstufung"
                required={true}
                itemArray={[
                  { label: "leicht", value: "leicht" },
                  { label: "mittel", value: "mittel" },
                  { label: "schwer", value: "schwer" },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <ControlledSelectField
                control={control}
                name="frequency"
                label="Eintrittswahrscheinlichkeit"
                required={true}
                itemArray={[
                  {
                    label: "gering",
                    value: "gering",
                  },
                  {
                    label: "mittel",
                    value: "mittel",
                  },
                  {
                    label: "hoch",
                    value: "hoch",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                control={control}
                name="title"
                label="Titel"
                required={true}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                control={control}
                name="description"
                label="Beschreibung"
                helperText="Beschreiben Sie das Risiko detailliert."
                required={true}
                multiline
                fullWidth
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                control={control}
                name="measure.content"
                label="Präventionsmaßnahme"
                helperText="Was wird unternommen, um das Risiko zu minimieren?"
                fullWidth
                multiline
              />
            </Grid>

            <Grid item xs={4}>
              <DEDateField
                control={control}
                name="measure.date"
                label="Datum der Präv."
                fullWidth
                multiline
              />
            </Grid>

            <Grid item xs={5}>
              <SelectController
                control={control}
                name="responsibleUser"
                label="Zugewiesen an:"
              />
            </Grid>
          </Grid>
        </>
      )}
      {activeStep === 1 && (
        <Step3Control control={control} zeitraumval={zeitraumval} />
      )}
    </>
  );
}
