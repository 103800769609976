import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  useGetKontrolleFieldsQuery,
  useUpdateFBMutation,
} from "../../../features/entry/entryApiSlice";
import EntryList from "../entries/EntryList";
import {
  Box,
  Paper,
  Typography,
  Container,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { useDispatch } from "react-redux";
import { setDialog } from "src/features/dialog/dialogSlice";
import FbDataFormRisk from "./Forms/FbDataFormRisk";
import MySlideSnackbar from "../../../components/snackbar/MySlideSnackbar";
import FbDataControls from "./EntryDataFiles/FbDataControls";
import FbDataForm from "./EntryDataFiles/FbDataForm";

export default function EntryContentArea({
  years,
  sortedItems,
  selectedItem,
  setSelectedItem,
}) {
  console.log("selectedItem", selectedItem);
  const { data: kontrolleFieldsData, refetch: refetchKontrolle } =
    useGetKontrolleFieldsQuery(selectedItem?._id, { skip: !selectedItem?._id });
  const dispatch = useDispatch();
  const [updateFB] = useUpdateFBMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const { handleSubmit, control, formState, reset, watch } = useForm({
    defaultValues: selectedItem,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "measure",
  });
  const { fields: kontrolleFields } = useFieldArray({
    control,
    name: "kontrolle",
  });
  const meetingContent = watch("discussionInTeamMeetingContent");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    reset({
      ...item,
      responsibleUser: item?.responsibleUser?._id || "",
    });
  };

  useEffect(() => {
    if (kontrolleFieldsData) {
      const sortedKontrolleFields = [...kontrolleFieldsData].sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
      reset({
        ...watch(),
        kontrolle: sortedKontrolleFields,
      });
    }
  }, [kontrolleFieldsData, reset, watch]);

  const handleUpdate = async (data) => {
    const result = await updateFB({ ...data });
    if (result) {
      refetchKontrolle();
      setMsg("Sie haben den Eintrag erfolgreich aktualisiert!");
      setSnackbarOpen(true);
    }
  };

  const handleKontrolle = () => {
    dispatch(
      setDialog({
        content: "KontrolleDialog",
        data: selectedItem,
      })
    );
  };

  const handleRiskManagement = () => {
    dispatch(
      setDialog({
        content: "RiskDialog",
        data: selectedItem,
      })
    );
  };

  const handleRemove = (index) => {
    remove(index);
  };

  const handleBackToList = () => {
    setSelectedItem(null);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "60vh",
        marginTop: "20px",
        flexDirection: isMobile && selectedItem ? "column" : "row",
      }}
    >
      {!isMobile || !selectedItem ? (
        <EntryList
          years={years}
          sortedItems={sortedItems}
          handleSelectItem={handleSelectItem}
          selectedItem={selectedItem}
          style={{
            width: isMobile ? "100%" : "30%",
            overflow: "auto",
            margin: "1px",
            borderColor: "#CCCCCC",
            borderWidth: "1px",
            borderStyle: "solid",
          }}
        />
      ) : null}

      {isMobile && selectedItem ? (
        <Paper
          style={{
            width: "100%",
            overflow: "auto",
            marginTop: "20px",
            borderColor: "#CCCCCC",
            borderWidth: "1px",
            borderStyle: "solid",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <IconButton onClick={handleBackToList}>
              <Iconify icon="eva:arrow-back-fill" /> Back
            </IconButton>
          </Box>
          <Container
            maxWidth="lg"
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <form onSubmit={handleSubmit(handleUpdate)}>
              {selectedItem.incidentType === "risiko" ? (
                <FbDataFormRisk
                  selectedItem={selectedItem}
                  control={control}
                  append={append}
                  fields={fields}
                  kontrolleFields={kontrolleFields}
                  meetingContent={meetingContent}
                  handleRemove={handleRemove}
                />
              ) : (
                <FbDataForm
                  selectedItem={selectedItem}
                  control={control}
                  append={append}
                  fields={fields}
                  kontrolleFields={kontrolleFields}
                  meetingContent={meetingContent}
                  handleRiskManagement={handleRiskManagement}
                  handleRemove={handleRemove}
                />
              )}

              <FbDataControls
                formState={formState}
                handleSubmit={handleSubmit}
                handleKontrolle={handleKontrolle}
                handleRiskManagement={handleRiskManagement}
                selectedItem={selectedItem}
              />
            </form>
          </Container>
        </Paper>
      ) : (
        !isMobile && (
          <Paper
            style={{
              width: "70%",
              overflow: "auto",
              margin: "1px",
              borderColor: "#CCCCCC",
              borderWidth: "1px",
              borderStyle: "solid",
            }}
          >
            {selectedItem ? (
              <Container
                maxWidth="lg"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100vh",
                }}
              >
                <form onSubmit={handleSubmit(handleUpdate)}>
                  {selectedItem.incidentType === "risiko" ? (
                    <FbDataFormRisk
                      selectedItem={selectedItem}
                      control={control}
                      append={append}
                      fields={fields}
                      kontrolleFields={kontrolleFields}
                      meetingContent={meetingContent}
                      handleRemove={handleRemove}
                    />
                  ) : (
                    <FbDataForm
                      selectedItem={selectedItem}
                      control={control}
                      append={append}
                      fields={fields}
                      kontrolleFields={kontrolleFields}
                      meetingContent={meetingContent}
                      handleRiskManagement={handleRiskManagement}
                      handleRemove={handleRemove}
                    />
                  )}

                  <FbDataControls
                    formState={formState}
                    handleSubmit={handleSubmit}
                    handleKontrolle={handleKontrolle}
                    handleRiskManagement={handleRiskManagement}
                    selectedItem={selectedItem}
                  />
                </form>
              </Container>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Iconify
                    icon="line-md:list-3"
                    sx={{
                      color: "text.disabled",
                      width: 40,
                      height: 40,
                      marginRight: 1,
                    }}
                  />
                  Wählen Sie ein Element aus der Liste aus.
                </Typography>
              </Box>
            )}
          </Paper>
        )
      )}
      <MySlideSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={msg}
      />
    </div>
  );
}
