import React from "react";
import { OutlinedInput, InputAdornment } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Iconify from "../../../components/iconify";

const StyledSearch = styled(OutlinedInput)(({ theme, width }) => ({
  width: width,
  padding: "0px 12px",
  height: 50,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `2px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const SearchBar = ({ value, onChange, placeholder, width = "240px" }) => {
  return (
    <StyledSearch
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      width={width}
      startAdornment={
        <InputAdornment position="start">
          <Iconify
            icon="eva:search-fill"
            sx={{ color: "text.disabled", width: 20, height: 20 }}
          />
        </InputAdornment>
      }
    />
  );
};

export default SearchBar;
