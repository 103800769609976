import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Divider,
  Button,
} from "@mui/material";
import ControlledSelectField from "../../../../components/forms/ControlledSelectField";
import Iconify from "../../../../components/iconify";
import MySlideSnackbar from "../../../../components/snackbar/MySlideSnackbar";
import FormControl from "../Forms/FormControl";
import dayjs from "dayjs";
import { useUpdateControlMutation } from "../../../../features/entry/entryApiSlice";
import ControlledToggleButtonGroup from "../../../../components/forms/ControlledToggleButtonGroup";

export default function UpdateControlDialog({ handleClose, entry }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [updateControl] = useUpdateControlMutation();

  const { handleSubmit, control, watch, setError, clearErrors } = useForm({
    defaultValues: {
      succeed: "false",
      status: entry?.status,
      date: "1_monat",
      individuellesDatum: dayjs(),
    },
  });

  const controlSuccess = watch("succeed");
  const zeitraumval = watch("date");

  const onSubmit = async (data) => {
    const succeedBoolean = data.succeed === "true";
    let kontrolleData = null;

    if (!succeedBoolean) {
      // Neue Kontrolle erforderlich, wenn die Kontrolle nicht erfolgreich ist
      kontrolleData = {
        date: "",
      };

      if (data.date === "1_monat") {
        const today = new Date();
        const nextMonth = new Date(today);
        nextMonth.setMonth(today.getMonth() + 1);
        kontrolleData.date = nextMonth;
      } else if (data.date === "3_monate") {
        const today = new Date();
        const nextThreeMonths = new Date(today);
        nextThreeMonths.setMonth(today.getMonth() + 3);
        kontrolleData.date = nextThreeMonths;
      } else if (data.date === "1_jahr") {
        const today = new Date();
        const nextYear = new Date(today);
        nextYear.setFullYear(today.getFullYear() + 1);
        kontrolleData.date = nextYear;
      } else if (data.date === "individuell") {
        if (!data.individuellesDatum) {
          setError("individuellesDatum", {
            type: "manual",
            message: "Bitte wählen Sie ein Datum aus.",
          });
          return; // Verhindert das Absenden des Formulars, wenn das Datum nicht ausgewählt ist
        } else {
          clearErrors("individuellesDatum");
          kontrolleData.date = new Date(data.individuellesDatum);
        }
      }
    }

    const dataToUpdate = {
      entryID: entry?.entryID,
      controlID: entry?.controlID,
      status: data.status,
      kontrolleDataNew: kontrolleData,
      succeed: succeedBoolean,
    };

    console.log("dataToUpdate", dataToUpdate);
    const result = await updateControl(dataToUpdate);

    if (result) {
      handleClose("REFETCH");

      setMsg("Daten erfolgreich aktualisiert!");
      setSnackbarOpen(true);
    }
  };

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
            id="customized-dialog-title"
          >
            Eintrag kontrollieren
          </Typography>
          <Box sx={{ flex: "0 0 auto" }}>
            <Iconify
              onClick={handleClose}
              icon="mingcute:close-line"
              style={{ fontSize: "50px", color: "action.active" }}
              sx={{
                transition: "transform 0.1s",
                "&:hover": {
                  transform: "scale(1.8)",
                },
              }}
            />
          </Box>
        </Box>

        <Divider />
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "15px",
                flex: 1,
                mb: 2,
              }}
              id="customized-dialog-title"
            >
              War die Kontrolle erfolgreich?
            </Typography>
            <ControlledToggleButtonGroup
              control={control}
              name="succeed"
              options={[
                { label: "Erfolgreich", value: "true" },
                { label: "Nicht erfolgreich", value: "false" },
              ]}
              fullWidth
            />

            {controlSuccess === "true" && (
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: "15px",
                    flex: 1,
                    mb: 2,
                  }}
                  id="customized-dialog-title"
                >
                  Soll der Status des Eintrags angepasst werden?
                </Typography>
                <ControlledSelectField
                  control={control}
                  name="status"
                  label="Status"
                  itemArray={[
                    { label: "Offen", value: "offen" },
                    { label: "In Bearbeitung", value: "in Bearbeitung" },
                    { label: "Abgeschlossen", value: "abgeschlossen" },
                  ]}
                  fullWidth
                />
              </Box>
            )}

            {controlSuccess === "false" && (
              <Box sx={{ mt: 3 }}>
                <FormControl control={control} zeitraumval={zeitraumval} />
              </Box>
            )}
          </form>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            color="secondary"
          >
            Absenden
          </Button>
        </DialogActions>
      </Dialog>

      <MySlideSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={msg}
      />
    </div>
  );
}
