import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  styled,
  Dialog,
  DialogActions,
  Typography,
  DialogContent,
  Divider,
} from "@mui/material";
import { useArchiveNewMutation } from "../../../../features/news/newsApiSlice";
import { useNavigate } from "react-router-dom";

// Styled components using MUI's 'styled' utility
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

ArchiveDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  importedData: PropTypes.object.isRequired,
};
export default function ArchiveDialog({ handleClose, importedData }) {
  const navigate = useNavigate();
  const [archiveNew] = useArchiveNewMutation();

  const data = {
    content: " den Beitrag",
    buttonContent: "Beitrag archivieren",
  };

  const handleArchiveNew = async () => {
    try {
      let newId = importedData?.newId;
      const result = await archiveNew(newId); // Archivieren statt Löschen

      handleClose("REFETCH");
      navigate(-1); // Navigiere zurück zur Übersicht
    } catch (error) {
      console.error("Failed to archive the post: ", error);
    }
  };

  return (
    <BootstrapDialog
      onClose={() => handleClose()}
      aria-labelledby="customized-dialog-title"
      open
    >
      <DialogContent>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Sind Sie sicher, dass Sie
          {data.content} archivieren möchten?
        </Typography>
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <Button
          style={{
            background: "#9E9E9E",
            marginBottom: 2,
            textTransform: "none",
          }}
          variant="contained"
          onClick={() => handleClose()}
        >
          Abbrechen
        </Button>
        <Button
          color="primary"
          variant="contained"
          style={{
            textTransform: "none",
          }}
          onClick={() => handleArchiveNew()} // Funktion zum Archivieren
        >
          {data.buttonContent}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
