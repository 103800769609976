import PropTypes from "prop-types";
// @mui
import { Grid } from "@mui/material";
// components
import MailTextField from "../../../components/forms/MailTextField";
import TextField from "../../../components/forms/TextField";

// ----------------------------------------------------------------------

export default function TenantForm({ control }) {
  return (
    <Grid spacing={3} container>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          name="tenantName"
          label="Name der Praxis"
          errorMsg="Bitte angeben"
          required={true}
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={6}></Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          name="address"
          label="Straße, Hausnummer"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          name="zip"
          label="PLZ"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          name="city"
          label="Stadt"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          name="contactPerson"
          label="Ansprechpartner"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          name="phone"
          label="Rufnummer"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <MailTextField name="tenantMail" fullWidth={true} control={control} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          name="homepage"
          label="Website"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          name="comment"
          label="Kommentar"
          errorMsg="Bitte angeben"
          control={control}
          multiline
        />
      </Grid>
    </Grid>
  );
}

TenantForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
};
