import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import OfficeCardItem from "./OfficeCardItem";

function OfficeCardItemList({ offices }) {
  return (
    <div>
      <Grid container spacing={3}>
        {offices &&
          offices.map((office) => (
            <Grid key={office._id} item xs={12} md={4}>
              <OfficeCardItem
                office={office}
                lastOffice={offices.length === 1}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

OfficeCardItemList.propTypes = {
  offices: PropTypes.array,
};

export default OfficeCardItemList;
