import { Navigate, Route, Routes } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import SimpleLayout from "../layouts/simple";
import BlogPage from "./BlogPage";
import UserPage from "./UserPage";
import LoginPage from "./LoginPage";
import Page404 from "./Page404";
import TasksPage from "./TasksPage";
import SupportPage from "./SupportPage";
import DashboardPage from "./DashboardPage";
import RegisterPage from "./RegisterPage";
import { ROLES_LIST } from "../features/roles/roles.config";
import ProfilPage from "./ProfilPage";
import SettingsPage from "./SettingsPage";
import RequireAuth from "../features/auth/RequireAuth";
import PersistLogin from "../features/auth/PersistLogin";
import Prefetch from "../features/auth/Prefetch";
import useAuth from "../hooks/useAuth";
import FilesPage from "./FilesPage";
import CalendarPage from "./CalendarPage";
import AuditPage from "./AuditPage";
import OfficePage from "./OfficePage";
import NewsPage from "./NewsPage";
import LawsPage from "./LawsPage";
import SurveyPage from "./SurveyPage";
import EntryPage from "./EntryPage";
import PublicSurveyPage from "./PublicSurveyPage";
import NewsDetails from "../sections/@dashboard/news/NewsDetails";
import DraftsNewsPage from "../sections/@dashboard/news/DraftsNewsPage";
import ArchivNewsPage from "../sections/@dashboard/news/ArchivNewsPage";
import ChatPage from "./ChatPage";
import ForgotPassword from "./Forgotpassword";
import AdminPage from "./AdminPage";

// ----------------------------------------------------------------------

const dev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export default function Router() {
  const { isAdmin, isSuperAdmin } = useAuth();

  // Protected Routes
  const protectedRoutes = (
    <Route path="/" element={<PersistLogin />}>
      <Route
        path="register"
        element={isSuperAdmin ? <RegisterPage /> : <Navigate to="/" />}
      />
      <Route
        path="/"
        element={<RequireAuth allowedRoles={[ROLES_LIST.User]} />}
      >
        {/* <Navigate to="/dashboard/app" /> */}
        <Route element={<Prefetch />}>
          <Route index element={<Navigate to="/dashboard/app" />} />
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route path="app" element={<DashboardPage />} />
            <Route
              path="office"
              element={isAdmin ? <OfficePage /> : <Navigate to="/" />}
            />
            <Route
              path="user"
              element={isAdmin ? <UserPage /> : <Navigate to="/" />}
            />

            <Route
              path="admin"
              element={isSuperAdmin ? <AdminPage /> : <Navigate to="/" />}
            />

            <Route path="task" element={<TasksPage />} />
            <Route path="blog" element={<BlogPage />} />
            <Route path="files/*" element={<FilesPage />} />
            <Route path="profil" element={<ProfilPage />} />
            <Route path="support" element={<SupportPage />} />
            <Route path="settings/*" element={<SettingsPage />} />
            <Route path="calendar" element={<CalendarPage />} />
            <Route path="entry" element={<EntryPage />} />
            <Route path="chat" element={<ChatPage />} />
            <Route path="news" element={<NewsPage />} />
            <Route path="news/:id" element={<NewsDetails />} />
            <Route path="news/drafts" element={<DraftsNewsPage />} />
            <Route path="news/archive" element={<ArchivNewsPage />} />
            <Route path="audit/*" element={<AuditPage />} />
            <Route path="laws" element={<LawsPage />} />
            <Route path="survey" element={<SurveyPage />} />
          </Route>
        </Route>
      </Route>
    </Route>
  );

  return (
    <Routes>
      {/* For Survey Routes: /tenantName/officeName/surveyName
      Delivers the survey for the patients or employees to fill out.
      */}
      {protectedRoutes}
      <Route path="survey">
        <Route path=":surveyId" element={<PublicSurveyPage />} />
      </Route>

      <Route path="/" element={<SimpleLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="resetpassword" element={<ForgotPassword />} />
        <Route path="404" element={<Page404 />} />
      </Route>
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}
