import PropTypes from "prop-types";
// @mui
import { Grid, FormHelperText } from "@mui/material";
// components
import MailTextField from "../../../components/forms/MailTextField";
import PasswordTextField from "../../../components/forms/PasswordTextField";
import TextField from "../../../components/forms/TextField";
import PasswordCheckTextField from "../../../components/forms/PasswordCheckTextField";
import DEDateField from "../../../components/forms/DEDateField";
import ControlledSelectField from "../../../components/forms/ControlledSelectField";

// ----------------------------------------------------------------------

export default function RegisterForm({ control, watch }) {
  const handleCheckMatch = (checkPwd) => {
    if (watch("password") !== checkPwd) {
      return "Die Passwörter stimmen nicht überein";
    }
    return true;
  };

  return (
    <Grid spacing={3} container>
      <Grid item xs={12} md={3}>
        <ControlledSelectField
          name="title"
          label="Anrede"
          required={true}
          control={control}
          fullWidth
          itemArray={[
            { value: "Herr", label: "Herr" },
            { value: "Frau", label: "Frau" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={9}></Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          name="firstName"
          label="Vorname"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          name="lastName"
          label="Nachname"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DEDateField
          fullWidth
          control={control}
          name="birthdate"
          label="Geburtsdatum"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          name="phone"
          label="Mobil"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <MailTextField fullWidth control={control} />
        <FormHelperText style={{ textAlign: "left" }}>
          Diese Mail-Adresse ist gleichzeitig der Benutzername mit dem Sie sich
          in das QM-System einloggen.
        </FormHelperText>
      </Grid>
      <Grid item xs={12} md={6}>
        <PasswordTextField fullWidth control={control} />
      </Grid>
      <Grid item xs={12} md={6}>
        <PasswordCheckTextField
          fullWidth
          handleCheckMatch={handleCheckMatch}
          name="checkPassword"
          label="Passwort wiederholen"
          control={control}
        />
      </Grid>
    </Grid>
  );
}

RegisterForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
};
