export const headCells = [
  {
    value: "fileName",
    label: "Dokumentname",
    align: "left",
    first: true,
  },
  {
    value: "type",
    label: "Typ",
    align: "right",
  },
  {
    value: "status",
    label: "Status",
    align: "center",
  },
  {
    value: "version",
    label: "Version",
    align: "right",
  },
  {
    value: "recallDate",
    label: "Wiedervorlage",
    align: "right",
    type: "date",
  },
  {
    value: "assignedUser",
    label: "Zugewiesen an",
    align: "right",
    type: "user",
  },
  {
    value: "responsibleUser",
    label: "Verantwortlich",
    align: "right",
    type: "user",
  },
  {
    value: "updatedAt",
    label: "Zuletzt geändert",
    align: "right",
    type: "date",
  },
];

export const FILTER_STATUS_OPTIONS = [
  {
    value: "new",
    label: "NEU",
  },
  {
    value: "edit",
    label: "IN BEARBEITUNG",
  },
  {
    value: "revision",
    label: "IN PRÜFUNG",
  },
  {
    value: "released",
    label: "FREIGEGEBEN",
  },
];

export const FILEDATA_TYPE_OPTIONS_LONG = [
  {
    value: "FB",
    label: "Formblatt (FB)",
  },
  {
    value: "VA",
    label: "Verfahrensanweisung (VA)",
  },
  {
    value: "NW",
    label: "Nachweis (NW)",
  },
  {
    value: "SONSTIGES",
    label: "Sonstiges",
  },
];

export const SORT_BY_OPTIONS = [
  { value: "featured", label: "Featured" },
  { value: "newest", label: "Newest" },
  { value: "priceDesc", label: "Price: High-Low" },
  { value: "priceAsc", label: "Price: Low-High" },
];

export const FILTER_CATEGORY_OPTIONS = [
  "All",
  "Shose",
  "Apparel",
  "Accessories",
];
export const FILTER_RATING_OPTIONS = [
  "up4Star",
  "up3Star",
  "up2Star",
  "up1Star",
];
export const FILTER_PRICE_OPTIONS = [
  { value: "below", label: "Below $25" },
  { value: "between", label: "Between $25 - $75" },
  { value: "above", label: "Above $75" },
];
export const FILTER_COLOR_OPTIONS = [
  "#00AB55",
  "#000000",
  "#FFFFFF",
  "#FFC0CB",
  "#FF4842",
  "#1890FF",
  "#94D82D",
  "#FFC107",
];

export const statuses = {
  new: "NEU",
  edit: "IN BEARBEITUNG",
  revision: "ZU PRÜFEN",
  released: "FREIGEGEBEN",
};

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
