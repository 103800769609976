import { Avatar } from "@mui/material";
import React from "react";

// ---------------------------------------------------------
// Funktionen für Avatar.

// function stringToColor(string) {
//   let hash = 0;
//   let i;

//   /* eslint-disable no-bitwise */
//   for (i = 0; i < string.length; i += 1) {
//     hash = string.charCodeAt(i) + ((hash << 5) - hash);
//   }

//   let color = '#';

//   for (i = 0; i < 3; i += 1) {
//     const value = (hash >> (i * 8)) & 0xff;
//     color += `00${value.toString(16)}`.slice(-2);
//   }
//   /* eslint-enable no-bitwise */

//   return color;
// }

function stringAvatar(name, style) {
  return {
    sx: {
      bgcolor: "66C1B3",
      // bgcolor: stringToColor(name),
      fontSize: style ? "50px" : "",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

// ---------------------------------------------------------

export default function AvatarIntern({ name, style }) {
  // Hier implementiere ich noch:
  // Wenn ein Bild hochgeladen ( dieser sucht zuerst 'currentUserData' ) dann lade Bild hoch,
  // sonst nimm den Avatar.

  return <Avatar {...stringAvatar(name, style)} style={style} />;
}
