import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextField from "../TextField";
import ControlledSelectField from "../ControlledSelectField";
import { FILE_STATUS_ARRAY } from "../../../utils/fileUtils";
import ControlledCheckbox from "../ControlledCheckbox";

function FileDataWorkflowForm({ control }) {
  return (
    <Grid spacing={3} container>
      <Grid item xs={12} md={8}>
        <TextField
          name="fileName"
          label="Dateiname"
          fullWidth
          required={true}
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          name="extension"
          label="Dateityp"
          variant={"standard"}
          disabled
          fullWidth
          required={true}
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          name="version"
          label="Version"
          errorMsg="Bitte angeben"
          required={true}
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ControlledSelectField
          name="status"
          label="Status"
          style={{ minWidth: 250 }}
          errorMsg="Bitte angeben"
          required={true}
          itemArray={FILE_STATUS_ARRAY}
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          name="comment"
          label="Kommentar"
          fullWidth
          errorMsg="Bitte angeben"
          multiline={true}
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ControlledCheckbox
          name="recall"
          label="Wiedervorlage?"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          name="recallAmount"
          label="Gültigkeitsprüfung in"
          type="number"
          required={true}
          errorMsg="Bitte angeben"
          control={control}
          helperText="Nur Zahlen eingeben"
        />
      </Grid>
      <Grid item xs={6}>
        <ControlledSelectField
          name="recallPeriod"
          label="Zeitraum"
          required={true}
          errorMsg="Bitte angeben"
          control={control}
          itemArray={[
            { label: "Tage", value: "days" },
            { label: "Wochen", value: "weeks" },
            { label: "Monate", value: "months" },
            { label: "Jahre", value: "years" },
          ]}
          helperText="Der Zeitraum, wann an das Dokument erinnert werden soll. (Standard: 1 Jahr)"
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ControlledCheckbox
          name="released"
          label="Freigegeben?"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
    </Grid>
  );
}

FileDataWorkflowForm.propTypes = { props: PropTypes.object };

export default FileDataWorkflowForm;
