import React from "react";
import PropTypes from "prop-types";
import { redirect, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";

function PreviewDocument() {
  let { fileId } = useParams();

  const dispatch = useDispatch();

  fileId &&
    dispatch(
      setDialog({
        title: "Datei anzeigen",
        type: "CREATE",
        content: "file-show",
        redirect: "/",
        data: {
          fileData: { _id: fileId, fileName: "TEST.docx" },
          action: "CONFIRMATION",
        },
      })
    );

  return <div>PreviewDocument with id: {fileId}</div>;
}

PreviewDocument.propTypes = {};

export default PreviewDocument;
