import React, { useState } from "react";
import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// Components
import TaskList from "./TaskList";
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <Iconify
        icon={"material-symbols:arrow-forward-ios"}
        sx={{ fontSize: "0.9rem" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// ----------------------------------------------------------------------

MobileAccordion.propTypes = {
  tasks: PropTypes.array.isRequired,
  handleDeleteTask: PropTypes.func.isRequired,
  handleClickOpen: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------

export default function MobileAccordion({
  tasks,
  handleDeleteTask,
  handleClickOpen,
  isMobile,
}) {
  const [expanded, setExpanded] = useState("new");

  const tasksNeu = tasks.filter((task) => task.status === "new");
  const tasksInBearbeitung = tasks.filter((task) => task.status === "edit");
  const tasksRevision = tasks.filter((task) => task.status === "revision");
  const tasksErledigt = tasks.filter((task) => task.status === "done");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "new"}
        style={{
          marginTop: "20px",
          background: "#e1ecf0",
          borderRadius: "10px",
        }}
        onChange={handleChange("new")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          Neu
        </AccordionSummary>
        <AccordionDetails>
          <TaskList
            tasks={tasksNeu}
            handleDeleteTask={handleDeleteTask}
            handleClickOpen={handleClickOpen}
            isMobile
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "edit"}
        style={{
          marginTop: "20px",
          background: "#e8e8e8",
          borderRadius: "10px",
        }}
        onChange={handleChange("edit")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          In Bearbeitung
        </AccordionSummary>
        <AccordionDetails>
          <TaskList
            tasks={tasksInBearbeitung}
            handleDeleteTask={handleDeleteTask}
            handleClickOpen={handleClickOpen}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "revision"}
        style={{
          marginTop: "20px",
          background: "#fefebe",
          borderRadius: "10px",
        }}
        onChange={handleChange("revision")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          In Überprüfung
        </AccordionSummary>
        <AccordionDetails>
          <TaskList
            tasks={tasksRevision}
            handleDeleteTask={handleDeleteTask}
            handleClickOpen={handleClickOpen}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "done"}
        style={{
          marginTop: "20px",
          background: "#c9f1c9",
          borderRadius: "10px",
        }}
        onChange={handleChange("done")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          Erledigt
        </AccordionSummary>
        <AccordionDetails>
          <TaskList
            tasks={tasksErledigt}
            handleDeleteTask={handleDeleteTask}
            handleClickOpen={handleClickOpen}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
