import React, { createContext, useContext, useEffect, useState } from "react";
import { initializeSocket } from "./socket"; // Stelle sicher, dass dies korrekt ist
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../features/auth/authSlice";

// Socket Context erstellen
const SocketContext = createContext(null);

// Socket Provider
export const SocketProvider = ({ children }) => {
  const currentToken = useSelector(selectCurrentToken);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (currentToken) {
      const socketInstance = initializeSocket(currentToken);
      setSocket(socketInstance);

      socketInstance.on("connect", () => {
        console.log("Socket verbunden mit ID:", socketInstance.id);
      });

      socketInstance.on("disconnect", (reason) => {
        console.log("Verbindung getrennt:", reason);
      });

      // Cleanup bei Unmount
      return () => {
        socketInstance.disconnect();
      };
    }
  }, [currentToken]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

// Socket Hook für Komponenten
export const useSocket = () => {
  return useContext(SocketContext);
};
