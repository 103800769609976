import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
// @mui
import { Stack, Button, Container, Typography, Box } from "@mui/material";
// components
import { setDialog } from "../features/dialog/dialogSlice";
import Iconify from "../components/iconify";
import OfficeCardsView from "../sections/@dashboard/office/OfficeCardsView";
import Loading from "../components/spinners/Loading";
import { useGetTenantInformationQuery } from "../features/tenants/tenantsApiSlice";
import { allowNewOffice, getCurrentOfficesCount } from "../utils/tenantUtils";

// ----------------------------------------------------------------------

export default function OfficePage() {
  const dispatch = useDispatch();
  const { data: tenant, isLoading } = useGetTenantInformationQuery();

  const officesCount = tenant && getCurrentOfficesCount(tenant);

  const handleOpenNewOfficeDialog = () => {
    if (allowNewOffice(tenant)) {
      dispatch(
        setDialog({
          title: "Neue Praxis erstellen",
          type: "CREATE",
          content: "office-form",
        })
      );
    } else {
      dispatch(
        setDialog({
          title: "Maximale Anzahl an Praxen erreicht!",
          type: "REDIRECT",
          text: "Die für diese Praxis maximale Anzahl an Praxen wurde erreicht. Wenn Sie mehr Praxen benötigen, buchen Sie bitte unter Einstellungen -> Module -> WEITERE PRAXWN BUCHEN die gewünschte Anzahl an Praxen hinzu.",
          redirectTo: "/dashboard/settings/modules",
          redirectButtonText: "ZUR BUCHUNGSSEITE",
        })
      );
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title> Digit QM | Praxis </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Praxis
          </Typography>
          <Button
            variant="contained"
            onClick={handleOpenNewOfficeDialog}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Neue Praxis
          </Button>
        </Stack>
        <Box sx={{ my: 2 }}>
          <Typography variant="h4">
            Anzahl Praxen: {`(${officesCount})`}
          </Typography>
        </Box>
        <OfficeCardsView />
      </Container>
    </>
  );
}
