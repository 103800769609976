import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  CardMedia,
  useMediaQuery,
  Tooltip,
  Link as MuiLink,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import Iconify from "../../../components/iconify";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";

const DraftNewsItem = ({ news, onEdit, onDelete }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("de-DE");
  };

  const isTextLong = news.content.length > 200;

  const getImageSrc = () => {
    if (news.imagePath && news.imagePath !== "") {
      return news.imagePath;
    }
    switch (news.imageDefaultPath) {
      case "1":
        return require("./NewsDialogComponents/1.jpg");
      case "2":
        return require("./NewsDialogComponents/2.jpg");
      case "3":
        return require("./NewsDialogComponents/3.jpg");
      default:
        return "/assets/images/praxis.jpg";
    }
  };

  const handleDelete = () => {
    dispatch(
      setDialog({
        content: "DeleteForm",
        data: { newId: news?._id },
        art: "DELETEDRAFTNEW",
      })
    );
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        width: "100%",
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "1px solid rgba(255, 255, 255, 0.3)",
        padding: 2,
        position: "relative",
        mb: 3, // Add margin between cards
      }}
    >
      {/* Bild links */}
      <CardMedia
        component="img"
        sx={{
          width: isMobile ? "100%" : 150, // Full width for mobile, fixed size for desktop
          height: isMobile ? "auto" : 150, // Auto height for mobile, fixed height for desktop
          borderRadius: 2,
          objectFit: "cover",
          marginBottom: isMobile ? 2 : 0, // Adds space between image and content for mobile
        }}
        image={getImageSrc()}
        alt={news.title}
      />

      {/* Text und Buttons rechts vom Bild */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          marginLeft: isMobile ? 0 : 2,
        }}
      >
        <CardContent sx={{ flex: "1 0 auto", paddingLeft: 0, paddingTop: 0 }}>
          {/* Titel */}
          <Typography
            component="div"
            variant="h6"
            sx={{ fontSize: "1.25rem", mb: 1 }}
          >
            {news.title}
          </Typography>
          {/* Titel */}
          <Typography
            component="div"
            variant="h4"
            sx={{ fontSize: "1.25rem", mb: 1 }}
          >
            {news.subtitle}
          </Typography>

          {/* Textabschnitt */}
          <Typography variant="body1" sx={{ display: "inline" }}>
            {expanded
              ? news.content
              : `${news.content.slice(0, 200)}${isTextLong ? "..." : ""}`}
          </Typography>
          {isTextLong && (
            <Button
              variant="text"
              size="small"
              onClick={handleExpandClick}
              sx={{ ml: 1 }}
            >
              {expanded ? "Weniger lesen" : "Mehr lesen"}
            </Button>
          )}

          {/* Veröffentlichungsdatum und Buttons auf gleicher Höhe */}
          <Stack
            direction={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "center"}
            sx={{ mt: 2 }}
          >
            {/* URL anzeigen */}
            {news?.url && (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Tooltip title={news.url}>
                  <Iconify
                    icon="ic:baseline-public"
                    sx={{ cursor: "pointer" }}
                  />
                </Tooltip>
                <MuiLink
                  href={
                    news.url.startsWith("http://") ||
                    news.url.startsWith("https://")
                      ? news.url
                      : `https://${news.url}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: "none",
                    fontWeight: 500,
                    ml: 1,
                    color: "primary.main",
                  }}
                >
                  Website öffnen
                </MuiLink>
              </Stack>
            )}
            {/* Veröffentlichungsdatum */}
            <Typography variant="subtitle2" color="text.secondary">
              Erstellt am: {formatDate(news.createdAt)}
            </Typography>

            {/* Bearbeiten und Löschen Buttons */}
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={onEdit}
              >
                Bearbeiten
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => handleDelete()}
              >
                Löschen
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Box>
    </Card>
  );
};

export default DraftNewsItem;
