import { apiSlice } from "../../app/api/apiSlice";

export const commentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCommentsForTask: builder.query({
      query: (taskId) => ({
        url: `/comments?taskId=${taskId}`, // Verwende `taskId` als Query-Parameter
        method: "GET",
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Comment", id })),
              { type: "Comment", id: "LIST" },
            ]
          : [{ type: "Comment", id: "LIST" }],
    }),

    addNewComment: builder.mutation({
      query: (comment) => ({
        url: "/comments",
        method: "POST",
        body: {
          ...comment,
        },
      }),
      invalidatesTags: [{ type: "Comment", id: "LIST" }],
    }),
    updateComment: builder.mutation({
      query: (commentUpdate) => ({
        url: "/comments",
        method: "PATCH",
        body: {
          ...commentUpdate,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Comment", id: arg.id },
      ],
    }),
    deleteComment: builder.mutation({
      query: (toDeleteComment) => ({
        url: `/comments`,
        method: "DELETE",
        body: { ...toDeleteComment },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Comment", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetCommentsForTaskQuery,
  useAddNewCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
} = commentApiSlice;
