import React from "react";
import PropTypes from "prop-types";
import SelectDocumentType from "./FileUploadDialogSteps/SelectDocumentType";
import SelectDirectory from "./FileUploadDialogSteps/SelectDirectory";
import { Divider, Typography } from "@mui/material";
import { Icon } from "@iconify/react";

function SelectDocumentDataStep({ data, onSubmitDocument, onSubmitDirectory }) {
  const { directory, qmFolders } = data;
  const { main: foundMainFolder, sub: foundSubFolder } = directory;

  return (
    <div>
      <div>
        <Typography variant="h4">
          Ausgewählte Datei: {data?.file?.name}
        </Typography>
        {data?.fileData?.fileType && (
          <Typography variant="h6">
            Erkannte Dokumentart: {data?.fileData?.fileType ?? ""}
            <Icon
              icon="icon-park-twotone:success"
              color="lawngreen"
              width="24"
              height="24"
              inline
              style={{ marginLeft: 5 }}
            />
          </Typography>
        )}
        {foundMainFolder.name && (
          <Typography variant="h6">
            Erkanntes Verzeichnis: {foundMainFolder.name ?? ""}
            <Icon
              icon="icon-park-twotone:success"
              color="lawngreen"
              width="24"
              height="24"
              inline
              style={{ marginLeft: 5 }}
            />
          </Typography>
        )}
        {foundSubFolder && (
          <Typography variant="h6">
            Erkanntes Unterverzeichnis: {foundSubFolder.name ?? ""}
            <Icon
              icon="icon-park-twotone:success"
              color="lawngreen"
              width="24"
              height="24"
              inline
              style={{ marginLeft: 5 }}
            />
          </Typography>
        )}
      </div>
      <Divider sx={{ m: 5 }} variant="middle" />
      <SelectDocumentType
        defaultDocType={data?.fileData?.fileType ?? ""}
        onSubmit={onSubmitDocument}
      />
      <Divider sx={{ m: 5 }} variant="middle" />
      <SelectDirectory
        qmFolders={qmFolders}
        mainFolder={foundMainFolder.id}
        subFolder={foundSubFolder?.id ?? false}
        onSubmit={onSubmitDirectory}
      />
      <Divider sx={{ m: 5 }} variant="middle" />
    </div>
  );
}

SelectDocumentDataStep.propTypes = {
  data: PropTypes.object,
  onSubmitDirectory: PropTypes.func,
  onSubmitDocument: PropTypes.func,
};

export default SelectDocumentDataStep;
