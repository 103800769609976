import React, { useState } from "react"; // Importiere useState
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Grid,
  Button, // Importiere Button für Löschen und Bearbeiten
} from "@mui/material";
import Iconify from "../../../components/iconify";
import Label from "../../../components/label";
import { useGetAllLawsQuery } from "../../../features/laws/lawsApiSlice";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { useDispatch } from "react-redux";
import { serverURL } from "../../../app/api/axios/http.service";
import { CircleLoader } from "react-spinners";

export default function LawAccordion({ canEdit }) {
  const { data: laws, isLoading: lawsLoading } = useGetAllLawsQuery();

  const dispatch = useDispatch();

  console.log("laws", laws);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDelete = (lawId) => {
    dispatch(
      setDialog({
        content: "DeleteForm",
        data: { lawId: lawId },
        art: "DELETELAW",
      })
    );
  };

  const handleEdit = (law) => {
    dispatch(setDialog({ content: "LawDialog", data: law, lawFlag: "EDIT" }));
  };
  if (lawsLoading) {
    return (
      <div>
        <CircleLoader />
      </div>
    );
  }

  return (
    <Card sx={{ width: "100%", mt: 4 }}>
      <CardContent sx={{ p: 0 }}>
        {laws && laws.length > 0 ? (
          laws.map((law, index) => (
            <Accordion
              key={index}
              expanded={expanded === index} // Setze den expandierten Zustand für das aktuelle Accordion
              onChange={handleChange(index)} // Verwende die Funktion zum Umschalten
              sx={{
                width: "100%",
                backgroundColor: "transparent",
                m: 0,
              }}
            >
              <AccordionSummary
                sx={{
                  display: "flex",
                  alignItems: "center", // Vertikal zentrieren
                  justifyContent: "space-between", // Abstand zwischen Inhalt und Icon
                }}
              >
                {/* Container für Titel und Aushangpflichtig */}
                <Grid container sx={{ flexGrow: 1, alignItems: "center" }}>
                  <Grid item sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6">{law.title}</Typography>
                    {law.favorite && (
                      <Iconify
                        icon="ic:round-star"
                        sx={{ ml: 1, color: "gold" }}
                      />
                    )}
                  </Grid>
                  {law.subjectToDisplay && (
                    <Label
                      color="error"
                      sx={{
                        ml: {
                          xs: 0, // Kein Abstand in der mobilen Ansicht
                          sm: 2, // Abstand in der Desktop-Ansicht
                        },
                        mt: {
                          xs: 1, // Abstand nach oben in der mobilen Ansicht
                          sm: 0, // Kein Abstand nach oben in der Desktop-Ansicht
                        },
                      }}
                    >
                      Aushangpflichtig
                    </Label>
                  )}
                </Grid>

                {/* Icon-Spalte für das Expand-Icon */}
                <Grid item>
                  <Iconify
                    icon="ic:round-expand-more"
                    sx={{
                      transition: "transform 0.2s ease-in-out",
                      transform:
                        expanded === index ? "rotate(180deg)" : "rotate(0deg)", // Rotation basierend auf dem expandierten Zustand
                    }}
                  />
                </Grid>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "white" }}>
                <Typography>{law.description}</Typography>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                  {law?.documentPath && (
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      sx={{ mt: "10px" }}
                    >
                      <Tooltip title="Dokument öffnen">
                        <Iconify
                          icon="ic:baseline-description"
                          sx={{ cursor: "pointer" }}
                        />
                        <Link
                          href={`${serverURL()}/public/law/document/${law._id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ cursor: "pointer", ml: 0.5 }}
                        >
                          Dokument öffnen
                        </Link>
                      </Tooltip>
                    </Stack>
                  )}
                  {law.externalUrl && (
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Tooltip title={law.externalUrl}>
                        <Iconify
                          icon="ic:baseline-public"
                          sx={{ cursor: "pointer" }}
                        />
                        <Link
                          href={`https://${law.externalUrl}`}
                          target="_blank"
                          rel="noopener"
                          sx={{ cursor: "pointer", ml: 0.5 }}
                        >
                          Website öffnen
                        </Link>
                      </Tooltip>
                    </Stack>
                  )}
                </Stack>

                {canEdit && (
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ justifyContent: "flex-end" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ textTransform: "none" }}
                      onClick={() => handleEdit(law)}
                    >
                      Bearbeiten
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      sx={{ textTransform: "none" }}
                      onClick={() => handleDelete(law._id)}
                    >
                      Löschen
                    </Button>
                  </Stack>
                )}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          // Message when no laws are found
          <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
            Keine Gesetze gefunden!
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
