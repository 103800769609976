import { createSlice } from "@reduxjs/toolkit";
import { authApiSlice } from "./authApiSlice";

const authSlice = createSlice({
  name: "auth",
  initialState: { user: null, token: null, roles: null },
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken, roles, userData } = action.payload;
      state.user = user;
      state.token = accessToken;
      state.roles = roles;
      state.userData = userData; // id, first & lastName, birthdate, phone
      state.selectedOffice = userData.selectedOffice;
    },
    setSelectedOffice: (state, action) => {
      const { selectedOffice } = action.payload;
      state.selectedOffice = selectedOffice;
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
      state.roles = null;
      state.userData = null;
      state.selectedOffice = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApiSlice.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        console.log("payload :>> ", payload);
        const { user, accessToken, roles, userData } = payload;
        state.token = accessToken;
        state.user = user;
        state.roles = roles;
        state.userData = userData; // id, first & lastName, birthdate, phone
        state.selectedOffice = userData.selectedOffice;
      }
    );
  },
});

export const { setCredentials, setSelectedOffice, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentRoles = (state) => state.auth.roles;
export const selectCurrentOffice = (state) => state.auth.selectedOffice;
export const selectCurrentUserData = (state) => state.auth.userData;
