import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

function FileDataCommentField({ control }) {
  return (
    <Controller
      name="comment"
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          variant="outlined"
          label="Kommentar"
          fullWidth
          multiline
          rows={3}
        />
      )}
    />
  );
}

FileDataCommentField.propTypes = {
  control: PropTypes.object,
};

export default FileDataCommentField;
