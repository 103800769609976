import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Typography, Box, Button, Divider } from "@mui/material";
import BillingForm from "../../../components/forms/Forms/CreateFileDataForm/BillingForm";
import ErrorAlert from "../../../components/alerts/ErrorAlert";
import {
  useAddNewBillingMutation,
  useGetTenantBillingQuery,
} from "../../../features/billing/billingsApiSlice";
import Loading from "../../../components/spinners/Loading";

const BillingSettings = () => {
  const {
    data: billing,
    refetch,
    isLoading,
    isFetching,
  } = useGetTenantBillingQuery();
  const [error, setError] = React.useState(null);
  const [addNewBilling] = useAddNewBillingMutation();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      address: "",
      zip: "",
      city: "",
      iban: "",
      bic: "",
    },
  });

  useEffect(() => {
    refetch();
    reset(billing);
    return () => {
      reset();
    };
  }, [refetch, reset, billing]);

  const onSubmit = async (data) => {
    setError(null);
    console.log("data :>> ", data);
    if (!data.billingCycle) {
      setError("Bitte eine Zahlungsweise auswählen.");
      return null;
    }
    try {
      const result = await addNewBilling(data);
      console.log("result :>> ", result);
      console.log("Billing created successfully:");
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Abrechnung
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Typography sx={{ my: 2 }} variant="body1">
        Bitte geben Sie hier die Informationen zur Abrechnung und
        Rechnungserstellung an.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BillingForm control={control} />
        {error && <ErrorAlert errorMessage={error} />}
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
          >
            SPEICHERN
          </Button>
        </Box>
      </form>
    </>
  );
};

export default BillingSettings;
