import { apiSlice } from "../../app/api/apiSlice";

export const tenantsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTenants: builder.query({
      query: () => "/tenant",
      // keepUnusedDataFor: 5,
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: "Tenant", id })), "Tenant"]
          : ["Tenant"],
    }),
    getTenantInformation: builder.query({
      query: () => "/tenant/information",
      // keepUnusedDataFor: 5,
    }),
    addNewTenant: builder.mutation({
      query: (tenant) => ({
        url: "/tenant",
        method: "POST",
        body: {
          ...tenant,
        },
        invalidatesTags: ["Tenant"],
      }),
    }),
    updateTenant: builder.mutation({
      query: (data) => ({
        url: "/tenant",
        method: "PATCH",
        body: { ...data },
        invalidatesTags: ["Tenant"],
      }),
    }),
    updateTenantLogo: builder.mutation({
      query: (data) => ({
        url: "/tenant/change-logo",
        method: "POST",
        body: { ...data }, // logoName
        invalidatesTags: ["Tenant"],
      }),
    }),
    deleteTenant: builder.mutation({
      query: (data) => ({
        url: "/tenant",
        method: "DELETE",
        body: data,
        invalidatesTags: ["Tenant"],
      }),
    }),
  }),
});

export const {
  useGetTenantsQuery,
  useGetTenantInformationQuery,
  useUpdateTenantMutation,
  useUpdateTenantLogoMutation,
} = tenantsApiSlice;
