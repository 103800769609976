import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import { useForm } from "react-hook-form";
import ControlledCheckbox from "../../../components/forms/ControlledCheckbox";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../../app/api/apiSlice";
import { useEntryFilterUpdateMutation } from "../../../features/entry/entryApiSlice";

EntryFilterSidebar.propTypes = {
  openFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  handleSaveFilter: PropTypes.func,
  filter: PropTypes.object,
};

export default function EntryFilterSidebar({
  openFilter,
  onOpenFilter,
  onCloseFilter,
  filter,
  setSelectedItem,
}) {
  console.log("Received filter:", filter);

  const [entryFilterUpdate] = useEntryFilterUpdateMutation();
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: filter || {}, // Initialize with the filter prop or an empty object
  });

  const dispatch = useDispatch();

  const watchFehler = watch("fehler");
  const watchBeschwerden = watch("beschwerden");
  const watchRisiken = watch("risiken");

  // Effect to reset form state when the filter prop changes
  useEffect(() => {
    if (filter) {
      console.log("Resetting form with new filter values:", filter);
      reset(filter);
    }
  }, [filter, reset]);

  const onSubmit = async (data) => {
    try {
      const result = await entryFilterUpdate({ entryFilterData: data });
      if (result) {
        onCloseFilter();
        reset(data);
        setSelectedItem(null);
      }
      dispatch(apiSlice.util.resetApiState());
    } catch (error) {
      console.error("Error saving task filter:", error);
    }
  };

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Iconify icon="ic:round-filter-list" />}
        onClick={onOpenFilter}
      >
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 300, border: "none", overflow: "hidden", p: 2 },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ padding: "8px 16px" }}
          >
            {/* Fehler Section */}
            <div>
              <ControlledCheckbox
                control={control}
                name="fehler"
                label="Fehler"
              />
              {watchFehler && (
                <Stack spacing={1} sx={{ ml: 2, mt: 1 }}>
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Status
                  </Typography>
                  <ControlledCheckbox
                    control={control}
                    name="fehlerOffen"
                    label="Offen"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="fehlerInBearbeitung"
                    label="In Bearbeitung"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="fehlerAbgeschlossen"
                    label="Abgeschlossen"
                  />

                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Einstufung
                  </Typography>
                  <ControlledCheckbox
                    control={control}
                    name="fehlerLeicht"
                    label="Leicht"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="fehlerMittel"
                    label="Mittel"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="fehlerSchwer"
                    label="Schwer"
                  />

                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Häufigkeit
                  </Typography>
                  <ControlledCheckbox
                    control={control}
                    name="haeufigkeitFehlerErstmalig"
                    label="Erstmalig"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="haeufigkeitFehlerSelten"
                    label="Selten"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="haeufigkeitFehlerHaeufig"
                    label="Häufig"
                  />
                </Stack>
              )}
            </div>

            {/* Beschwerden Section */}
            <div>
              <ControlledCheckbox
                control={control}
                name="beschwerden"
                label="Beschwerden"
              />
              {watchBeschwerden && (
                <Stack spacing={1} sx={{ ml: 2, mt: 1 }}>
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Status
                  </Typography>
                  <ControlledCheckbox
                    control={control}
                    name="beschwerdenOffen"
                    label="Offen"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="beschwerdenInBearbeitung"
                    label="In Bearbeitung"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="beschwerdenAbgeschlossen"
                    label="Abgeschlossen"
                  />

                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Einstufung
                  </Typography>
                  <ControlledCheckbox
                    control={control}
                    name="einstufungBeschwerdenLeicht"
                    label="Leicht"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="einstufungBeschwerdenMittel"
                    label="Mittel"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="einstufungBeschwerdenSchwer"
                    label="Schwer"
                  />

                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Häufigkeit
                  </Typography>
                  <ControlledCheckbox
                    control={control}
                    name="haeufigkeitBeschwerdenErstmalig"
                    label="Erstmalig"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="haeufigkeitBeschwerdenSelten"
                    label="Selten"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="haeufigkeitBeschwerdenHaeufig"
                    label="Häufig"
                  />
                </Stack>
              )}
            </div>

            {/* Risiken Section */}
            <div>
              <ControlledCheckbox
                control={control}
                name="risiken"
                label="Risiken"
              />
              {watchRisiken && (
                <Stack spacing={1} sx={{ ml: 2, mt: 1 }}>
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Status
                  </Typography>
                  <ControlledCheckbox
                    control={control}
                    name="statusRisikenRelevant"
                    label="Relevant"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="statusRisikenNichtRelevant"
                    label="Nicht Relevant"
                  />

                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Einstufung
                  </Typography>
                  <ControlledCheckbox
                    control={control}
                    name="einstufungRisikenLeicht"
                    label="Leicht"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="einstufungRisikenMittel"
                    label="Mittel"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="einstufungRisikenSchwer"
                    label="Schwer"
                  />

                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    Eintrittswahrscheinlichkeit
                  </Typography>
                  <ControlledCheckbox
                    control={control}
                    name="eintrittswahrscheinlichkeitGering"
                    label="Gering"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="eintrittswahrscheinlichkeitMittel"
                    label="Mittel"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="eintrittswahrscheinlichkeitHoch"
                    label="Hoch"
                  />
                </Stack>
              )}
            </div>

            <Box sx={{ p: 3 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="secondary"
                variant="contained"
                startIcon={<Iconify icon="ic:round-save" />}
              >
                Filter aktualisieren
              </Button>
            </Box>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}
