import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import ControlledCheckbox from "../ControlledCheckbox";
import AssignFileToUserForm from "./AssignFileToUserForm";

function AdminRevisionForm({ control, revisionChecked }) {
  return (
    <div>
      <Grid spacing={3} container>
        <Grid item xs={12}>
          <ControlledCheckbox
            name="revision"
            label="Mitarbeiter*in zur Überarbeitung zuweisen."
            errorMsg="Bitte angeben"
            control={control}
            description="Änderungen werden gespeichert und die Person wird über die erneute Zuweisung benachrichtigt."
          />
        </Grid>
      </Grid>
      {revisionChecked && <AssignFileToUserForm control={control} />}
    </div>
  );
}

AdminRevisionForm.propTypes = {
  control: PropTypes.object,
  revisionChecked: PropTypes.bool,
};

export default AdminRevisionForm;
