import React, { useState } from "react";
import { Button, Container, TextField, Typography, Paper } from "@mui/material";
import { useForm } from "react-hook-form";
import { resetAdminPasswort } from "../app/api/axios/services/public.service";
import ErrorAlert from "../components/alerts/ErrorAlert";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet-async";

export default function ForgotPassword() {
  const [state, setState] = useState(false);
  const [isError, setError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleAdminClick = () => {
    setState("IS_ADMIN");
  };

  const onSubmit = async (data) => {
    setError(null);
    console.log("data :>> ", data);
    try {
      let result = await resetAdminPasswort(data.email);
      console.log("result :>> ", result);
      if (result.status === 200) {
        setState("RESET_SUCCESS");
      }
    } catch (error) {
      setError(error);
      console.log("error :>> ", error);
    }
  };

  if (state === "RESET_SUCCESS") {
    return <PasswordResetSuccess />;
  }

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title> Digit QM | Password zurücksetzen </title>
      </Helmet>
      <Paper
        elevation={6}
        sx={{ p: 4, textAlign: "center", width: "100%", py: 8 }}
      >
        <Typography variant="h4" gutterBottom>
          Passwort zurücksetzen
        </Typography>
        {!state && (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Wenn Sie ein normaler Benutzer sind, wenden Sie sich bitte an
              Ihren Administrator.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAdminClick}
            >
              Ich bin ein Admin
            </Button>
          </>
        )}

        {state === "IS_ADMIN" && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              fullWidth
              margin="normal"
              label="Admin Email"
              type="email"
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email?.message}
              {...register("email", {
                required: "E-Mail ist erforderlich",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Ungültige E-Mail-Adresse",
                },
              })}
            />
            <Typography variant="body1" sx={{ mb: 2 }}>
              Bitte verwenden Sie die E-Mail Adresse mit der Sie sich sonst in
              die Software einloggen. <br />
              Ihnen wird per Mail ein neues Passwort zugeschickt.
            </Typography>
            {isError && (
              <div style={{ marginTop: 15 }}>
                <ErrorAlert errorMessage={isError?.response?.data?.message} />
              </div>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              PASSWORT ZURÜCKSETZEN
            </Button>
          </form>
        )}
      </Paper>
    </Container>
  );
}

const PasswordResetSuccess = () => {
  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Paper
        elevation={6}
        sx={{ p: 4, textAlign: "center", width: "100%", py: 8 }}
      >
        <Typography variant="h5" gutterBottom>
          Passwort zurückgesetzt
        </Typography>
        <Icon
          icon="icon-park-twotone:success"
          color="lawngreen"
          width="120"
          height="120"
          style={{ margin: 10 }}
        />
        <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
          Eine E-Mail mit Ihrem neuen Passwort wurde an Ihre E-Mail-Adresse
          gesendet. Bitte überprüfen Sie Ihr Postfach.
        </Typography>
        <Button
          variant="contained"
          component={Link}
          to="/login"
          //   onClick={handleClose}
          sx={{ mt: 3 }}
        >
          Zum Login
        </Button>
      </Paper>
    </Container>
  );
};
