import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import EntryCard from "./EntryCard";

export default function EntryList({
  years,
  sortedItems,
  handleSelectItem,
  selectedItem,
  style,
}) {
  return (
    <>
      <Paper style={style}>
        {years && years.length > 0 ? (
          years.map((year) => (
            <Accordion
              key={year}
              defaultExpanded={year === new Date().getFullYear()}
            >
              <AccordionSummary
                style={{ background: "#edf0ee" }}
                expandIcon={<Iconify icon="mdi:chevron-down" />}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                  }}
                >
                  {year}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                {sortedItems ? (
                  <>
                    {sortedItems &&
                      sortedItems.map((item) => {
                        const itemYear = new Date(item.createdAt).getFullYear();
                        if (itemYear === year) {
                          return (
                            <EntryCard
                              key={item._id}
                              data={item}
                              handleClickOpen={handleSelectItem}
                              selected={
                                selectedItem && item._id === selectedItem._id
                              }
                            />
                          );
                        }
                        return null;
                      })}
                  </>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Typography
                      variant="h6"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Iconify
                        icon="line-md:search-twotone"
                        sx={{
                          color: "text.disabled",
                          width: 40,
                          height: 40,
                          marginRight: 1,
                        }}
                      />
                      Keine Einträge gefunden!
                    </Typography>
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Iconify
                icon="line-md:search-twotone"
                sx={{
                  color: "text.disabled",
                  width: 40,
                  height: 40,
                  marginRight: 1,
                }}
              />
              Keine Einträge gefunden!
            </Typography>
          </Box>
        )}
      </Paper>
    </>
  );
}
