export const getCurrentUserCount = (tenant) => {
  return `${tenant?.users?.length}/${tenant?.allowedUsers.toString()}`;
};

export const allowNewUser = (tenant) => {
  let allow = true;
  if (tenant?.users?.length === tenant?.allowedUsers) allow = false;
  return allow;
};

export const getCurrentOfficesCount = (tenant) => {
  return `${tenant?.offices?.length}/${tenant?.allowedOffices.toString()}`;
};

export const allowNewOffice = (tenant) => {
  let allow = true;
  if (tenant?.offices?.length === tenant?.allowedOffices) allow = false;
  return allow;
};
