import * as React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import { Icon } from "@iconify/react";

export default function ProgressMobileStepper({
  handleBack,
  handleNext,
  handleClose,
  activeStep,
  steps,
  lastStep,
  lastStepButtonText,
  allowNext,
}) {
  return (
    <MobileStepper
      variant="dots"
      steps={steps}
      position="static"
      activeStep={activeStep}
      sx={{ flexGrow: 1 }}
      nextButton={
        <div>
          <Button
            size="small"
            variant="contained"
            color={lastStep ? "success" : "secondary"}
            onClick={() => handleNext()}
            disabled={!allowNext && !lastStep}
          >
            {lastStep ? lastStepButtonText ?? "Beenden" : "Weiter"}
            {!lastStep && (
              <Icon
                icon="material-symbols:keyboard-arrow-right"
                width="24"
                height="24"
              />
            )}
          </Button>
          {lastStep && (
            <Button
              sx={{ ml: 2 }}
              size="small"
              variant="contained"
              color={"error"}
              onClick={() => handleClose()}
            >
              BEENDEN
            </Button>
          )}
        </div>
      }
      backButton={
        <Button
          size="small"
          onClick={handleBack}
          disabled={activeStep === 0 || lastStep}
        >
          <Icon
            icon="material-symbols:keyboard-arrow-left"
            width="24"
            height="24"
          />
          Zurück
        </Button>
      }
    />
  );
}
