import React from "react";
import { Snackbar, Button } from "@mui/material";

export default function MySnackbar({ open, onClose, message }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      message={message}
      action={
        <Button color="secondary" size="small" onClick={onClose}>
          SCHLIESSEN
        </Button>
      }
    />
  );
}
