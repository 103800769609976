import useAuth from "../hooks/useAuth";

export const useSupportAreaArray = () => {
  const { isAdmin } = useAuth();

  const supportAreaArray = [
    { label: "Dashboard", value: "Dashboard" },
    isAdmin ? { label: "Praxisseite", value: "Praxisseite" } : null,
    isAdmin ? { label: "Benutzerseite", value: "Benutzerseite" } : null,
    { label: "Aufgabenseite", value: "Aufgabenseite" },
    { label: "Kalendar", value: "Kalendar" },
    { label: "Dateien", value: "Dateien" },
    {
      label: "Fehler-/Beschwerden- & Risikomanagement",
      value: "Fehler-/Beschwerden- & Risikomanagement",
    },
    { label: "Neuigkeiten", value: "Neuigkeiten" },
    { label: "Gesetze", value: "Gesetze" },
    { label: "Befragung", value: "Befragung" },
    { label: "Profil", value: "Profil" },
    { label: "Einstellungen", value: "Einstellungen" },
    { label: "Sonstige Anliegen", value: "sonstige" },
  ].filter(Boolean);

  return supportAreaArray;
};
