import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
// import { useLocation } from 'react-router-dom';
import { Navigate, useLocation, useNavigate } from "react-router-dom/dist";

import StyledIcon from "../components/styledicon/StyledIcon";
import { LoginForm } from "../sections/auth/login";
import ErrorAlert from "../components/alerts/ErrorAlert";
import { useLoginMutation } from "../features/auth/authApiSlice";
import { selectCurrentUser } from "../features/auth/authSlice";
import usePersist from "../hooks/usePersist";
import Loading from "../components/spinners/Loading";
import { Helmet } from "react-helmet-async";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.mojave-med.de/">
        Mojave Med QM Software
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function LoginPage() {
  const [persist, setPersist] = usePersist();
  const { handleSubmit, control } = useForm({
    defaultValues: { username: "", password: "", stayLoggedIn: persist },
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [errMsg, setErrMsg] = useState("");
  const [login, { isLoading }] = useLoginMutation();
  const user = useSelector(selectCurrentUser);
  const from = location.state?.from?.pathname || "/";

  const handleLogin = async (formValue) => {
    const { username, password, stayLoggedIn } = formValue;
    setPersist(stayLoggedIn);

    try {
      await login({ username, password, stayLoggedIn }).unwrap();
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.originalStatus) {
        // isLoading: true until timeout occurs
        setErrMsg("No Server Response");
      } else if (err.originalStatus === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.originalStatus === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
    }
  };

  const onSubmit = (data) => {
    handleLogin(data);
  };

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Helmet>
        <title> Digit QM | Login </title>
      </Helmet>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={2}
        md={5}
        sx={{
          backgroundImage: "url(/assets/images/med-utils.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "left",
        }}
      />
      <Grid item xs={12} sm={10} md={7} component={Paper} elevation={6} square>
        <Box
          sx={{
            mt: 12,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <StyledIcon icon="material-symbols:lock-outline" size={28} />
          <Typography align="center" variant="h4" gutterBottom>
            Anmelden
          </Typography>
          <Box textAlign={"center"} style={{ maxWidth: 500 }} sx={{ mt: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
              <LoginForm control={control} />
            </form>
            {errMsg && <ErrorAlert errorMessage={errMsg} />}

            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 1 }}>
                  Benötigen Sie einen Account? {""}
                  <Link
                    href="https://ww2.mojave-med.de/kontaktformular/"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="subtitle2"
                  >
                    Kontaktieren Sie uns!
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 3 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
