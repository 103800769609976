import * as React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import {
  styled,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import TaskForm from "./TaskForm";
import { useAddNewTaskMutation } from "../../../features/task/taskApiSlice";
import Iconify from "../../../components/iconify";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../../features/auth/authSlice";

// ---------------------------------------------------------------------------------------------------------

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

let dV = {
  taskName: "123",
  area: "",
  description: "",
  dueDate: "",
  responsibleUser: "",
  status: "",
};

CreateTaskDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

// --------------------------------------------------------------------------------------------------------------

export default function CreateTaskDialog({ handleClose }) {
  const [addNewTask] = useAddNewTaskMutation();
  const currentUserData = useSelector(selectCurrentUserData);

  dV = {
    taskName: "",
    description: "",
    area: "",
    dueDate: new Date(),
    responsibleUser: "",
    auditRelevant: false,
    visibility: true,
    status: "new",
    comments: [],
    scope: false,
  };

  const { handleSubmit, control } = useForm({
    defaultValues: { ...dV },
  });

  const onSubmit = async (data) => {
    try {
      let result = await addNewTask({
        ...data,
      });

      handleClose("REFETCH");
      console.log("result :>> ", result);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
            id="customized-dialog-title"
          >
            Neue Aufgabe erstellen
          </Typography>
          <Box sx={{ flex: "0 0 auto" }}>
            <Iconify
              onClick={handleClose}
              icon="mingcute:close-line"
              style={{ fontSize: "50px", color: "action.active" }}
              sx={{
                transition: "transform 0.1s", // Smooth transition for transform
                "&:hover": {
                  transform: "scale(1.8)", // Scale up the icon when hovered
                },
              }}
            />
          </Box>
        </Box>

        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1" color="textPrimary">
              Erstellen Sie eine neue Aufgabe und weisen Sie diese einem
              Mitarbeiter zu.
            </Typography>
          </DialogContentText>
          <br />
          <TaskForm control={control} />
          <Typography variant="h6" sx={{ m: 1 }} color="textPrimary">
            Erstellt von:{" "}
            {currentUserData.firstName + " " + currentUserData.lastName}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: "#9E9E9E" }}
            variant="contained"
            onClick={handleClose}
          >
            Abbrechen
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="secondary"
          >
            Erstellen
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
