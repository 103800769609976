import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Button, TextField, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserData } from "../../../features/auth/authSlice";
import {
  useAddNewCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} from "../../../features/comment/commentApiSlice";
import { AvatarIntern } from "../../../components/avatar";
import { apiSlice } from "../../../app/api/apiSlice";

// -----------------------------------------------------------------

function timeAgo(timestamp) {
  const now = Date.now();
  const past = new Date(timestamp);
  const diffInSeconds = Math.floor((now - past) / 1000);

  // Funktion um "Minute" und "Minuten" zu unterscheiden
  const pluralize = (count, singular, plural) =>
    count === 1 ? singular : plural;

  if (diffInSeconds < 60 || diffInSeconds === null) {
    return "gerade eben";
  } else if (diffInSeconds < 3600) {
    // Weniger als 1 Stunde
    const minutes = Math.floor(diffInSeconds / 60);
    return `vor ${minutes} ${pluralize(minutes, "Minute", "Minuten")}`;
  } else if (diffInSeconds < 86400) {
    // Weniger als 1 Tag
    const hours = Math.floor(diffInSeconds / 3600);
    return `vor ${hours} ${pluralize(hours, "Stunde", "Stunden")}`;
  } else if (diffInSeconds < 604800) {
    // Weniger als 1 Woche
    const days = Math.floor(diffInSeconds / 86400);
    return `vor ${days} ${pluralize(days, "Tag", "Tagen")}`;
  } else if (diffInSeconds < 2592000) {
    // Weniger als 1 Monat
    const weeks = Math.floor(diffInSeconds / 604800);
    return `vor ${weeks} ${pluralize(weeks, "Woche", "Wochen")}`;
  } else if (diffInSeconds < 31536000) {
    // Weniger als 1 Jahr
    const months = Math.floor(diffInSeconds / 2592000);
    return `vor ${months} ${pluralize(months, "Monat", "Monaten")}`;
  } else if (diffInSeconds < 3153600000000000) {
    // Mehr als 1 Jahr
    const years = Math.floor(diffInSeconds / 31536000);
    return `vor ${years} ${pluralize(years, "Jahr", "Jahren")}`;
  } else {
    return "gerade eben";
  }
}

// -----------------------------------------------------------------

function Comments({ taskId, comments }) {
  const currentUserData = useSelector(selectCurrentUserData);
  const [addNewComment] = useAddNewCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const [updateComment] = useUpdateCommentMutation();

  const [newComment, setNewComment] = useState("");
  const [commentList, setCommentList] = useState([...comments].reverse() || []);

  const [isFocused, setIsFocused] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editComment, setEditComment] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setCommentList([...comments].reverse());
  }, [comments]);

  const handleAddComment = async () => {
    if (!newComment.trim()) {
      return;
    }

    try {
      const commentNew = {
        author: currentUserData.id,
        content: newComment,
        taskId: taskId,
      };
      await addNewComment(commentNew);

      dispatch(apiSlice.util.resetApiState());
      setCommentList((prevCom) => [commentNew, ...prevCom]);
      setNewComment("");
      setIsFocused(false);
    } catch (error) {
      console.error("Fehler beim Hinzufügen des Kommentars:", error);
    }
  };

  const handleDeleteComment = async (id) => {
    try {
      const commentContent = {
        commentId: id,
        taskId: taskId,
      };
      await deleteComment({ ...commentContent });

      dispatch(apiSlice.util.resetApiState());
      const updatedComments = commentList.filter(
        (comment) => comment._id !== id
      );
      setCommentList(updatedComments);
    } catch (error) {
      console.error("Fehler beim Hinzufügen des Kommentars:", error);
    }
  };

  const handleUpdateComment = async (commentId) => {
    if (!editComment.trim()) return;

    try {
      await updateComment({ commentId, content: editComment, taskId });

      dispatch(apiSlice.util.resetApiState());
      const updatedComments = commentList.map((comment, index) =>
        index === editIndex ? { ...comment, content: editComment } : comment
      );
      setCommentList(updatedComments);
      setEditIndex(-1);
      setEditComment("");
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Kommentars:", error);
    }
  };

  const handleFocus = () => {
    setIsFocused((prevIsFocused) => !prevIsFocused);
    setEditIndex(null);
    setNewComment("");
  };

  const handleEdit = (index, content) => {
    setEditIndex(index);
    setEditComment(content);
  };

  return (
    <Grid container>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item sx={{ mt: "5px" }}>
          <AvatarIntern
            name={`${currentUserData.firstName} ${currentUserData.lastName}`}
          />
        </Grid>
        <Grid item xs>
          <TextField
            id="comment-input"
            placeholder="Schreibe einen Kommentar"
            fullWidth
            multiline
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            onFocus={!isFocused && handleFocus}
          />
          {isFocused && (
            <Grid
              container
              justifyContent="flex-start"
              spacing={1}
              sx={{ mt: "5px" }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleAddComment}
                >
                  Speichern
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" size="small" onClick={handleFocus}>
                  Abbrechen
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* Vorhandene Kommentare anzeigen */}
      {commentList.map((comment, index) => (
        <Grid item xs={12} key={index} sx={{ mt: "5px" }}>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item sx={{ mt: "25px" }}>
              <AvatarIntern
                name={`${comment.author.firstName} ${comment.author.lastName}`}
              />
            </Grid>

            {index !== editIndex ? (
              <Grid item xs>
                <Paper style={{ padding: "10px", marginTop: "10px" }}>
                  <TextField
                    label={
                      `${
                        comment.author.firstName || currentUserData.firstName
                      } ${
                        comment.author.lastName || currentUserData.lastName
                      }` +
                      " • " +
                      timeAgo(comment.createdAt)
                    }
                    fullWidth
                    multiline
                    variant="standard"
                    focused
                    placeholder="Schreibe ein Kommentar"
                    value={comment.content}
                    InputProps={{
                      disableUnderline: true,
                      style: { color: "black" },
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      style: { color: "grey", fontSize: "1.05rem" },
                    }}
                  />
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        cursor: "pointer",
                        color: "grey",
                        fontSize: "0.875rem",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                        marginRight: 2,
                      }}
                      onClick={() => handleDeleteComment(comment._id)}
                    >
                      Löschen
                    </Grid>
                    <Grid
                      item
                      sx={{
                        cursor: "pointer",
                        color: "grey",
                        fontSize: "0.875rem",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                        marginRight: 2,
                      }}
                      onClick={() => handleEdit(index, comment.content)}
                    >
                      Bearbeiten
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ) : (
              <Grid item xs>
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  value={editComment}
                  onChange={(e) => setEditComment(e.target.value)}
                  style={{ marginTop: "10px" }}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: index !== editIndex,
                  }}
                  InputLabelProps={{
                    style: { color: "grey", fontSize: "1.05rem" },
                  }}
                />
                <Grid
                  container
                  justifyContent="flex-start"
                  spacing={1}
                  sx={{ mt: "5px" }}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleUpdateComment(comment._id)}
                    >
                      Änderung speichern
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setEditIndex(null);
                      }}
                    >
                      Abbrechen
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

Comments.propTypes = {
  taskId: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      authorName: PropTypes.string, // Optional, falls der Name des Autors nicht immer verfügbar ist
    })
  ),
};

export default Comments;
