import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import {
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import CustomTooltip from "../../sections/@dashboard/tasks/CustomTooltip";

export default function ControlledRadioButton({
  control,
  name,
  label,
  itemArray,
  required,
  tooltip,
}) {
  return (
    <FormControl>
      <Box display="flex" alignItems="center">
        <FormLabel required={required} id={`${name}-label`}>
          {label}
        </FormLabel>
        {tooltip && (
          <Box ml={1}>
            <CustomTooltip
              title={tooltip?.title}
              content={tooltip?.content}
              icon={tooltip.icon}
            />
          </Box>
        )}
      </Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup
            aria-labelledby={`${name}-label`}
            {...field}
            value={field.value}
            onChange={(event) => field.onChange(event.target.value)}
          >
            {itemArray.map((item) => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}

ControlledRadioButton.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  itemArray: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};
