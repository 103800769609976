import React from "react";
import PropTypes from "prop-types";
import FileUpload from "../FileUpload";
import { Divider, Typography } from "@mui/material";

function SelectFileStep({ handleFile }) {
  return (
    <div>
      <div>
        <Typography variant="h3">
          Hinweis zum Hochladen von Dokumenten
        </Typography>
        <Divider />
        <Typography sx={{ mt: 3 }} variant="h6">
          Zur Vereinfachung des Prozesses ist es sinnvoll, die Dateien in
          folgendem Schema zu benennen:
        </Typography>
        <Typography sx={{ mt: 1 }} variant="h6">
          [HAUPTORDNER][UNTERORDNER] [DOKUMENTENART]-[NUMMER] [TITEL]
        </Typography>
        <Typography sx={{ mt: 1 }} variant="h6">
          Beispiele:
          <ul style={{ marginLeft: 50 }}>
            <li>2A VA-01 Terminplanung und -vergabe.docx</li>
            <li>6A FB-03 Regelmäßige Aufgaben im QM Normrevision.docx </li>
            <li>8 NW-25 TS-Protokoll_2023-17-03.docx</li>
          </ul>
        </Typography>
      </div>
      <FileUpload handleFile={handleFile} />
    </div>
  );
}

SelectFileStep.propTypes = { onSubmit: PropTypes.func };

export default SelectFileStep;
