import PropTypes from "prop-types";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

export default function ControlledCheckbox({
  control,
  name,
  label,
  description,
  disabled,
  defaultChecked,
}) {
  return (
    <div>
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Checkbox
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value}
                disabled={disabled}
                defaultChecked={defaultChecked}
              />
            )}
          />
        }
        label={
          <Typography sx={{ color: disabled ? "text.disabled" : "inherit" }}>
            {label}
          </Typography>
        }
      />
      {description && (
        <FormHelperText
          sx={{
            textAlign: "left",
            color: disabled ? "text.disabled" : "inherit",
          }}
        >
          {description}
        </FormHelperText>
      )}
    </div>
  );
}

ControlledCheckbox.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
};

ControlledCheckbox.defaultProps = {
  name: "",
  label: "",
  description: "",
  disabled: false,
};
