import React from "react";
import { Grid, Button, Box } from "@mui/material";
import Iconify from "../../../../components/iconify";

export default function FbDataControls({
  formState,
  handleKontrolle,
  handleRiskManagement,
  selectedItem,
}) {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 16,
        left: 0,
        width: "100%",
        zIndex: 1000,
        backgroundColor: "transparent",
        p: 2,
        pr: 5,
      }}
    >
      <Grid container alignItems="center" justifyContent="flex-end">
        <Grid item>
          {/* <Button
            onClick={() => console.log("download")}
            variant="contained"
            startIcon={<Iconify icon="tabler:download" />}
            sx={{ mr: 1, background: "#808080" }}
            disabled
          >
            Herunterladen
          </Button> */}
          <Button
            onClick={handleKontrolle}
            variant="contained"
            startIcon={
              <Iconify icon="fluent-emoji-high-contrast:passport-control" />
            }
            sx={{ mr: 1, background: "#2196F3", textTransform: "none" }}
            disabled={selectedItem?.incidentToRisk}
          >
            Neue Kontrolle festlegen
          </Button>
          {selectedItem?.incidentType !== "risiko" && (
            <Button
              onClick={handleRiskManagement}
              variant="contained"
              startIcon={<Iconify icon="carbon:tree-fall-risk" />}
              sx={{ mr: 1, background: "#2196F3", textTransform: "none" }}
              disabled={selectedItem?.incidentToRisk}
            >
              Ins Risikomanagement aufnehmen
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            sx={{ textTransform: "none" }}
            startIcon={<Iconify icon="dashicons:update-alt" />}
            disabled={!formState.isDirty}
          >
            Änderung speichern
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
