import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "@hello-pangea/dnd";
import styled from "styled-components";
import { Card, Typography, Stack, Tooltip, Grid } from "@mui/material";
import Iconify from "../../../components/iconify/Iconify";

// Adjust Container to use transient prop for isDragging
const Container = styled.div`
  border-radius: 10px;
  padding: 4px;
  margin-bottom: 8px;
  min-height: 90px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: ${(props) => (props.$isDragging ? "#7d7df8" : props.color)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

// PropType validations
TaskCard.propTypes = {
  index: PropTypes.number.isRequired,
  task: PropTypes.object.isRequired,
  color: PropTypes.string,
  handleClickOpen: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

// TaskCard component definition
export default function TaskCard({
  index,
  task,
  color,
  handleClickOpen,
  isMobile,
}) {
  const TaskContent = ({ provided, snapshot }) => (
    <Container
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      $isDragging={snapshot.isDragging}
      color={color}
      onClick={() => handleClickOpen(task)}
    >
      <Card elevation={3} sx={{ position: "relative", background: "#b8dfe6" }}>
        <Typography
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "12px",
          }}
        >
          Bereich: {task?.area ? task?.areaName : "Praxis"}
        </Typography>
        <Card
          elevation={3}
          sx={{ position: "relative", backgroundColor: "#fffff " }}
        >
          <Stack spacing={2} sx={{ padding: "11px" }}>
            <Tooltip title={task.name} arrow>
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  paddingTop: "5px",
                  textDecoration:
                    task.status === "done" ? "line-through" : "none",
                  textDecorationThickness: "1.5px",
                }}
              >
                {task.taskName}
              </Typography>
            </Tooltip>
            <Stack direction="row" spacing={2}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Tooltip title="Zugewiesen an:">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Iconify icon="octicon:feed-person-16" size={10} />
                    <Typography
                      sx={{
                        color: "text.disabled",
                        paddingLeft: "4px",
                        fontSize: "12px",
                      }}
                    >
                      {task.responsibleUser.firstName}{" "}
                      {task.responsibleUser.lastName}
                    </Typography>
                  </Stack>
                </Tooltip>
                <Tooltip title="Erstellt am:">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Iconify icon="clarity:date-line" size={10} />
                    <Typography
                      sx={{
                        color: "text.disabled",
                        paddingLeft: "4px",
                        fontSize: "12px",
                      }}
                    >
                      {new Date(task.createdAt).toLocaleDateString("de-DE")}
                    </Typography>
                  </Stack>
                </Tooltip>
              </Grid>
            </Stack>
          </Stack>
        </Card>
      </Card>
    </Container>
  );

  if (isMobile) {
    return (
      <TaskContent
        provided={{ draggableProps: {}, dragHandleProps: {}, innerRef: null }}
        snapshot={{ isDragging: false }}
      />
    );
  }

  return (
    <Draggable draggableId={task._id} index={index}>
      {(provided, snapshot) => (
        <TaskContent provided={provided} snapshot={snapshot} />
      )}
    </Draggable>
  );
}
