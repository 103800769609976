import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { Icon } from "@iconify/react";

export default function SuccessDialog({ dialog, handleClose }) {
  return (
    <Dialog
      open
      // TransitionComponent={Transition}
      keepMounted
      onClose={handleClose} // Close when click oustside the dialog
      // maxWidth="lg"
      // fullWidth
      aria-describedby="dialog-success"
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {dialog?.title}
          <Button onClick={() => handleClose("REFETCH")} color="inherit">
            <Icon width="40" icon="icon-park-solid:close-one" />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ textAlign: "center" }}>
          <Icon
            icon="icon-park-twotone:success"
            color="lawngreen"
            width={dialog?.data?.iconSize || "20vh"}
            height={dialog?.data?.iconSize || "20vh"}
            style={{ margin: 25 }}
          />
          <Typography variant="h5" id="success-dialog-description">
            {dialog?.data?.successText}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose("REFETCH")}>Beenden</Button>
      </DialogActions>
    </Dialog>
  );
}
