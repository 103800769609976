import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  styled,
  Dialog,
  DialogActions,
  Typography,
  DialogContent,
  Divider,
} from "@mui/material";
import { useDeleteTaskMutation } from "../../../features/task/taskApiSlice";
import { useDeleteEventMutation } from "../../../features/event/eventApiSlice";
import {
  useDeleteControlMutation,
  useDeleteFBMutation,
  useDeleteMeasureMutation,
} from "../../../features/entry/entryApiSlice";
import { useDeleteNewMutation } from "../../../features/news/newsApiSlice";
import { useNavigate } from "react-router-dom";
import { useDeleteLawMutation } from "../../../features/laws/lawsApiSlice";

// Styled components using MUI's 'styled' utility
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

DeleteDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  importedData: PropTypes.object.isRequired,
};

export default function DeleteDialog({
  handleClose,
  importedData,
  art,
  handleRemove,
}) {
  const [deleteTask] = useDeleteTaskMutation();
  const [deleteEvent] = useDeleteEventMutation();
  const [deleteFBMEntry] = useDeleteFBMutation();
  const [deleteControl] = useDeleteControlMutation();
  const [deleteMeasure] = useDeleteMeasureMutation();
  const [deleteNew] = useDeleteNewMutation();
  const [deleteLaw] = useDeleteLawMutation();
  const navigate = useNavigate();

  let data = { content: "", buttonContent: "" };

  if (art === "TASK") {
    data = { content: "die Aufgabe", buttonContent: "Aufgabe löschen" };
  } else if (art === "EVENT") {
    data = { content: "das Event", buttonContent: "Event löschen" };
  } else if (art === "DELETEFB") {
    data = { content: "den Eintrag", buttonContent: "Eintrag löschen" };
  } else if (art === "DELETEFBRCONTROL") {
    data = { content: " die Kontrolle", buttonContent: "Kontrolle löschen" };
  } else if (art === "DELETEFBRMEAUSURE") {
    data = { content: " die Maßnahme", buttonContent: "Maßnahme löschen" };
  } else if (art === "DELETELAW") {
    data = {
      content: " den Gesetzesbeitrag",
      buttonContent: "Beitrag löschen",
    };
  } else if (art === "DELETENEW" || "DELETEDRAFTNEW") {
    data = { content: " den Beitrag", buttonContent: "Beitrag löschen" };
  }
  const handleDeleteTask = async () => {
    if (art === "TASK") {
      try {
        console.log("Deleting task with ID :>> ", importedData);
        await deleteTask({ _id: importedData });
        handleClose("REFETCH");
      } catch (error) {
        console.error("Deletion error :>> ", error);
      }
    }
    if (art === "EVENT") {
      try {
        await deleteEvent({ _id: importedData });
        handleClose("REFETCH");
      } catch (error) {
        console.error("Failed to add new event: ", error);
      }
    }
    if (art === "DELETEFB") {
      try {
        await deleteFBMEntry({ _id: importedData });
        handleClose("REFETCH");
      } catch (error) {
        console.error("Failed to add new event: ", error);
      }
    }
    if (art === "DELETEFBRCONTROL") {
      try {
        const result = await deleteControl({
          entryId: importedData?.entryId,
          controlID: importedData?.controlID,
        });
        if (result) {
          handleClose("REFETCH");
        }
      } catch (error) {
        console.error("Failed to add new event: ", error);
      }
    }
    if (art === "DELETEFBRMEAUSURE") {
      try {
        handleRemove(importedData?.index);
        const result = await deleteMeasure({
          entryId: importedData?.entryId,
          measureID: importedData?.measureID,
        });
        if (result) {
          handleClose("REFETCH");
        }
      } catch (error) {
        console.error("Failed to add new event: ", error);
      }
    }
    if (art === "DELETENEW") {
      try {
        let newId = importedData?.newId;
        const result = await deleteNew(newId);
        if (result) {
          handleClose("REFETCH");
          navigate(-1);
        }
      } catch (error) {
        console.error("Failed to add new event: ", error);
      }
    }
    if (art === "DELETEDRAFTNEW") {
      try {
        let newId = importedData?.newId;
        const result = await deleteNew(newId);
        if (result) {
          handleClose("REFETCH");
        }
      } catch (error) {
        console.error("Failed to add new event: ", error);
      }
    }
    if (art === "DELETELAW") {
      try {
        let lawId = importedData?.lawId;
        const result = await deleteLaw(lawId);
        if (result) {
          handleClose("REFETCH");
        }
      } catch (error) {
        console.error("Failed to add new event: ", error);
      }
    }
  };

  return (
    <BootstrapDialog
      onClose={() => handleClose()}
      aria-labelledby="customized-dialog-title"
      open
    >
      <DialogContent>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Sind Sie sicher, dass Sie
          {data.content} löschen möchten?
        </Typography>
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <Button
          style={{
            background: "#9E9E9E",
            marginBottom: 2,
            textTransform: "none",
          }}
          variant="contained"
          onClick={() => handleClose()}
        >
          Abbrechen
        </Button>
        <Button
          color="error"
          variant="contained"
          style={{
            textTransform: "none",
          }}
          onClick={() => handleDeleteTask()}
        >
          {data.buttonContent}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
