import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useController } from "react-hook-form";
import FileUpload from "../../../../components/FileUploadArea/FileUpload";
import ControlledCheckbox from "../../../../components/forms/ControlledCheckbox";
import AllFileTypesUpload from "../../../../components/FileUploadArea/AllFileTypesUpload";

const defaultImages = [
  require("./1.jpg"),
  require("./2.jpg"),
  require("./3.jpg"),
];

export default function NewsDialogStep2({
  control,
  setValue,
  file,
  setFile,
  filePreview,
  setFilePreview,
  fileDocument,
  setFileDocument,
  fileDocumentPreview,
  setFileDocumentPreview,
  useCustomImage,
  useCustomDocument,
}) {
  const {
    field: { value: selectedImageIndex, onChange: setSelectedImageIndex },
  } = useController({
    name: "selectedImageIndex",
    control,
    defaultValue: 0, // Default to the first image
  });

  return (
    <>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
        Wählen Sie ein Bild aus:
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {defaultImages.map((imgSrc, index) => (
          <Grid item xs={4} key={index}>
            <Box
              component="img"
              src={imgSrc}
              alt={`default-img-${index}`}
              onClick={() => {
                setSelectedImageIndex(index);
                setFile(null);
                setFilePreview(imgSrc);
                setValue("imageDefaultPath", index + 1); // Set imageDefaultPath to match defaultImages index
              }}
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: 1,
                border:
                  selectedImageIndex === index
                    ? "2px solid primary.main"
                    : "1px solid grey",
                cursor: "pointer",
              }}
            />
          </Grid>
        ))}
      </Grid>

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <ControlledCheckbox
          control={control}
          name="useCustomImage"
          label="Eigenes Bild verwenden"
        />
      </Box>

      {useCustomImage && (
        <Box sx={{ mb: 2 }}>
          <FileUpload
            file={file}
            setFile={setFile}
            filePreview={filePreview}
            setFilePreview={setFilePreview}
          />
        </Box>
      )}
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <ControlledCheckbox
          control={control}
          name="useCustomDocument"
          label="Eigenes Dokument verwenden"
        />
      </Box>

      {useCustomDocument && (
        <Box sx={{ mb: 2 }}>
          <AllFileTypesUpload
            file={fileDocument}
            setFile={setFileDocument}
            filePreview={fileDocumentPreview}
            setFilePreview={setFileDocumentPreview}
          />
        </Box>
      )}
    </>
  );
}
