import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextField from "../TextField";
import ControlledCheckbox from "../ControlledCheckbox";

function UpdateFileDataForm({ control, revisionChecked }) {
  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <ControlledCheckbox
          name="revision"
          label="Bearbeitung beenden und zur Prüfung an verantwortliche Person senden."
          errorMsg="Bitte angeben"
          control={control}
          description="Eine nachträgliche Änderung des Dokuments ist dann nur durch erneute Zuweisung durch die verantwortliche Person möglich."
        />
      </Grid>

      {revisionChecked && (
        <Grid item xs={12}>
          <TextField
            required={false}
            name="description"
            label="Beschreibung"
            fullWidth
            errorMsg="Bitte angeben was bearbeitet wurde."
            multiline={true}
            control={control}
            helperText="Bitte eine kurze Beschreibung angeben, was durchgeführt wurde."
          />
        </Grid>
      )}
    </Grid>
  );
}

UpdateFileDataForm.propTypes = { props: PropTypes.object };

export default UpdateFileDataForm;
