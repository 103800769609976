import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Breadcrumbs,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemButton,
} from "@mui/material";
import { Icon } from "@iconify/react";

const FolderSelector = ({
  folderStructure,
  control,
  name,
  required,
  infoText,
  proofFolder, // Preselected folder path
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: required && "Bitte einen Ordner auswählen.",
    },
  });

  const [selectedFolders, setSelectedFolders] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");

  const handleFolderClick = (folder) => {
    const newPath = [...selectedFolders, folder.name];
    setSelectedFolders(newPath);
    field.onChange(newPath); // Update the selected folder names
  };

  const handleBackClick = (index) => {
    const newSelectedFolders = selectedFolders.slice(0, index + 1);
    setSelectedFolders(newSelectedFolders);
    field.onChange(newSelectedFolders);
  };

  const handleAddFolder = () => {
    const newFolder = newFolderName; // Just the name of the new folder

    setNewFolderName("");
    setOpenDialog(false);
    const newPath = [...selectedFolders, newFolder];
    setSelectedFolders(newPath);
    field.onChange(newPath); // Update the selected folder names
  };

  // Function to find folder by name recursively
  const findFolderByPath = (structure, folderName) => {
    for (const folder of structure) {
      if (folder.type === "folder") {
        // If the current folder matches the name we're looking for, return its path
        if (folder.name === folderName) {
          return [folder.name];
        }
        // Otherwise, recursively search in the folder's children
        if (folder.children && folder.children.length) {
          const childPath = findFolderByPath(folder.children, folderName);
          if (childPath.length) {
            // If a match is found in children, prepend the current folder name
            return [folder.name, ...childPath];
          }
        }
      }
    }
    return []; // Return an empty array if no match is found
  };

  useEffect(() => {
    if (proofFolder && !selectedFolders.length) {
      const cleanPath = proofFolder.replace(/^files\\/, "");
      const pathSegments = cleanPath.split("\\");

      // Use the last segment (folder name) for searching
      const folderName = pathSegments[pathSegments.length - 1];

      const preselectedPath = findFolderByPath(folderStructure, folderName);

      if (preselectedPath.length) {
        setSelectedFolders(preselectedPath);
        field.onChange(preselectedPath); // Update the form field
      }
    }
  }, [proofFolder, folderStructure, field]);

  // Helper function to find the current level in the folder structure
  const findCurrentLevel = (structure, selectedPath) => {
    let currentLevel = structure;

    selectedPath.forEach((folderName) => {
      const selectedFolder = currentLevel.find(
        (folder) => folder.name === folderName && folder.type === "folder"
      );

      if (selectedFolder && selectedFolder.children) {
        currentLevel = selectedFolder.children;
      } else {
        currentLevel = [];
      }
    });

    return currentLevel;
  };

  const currentLevel = findCurrentLevel(folderStructure, selectedFolders);

  return (
    <Box sx={{ minHeight: 125 }}>
      <Typography>Ausgewählter Ordner:</Typography>
      <div style={{ minHeight: 26 }}>
        {selectedFolders.length > 0 ? (
          <Breadcrumbs separator=">">
            {selectedFolders.map((folderName, index) => (
              <Typography
                key={folderName}
                color={
                  index === selectedFolders.length - 1
                    ? "textPrimary"
                    : "inherit"
                }
                onClick={() => handleBackClick(index)}
                style={{
                  cursor:
                    index === selectedFolders.length - 1
                      ? "default"
                      : "pointer",
                }}
              >
                <Icon icon="material-symbols:folder-outline" />
                {folderName}
              </Typography>
            ))}
          </Breadcrumbs>
        ) : (
          <Typography
            variant="body1"
            sx={error && { color: "red", fontWeight: "bold" }}
          >
            {infoText ?? "Bitte einen Ordner auswählen."}
          </Typography>
        )}
      </div>
      <div style={{ minHeight: 85 }}>
        <List>
          {currentLevel.map(
            (item) =>
              item.type === "folder" && (
                <ListItem
                  button
                  key={item.id}
                  onClick={() => handleFolderClick(item)}
                >
                  <ListItemIcon>
                    <Icon icon="material-symbols:folder-outline" />
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
              )
          )}
          {selectedFolders.length > 0 && (
            <ListItemButton onClick={() => setOpenDialog(true)}>
              <ListItemIcon>
                <Icon icon="material-symbols:add-circle-outline" />
              </ListItemIcon>
              <ListItemText primary="Neuen Ordner erstellen" />
            </ListItemButton>
          )}
        </List>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Neuen Unterordner hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Ordnername"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            variant="outlined"
            color="error"
          >
            Abbrechen
          </Button>
          <Button onClick={handleAddFolder} variant="contained" color="primary">
            HINZUFÜGEN
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

FolderSelector.propTypes = {
  folderStructure: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      children: PropTypes.array,
    })
  ).isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  proofFolder: PropTypes.string, // Add proofFolder as a prop
};

export default FolderSelector;
