import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Iconify from "../iconify";
import { useGetOfficesQuery } from "../../features/offices/officesApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentOffice } from "../../features/auth/authSlice";
import { ListItemIcon, ListItemText } from "@mui/material";

function ControlledOfficeMenu({ selectedOffice, setSelectedOffice }) {
  const {
    data: offices,
    // isLoading: isLoadingOffices,
    // isError: isErrorOffices,
  } = useGetOfficesQuery();

  const currentOffice = useSelector(selectCurrentOffice);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (offices && currentOffice) {
      setSelectedOffice(currentOffice ?? "");
    }
  }, [offices, currentOffice]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectOffice = (office) => {
    setSelectedOffice(office._id);
    handleClose();
    // getSelectedOffice(office)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        fullWidth
        variant="outlined"
        // disabled // Disable for user with no rights for changing offices
        startIcon={
          <Iconify icon="fa-solid:clinic-medical" width={24} height={24} />
        }
      >
        Praxis:{" "}
        {offices && offices?.find((o) => o._id === selectedOffice)?.officeName}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {offices &&
          offices.map((office) => {
            return (
              <MenuItem
                key={office._id}
                onClick={() => handleSelectOffice(office)}
              >
                <ListItemIcon>
                  <Iconify
                    icon="fa-solid:clinic-medical"
                    width={24}
                    height={24}
                  />
                </ListItemIcon>
                <ListItemText>{office.officeName}</ListItemText>
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}

ControlledOfficeMenu.propTypes = {};

export default ControlledOfficeMenu;
