import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import {
  useDeleteAuditMutation,
  useGetAllAuditsQuery,
  useUpdateAuditMutation,
} from "../../../features/audit/auditApiSlice";
import Loading from "../../../components/spinners/Loading";
import Label from "../../../components/label";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import NoAuditError from "./NoAuditError";
import useAuth from "../../../hooks/useAuth";

const AuditsOverviewTable = () => {
  const { isAdmin } = useAuth();
  const {
    data: audits,
    error,
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useGetAllAuditsQuery();
  const [updateAudit, { isError: isErrorUpdate, isLoading: isLoadingUpdate }] =
    useUpdateAuditMutation();
  const [deleteAudit, { isError: isErrorDelete, isLoading: isLoadingDelete }] =
    useDeleteAuditMutation();

  const handleStatusChange = async (auditId, newStatus) => {
    try {
      await updateAudit({
        _id: auditId,
        updateFields: { status: newStatus },
      }).unwrap();
      console.log(`Audit ${auditId} updated to status: ${newStatus}`);
    } catch (error) {
      console.error("Error updating audit status:", error);
    }
  };

  const handleDeleteAudit = async (auditId) => {
    try {
      let res = await deleteAudit(auditId).unwrap();
      if (res && res.message) {
        window.alert(res.message);
        refetch();
      }
    } catch (error) {
      console.error("Error deleting audit", error);
    }
  };

  if (error && error?.data?.noAudits) {
    return <NoAuditError />;
  }

  if (isError) {
    return (
      <Typography color="error">
        Probleme beim Laden der Audits. Bitte versuchen Sie es erneut.
      </Typography>
    );
  }

  if (isErrorDelete) {
    return (
      <Typography color="error">
        Probleme beim Löschen des Audits. Bitte versuchen Sie es erneut.
      </Typography>
    );
  }

  if (isErrorUpdate) {
    return (
      <Typography color="error">
        Probleme beim ändern des Status des Audits. Bitte versuchen Sie es
        erneut.
      </Typography>
    );
  }

  return (
    <TableContainer sx={{ maxHeight: "80vh" }} component={Paper} elevation={3}>
      {(isLoading || isFetching) && <Loading />}
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left">Status</TableCell>
            <TableCell>Jahr</TableCell>
            {isAdmin && <TableCell align="center">Status ändern</TableCell>}
            {isAdmin && <TableCell align="center">Löschen</TableCell>}
            <TableCell align="center">Anzeigen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {audits?.map((audit) => (
            <TableRow key={audit._id}>
              <TableCell align="left">
                <AuditStatusLabel status={audit.status} />
              </TableCell>
              <TableCell>{audit.year}</TableCell>
              {isAdmin && (
                <TableCell align="center">
                  <Select
                    value={audit.status}
                    onChange={(e) =>
                      handleStatusChange(audit._id, e.target.value)
                    }
                    displayEmpty
                    variant="outlined"
                    sx={{ ml: 1, minWidth: 200 }}
                  >
                    <MenuItem value="open">OFFEN</MenuItem>
                    <MenuItem value="inProgress">IN BEARBEITUNG</MenuItem>
                    <MenuItem value="completed">BEENDET</MenuItem>
                    <MenuItem value="archived">ARCHIVIERT</MenuItem>
                  </Select>
                </TableCell>
              )}
              {isAdmin && (
                <TableCell align="center">
                  <Button
                    onClick={() => handleDeleteAudit(audit._id)}
                    variant="contained"
                    color="error"
                  >
                    <Icon width={"26"} icon="material-symbols:delete" />
                  </Button>
                </TableCell>
              )}
              <TableCell align="center">
                <Button
                  component={NavLink}
                  to={`${audit.year}`}
                  variant="contained"
                  fullWidth
                >
                  Audit anzeigen
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AuditsOverviewTable;

function AuditStatusLabel({ status }) {
  let color, text;

  switch (status) {
    case "open":
      color = "success";
      text = "OFFEN";
      break;
    case "inProgress":
      color = "warning";
      text = "IN BEARBEITUNG";
      break;
    case "completed":
      color = "error";
      text = "BEENDET";
      break;
    case "archived":
      color = "info";
      text = "ARCHIVIERT";
      break;
    default:
      color = "default";
      text = "UNBEKANNT";
      break;
  }

  return (
    <Label style={{ minWidth: 125 }} color={color}>
      {text}
    </Label>
  );
}

AuditStatusLabel.propTypes = {
  status: PropTypes.string,
};
