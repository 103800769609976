import React, { useCallback } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useDropzone } from "react-dropzone";

export default function AllFileTypesUpload({
  file,
  setFile,
  filePreview,
  setFilePreview,
  onUpload,
  previewTitle = "Vorschau:",
  dropzoneText = "Hier klicken oder Datei hierherziehen",
  acceptedFileTypes = "*", // Akzeptiere alle Dateiformate
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFile(file);

      // Überprüfen, ob die Datei ein Bild ist
      const isImage = file.type.startsWith("image/");
      if (isImage) {
        const previewUrl = URL.createObjectURL(file);
        setFilePreview(previewUrl);
      } else {
        setFilePreview(null); // Keine Vorschau für Nicht-Bilder
      }
      onUpload(file);
    },
    [onUpload, setFile, setFilePreview]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    multiple: false,
  });

  const handleRemoveFile = () => {
    setFile(null);
    setFilePreview(null);
  };

  return (
    <Box fullWidth>
      {file ? (
        <>
          <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}>
            {previewTitle}
          </Typography>
          <Typography variant="body2" component="p" sx={{ mb: 2 }}>
            {file?.name}
          </Typography>
          {filePreview ? (
            // Wenn es sich um ein Bild handelt, zeige die Vorschau
            <Box mt={2} sx={{ textAlign: "center" }}>
              <img
                src={filePreview}
                alt="File Preview"
                style={{ maxWidth: "100%", maxHeight: 200 }}
              />
            </Box>
          ) : (
            // Andernfalls zeige einen Link
            <Typography variant="body2" component="p" sx={{ mt: 2 }}>
              Dokument hochgeladen.{" "}
              <a
                href={URL.createObjectURL(file)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Hier ansehen
              </a>
            </Typography>
          )}
          <Box sx={{ textAlign: "center" }}>
            <Button
              sx={{ m: 2, minWidth: 250 }}
              variant="contained"
              color="warning"
              onClick={handleRemoveFile}
            >
              Datei ändern
            </Button>
          </Box>
        </>
      ) : (
        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed #000000",
            borderRadius: "8px",
            padding: "20px",
            textAlign: "center",
            cursor: "pointer",
            mb: 3,
            color: "#000000",
            fontWeight: "bold",
            width: "100%",
            maxWidth: "600px", // Breite des Upload-Bereichs
            margin: "0 auto", // Zentriert den Bereich
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography
              variant="body1"
              sx={{ color: "black", fontWeight: "bold" }}
            >
              Ziehe die Datei hierher...
            </Typography>
          ) : (
            <Typography
              variant="body1"
              sx={{ color: "black", fontWeight: "bold" }}
            >
              {dropzoneText}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
