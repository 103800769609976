import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import TextField from "../../../../components/forms/TextField";
import MulitSelectUsersController from "../../../../components/forms/MultiSelectUsersController";
import { useUpdateGroupMutation } from "../../../../features/chats/chatsApiSlice";

const GroupEditDialog = ({ handleClose, groupData }) => {
  const [updateGroup] = useUpdateGroupMutation();
  const userIds = groupData?.users.map((user) => user._id) || [];
  console.log("groupData", groupData);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      groupName: groupData?.groupName || "",
      users: userIds || [],
    },
  });

  // Bei erfolgreicher Speicherung
  const onSubmit = async (data) => {
    const result = await updateGroup({
      chatId: groupData?._id,
      newGroupName: data.groupName,
      newUsers: data.users,
    });
    if (result) {
      handleClose("REFETCH");
    }
  };

  return (
    <Dialog open handleClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Gruppe bearbeiten</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Sie können hier den Namen der Gruppe ändern und neue Benutzer
            hinzufügen.
          </Typography>
          <TextField
            control={control}
            label="Gruppenname"
            name="groupName"
            fullWidth
            required
            sx={{ mb: 2 }}
          />

          <MulitSelectUsersController
            control={control}
            name="users"
            label="Personen"
            required={true}
            fullWidth
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          Abbrechen
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="secondary"
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupEditDialog;
