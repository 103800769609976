import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
// components
import Logo from "../../components/logo";

// ----------------------------------------------------------------------

const StyledHeader = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

const StyledFooterLogo = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  padding: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5),
  },
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  return (
    <>
      <StyledHeader>
        <Logo logoPath="/assets//logos/DIGITQM_transparent.png" />
      </StyledHeader>

      <Outlet />

      {/* Logo unten links */}
      <StyledFooterLogo>
        <Logo logoPath="/assets//logos/mojave-logo.png" />
      </StyledFooterLogo>
    </>
  );
}
