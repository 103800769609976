import { apiSlice } from "../../app/api/apiSlice";

export const lawsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllLaws: builder.query({
      query: () => ({
        url: "/laws",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Law", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Law", id })),
          ];
        }
        return [{ type: "Law", id: "LIST" }];
      },
    }),
    getLawById: builder.query({
      query: (lawId) => ({
        url: `/laws/${lawId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Law", id: arg }],
    }),
    createNewLaw: builder.mutation({
      query: (law) => ({
        url: "/laws",
        method: "POST",
        body: {
          ...law,
        },
      }),
      invalidatesTags: [{ type: "Law", id: "LIST" }],
    }),
    updateLaw: builder.mutation({
      query: (lawUpdate) => ({
        url: `/laws`,
        method: "PATCH",
        body: {
          ...lawUpdate,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Law", id: arg.id }],
    }),
    deleteLaw: builder.mutation({
      query: (lawId) => ({
        url: `/laws`,
        method: "DELETE",
        body: {
          lawId,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Law", id: arg }],
    }),
  }),
});

export const {
  useGetAllLawsQuery,
  useGetLawByIdQuery,
  useCreateNewLawMutation,
  useUpdateLawMutation,
  useDeleteLawMutation,
} = lawsApiSlice;
