import React from "react";
import { Helmet } from "react-helmet-async";
// @mui
import { Button, Container, Stack, Typography } from "@mui/material";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import Iconify from "../components/iconify";
import useAuth from "../hooks/useAuth";
import CreateAudit from "../sections/@dashboard/audit/CreateAudit";
import AuditView from "../sections/@dashboard/audit/AuditView";
// components

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AuditPage() {
  const { isAdmin } = useAuth();
  let location = useLocation();
  return (
    <>
      <Helmet>
        <title> Digit QM | Audit </title>
      </Helmet>

      <Container sx={{ minHeight: "100%" }} maxWidth="">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <div>
            <Typography variant="h4" gutterBottom>
              Audit
            </Typography>
            {location?.pathname !== "/dashboard/audit" && (
              <Button
                component={NavLink}
                to={"/dashboard/audit"}
                variant="contained"
                color="warning"
                sx={{ minWidth: 300 }}
                startIcon={<Iconify icon="mdi:arrow-left" />}
              >
                MENÜ
              </Button>
            )}
          </div>
        </Stack>
        <Routes>
          <Route index element={<AuditMainMenu isAdmin={isAdmin} />} />
          <Route path="view/*" element={<AuditView />} />
          {isAdmin && <Route path="create" element={<CreateAudit />} />}
        </Routes>
      </Container>
    </>
  );
}

function AuditMainMenu({ isAdmin }) {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{ minHeight: "100%", py: 3 }}
    >
      <Button
        component={NavLink}
        to={`view/${new Date().getFullYear()}`}
        variant="contained"
        sx={{ fontSize: "120%", minWidth: 300, my: 3 }}
        startIcon={<Iconify icon="gala:menu-left" />}
      >
        Aktuelle Checkliste anzeigen
      </Button>
      <Button
        component={NavLink}
        to={"view"}
        variant="contained"
        sx={{ fontSize: "120%", minWidth: 300, my: 3 }}
        startIcon={<Iconify icon="gala:menu-left" />}
      >
        Audit auswählen
      </Button>
      {isAdmin && (
        <Button
          component={NavLink}
          to={"create"}
          variant="contained"
          sx={{ fontSize: "120%", minWidth: 300, my: 3 }}
          startIcon={<Iconify icon="mdi:folders-outline" />}
        >
          Audit anlegen
        </Button>
      )}
    </Stack>
  );
}
