import React from "react";
import { Grid, Typography, Box, Tooltip, Button } from "@mui/material";
import TextField from "../../../../components/forms/TextField";
import DEDateField from "../../../../components/forms/DEDateField";
import Iconify from "../../../../components/iconify";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../../features/dialog/dialogSlice";

export default function ControlSection({
  kontrolleFields,
  control,
  selectedItem,
  handleRiskManagement,
}) {
  const dispatch = useDispatch();

  const handleOpenControlDialog = (controlID) => {
    dispatch(
      setDialog({
        content: "UpdateControlDialog",
        data: {
          entryID: selectedItem?._id,
          controlID,
          status: selectedItem?.status,
        },
      })
    );
  };

  const handleDeleteControl = (entryId, controlID) => {
    dispatch(
      setDialog({
        content: "DeleteForm",
        data: { entryId, controlID },
        art: "DELETEFBRCONTROL",
      })
    );
  };

  return (
    <>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "20px",
                fontWeight: "bold",
                flex: 1,
                m: 1,
              }}
              id="customized-dialog-title"
            >
              Ergebnis der Kontrolle
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <Typography variant="body1" sx={{ maxWidth: 300 }}>
                  <strong>Hinweise:</strong>
                  <ul style={{ paddingLeft: 20 }}>
                    <li>
                      Sie können die Kontrolle erst als (nicht) erledigt
                      kennzeichnen, wenn das Kontrolldatum erreicht ist oder
                      drüber ist!
                    </li>
                    <li>
                      Keiner kann den Kontrollnamen oder das Datum ändern!
                    </li>
                    <li>
                      Der Ersteller des Eintrags bekommt eine Benachrichtigung,
                      um die Kontrolle durchzuführen!
                    </li>
                  </ul>
                </Typography>
              }
            >
              <Iconify
                icon="clarity:help-solid"
                style={{ fontSize: "50px", color: "action.active" }}
                sx={{
                  transition: "transform 0.1s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      {kontrolleFields?.map((kontrolle, index) => (
        <React.Fragment key={kontrolle.id}>
          <Grid container spacing={1} alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={6} sm={2.75}>
              <DEDateField
                control={control}
                name={`kontrolle[${index}].date`}
                label={`Datum Kontrolle ${index + 1}`}
                fullWidth
                disabled={selectedItem?.incidentToRisk}
              />
            </Grid>
            <Grid item xs={7}>
              {kontrolle.succeed == null ? (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: "12px",
                      color: "black",
                      borderColor: "black",
                      textTransform: "none",
                      transition: "transform 0.1s",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                      ...(new Date(kontrolle.date) > new Date() ||
                      selectedItem?.incidentToRisk
                        ? {
                            backgroundColor: (theme) =>
                              theme.palette.action.disabledBackground,
                            opacity: (theme) =>
                              theme.palette.action.disabledOpacity,
                          }
                        : {}),
                    }}
                    disabled={
                      selectedItem?.incidentToRisk ||
                      new Date(kontrolle.date) >= new Date()
                    }
                    onClick={() => handleOpenControlDialog(kontrolle?._id)}
                  >
                    <Iconify
                      icon="ant-design:control-outlined"
                      sx={{
                        height: "30px",
                        width: "30px",
                        color: "black",
                      }}
                    />
                    Kontrolle jetzt durchführen
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: "12px",
                      color: "black",
                      borderColor: "black",
                      transition: "transform 0.1s",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                      ...(new Date(kontrolle.date) > new Date() ||
                      selectedItem?.incidentToRisk
                        ? {
                            backgroundColor: (theme) =>
                              theme.palette.action.disabledBackground,
                            opacity: (theme) =>
                              theme.palette.action.disabledOpacity,
                          }
                        : {}),
                    }}
                    disabled={
                      selectedItem?.incidentToRisk ||
                      new Date(kontrolle.date) >= new Date()
                    }
                    onClick={handleRiskManagement}
                  >
                    <Iconify
                      icon="mdi:alert"
                      sx={{
                        height: "30px",
                        width: "30px",
                        color: "warning.main",
                      }}
                    />
                    Ins Risiko-management
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      color: "white",
                      backgroundColor: "red",
                      borderColor: "red",
                      transition: "transform 0.1s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor: "darkred",
                      },
                      ...(selectedItem?.incidentToRisk
                        ? {
                            backgroundColor: (theme) =>
                              theme.palette.action.disabledBackground,
                            opacity: (theme) =>
                              theme.palette.action.disabledOpacity,
                          }
                        : {}),
                    }}
                    disabled={selectedItem?.incidentToRisk}
                    onClick={() =>
                      handleDeleteControl(selectedItem?._id, kontrolle?._id)
                    }
                  >
                    <Iconify
                      icon="mdi:delete"
                      sx={{
                        height: "25px",
                        width: "25px",
                        color: "white", // Iconfarbe auf weiß setzen
                      }}
                    />
                  </Button>
                </Box>
              ) : (
                <Grid item xs={5}>
                  {kontrolle.succeed ? (
                    <Typography
                      sx={{ fontWeight: "bold", textAlign: "left", marginY: 1 }}
                    >
                      <Iconify
                        icon="mdi:done-outline"
                        sx={{
                          height: "1em",
                          width: "1em",
                          color: "green",
                          mr: "20px",
                        }}
                      />
                      Erfolgreich
                    </Typography>
                  ) : (
                    <Typography
                      sx={{ fontWeight: "bold", textAlign: "left", marginY: 1 }}
                    >
                      <Iconify
                        icon="mdi:close-outline"
                        sx={{
                          height: "1em",
                          width: "1em",
                          color: "red",
                          mr: "20px",
                        }}
                      />
                      Nicht Erfolgreich
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
}
