import { useGetOfficesQuery } from "../features/offices/officesApiSlice";

const useOffices = () => {
  const { data: offices } = useGetOfficesQuery();

  let hasOffices = false;

  if (offices && offices.length > 0) {
    hasOffices = true;
  }

  return { hasOffices };
};
export default useOffices;
