import React from "react";
import PropTypes from "prop-types";
import OfficeForm from "../forms/Forms/OfficeForm";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  useAddNewOfficeMutation,
  useUpdateOfficeMutation,
} from "../../features/offices/officesApiSlice";
import { useDispatch } from "react-redux";
import { setDialog } from "../../features/dialog/dialogSlice";
import ErrorAlert from "../alerts/ErrorAlert";

const defaultOffice = {
  mail: "",
  name: "",
  contactPerson: "",
  address: "",
  zip: "",
  city: "",
  phone: "",
  comment: "",
  homepage: "",
  officeName: "",
};

function OfficeFormDialog({ dialog, officeData, handleClose }) {
  console.log("dialog :>> ", dialog);
  const { type } = dialog;
  const { handleSubmit, control, setError } = useForm({
    defaultValues: officeData ?? defaultOffice,
  });

  const [addNewOffice, { isError }] = useAddNewOfficeMutation();

  const [updateOffice, { isError: isEditError }] = useUpdateOfficeMutation();

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    if (type === "CREATE") {
      try {
        let result = await addNewOffice({ ...data }).unwrap();
        console.log("result :>> ", result);

        if (result?.message === "New office created") {
          dispatch(
            setDialog({
              title: "Neue Praxis wurde erfolgreich erstellt!",
              type: "SUCCESS",
              data: {
                successText: "Neue Praxis wurde erfolgreich erstellt!",
              },
            })
          );
        }
      } catch (error) {
        if (error?.status === 409) {
          // Officename already in use
          setError("officeName", {
            type: "custom",
            message: "Dieser Praxisname ist bereits vergeben.",
          });
        }
        console.log("error :>> ", error);
      }
    }

    if (type === "EDIT") {
      let updateOfficeData = { ...data, _id: officeData._id };
      //   delete updateOfficeData?._id;
      try {
        let result = await updateOffice(updateOfficeData).unwrap();
        if (result?.type === "success") {
          dispatch(
            setDialog({
              title: data.officeName + " wurde erfolgreich geändert!",
              type: "SUCCESS",
              data: {
                successText: data.officeName + " wurde erfolgreich geändert!",
              },
            })
          );
        }
      } catch (error) {
        console.log("error :>> ", error);
        if (error?.status === 409) {
          // Officename already in use
          setError("officeName", {
            type: "custom",
            message: "Dieser Praxisname wird bereits benutzt.",
          });
        }
      }
    }
  };

  return (
    <div>
      <Dialog open maxWidth="lg">
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <DialogTitle>{dialog?.title ?? ""}</DialogTitle>
          <DialogContent>
            <OfficeForm control={control} />
            {isError && (
              <div style={{ marginTop: 15 }}>
                <ErrorAlert errorMessage="Fehler beim erstellen des Standorts" />
              </div>
            )}
            {isEditError && (
              <ErrorAlert errorMessage="Fehler beim updaten des Standorts" />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Beenden
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              autoFocus
            >
              {type === "CREATE" ? "NEUE PRAXIS ERSTELLEN" : "SPEICHERN"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

OfficeFormDialog.propTypes = {
  dialog: PropTypes.object,
  handleClose: PropTypes.func,
};

export default OfficeFormDialog;
