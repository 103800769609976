import React, { useRef } from "react";
import PropTypes from "prop-types";
import { SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";
import { L10n } from "@syncfusion/ej2-base";
import { downloadFileByID } from "../../../../app/api/axios/services/files.service";
import germanLocale from "./de.json";

// Load the German locale
L10n.load(germanLocale);

// For later use:
// https://hub.docker.com/r/syncfusion/spreadsheet-server
// Server to local run syncfusion service

function SpreadsheetEditor({ data }) {
  const { fileData, action } = data;
  const { _id, fileName } = fileData;
  const spreadsheetRef = useRef(null);
  let contentChanged = false;

  // Use this to know if user is allowed to edit or not
  console.log("action :>> ", action);

  const loadDocument = async () => {
    const file = await downloadFileByID(_id); // Download file with id
    // Convert to blob
    const blob = new Blob([file.data], { type: file?.headers["content-type"] }); // Adjust MIME type as per your file type

    var f = new File([blob], fileName, {
      type: file?.headers["content-type"],
    });

    if (spreadsheetRef.current) {
      spreadsheetRef.current.open({ file: f });
    }
  };

  const renderComplete = () => {
    window.onbeforeunload = function () {
      return "Want to save your changes?";
    };

    loadDocument();

    setInterval(() => {
      if (contentChanged) {
        //You can save the document as below
        spreadsheetRef.current.documentEditor // EDIT HERE!
          .saveAsBlob("Xlsx")
          .then((blob) => {
            let exportedDocument = blob;
            //Now, save the document where ever you want.
            console.log("AUTOSAVE");
          });
        contentChanged = false;
      }
    }, 10000);
    // SAVES DOC EVERY 10 SECS
    spreadsheetRef.current.contentChange = () => {
      contentChanged = true;
    };
  };

  React.useEffect(() => {
    renderComplete();
  });

  return (
    <div style={{ height: "100%", overflow: "scroll" }}>
      <SpreadsheetComponent
        id="spreadsheetRef"
        locale="de"
        style={{ display: "block" }}
        height="100%"
        ref={spreadsheetRef}
        allowOpen={true}
        openUrl="https://services.syncfusion.com/react/production/api/spreadsheet/open"
        // isProtected={true} // Seems not to work
      />
    </div>
  );
}

SpreadsheetEditor.propTypes = {
  data: PropTypes.shape({
    fileData: PropTypes.object.isRequired,
    action: PropTypes.string,
  }).isRequired,
};

export default SpreadsheetEditor;
