import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import TextField from "../../TextField";
import ControlledRadioButton from "../../ControlledRadioButton";

function BillingForm({ control }) {
  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          Kontoinhaber
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          name="firstName"
          label="Vorname"
          required={true}
          fullWidth
          errorMsg="Bitte angeben"
          control={control}
          helperText="Bitte einen Vornamen für den Zahlenden angeben."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          name="lastName"
          label="Nachname"
          required={true}
          fullWidth
          errorMsg="Bitte angeben"
          control={control}
          helperText="Bitte einen Nachname für den Zahlenden angeben."
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="address"
          label="Straße, Hausnummer"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          name="zip"
          label="PLZ"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          name="city"
          label="Stadt"
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          Bankdaten
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="iban"
          label="IBAN"
          required={true}
          fullWidth
          errorMsg="Bitte angeben"
          control={control}
          helperText="Bitte eine gültige IBAN angeben."
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="bic"
          label="BIC"
          fullWidth
          errorMsg="Bitte angeben"
          control={control}
        />
      </Grid>
      <Grid item xs={8}>
        <ControlledRadioButton
          name="billingCycle"
          label="Zahlungsweise"
          required={true}
          errorMsg="Bitte angeben"
          control={control}
          itemArray={[
            { label: "Monatlich", value: "monthly" },
            { label: "Jährlich", value: "annually" },
          ]}
          helperText="in welchem Turnus soll der Betrag abgebucht werden?"
        />
      </Grid>
    </Grid>
  );
}

BillingForm.propTypes = { props: PropTypes.object };

export default BillingForm;
