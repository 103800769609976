export const surveySelectItems = [
  {
    label: "Punkte 1-10",
    value: "points",
    icon: "icon",
  },
  {
    label: "Stimme zu/nicht zu",
    value: "opinion",
    icon: "icon",
  },
  {
    label: "Smileys",
    value: "smiley",
    icon: "icon",
  },
  {
    label: "Offene Frage",
    value: "openQuestion",
    icon: "icon",
  },
  // Wenn Benutzer dies auswählt, müssen Felder für Eintragung von Antwortmöglichkeiten gerendert werden
  // {
  //   label: "Multiple Choice",
  //   value: "multipleChoice",
  //   icon: "icon",
  // },
  // {
  //   label: "Single Choice",
  //   value: "singleQuestion",
  //   icon: "icon",
  // },
];

export const getSurveyOptionFromValue = (value) => {
  return surveySelectItems.find((s) => s.value === value);
};
