// @mui
import PropTypes from "prop-types";
import {
  Box,
  Stack,
  Link,
  Card,
  Button,
  Divider,
  Typography,
  CardHeader,
  styled,
} from "@mui/material";
// utils
import { fToNow } from "../../../utils/formatTime";
// components
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

const ScrollableBox = styled(Box)(({ theme }) => ({
  maxHeight: 340, // Max-Höhe für die Liste
  overflowY: "auto", // Scrollbar aktivieren
  paddingRight: theme.spacing(1),
  "&::-webkit-scrollbar": {
    display: "none", // Scrollbar in WebKit-Browsern (Chrome, Safari) ausblenden
  },
  "-ms-overflow-style": "none", // Scrollbar in Internet Explorer und Edge ausblenden
  "scrollbar-width": "none", // Scrollbar in Firefox ausblenden
}));

export default function AppNewsUpdate({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <ScrollableBox>
        <Stack
          spacing={3}
          sx={{
            p: 3,
            pr: 0,
            // Ensuring the Stack does not exceed the ScrollableBox height
            minHeight: 0, // Ensures that the Stack respects the max height of the ScrollableBox
          }}
        >
          {list && list.length > 0 ? (
            list.map((news) => <NewsItem key={news._id} news={news} />)
          ) : (
            <Typography variant="body2" color="text.secondary">
              No news available.
            </Typography>
          )}
        </Stack>
      </ScrollableBox>

      <Divider />

      {/* <Box sx={{ p: 2, textAlign: "right" }}>
        <Button
          size="small"
          color="inherit"
          endIcon={<Iconify icon={"eva:arrow-ios-forward-fill"} />}
        >
          View all
        </Button>
      </Box> */}
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  const navigate = useNavigate();
  const { imagePath, title, description, postedAt, path } = news;

  const getImageSrc = () => {
    if (imagePath === "1") {
      return require("../news/NewsDialogComponents/1.jpg");
    } else if (imagePath === "2") {
      return require("../news/NewsDialogComponents/2.jpg");
    } else if (imagePath === "3") {
      return require("../news/NewsDialogComponents/3.jpg");
    } else if (imagePath === "/assets/images/gesetz.webp") {
      return "/assets/images/gesetz.webp";
    } else {
      return "/assets/images/praxis.jpg";
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      onClick={() => navigate(path)}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          borderRadius: 1,
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Box
        component="img"
        alt={title}
        src={getImageSrc()}
        sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
      />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link color="inherit" variant="subtitle2" underline="hover" noWrap>
          {title}
        </Link>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      </Box>

      <Typography
        variant="caption"
        sx={{ pr: 3, flexShrink: 0, color: "text.secondary" }}
      >
        {fToNow(postedAt)}
      </Typography>
    </Stack>
  );
}
