import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import Scrollbar from "../../../../components/scrollbar/Scrollbar";
import MultiSelectUsersController from "../../../../components/forms/MultiSelectUsersController";

import { Icon } from "@iconify/react";
import { FILTER_STATUS_OPTIONS } from "./fileDataTableUtils";
import { FormProvider, useForm } from "react-hook-form";

FileDataTableFilterSidebar.propTypes = {
  openFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
};

export default function FileDataTableFilterSidebar({
  openFilter,
  onOpenFilter,
  onCloseFilter,
  activeFilters,
}) {
  // Initialize a single state object for all filters
  const [filters, setFilters] = useState({
    ...activeFilters,
  });
  const [filtersActive, setFiltersActive] = useState(false);

  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      responsibleUser: [],
    },
  });

  const handleFiltersActive = (active) => {
    setFiltersActive(active);
  };

  // General handler for checkbox changes
  const handleCheckboxChange = (event, type) => {
    const { value, checked } = event.target;
    setFilters((prev) => ({
      ...prev,
      [type]: checked
        ? [...prev[type], value]
        : prev[type].filter((option) => option !== value),
    }));
  };

  // Handler to clear all filters
  const handleClearAll = () => {
    reset();
    let defaultFilters = {
      status: ["new", "edit", "revision", "released"],
      responsibleUser: [],
    };
    setFilters(defaultFilters);
    handleFiltersActive(false);
    onCloseFilter(defaultFilters);
  };

  const onSubmit = (data) => {
    setFilters((prev) => ({
      ...prev,
      responsibleUser: data.responsibleUser,
    }));
    onCloseFilter({ ...filters, responsibleUser: data.responsibleUser });
  };

  const onClose = (setFilters) => {
    if (setFilters) {
      handleSubmit(onSubmit)();
      handleFiltersActive(true);
    } else {
      onCloseFilter(false);
    }
  };

  return (
    <>
      <Button
        disableRipple
        color={filtersActive ? "warning" : "inherit"}
        variant={filtersActive ? "contained" : "outlined"}
        sx={{ ml: 1 }}
        startIcon={<Icon icon="mdi:filter-cog" />}
        onClick={onOpenFilter}
      >
        FILTER&nbsp;
      </Button>
      {filtersActive && (
        <Tooltip title="Filter löschen">
          <IconButton sx={{ mx: 1 }} color="error" onClick={handleClearAll}>
            <Icon icon="mdi:filter-remove-outline" />
          </IconButton>
        </Tooltip>
      )}

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={() => onClose(false)}
        PaperProps={{
          sx: { width: 280, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            FILTER
          </Typography>
          <IconButton onClick={() => onClose(false)}>
            <Icon icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <FormProvider {...{ handleSubmit, control, watch }}>
            <Stack spacing={3} sx={{ p: 3 }}>
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Dokumente mit Status anzeigen:
                </Typography>
                <FormGroup>
                  {FILTER_STATUS_OPTIONS.map((item) => (
                    <FormControlLabel
                      key={item.value}
                      control={
                        <Checkbox
                          value={item.value}
                          checked={filters.status.includes(item.value)}
                          onChange={(event) =>
                            handleCheckboxChange(event, "status")
                          }
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </FormGroup>
              </div>

              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Verantwortlich:
                </Typography>
                <MultiSelectUsersController
                  control={control}
                  name="responsibleUser"
                  label="Mitarbeiter"
                  fullWidth
                />
              </div>
            </Stack>
          </FormProvider>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            sx={{ mb: 1 }}
            startIcon={<Icon icon="ic:round-clear-all" />}
            onClick={() => onClose(true)}
          >
            FILTER ANWENDEN
          </Button>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            startIcon={<Icon icon="ic:round-clear-all" />}
            onClick={handleClearAll}
          >
            FILTER LÖSCHEN
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
