import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

// Define the ControlledToggleButtonGroup component
const ControlledToggleButtonGroup = ({ control, name, options, fullWidth }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: "Bitte auswählen" }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div>
          <ToggleButtonGroup
            color="primary"
            value={value}
            exclusive
            fullWidth={fullWidth}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                onChange(newValue); // Update the value if it's not null
              }
            }}
            aria-label={name}
            sx={{
              "& .MuiToggleButton-root": {
                "&.Mui-selected": {
                  bgcolor:
                    name === "succeed"
                      ? value === "true"
                        ? "success.main"
                        : value === "false"
                        ? "error.main"
                        : "primary.main"
                      : "primary.main",
                  color: "white",
                },
              },
            }}
          >
            {options.map((option) => (
              <ToggleButton
                key={option.value}
                value={option.value}
                aria-label={option.label}
                sx={{
                  color: error ? "red" : "primary",
                }}
              >
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {error && (
            <div>
              <p style={{ color: "red", textAlign: "center" }}>
                {error.message}
              </p>
            </div>
          )}
        </div>
      )}
    />
  );
};

// PropType validation
ControlledToggleButtonGroup.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
    })
  ).isRequired,
  fullWidth: PropTypes.bool,
};

ControlledToggleButtonGroup.defaultProps = {
  fullWidth: false,
};

export default ControlledToggleButtonGroup;
