export const settingsNavList = [
  {
    title: "Unternehmen",
    path: "/dashboard/settings/tenant",
    icon: "mdi:company",
    role: "Admin",
  },
  {
    title: "Module",
    path: "/dashboard/settings/modules",
    icon: "mdi:extension",
    role: "Admin",
  },
  {
    title: "Abrechnung",
    path: "/dashboard/settings/payment",
    icon: "material-symbols:payments",
    role: "Admin",
  },
  {
    title: "Benachrichtigungen",
    path: "/dashboard/settings/notifications",
    icon: "mdi:notifications",
  },
  {
    title: "Passwort ändern",
    path: "/dashboard/settings/password",
    icon: "mdi:password",
  },
  {
    title: "FAQs",
    path: "/dashboard/settings/faq",
    icon: "line-md:question",
  },
];
