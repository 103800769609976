import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  styled,
} from "@mui/material";
import NewsForm from "./NewsForm";
import VisibilityForm from "./VisibilityForm";
import Iconify from "../../../../components/iconify";
import { FormProvider, useForm } from "react-hook-form";
import {
  useAddNewNewsMutation,
  useUpdateNewsMutation,
} from "../../../../features/news/newsApiSlice";
import NewsDialogStep2 from "./NewsDialogStep2";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../../../app/api/apiSlice";
import { uploadNewsImageAxios } from "../../../../app/api/axios/services/files.service";
import { uploadNewsDocumentAxios } from "../../../../app/api/axios/services/files.service"; // Neue Methode für Dokument-Upload
import { serverURL } from "../../../../app/api/axios/http.service";

const steps = ["Inhalt eingeben", "Bild hochladen", "Zielgruppe auswählen"];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function NewsDialog({ handleClose, newsFlag, newsDetails }) {
  const dispatch = useDispatch();
  const [addNewNews] = useAddNewNewsMutation();
  const [updateNews] = useUpdateNewsMutation();
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [fileDocument, setFileDocument] = useState(null); // State für Dokumente
  const [fileDocumentPreview, setFileDocumentPreview] = useState(null); // Vorschau für Dokumente

  const methods = useForm({
    defaultValues: {
      title: newsDetails?.title || "",
      subtitle: newsDetails?.subtitle || "",
      content: newsDetails?.content || "",
      status: newsDetails?.status || "",
      visibilityScope: newsDetails?.visibilityScope || "office",
      selectedImageIndex: newsDetails?.imageDefaultPath || 0,
      imageDefaultPath: newsDetails?.imageDefaultPath || "1",
      imagePath: newsDetails?.imagePath || "",
      url: newsDetails?.url || "",
      documentPath: newsDetails?.documentPath || "",
      useCustomImage: newsDetails?.imagePath ? true : false,
      useCustomDocument: newsDetails?.documentPath ? true : false, // Status für benutzerdefiniertes Dokument
      linkDocument: false,
      favorite: newsDetails?.favorite || false,
    },
  });

  const { handleSubmit, trigger, control, setValue, watch } = methods;

  const useCustomImage = watch("useCustomImage");
  const linkDocument = watch("linkDocument");
  const useCustomDocument = watch("useCustomDocument");

  // Set filePreview if an existing image path is available
  useEffect(() => {
    if (newsDetails?.imagePath) {
      const imageUrl = `${serverURL()}/public/new/image/${newsDetails._id}`;
      setFilePreview(imageUrl);
      setFile(imageUrl); // Only set file as imageUrl if file input is empty
    }
    if (newsDetails?.documentPath) {
      const documentPath = `${serverURL()}/public/new/document/${
        newsDetails._id
      }`;
      setFileDocumentPreview(documentPath);
      setFileDocument(documentPath);
    }
  }, [newsDetails]);

  const handleNext = async () => {
    const isValid = await trigger();
    if (isValid) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const onSubmit = async (data, status) => {
    try {
      if (newsDetails) {
        await updateNews({
          id: newsDetails._id,
          ...data,
          status,
          useCustomImage,
          useCustomDocument,
        });
        if (file !== filePreview) {
          if (useCustomImage === true) {
            await uploadNewsImageAxios(newsDetails._id, file);
          }
        }
        if (fileDocument !== fileDocumentPreview) {
          if (useCustomDocument) {
            await uploadNewsDocumentAxios(newsDetails._id, fileDocument); // Dokument hochladen
          }
        }
        handleClose("REFETCH");
        window.location.reload();
      } else {
        const result = await addNewNews({ ...data, status });
        const newsId = result.data.newsId;
        if (file) await uploadNewsImageAxios(newsId, file);
        if (fileDocument) await uploadNewsDocumentAxios(newsId, fileDocument); // Dokument hochladen
        handleClose("REFETCH");
      }
    } catch (error) {
      console.error("Error processing news: ", error);
    }
  };

  const renderButtons = () => {
    if (activeStep === steps.length - 1) {
      if (newsFlag === "EDIT") {
        if (newsDetails?.status === "draft") {
          return (
            <>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                }}
                onClick={() =>
                  handleSubmit((data) => onSubmit(data, "draft"))()
                }
              >
                Aktualisieren
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: "none",
                }}
                onClick={() => {
                  handleSubmit((data) => onSubmit(data, "publish"))();
                  dispatch(apiSlice.util.resetApiState());
                }}
              >
                Veröffentlichen
              </Button>
            </>
          );
        }
        return (
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
            }}
            onClick={() => handleSubmit((data) => onSubmit(data, "publish"))()}
          >
            Aktualisieren
          </Button>
        );
      }
      return (
        <>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
            }}
            onClick={() => handleSubmit((data) => onSubmit(data, "draft"))()}
          >
            Speichern
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              textTransform: "none",
            }}
            onClick={() => handleSubmit((data) => onSubmit(data, "publish"))()}
          >
            Veröffentlichen
          </Button>
        </>
      );
    }
    return (
      <Button
        variant="contained"
        sx={{
          textTransform: "none",
        }}
        onClick={handleNext}
      >
        Weiter
      </Button>
    );
  };

  return (
    <FormProvider {...methods}>
      <BootstrapDialog open>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography
            id="customized-dialog-title"
            sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
          >
            {newsFlag === "EDIT"
              ? "Beitrag bearbeiten"
              : "Neuen Beitrag erstellen"}
          </Typography>
          <Box sx={{ flex: "0 0 auto" }}>
            <Iconify
              onClick={handleClose}
              icon="mingcute:close-line"
              style={{ fontSize: "50px", color: "action.active" }}
              sx={{
                transition: "transform 0.1s",
                "&:hover": {
                  transform: "scale(1.8)",
                },
              }}
            />
          </Box>
        </Box>

        <DialogContent dividers>
          <Stepper activeStep={activeStep} sx={{ pb: 2 }}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* Step 1: Title, Subtitle, and Content */}
          {activeStep === 0 && <NewsForm />}

          {/* Step 2: Image and Document Upload */}
          {activeStep === 1 && (
            <NewsDialogStep2
              control={control}
              setValue={setValue}
              file={file}
              setFile={setFile}
              filePreview={filePreview}
              setFilePreview={setFilePreview}
              fileDocument={fileDocument}
              setFileDocument={setFileDocument}
              fileDocumentPreview={fileDocumentPreview}
              setFileDocumentPreview={setFileDocumentPreview}
              useCustomImage={useCustomImage}
              useCustomDocument={useCustomDocument}
              linkDocument={linkDocument}
            />
          )}

          {/* Step 3: Select Audience */}
          {activeStep === 2 && <VisibilityForm control={control} />}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleBack}
            sx={{
              textTransform: "none",
            }}
            disabled={activeStep === 0}
          >
            Zurück
          </Button>
          {renderButtons()}
        </DialogActions>
      </BootstrapDialog>
    </FormProvider>
  );
}

NewsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  newsFlag: PropTypes.string.isRequired,
  newsDetails: PropTypes.object,
};
