import React, { useState } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import ChatList from "../sections/@dashboard/chats/ChatList";
import ChatWindow from "../sections/@dashboard/chats/ChatWindow";

const ChatPage = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  // Tracks whether to show the chat window on mobile
  const [showChatWindow, setShowChatWindow] = useState(false);
  // Detect if screen size is small
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
    setShowChatWindow(true);
  };

  const handleBackToChatList = () => {
    setShowChatWindow(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "80vh",
        overflow: "hidden",
        bgcolor: "background.default",
      }}
    >
      <Grid container sx={{ height: "100%", overflow: "hidden" }}>
        {!isMobile || !showChatWindow ? (
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              flexDirection: "column",
              bgcolor: "background.paper",
              borderRight: "1px solid #e0e0e0",
              display: isMobile && showChatWindow ? "none" : "flex",
              overflow: "hidden",
            }}
          >
            <ChatList onSelectChat={handleSelectChat} />
          </Grid>
        ) : null}

        {(!isMobile || showChatWindow) && (
          <Grid
            item
            xs={12}
            sm={9}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflowY: "auto",
              position: isMobile ? "absolute" : "static",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: isMobile ? 1 : "auto",
            }}
          >
            {selectedChat ? (
              <ChatWindow chat={selectedChat} onBack={handleBackToChatList} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexGrow: 1,
                }}
              >
                <Typography variant="h6" color="textSecondary">
                  Wähle einen Chat, um Nachrichten anzuzeigen
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ChatPage;
