import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import Button from "@mui/material/Button";
import {
  styled,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import EventForm from "./EventForm";
import { useAddNewEventMutation } from "../../../features/event/eventApiSlice";
import Iconify from "../../../components/iconify";
import dayjs from "dayjs";
import MySnackbar from "./MySnackbar";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

CreateEventDialog.propTypes = {
  eventDetails: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

export default function CreateEventDialog({ handleClose }) {
  const [addNewEvent] = useAddNewEventMutation();

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      creator: "64f058c0675b3293e1df8fe9",
      title: "",
      description: "",
      location: "",
      allDay: false,
      start: dayjs().format("YYYY-MM-DDTHH:mm"),
      end: dayjs().format("YYYY-MM-DDTHH:mm"),
      user: [],
      color: "#89cff0",
    },
  });
  console.log("bla");
  const currentColor = watch("color");

  const onSubmit = async (data) => {
    // console.log('data.start :>> ', data.start);
    // console.log('data.end :>> ', data.end);
    // if (data.start >= data.end) {
    console.log("data :>> ", data);
    try {
      let result = await addNewEvent({
        ...data,
      });

      handleClose("REFETCH");
      console.log("result :>> ", result);
    } catch (error) {
      console.log("error :>> ", error);
    }
    // } else {
    //   console.log("Error :>> ");
    //   setSnackbarOpen(true);
    // }
  };

  return (
    <div>
      <FormProvider {...{ handleSubmit, control, watch }}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
            }}
          >
            <Typography
              sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
              id="customized-dialog-title"
            >
              Neues Event erstellen
            </Typography>
            <Box sx={{ flex: "0 0 auto" }}>
              <Iconify
                onClick={handleClose}
                icon="mingcute:close-line"
                style={{ fontSize: "50px", color: "action.active" }}
              />
            </Box>
          </Box>

          <DialogContent dividers>
            <EventForm control={control} />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ background: "#9E9E9E" }}
              variant="contained"
              onClick={handleClose}
            >
              Abbrechen
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              style={{ background: currentColor }}
            >
              Erstellen
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </FormProvider>

      <MySnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="Enddatum ist älter als das Anfangsdatum!"
      />
    </div>
  );
}
