import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

export default function RedirectDialog({ dialog, handleClose }) {
  console.log("dialog :>> ", dialog);
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(dialog?.redirectTo ?? "/");
    handleClose();
  };

  return (
    <Dialog
      open
      // TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose()} // Close when click oustside the dialog
      // maxWidth="lg"
      // fullWidth
      aria-describedby="dialog-success"
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {dialog?.title}
          <Button onClick={() => handleClose()} color="inherit">
            <Icon width="40" icon="icon-park-solid:close-one" />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ textAlign: "center" }}>
          <Icon
            icon="icon-park-twotone:info"
            color="turquoise"
            width={dialog?.data?.iconSize || "10vh"}
            height={dialog?.data?.iconSize || "10vh"}
            style={{ margin: 25 }}
          />
          <Typography
            variant="h5"
            id="success-dialog-description"
            align="justify"
          >
            {dialog?.text}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => handleRedirect()}
        >
          {dialog?.redirectButtonText ?? "WEITERLEITEN"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
