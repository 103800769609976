import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";
import { getComparator, stableSort } from "./fileDataTableUtils";
import FileDataTableToolbar from "./FileDataTableToolbar";
import FileDataTableHead from "./FileDataTableHead";
import FileDataTableRow from "./FileDataTableRow";
import { downloadFileByID } from "../../../../app/api/axios/services/files.service";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../../../features/auth/authSlice";
import useAuth from "../../../../hooks/useAuth";
import { Icon } from "@iconify/react";

const FileDataTable = ({
  fileDatas,
  handleOpenFileDialog,
  handleOpenAssignFileDialog,
}) => {
  const [tableState, setTableState] = React.useState({
    filters: {
      status: ["new", "edit", "revision", "released"],
    },
    filterActive: false,
    openFilter: false,
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState(""); // New state for search term
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { isAdmin } = useAuth();

  const userData = useSelector(selectCurrentUserData);

  const handleOpenFilter = () => {
    setTableState((prev) => ({
      ...prev,
      openFilter: true,
    }));
  };

  const handleCloseFilter = (newFilters) => {
    if (newFilters) {
      setTableState((prev) => ({
        ...prev,
        openFilter: false,
        filters: { ...newFilters },
      }));
    } else {
      setTableState((prev) => ({
        ...prev,
        openFilter: false,
      }));
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (!isAdmin) {
      return null;
    }
    if (selected.length > 0) {
      setSelected([]);
      return;
    }

    if (event.target.checked) {
      console.log("true");
      const newSelected = filteredRows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    if (!isAdmin) {
      return null;
    }
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleEditSelected = () => {
    if (!isAdmin) {
      return null;
    }
    if (selected.length === 1) {
      let selectedFileData = fileDatas.filter((f) => selected.includes(f._id));
      handleOpenFileDialog(selectedFileData[0], "SINGLE-FILEDATA");
    } else {
      handleOpenFileDialog(selected, "MULTI-FILEDATA");
    }
  };

  const handleEditFileData = async (event, fileData, action) => {
    event.stopPropagation();
    // console.log("fileData :>> ", fileData);
    if (fileData._id && action === "ASSIGN") {
      handleOpenAssignFileDialog(fileData);
      return;
    }

    if (
      fileData._id &&
      ["SHOW", "EDIT", "REVIEW", "CONFIRMATION", "RELEASE", "DELETE"].includes(
        action
      )
    ) {
      handleOpenFileDialog(fileData, action);
      return;
    }

    if (fileData._id && action === "NACHWEIS") {
      handleOpenFileDialog(fileData, "create-proof");
      return;
    }

    if (action === "DOWNLOAD") {
      // Fetch the file as arraybuffer
      const response = await downloadFileByID(fileData._id);
      const arrayBuffer = response.data;

      // Create a blob from the arraybuffer
      const blob = new Blob([arrayBuffer], {
        type: response.headers["content-type"],
      });

      // Create a link element, set its href to a URL created from the blob, and trigger a download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileData.fileName; // Set the desired file name

      // Trigger a download
      link.click();

      // Clean up the URL object and remove the link
      window.URL.revokeObjectURL(link.href);
    }
  };

  const handleSearch = (value) => {
    if (value) {
      setSearchTerm(value);
      return;
    }
    setSearchTerm("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const searchAndFilterRows = () => {
    let data = fileDatas.filter((file) =>
      file?.fileName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (tableState?.filters) {
      const { filters } = tableState;

      // Status-Filter
      if (filters.status && filters.status.length > 0) {
        data = data.filter((file) => filters.status.includes(file.status));
      }

      // Users-Filter
      if (filters.responsibleUser && filters.responsibleUser.length > 0) {
        data = data.filter((file) =>
          filters.responsibleUser.includes(file.responsibleUser._id)
        ); // assuming file.responsibleUser._id holds the user ID
      }
    }

    return data;
  };

  const filteredRows = searchAndFilterRows();

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, filteredRows, page, rowsPerPage]
  );

  return (
    <Paper>
      <FileDataTableToolbar
        numSelected={selected.length}
        onSearch={handleSearch}
        activeFilters={tableState.filters}
        openFilter={tableState.openFilter}
        handleOpenFilter={handleOpenFilter}
        handleCloseFilter={handleCloseFilter}
        handleEditSelected={handleEditSelected}
      />
      <TableContainer sx={{ maxHeight: 750 }}>
        <Table stickyHeader aria-label="collapsible table">
          <FileDataTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={fileDatas.length}
            isAdmin={isAdmin}
          />
          <TableBody>
            {visibleRows.map((file, index) => {
              const isItemSelected = isSelected(file._id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <FileDataTableRow
                  key={file._id}
                  file={file}
                  handleEditFileData={handleEditFileData}
                  handleClick={handleClick}
                  isItemSelected={isItemSelected}
                  labelId={labelId}
                  userData={userData}
                  isAdmin={isAdmin}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"Zeilen pro Seite:"}
      />
      <div style={{ padding: 5, borderTopWidth: 2, borderTopStyle: "solid" }}>
        <b>Gesamtanzahl: {filteredRows.length}</b>
        <p style={{ marginRight: 5 }}>
          <Icon
            icon="ic:twotone-error"
            color="darkred"
            width={"2vh"}
            height={"2vh"}
          />
          = Dokument wird aktuell bearbeitet und sollte nicht verändert werden!
        </p>
      </div>
    </Paper>
  );
};

FileDataTable.propTypes = {
  fileDatas: PropTypes.array.isRequired,
  handleOpenFileDialog: PropTypes.func,
  handleOpenAssignFileDialog: PropTypes.func,
};

export default FileDataTable;
