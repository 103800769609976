import React from "react";
import { Typography, Container, Paper, Button } from "@mui/material";

import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { setDialog } from "../features/dialog/dialogSlice";
import Iconify from "../components/iconify";
import DisplaySurveys from "../sections/@dashboard/survey/DisplaySurveys";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const SurveyPage = () => {
  const dispatch = useDispatch();

  const openDialog = () => {
    dispatch(
      setDialog({
        title: "Befragung erstellen",
        content: "CREATE-SURVEY",
      })
    );
  };

  return (
    <>
      <Helmet>
        <title> Digit QM | Befragungen </title>
      </Helmet>

      <Container maxWidth={""}>
        <Paper elevation={3} style={{ padding: 20, marginTop: 10 }}>
          <Typography textAlign={"center"} variant="h3" marginBottom={1.5}>
            Mitarbeiter- und Patientenbefragung
          </Typography>

          {/* Information über Fehler und Beschwerden */}
          <div style={{ marginBottom: 10 }}>
            <Typography variant="h6">
              Hier können Sie Fragebögen für Mitarbeiter und Patienten
              erstellen. Diese Fragebögen können dann ausgedruckt werden oder
              via Link oder QR-Code verteilt werden.
            </Typography>
          </div>

          {/* Dialog zum hinzufügen neuer Befragungen */}
          <Button
            onClick={openDialog}
            variant="contained"
            sx={{ minWidth: 300 }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Neue Befragung
          </Button>
        </Paper>
        <DisplaySurveys />
      </Container>
    </>
  );
};

export default SurveyPage;
