import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { ChromePicker } from "react-color";
import {
  Box,
  TextField,
  IconButton,
  Popover,
  Typography,
  Divider,
} from "@mui/material";
import Iconify from "src/components/iconify";

function ControlledColorComponent({ control, name, label }) {
  const [anchorEl, setAnchorEl] = useState(null);

  // Statische Standardfarben
  const standardColors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FFD700",
    "#9400D3",
    "#00CED1",
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color, onChange) => {
    onChange(color);
  };

  const open = Boolean(anchorEl);
  const id = open ? "color-picker-popover" : undefined;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue="#FFFFFF"
      render={({ field: { onChange, value } }) => (
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label={label}
              value={value}
              onClick={handleClick}
              onChange={(e) => handleColorChange(e.target.value, onChange)}
              fullWidth
            />
            <IconButton onClick={handleClick}>
              <Iconify
                icon="mdi:circle"
                style={{ fontSize: 24, color: value }}
              />
            </IconButton>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box sx={{ padding: 2 }}>
              <ChromePicker
                color={value}
                onChange={(color) => handleColorChange(color.hex, onChange)}
              />
              <Divider sx={{ my: 2 }} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {standardColors.map((color, index) => (
                  <IconButton
                    key={index}
                    onClick={() => handleColorChange(color, onChange)}
                    sx={{ padding: 0 }}
                  >
                    <Iconify
                      icon="mdi:circle"
                      style={{ fontSize: 24, color: color }}
                    />
                  </IconButton>
                ))}
              </Box>
            </Box>
          </Popover>
        </Box>
      )}
    />
  );
}

export default ControlledColorComponent;
