import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Iconify from "../iconify";
import { useUpdateSelectedOfficeMutation } from "../../features/users/usersApiSlice";
import { useGetOfficesQuery } from "../../features/offices/officesApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOffice } from "../../features/auth/authSlice";
import { ListItemIcon, ListItemText } from "@mui/material";
import { setDialog } from "../../features/dialog/dialogSlice";

function SelectCurrentOfficeMenu() {
  const dispatch = useDispatch();
  const { data: offices } = useGetOfficesQuery();
  const [changeSelectedOffice, { isLoading, isError }] =
    useUpdateSelectedOfficeMutation();
  const currentOffice = useSelector(selectCurrentOffice);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // TODO: Get current office from user returns id
  const [selectedOffice, setSelectedOffice] = React.useState("");

  useEffect(() => {
    const getNameFromOfficeId = (o, id) => {
      let foundOffice = o.find((o) => o._id === id);
      if (foundOffice) {
        return foundOffice.officeName;
      } else {
        // Currently no office selected
        dispatch(
          setDialog({
            title: "Keine Praxis ausgewählt",
            type: "information",
            content: {
              header: "Praxis auswählen!",
              text: "Aktuell ist keine Praxis ausgewählt. Bitte wählen Sie an der linken Seite des Fensters unter 'PRAXIS:' eine Praxis aus.",
            },
          })
        );
        return "";
      }
    };
    if (offices && offices.length > 1 && currentOffice) {
      setSelectedOffice(getNameFromOfficeId(offices, currentOffice) ?? "");
    }
    if (offices && offices.length === 1) {
      setSelectedOffice(offices[0].officeName);
    }
  }, [offices, currentOffice, dispatch]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectOffice = async (office) => {
    setSelectedOffice(office.officeName);
    //Update selected office
    try {
      let result = await changeSelectedOffice({
        selectedOffice: office._id,
      }).unwrap();
      if (result) {
        console.log("result :>> ", result);
        // Set state too
        handleClose();
      }
    } catch (error) {
      console.log("error :>> ", error);
      handleClose();
    } finally {
      window.location.reload();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="fade-button"
        sx={{ whiteSpace: "normal" }}
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        fullWidth
        variant="outlined"
        // disabled // Disable for user with no rights for changing offices
        startIcon={
          <Iconify icon="fa-solid:clinic-medical" width={24} height={24} />
        }
      >
        Praxis: {selectedOffice}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {offices &&
          offices.map((office) => {
            return (
              <MenuItem
                key={office._id}
                onClick={() => handleSelectOffice(office)}
                sx={{ width: 240 }}
              >
                <ListItemIcon>
                  <Iconify
                    icon="fa-solid:clinic-medical"
                    width={24}
                    height={24}
                  />
                </ListItemIcon>
                <ListItemText>{office.officeName}</ListItemText>
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}

SelectCurrentOfficeMenu.propTypes = {};

export default SelectCurrentOfficeMenu;
