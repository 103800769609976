import React from "react";
import { Grid, Box, Divider, Typography } from "@mui/material";
import NewsItem from "./NewsItem";

const NewsGrid = ({ newsData }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 3,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      {/* Top Divider */}
      <Divider
        sx={{
          borderBottomWidth: 3,
          backgroundColor: "#006666",
          mt: 3,
          mb: 3,
        }}
      />

      <Grid container spacing={2}>
        {newsData && newsData.length > 0 ? (
          newsData.map((news, index) => (
            <Grid item xs={12} md={6} key={news._id}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {/* Divider between items, but skip for the first item */}
                {index > 1 && (
                  <Divider
                    sx={{
                      borderBottomWidth: 3,
                      backgroundColor: "#006666",
                      mb: 3,
                    }}
                  />
                )}
                <NewsItem news={news} />
              </Box>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" align="center" sx={{ mt: 3, mb: 3 }}>
              Keine Beiträge gefunden
            </Typography>
          </Grid>
        )}
      </Grid>

      {/* Bottom Divider */}
      <Divider
        sx={{
          borderBottomWidth: 3,
          backgroundColor: "#006666",
          mt: 3,
          mb: 3,
        }}
      />
    </Box>
  );
};

export default NewsGrid;
