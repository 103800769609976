import React from "react";
import { Typography, Paper, Stack } from "@mui/material";
import { Icon } from "@iconify/react";

const PublicSurveyUploadSuccess = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        maxWidth: "100%",
        margin: "auto",
        padding: 3,
        textAlign: "center",
      }}
    >
      <Stack alignItems="center" spacing={2}>
        <Icon
          icon="icon-park-twotone:success"
          color="lawngreen"
          width={"20vh"}
          height={"20vh"}
          style={{ margin: 25 }}
        />
        <Typography variant="h5" fontWeight="bold">
          Antworten erfolgreich gesendet!
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Vielen Dank für das Ausfüllen der Befragung. Ihre Antworten wurden
          erfolgreich eingetragen.
        </Typography>
        <Typography variant="h6" fontWeight="bold">
          Sie können dieses Fenster nun schließen.
        </Typography>
      </Stack>
    </Paper>
  );
};

export default PublicSurveyUploadSuccess;
