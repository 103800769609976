// component
import { ROLES_LIST } from "../../features/roles/roles.config";
import Iconify from "../iconify/Iconify";

// ----------------------------------------------------------------------

const dev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard/app",

    icon: (
      <Iconify icon="majesticons:home-analytics-line" width={24} height={24} />
    ),
    role: ROLES_LIST.User,
    show: true,
    info: "Bietet eine Übersicht über das Qualitätsmanagement und den Status.",
  },
  {
    title: "Admin Zentrale",
    path: "/dashboard/admin",
    icon: (
      <Iconify
        icon="streamline:online-medical-service-monitor"
        width={24}
        height={24}
      />
    ),
    role: ROLES_LIST.SuperAdmin,
    show: true,
    info: "Gesamtübersicht über alle Praxen.",
  },
  {
    title: "Praxis",
    path: "/dashboard/office",
    icon: <Iconify icon="uil:clinic-medical" width={24} height={24} />,
    role: ROLES_LIST.Admin,
    show: true,
    info: "Hier können Standorte wie Praxen oder Kliniken verwaltet werden.",
  },
  {
    title: "Benutzer",
    path: "/dashboard/user",
    icon: <Iconify icon={"ph:users"} width={24} height={24} />,
    role: ROLES_LIST.Admin,
    show: true,
    info: "Benutzerverwaltung um Benutzer zu erstellen, bearbeiten oder löschen.",
  },
  {
    title: "Aufgaben",
    path: "/dashboard/task",
    icon: <Iconify icon={"carbon:task"} width={24} height={24} />,
    role: ROLES_LIST.User,
    show: true,
    info: "KANBAN-Board zur Verteilung von Aufgaben.",
  },
  {
    title: "Kalender",
    path: "/dashboard/calendar",
    icon: (
      <Iconify icon={"solar:calendar-line-duotone"} width={24} height={24} />
    ),
    role: ROLES_LIST.User,
    show: true,
    info: "Kalender zur Verwaltung von Terminen und Übersicht von Fristen.",
  },
  {
    title: "QM-Dokumente",
    path: "/dashboard/files",
    icon: <Iconify icon={"octicon:file-16"} width={24} height={24} />,
    role: ROLES_LIST.User,
    show: true,
    info: "Verwaltung der QM-Dokumente.",
  },
  // {
  //   title: "Prozesse",
  //   path: "/dashboard/blog",
  //   icon: <Iconify icon={"fluent-mdl2:processing"} width={24} height={24} />,
  //   role: ROLES_LIST.User,
  //   info: "",
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  //   role: ROLES_LIST.User,
  // },
  {
    title: "Fehler/Beschwerden/Risiko",
    path: "/dashboard/entry",
    icon: (
      <Iconify icon={"fluent:error-circle-48-regular"} width={24} height={24} />
    ),
    role: ROLES_LIST.User,
    show: true,
    info: "Fehler- & Beschwerdemanagement",
  },
  {
    title: "Audit",
    path: "/dashboard/audit",
    icon: <Iconify icon={"mdi:magnify"} width={24} height={24} />,
    role: ROLES_LIST.User,
    show: true,
    info: "Hier finden Sie Auditrelevante Informationen, Daten und Dokumente.",
  },
  {
    title: "Neuigkeiten",
    path: "/dashboard/news",
    icon: <Iconify icon={"ion:newspaper-outline"} width={24} height={24} />,
    role: ROLES_LIST.User,
    show: true,
    info: "Hier finden Sie wichtige Informationen.",
  },
  {
    title: "Gesetzestexte",
    path: "/dashboard/laws",
    icon: <Iconify icon={"octicon:law-16"} width={24} height={24} />,
    role: ROLES_LIST.User,
    show: true,
    info: "Hier finden Sie Gesetzestexte.",
  },
  {
    title: "Befragung",
    path: "/dashboard/survey",
    icon: <Iconify icon={"wpf:survey"} width={24} height={24} />,
    role: ROLES_LIST.User,
    show: true,
    info: "Mitarbeiter- und Patientenbefragungen erstellen, bearbeiten und auswerten.",
  },
];

export default navConfig;
