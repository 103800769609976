// import React from "react";
// import {
//   Card,
//   CardContent,
//   Typography,
//   CardMedia,
//   Box,
//   Stack,
//   useMediaQuery,
//   Badge,
//   Tooltip,
// } from "@mui/material";
// import Iconify from "../../../components/iconify";
// import { useNavigate } from "react-router-dom";
// import { useTheme } from "@mui/material/styles";
// import { serverURL } from "../../../app/api/axios/http.service";

// const NewsItem = ({ news, isUnread }) => {
//   console.log("news", news);
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const handleClick = () => {
//     navigate(`/dashboard/news/${news._id}`);
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString("de-DE"); // 'de-DE' gives you dd.mm.yyyy format
//   };

//   const getImageSrc = () => {
//     console.log("news", news);
//     if (news.imagePath) {
//       return `${serverURL()}/files/${news.imagePath}`;
//     }
//     switch (news.imageDefaultPath) {
//       case "1":
//         return require("./NewsDialogComponents/1.jpg");
//       case "2":
//         return require("./NewsDialogComponents/2.jpg");
//       case "3":
//         return require("./NewsDialogComponents/3.jpg");
//       default:
//         return "/assets/images/praxis.jpg";
//     }
//   };

//   return (
//     <Card
//       sx={{
//         display: "flex",
//         flexDirection: isMobile ? "column" : "row",
//         height: isMobile ? "auto" : "150px",
//         backgroundColor: "transparent",
//         boxShadow: "none",
//         border: "1px solid rgba(255, 255, 255, 0.3)",
//         borderRadius: 0,
//         cursor: "pointer",
//         width: "100%",
//         position: "relative",
//       }}
//       onClick={handleClick}
//     >
//       {!news?.viewUserStatus && (
//         <Tooltip title="Ungelesen">
//           <Box
//             sx={{
//               position: "absolute",
//               top: 8,
//               right: 8,
//               width: 12,
//               height: 12,
//               backgroundColor: "#3D9970",
//               borderRadius: "50%",
//             }}
//           />
//         </Tooltip>
//       )}
//       {news.favorite && (
//         <Tooltip title="Favorite">
//           <Iconify
//             icon="mdi:star"
//             width={30} // Größe anpassen
//             height={30} // Größe anpassen
//             sx={{
//               position: "absolute",
//               top: 8,
//               right: 20, // Abstand zum rechten Rand
//               color: "gold", // Farben für den Stern
//             }}
//           />
//         </Tooltip>
//       )}
//       <CardMedia
//         component="img"
//         sx={{
//           width: isMobile ? "100%" : 150,
//           height: isMobile ? "auto" : "100%",
//           borderRadius: 0,
//         }}
//         src={getImageSrc()}
//         alt={news.title}
//       />
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "space-between",
//           width: "100%",
//         }}
//       >
//         <CardContent
//           sx={{
//             flex: "1 0 auto",
//             paddingLeft: 2,
//             paddingTop: 1,
//             overflow: "hidden",
//           }}
//         >
//           <Box sx={{ marginBottom: "auto" }}>
//             <Typography
//               component="div"
//               variant="h6"
//               sx={{
//                 fontSize: "1.1rem",
//                 display: "-webkit-box",
//                 WebkitLineClamp: 2,
//                 WebkitBoxOrient: "vertical",
//                 overflow: "hidden",
//                 textOverflow: "ellipsis",
//                 maxWidth: isMobile ? "100%" : "85%",
//               }}
//             >
//               {news.title}
//             </Typography>

//             <Typography
//               variant="subtitle1"
//               color="text.secondary"
//               component="div"
//               sx={{
//                 display: "-webkit-box",
//                 WebkitLineClamp: 1,
//                 WebkitBoxOrient: "vertical",
//                 overflow: "hidden",
//                 textOverflow: "ellipsis",
//                 maxWidth: isMobile ? "100%" : "85%",
//               }}
//             >
//               {news.subtitle}
//             </Typography>
//           </Box>
//         </CardContent>

//         <Stack
//           direction={isMobile ? "column" : "row"}
//           alignItems={isMobile ? "flex-start" : "center"}
//           justifyContent="space-between"
//           sx={{
//             paddingLeft: 2,
//             paddingBottom: 1,
//             width: "100%",
//           }}
//         >
//           <Stack direction="row" alignItems="center" spacing={1}>
//             <Iconify icon="mdi:account" width={20} height={20} />
//             <Typography variant="body2" sx={{ fontWeight: 500 }}>
//               {news.author.firstName + " " + news.author.lastName}
//             </Typography>
//           </Stack>

//           <Stack
//             direction="row"
//             alignItems="center"
//             spacing={1}
//             sx={{
//               justifyContent: isMobile ? "flex-start" : "flex-end",
//               textAlign: isMobile ? "left" : "right",
//               mt: isMobile ? 1 : 0,
//             }}
//           >
//             <Iconify icon="mdi:calendar" width={20} height={20} />
//             <Typography
//               variant="body2"
//               sx={{ fontStyle: "italic", color: "gray" }}
//             >
//               Veröffentlicht am: {formatDate(news.createdAt)}
//             </Typography>
//           </Stack>
//         </Stack>
//       </Box>
//     </Card>
//   );
// };

// export default NewsItem;

import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Box,
  Stack,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import Iconify from "../../../components/iconify";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { serverURL } from "../../../app/api/axios/http.service";

const NewsItem = ({ news, isUnread }) => {
  console.log("news", news);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = () => {
    navigate(`/dashboard/news/${news._id}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("de-DE"); // 'de-DE' gives you dd.mm.yyyy format
  };

  const getImageSrc = () => {
    if (news?.imagePath) {
      const newsId = news._id;
      return `${serverURL()}/public/new/image/${newsId}`;
    }
    switch (news.imageDefaultPath) {
      case "1":
        return require("./NewsDialogComponents/1.jpg");
      case "2":
        return require("./NewsDialogComponents/2.jpg");
      case "3":
        return require("./NewsDialogComponents/3.jpg");
      default:
        return "/assets/images/praxis.jpg";
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        height: isMobile ? "auto" : "150px",
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "1px solid rgba(255, 255, 255, 0.3)",
        borderRadius: 0,
        cursor: "pointer",
        width: "100%",
        position: "relative",
      }}
      onClick={handleClick}
    >
      {!news?.viewUserStatus && (
        <Tooltip title="Ungelesen">
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              width: 12,
              height: 12,
              backgroundColor: "#3D9970",
              borderRadius: "50%",
            }}
          />
        </Tooltip>
      )}
      {news.favorite && (
        <Tooltip title="Favorite">
          <Iconify
            icon="mdi:star"
            width={30} // Size of the icon
            height={30}
            sx={{
              position: "absolute",
              top: 8,
              right: 20,
              color: "gold", // Color of the star icon
            }}
          />
        </Tooltip>
      )}
      <CardMedia
        component="img"
        sx={{
          width: isMobile ? "100%" : 150,
          height: isMobile ? "auto" : "100%",
          borderRadius: 0,
        }}
        image={getImageSrc()}
        alt={news.title}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <CardContent
          sx={{
            flex: "1 0 auto",
            paddingLeft: 2,
            paddingTop: 1,
            overflow: "hidden",
          }}
        >
          <Box sx={{ marginBottom: "auto" }}>
            <Typography
              component="div"
              variant="h6"
              sx={{
                fontSize: "1.1rem",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: isMobile ? "100%" : "85%",
              }}
            >
              {news.title}
            </Typography>

            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: isMobile ? "100%" : "85%",
              }}
            >
              {news.subtitle}
            </Typography>
          </Box>
        </CardContent>

        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "center"}
          justifyContent="space-between"
          sx={{
            paddingLeft: 2,
            paddingBottom: 1,
            width: "100%",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Iconify icon="mdi:account" width={20} height={20} />
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {news.author.firstName + " " + news.author.lastName}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              justifyContent: isMobile ? "flex-start" : "flex-end",
              textAlign: isMobile ? "left" : "right",
              mt: isMobile ? 1 : 0,
            }}
          >
            <Iconify icon="mdi:calendar" width={20} height={20} />
            <Typography
              variant="body2"
              sx={{ fontStyle: "italic", color: "gray" }}
            >
              Veröffentlicht am: {formatDate(news.createdAt)}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
};

export default NewsItem;
